import { Container, Typography, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { getDocument } from "../../Services/firestore";
import "./AdditionalFeatures.css";
import ChangeMobilityDialog from "./ChangeMobilityDialog";
import Header from "../../Components/Header";
import smartphone from "../../Assets/smartphone.jpeg";
import laptop from "../../Assets/laptop.jpeg";
import tablet from "../../Assets/tablet.jpeg";
import TablaCoberturaDispositivos from "./TablaCoberturaDispositivos/TablaCoberturaDispositivos";

const PAY_PERIODS = {
  month: "mensual",
  "3month": "trimestral",
  "6month": "semestral",
  year: "anual",
};

function AdditionalFeaturesDevices() {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const userUID = params.get("userUID");
  const ig = params.get("ig");
  const [device, setDevice] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (ig && userUID) calls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ig, userUID]);

  useEffect(() => {
    getDocument({
      docPath: `users/${userUID}`,
    }).then((data) => {
      setUserData(data.doc);
    });
  }, []);

  function handleCloseModal() {
    setOpenModal(false);
  }

  async function calls() {
    await getCustomerVehicle();
  }

  async function getCustomerVehicle() {
    try {
      const response = await getDocument({
        docPath: `/users/${userUID}/smartphones/${ig}/`,
      });
      setDevice(response.doc);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Header sx={{ height: "80px" }} />
      <Container className="main-container">
        <ChangeMobilityDialog
          userData={userData}
          vehicleID={ig}
          userUID={userUID}
          vehicle={device}
          isOpen={openModal}
          onClose={handleCloseModal}
        />
        <Container
          className="default-extras-container"
          style={{ display: "flex", padding: "0px" }}
        >
          {device ? (
            <>
              <VehicleData device={device} />

              <Button
                onClick={() =>
                  window.open(
                    `https://wa.me/525577460177?text=Hola,%20quiero%20cambiar%20el%20plan%20de%20mi%20${device.brand}%20${device.model}`
                  )
                }
                variant="contained"
                sx={{
                  width: "100%",
                  margin: "0 auto",
                  textTransform: "capitalize",
                }}
              >
                Cambiar Plan
              </Button>
              <TablaCoberturaDispositivos
                selectedMobility={device.plan}
                deviceValue={device.value}
              />

              <div className="subtotal-container subtotal-container--margin">
                <Typography
                  variant="body1"
                  style={{ fontSize: "1.05rem", fontWeight: "bold" }}
                >
                  Total:
                </Typography>
                <Typography variant="body1" style={{ fontSize: "0.95rem" }}>
                  $ {device.premium.toLocaleString("en-US")}
                  <p style={{ fontSize: "0.7rem", textAlign: "end" }}>
                    /{PAY_PERIODS[device.period]}
                  </p>
                </Typography>
              </div>
            </>
          ) : null}
        </Container>
      </Container>
    </>
  );
}

function VehicleData({ device }) {
  return (
    <>
      <h3
        style={{ textAlign: "center", margin: "0" }}
      >{`${device.brand} ${device.model}`}</h3>
      <Stack>
        {device && device.pictureDeviceFront ? (
          <img
            src={device.pictureDeviceFront}
            alt="imagen del auto"
            style={{
              width: "80%",
              margin: "0px auto 20px auto",
              textAlign: "center",
              borderRadius: "6px",
              maxHeight: device.type === "laptop" ? "400px" : "450px",
              maxWidth: device.type === "laptop" ? "600px" : "350px",
            }}
          />
        ) : (
          <img
            src={
              device?.type === "laptop"
                ? laptop
                : device.type === "tablet"
                ? tablet
                : smartphone
            }
            alt="imagen del auto"
            style={{
              width: "90%",
              margin: "0px auto 20px auto",
              textAlign: "center",
              borderRadius: "6px",
              maxHeight: device.type === "laptop" ? "400px" : "350px",
              maxWidth: device.type === "laptop" ? "400px" : "350px",
            }}
          />
        )}

        <Typography style={{ textAlign: "center" }}>
          Plan contratado:{" "}
          <span style={{ fontWeight: "bold" }}>
            {`${
              device.plan === 2
                ? "Súper Pro"
                : device.plan === 1
                ? "Pro"
                : "Básico"
            } `}
          </span>
        </Typography>
      </Stack>
    </>
  );
}

export default AdditionalFeaturesDevices;
