import { useReducer } from "react";

const roboInitialState = {
  currentLocation: "",
  currentAddress: "",
  deviceReport: null,
  vehicle: "",
  requiresMedicalAssistance: null,
  "911Report": "",
  accidentTimestamp: "",
  startReportTimestamp: "",
  isViolentEvent: null,
  factsDescription: "",
  accidentAddress: null,
  accidentLocation: {
    lat: "",
    lon: "",
  },
  phoneContact: "",
  source: "app",
  user: "",
  // field only for smartphone theft reports
  appTurnedOn: undefined,
};

function roboReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case "ON_LOAD":
      return {
        ...state,
        currentLocation: payload.currentLocation,
        deviceReport: payload.deviceReport,
        currentAddress: payload.currentAddress,
        user: payload.user,
        startReportTimestamp: payload.startReportTimestamp,
        accidentTimestamp: payload.accidentTimestamp,
      };

    case "SET_SECOND_PHONE":
      return {
        ...state,
        phoneContact: payload,
      };
    case "SET_CURRENT_POSITION":
      return {
        ...state,
        currentLocation: payload,
      };
    case "SET_ACCIDENT_TIMESTAMP":
      return {
        ...state,
        accidentTimestamp: payload,
      };

    case "SET_DEVICE_REPORT":
      return {
        ...state,
        deviceReport: payload,
      };
    case "SELECT_VEHICLE":
      return {
        ...state,
        vehicle: payload,
      };
    case "GET_MEDICAL_ASSISTANCE":
      console.log("siuu");
      return {
        ...state,
        requiresMedicalAssistance: payload,
      };
    case "REPORT_911":
      return {
        ...state,
        "911Report": payload,
      };
    case "APP_TURNED_ON":
      return {
        ...state,
        appTurnedOn: payload,
      };
    case "VIOLENT_EVENT":
      return {
        ...state,
        isViolentEvent: payload,
      };
    case "DESCRIPTION":
      return {
        ...state,
        factsDescription: payload,
      };
    case "ROBO_ADDRESS":
      return {
        ...state,
        accidentAddress: payload,
      };
    case "FACTS_POSITION":
      return {
        ...state,
        accidentLocation: payload,
      };
  }
}

export { roboReducer, roboInitialState };
