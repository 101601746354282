import postMessageInChannel from "./postMessageInChannel";

const webhook =
  "https://hooks.slack.com/services/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";

function postMessageInCustomerSupportChannel(messageBody) {
  return postMessageInChannel(webhook, messageBody);
}

export default postMessageInCustomerSupportChannel;
