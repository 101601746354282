// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB9vbtEAnjNgCQAM6U4mOQ8IfqsMs-53NE',
  authDomain: 'auto-clupp.firebaseapp.com',
  databaseURL: 'https://auto-clupp.firebaseio.com',
  projectId: 'auto-clupp',
  storageBucket: 'auto-clupp.appspot.com',
  messagingSenderId: '241778345447',
  appId: '1:241778345447:web:fe701be48174f4f5c9e942',
  measurementId: 'G-WFMGJ2RBE7',
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

