import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import useReadImage from "../../hooks/useReadImage";
import addPhotoIcon from "../../Assets/add-photo.png";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { logError } from "../../utils/index";

export default function PhotoCard({ uid, vid, imageType, setImage, type, photoUrl = "" }) {
  const { imgRef, inputImgRef, setInputImage, inputImage } = useReadImage();
  const theme = useTheme();
  const [isSavingImg, setIsSavingImg] = useState(false);

  useEffect(() => {
    if (inputImage && type === "vehicle") {
      uploadImageVehicle();
    } else if (inputImage && type === "odometer") {
      uploadImageOdometer();
    }
  }, [inputImage]);

  useEffect(() => {
    if (photoUrl && type === "vehicle") {
      setImage((prev) => ({
        ...prev,
        [imageType]: true,
      }));
    } else if (photoUrl && type === "odometer") {
      setImage((prev) => ({
        ...prev,
        odometer: {
          ...prev.odometer,
          kmTotal: true,
          pictureURL: true,
        },
      }));
    }
  }, [photoUrl]);

  async function uploadImageOdometer() {
    setIsSavingImg(true);
    try {
      const storageRef = ref(
        storage,
        `users/${uid}/vehicles/${vid}/odometer/IMG_${new Date().getTime()}.${inputImage.name
          .split(".")
          .pop()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, inputImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.error(error);
          logError({
            message: "Error al actualizar foto",
            error: error?.message,
            source: "Primeros datos de vehiculo",
            metadata: {
              userUid: uid || "No user",
              vehicleId: vid || "No vehicle",
              pictureType: imageType,
              type: type,
              userAgent: navigator?.userAgent,
              imageName: inputImage?.name,
            },
          });
          setIsSavingImg(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            setImage((prev) => ({
              ...prev,
              odometer: {
                pictureURL: downloadURL,
              },
            }));
            setIsSavingImg(false);
          });
        }
      );
    } catch (error) {
      console.error(error);
      logError({
        message: "Error al actualizar foto",
        error: error?.message,
        source: "Primeros datos de vehiculo",
        metadata: {
          userUid: uid || "No user",
          vehicleId: vid || "No vehicle",
          pictureType: imageType || "No type",
          type: type || "No type",
          userAgent: navigator?.userAgent,
          imageName: inputImage?.name,
        },
      });
      setIsSavingImg(false);
    }
  }

  const uploadImageVehicle = async () => {
    setIsSavingImg(true);
    try {
      const storageRef = ref(
        storage,
        `users/${uid}/vehicles/${vid}/${imageType}/${imageType}_${new Date().getTime()}.${inputImage.name
          .split(".")
          .pop()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, inputImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.error(error);
          logError({
            message: "Error al actualizar foto",
            error: error?.message,
            source: "Primeros datos de vehiculo",
            metadata: {
              userUid: uid || "No user",
              vehicleId: vid || "No vehicle",
              pictureType: imageType || "No type",
              type: type || "No type",
              userAgent: navigator?.userAgent,
              imageName: inputImage?.name,
            },
          });
          Swal.fire({
            text: "Error al subir foto, por favor intentalo más tarde",
            icon: "error",
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: "Ok",
          });
          setIsSavingImg(false);
        },
        async () => {
          const imgURL = await getDownloadURL(uploadTask.snapshot.ref);
          setImage((prev) => ({
            ...prev,
            [imageType]: imgURL,
          }));
          setIsSavingImg(false);
        }
      );
    } catch (error) {
      logError({
        error: error?.message,
        source: "Primeros datos de vehiculo",
        metadata: {
          description: "Error al subir foto de vehículo",
          userUid: uid || "No user",
          vehicleId: vid || "No vehicle",
          pictureType: imageType || "No type",
          type: type || "No type",
          userAgent: navigator?.userAgent,
          imageName: inputImage?.name,
        },
      });
      console.error(error);
    }
  };

  function alreadyImageSavedMessage() {
    Swal.fire({
      text: "El asegurado ya tiene esta foto guardada, sólo es posible subir la primera foto",
      icon: "info",
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: "Ok",
    });
  }

  if (photoUrl) {
    return (
      <Box
        onClick={alreadyImageSavedMessage}
        sx={{
          cursor: "pointer",
        }}
      >
        <img
          src={photoUrl}
          alt="imagen auto"
          style={{
            borderRadius: "10px",
            height: "120px",
            width: "120px",
            objectFit: "cover",
            cursor: "pointer",
            backgroundColor: "#fff",
            zIndex: 1,
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      {isSavingImg && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {inputImage ? (
        <img
          onClick={() => {
            inputImgRef.current.click();
          }}
          ref={imgRef}
          style={{
            zIndex: 1,
            height: "120px",
            width: "120px",
            borderRadius: "10px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          alt="imagen auto"
        />
      ) : (
        <Box
          sx={{
            height: "120px",
            width: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            borderRadius: "10px",
            cursor: "pointer",
            border: "1px dashed #000",
          }}
          onClick={() => {
            inputImgRef.current.click();
          }}
        >
          <img
            src={addPhotoIcon}
            alt="imagen auto"
            style={{
              height: "60px",
              width: "60px",
              objectFit: "cover",
              cursor: "pointer",
              backgroundColor: "#fff",
              zIndex: 1,
            }}
          />
        </Box>
      )}
      <input
        type="file"
        onChange={(e) => {
          setInputImage(e.target.files[0]);
        }}
        capture="camera"
        accept="image/png, image/jpeg, image/jpg"
        ref={inputImgRef}
        style={{
          width: "0.1px",
          height: "0.1px",
          opacity: "0",
          overflow: "hidden",
          position: "absolute",
          zIndex: "-1",
        }}
      />
    </Box>
  );
}