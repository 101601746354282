import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MobileHeader from "../../Components/MobileHeader";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useQueryParams from "../../hooks/useQueryParams";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Cluppcito from "../../Assets/Clupp_banderaMX.png";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import { doc, updateDoc } from "@firebase/firestore";
import { db } from "../../firebase";
import WhatsappService from "./WhatsappService";

const PersonalInformation = () => {
  const { user, isLoadingUser: isLoading } = useUser();
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [formData, setFormData] = useState({
    birthday: "",
    gender: "Masculino",
    zipCode: null,
  });

  const navigate = useNavigate();
  const query = useQueryParams();
  const uid = query.get("uid");
  const ig = query.get("ig");

  function rfcToDate(rfc) {
    let result = rfc.match(/^\w{4}(\w{2})(\w{2})(\w{2})/);
    let year = parseInt(result[1], 10) + 1900;
    if (year < 1950) year += 100;
    let month = parseInt(result[2], 10) - 1;
    let day = parseInt(result[3], 10);
    return new Date(year, month, day);
  }

  useEffect(() => {
    if (user) {
      const updatedFormData = { ...formData };
      if (user.rfc) updatedFormData.birthday = rfcToDate(user.rfc);
      if (user.gender) updatedFormData.gender = user.gender;
      if (user.zipCode) updatedFormData.zipCode = user.zipCode;
      setFormData(updatedFormData);
    }
  }, [user]);

  const handleChangeDate = (e) =>
    setFormData((prev) => ({ ...prev, birthday: e }));

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async () => {
    setIsSavingUser(true);

    try {
      if (!formData.birthday || !formData.gender || !formData.zipCode) {
        return Swal.fire({
          icon: "warning",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          text: `Es necesario que completes los datos antes de avanzar.`,
        });
      }
      const deviceRef = doc(db, `users/${uid}`);
      const updateInfo = {
        ...formData,
        birthday: {
          day: formData.birthday.getDate(),
          month: formData.birthday.getMonth() + 1,
          year: formData.birthday.getFullYear(),
        },
      };
      await updateDoc(deviceRef, { ...updateInfo });
      navigate(`/registro/gadgets/inicio?uid=${uid}&ig=${ig}`);
    } catch (err) {
      console.log("Error: ", err);
      return Swal.fire({
        icon: "error",
        title: "Error al cargar los datos",
        text: "Hubo un problema al cargar los datos. Por favor, inténtalo de nuevo más tarde.",
      });
    } finally {
      setIsSavingUser(false);
    }
  };

  return isLoading ? (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      <CircularProgress />
    </Stack>
  ) : (
    <>
      <MobileHeader user={user} showGradientHeader={false} />
      <Stack
        sx={{
          position: "relative",
          height: ["90vh", "100%"],
          width: ["95%", "50%"],
          margin: ["0px auto 40px auto", "0px auto 140px auto"],
        }}
      >
        <Box
          sx={{
            p: 2,
            marginTop: "20px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={Cluppcito}
            alt="cluppcito"
            width={"50%"}
            style={{ marginBottom: "10px" }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {`Conozcámonos mejor`}
          </Typography>
          <FormControl sx={{ marginBottom: "25px" }}>
            <FormLabel sx={{ color: "#000" }}>
              ¿En qué día te cantamos las mañanitas?
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateField
                sx={{ marginTop: "10px" }}
                label="Fecha de nacimiento"
                value={formData.birthday}
                onChange={handleChangeDate}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl
            sx={{
              width: "80%",
              marginBottom: "25px",
            }}
          >
            <FormLabel sx={{ color: "#000" }}>
              ¿Cuál es tu sexo biológico?
            </FormLabel>
            <Select
              sx={{
                marginTop: "10px",
              }}
              value={formData.gender}
              name="gender"
              onChange={handleChange}
            >
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Femenino"}>Femenino</MenuItem>
              <MenuItem value={"Prefiero no decir"}>Prefiero no decir</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: "80%",
            }}
          >
            <FormLabel sx={{ color: "#000" }}>
              ¿Cuál es tu código postal?
            </FormLabel>
            <TextField
              sx={{
                marginTop: "10px",
              }}
              label="Ej: 01000"
              variant="outlined"
              type="numer"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              inputProps={{ inputMode: "numeric" }}
            />
          </FormControl>

          <Stack
            sx={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              margin: "20px auto 0px auto",
            }}
          ></Stack>
        </Box>
      </Stack>
      <Stack
        sx={{
          position: "fixed",
          zIndex: 5,
          bottom: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: "0 -3px 20px 3px rgba(0, 0, 0, 0.1)",
        }}
      >
        <LoadingButton
          sx={{
            width: ["80%", "30%"],
            textTransform: "capitalize",
            fontSize: "22px",
          }}
          loading={isSavingUser}
          onClick={handleSubmit}
          variant={"contained"}
        >
          {"Continuar"}
        </LoadingButton>
      </Stack>
      <WhatsappService uid={uid} haveFooter={true} />
    </>
  );
};

export default PersonalInformation;
