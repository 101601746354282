import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Typography, Stack, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Swal from "sweetalert2";

import fetchVehicleByPlates from "../Services/vehicles/fetchVehicleByPlates";
import getPhoneContact from "../utils/getContactPhone";
import isVehicleInsured from "../utils/isVehicleInsured";
import Dialog from "../Components/Dialog";
import fetchUser from "../Services/users/fetchUser";
import { postMessageInClaimsChannel } from "../Services/slack";
import getReportMessageTemplate from "../utils/getReportMessageTemplate";

import getInsuranceStatusMessage from "../utils/getInsuranceStatusMessage";

const Report = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);

  const [plates, setPlates] = useState("");
  const [vehicle, setVehicle] = useState(undefined);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const navigate = useNavigate();

  // match only letters and numbers
  const hasInvalidCharacters = !/^[A-Za-z0-9]*$/i.test(plates);
  const hasInvalidLength = plates.length > 7 || plates.length < 4;

  const errorText =
    (hasInvalidLength || hasInvalidCharacters) &&
    "Ingresa unas placas válidas, sin guiones, ni espacios.";
  const platesInputHelperText = Boolean(errorText)
    ? errorText
    : "Ingresa las placas del vehículo sin guiones, ni espacios";

  const [isInsured] = isVehicleInsured(vehicle);
  const insuranceStatusMessage = getInsuranceStatusMessage(vehicle);

  async function handleOnSubmit(event) {
    event.preventDefault();

    if (Boolean(errorText)) {
      Swal.fire("", "Ingresa unas placas válidas", "warning");
      return;
    }

    if (plates.trim() === "PERMISO") {
      return Swal.fire(
        "Ups!",
        "Verifica que las placas sean correctas",
        "error"
      );
    }

    setIsLoading(true);
    const { vehicle, error } = await fetchVehicleByPlates(plates.trim());
    if (error) {
      if (error === "not/found") {
        Swal.fire({
          text: "Vehículo no asegurado con clupp, verifica que las placas sean correctas",
          icon: "warning",
          confirmButtonColor: "#00bbee",
        });
      } else {
        Swal.fire("", "Algo salió mal, inténtalo nuevamente", "error");
      }
      setIsLoading(false);
      return;
    }
    setVehicle(vehicle);
    setIsLoading(false);
  }

  function handleOnPlatesInputChange(event) {
    const value = event.target.value.toUpperCase();
    const hasInvalidCharacters = /-|\/|\s|_|\./g.test(value);
    if (value.length > 7 || hasInvalidCharacters) {
      return;
    }

    setPlates(value);
  }

  function handleCallInsurance() {
    const phoneNumber = getPhoneContact();
    window.open(`tel:${phoneNumber}`, "__blank");
  }

  async function handleCreateClaimAlert() {
    const userUid = vehicle.uid;
    setIsReportLoading(true);
    try {
      const user = await fetchUser(userUid);

      const messageBody = getReportMessageTemplate({ user, vehicle });

      await postMessageInClaimsChannel(messageBody);

      toggleConfirmModal();

      Swal.fire({
        title: "Siniestro reportado",
        text: "Nos pondremos en contacto con el asegurado a la brevedad",
        icon: "success",
        confirmButtonColor: "#00bbee",
      }).then((result) => {
        if (result.isConfirmed) {
          // reload the page
          navigate(0);
        }
      });
    } catch (error) {
      toggleConfirmModal();

      Swal.fire(
        "",
        "Algo salió mal, inténtalo nuevamente. Si el problema persiste, lláma a la aseguradora",
        "error"
      );
    } finally {
      setIsReportLoading(false);
    }
  }

  function toggleConfirmModal() {
    setIsConfirmModalOpen((state) => !state);
  }
  return (
    <Stack spacing={2} sx={{ p: 4, height: "100%" }}>
      <Dialog
        title="Reportar siniestro"
        content={
          <Stack>
            <Typography>
              ¿Estás seguro de querer reportar un siniestro? Trataremos de
              comunicarnos con el asegurado.
            </Typography>
          </Stack>
        }
        Actions={
          <Stack direction="row" spacing={2}>
            <Button
              onClick={toggleConfirmModal}
              color="inherit"
              sx={{ textTransform: "capitalize" }}
            >
              No, cancelar
            </Button>
            <LoadingButton
              loading={isReportLoading}
              onClick={handleCreateClaimAlert}
              sx={{ textTransform: "capitalize" }}
              disableElevation
              variant="contained"
            >
              Si, reportar
            </LoadingButton>
          </Stack>
        }
        isOpen={isConfirmModalOpen}
        onClose={toggleConfirmModal}
      />
      <Typography variant="h5" sx={{ textAlign: "center", userSelect: "none" }}>
        Reporte de siniestro
      </Typography>

      {Boolean(vehicle) ? (
        <Stack spacing={2} sx={{ height: "100%" }}>
          {vehicle?.pictureCarFront && (
            <Stack
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                height: "260px",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  boxShadow: "black 0px 0px 1px",
                  width: "100%",
                  height: "100%",
                }}
                src={vehicle?.pictureCarFront}
              />
            </Stack>
          )}
          <Stack spacing={1}>
            <Typography variant="h6" sx={{ userSelect: "none" }}>
              {vehicle?.brand} {vehicle?.model} {vehicle?.year}
            </Typography>

            <Typography
              sx={{
                fontWeight: 700,
                color: isInsured ? "primary.main" : "error.main",
                userSelect: "none",
              }}
            >
              {insuranceStatusMessage}
            </Typography>
            {isInsured && (
              <Typography variant="body2">
                Los ocupantes del vehículo cuentan con cobertura de gastos
                médicos mayores en hospital privado. Si requieren atención
                médica, dar clic en "Llamar al seguro" para conocer el hospital
                más cercano.
              </Typography>
            )}
          </Stack>
          <Stack spacing={3} justifyContent="center">
            <Button
              disabled={!isInsured}
              onClick={toggleConfirmModal}
              size="large"
              variant="outlined"
              sx={{ textTransform: "none" }}
            >
              Reportar siniestro
            </Button>
            <Button
              disabled={!isInsured}
              size="large"
              onClick={handleCallInsurance}
              disableElevation
              sx={{ textTransform: "none" }}
              variant="contained"
              color="primary"
            >
              Llamar al seguro
            </Button>
          </Stack>
        </Stack>
      ) : (
        <form
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onSubmit={handleOnSubmit}
        >
          <Stack spacing={2}>
            <Typography>Ingresa las placas del vehículo</Typography>
            <TextField
              inputProps={{
                autoCapitalize: "words",
              }}
              value={plates}
              onChange={handleOnPlatesInputChange}
              error={plates.length > 0 && Boolean(errorText)}
              required
              size="small"
              label="Placas"
              helperText={platesInputHelperText}
            />
            <LoadingButton
              size="medium"
              fullWidth={false}
              sx={{
                textTransform: "capitalize",
              }}
              color="primary"
              variant="contained"
              disableElevation
              disabled={isLoading || Boolean(errorText)}
              loading={isLoading}
              type="submit"
            >
              Continuar
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Stack>
  );
};

export default Report;
