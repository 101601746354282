import postMessageInClaimsChannel from "../Services/slack/postMessageInClaimsChannel";
import { addDocument } from "../Services/firestore";
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";

async function postMessageAndComment(userUID, messageSlack, messageAdmin) {
  const ref = doc(db, `users/${userUID}`);

  const docSnap = await getDoc(ref);

  let isTestCustomer = false;

  if (docSnap.exists()) {
    isTestCustomer = docSnap.data()?.clientStatus === 5;
  }

  postMessageInClaimsChannel(
    {
      text: messageSlack,
    },
    isTestCustomer
  );

  addDocument(`users/${userUID}/comments`, {
    comment: messageAdmin,
    timestamp: Date.now(),
  });
}

export { postMessageAndComment };
