import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";

function ExternalRedirect({ href = "" }) {
  useEffect(() => {
    window.location.href = href; // Replace with the desired external URL
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "1.5rem",
        width: "100%",
        gap: "5rem",
      }}
    >
      <Box
        sx={{
          width: "90%",
        }}
      >
        <Typography
          variant="h5"
          style={{ textAlign: "center", fontSize: ["1rem", "1.5rem"] }}
        >
          Estamos enviandote a la página solicitada...
        </Typography>
        <LinearProgress sx={{ mt: 2 }} variant="indeterminate" />

        <Typography sx={{ mt: 2 }} variant="body1">
          Si no se redirige automáticamente, haga clic{" "}
          <a href={href} target="_blank" rel="noreferrer">
            aquí:
          </a>
        </Typography>
      </Box>
    </div>
  );
}

export default ExternalRedirect;
