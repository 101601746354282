import Dialog from "../../Components/Dialog";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getDocuments } from "../../Services/firestore";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ElegirMovilidad from "./ElegirMovilidad";
import Swal from "sweetalert2";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { addMonths, isAfter, differenceInDays } from "date-fns";
import { useTheme } from "@mui/material";
import logError from "../../utils/logError";

function getNextOdometerPictureDate(records, vehicle) {
  function getKmPerMonth(latestRecord, oldestRecord, totalDays) {
    const averageKmPerMonth =
      ((latestRecord.kmTotal - oldestRecord.kmTotal) / totalDays) * 30;

    return Math.ceil(averageKmPerMonth);
  }

  function matchesMobilityPlan(km, mobility) {
    const stats = {
      0: [0, 250],
      1: [251, 500],
      2: [501, 1200],
      3: [1201, 1600],
    };
    // get the ranges
    // const min = stats[mobility][0];
    const max = stats[mobility][1];
    // return false if the assured drives more km than the ones covered on their plan
    // when the assured drives less km, its a match => return true
    return km <= max;
  }

  const validRecords = records.filter((record) => !record.deleted);
  const recordsCount = validRecords.length;

  function displayNextPictureDate(date) {
    if (isAfter(date, new Date())) {
      return true;
    }
    return false;
  }
  if (recordsCount === 0) {
    return false;
  }
  if (recordsCount === 1) {
    const lastRecordDate = new Date(validRecords[0].timestamp);
    const nextRecordDate = addMonths(lastRecordDate, 1);

    return displayNextPictureDate(nextRecordDate);
  }

  if (recordsCount === 2) {
    const daysPassedBetweenRecords = differenceInDays(
      new Date(validRecords[0].timestamp),
      new Date(validRecords[1].timestamp)
    );
    const kmDriven = getKmPerMonth(
      validRecords[0],
      validRecords[1],
      daysPassedBetweenRecords
    );

    const matchesPlan = matchesMobilityPlan(kmDriven, vehicle.mobility);

    let nextRecordDate = addMonths(
      new Date(validRecords[0].timestamp),
      matchesPlan ? 3 : 1
    );

    return displayNextPictureDate(nextRecordDate);
  }

  if (recordsCount >= 3) {
    const daysPassedBetweenRecords = differenceInDays(
      new Date(validRecords[0].timestamp),
      new Date(validRecords[2].timestamp)
    );
    // console.log(validRecords[0], validRecords[2]);
    const kmDriven = getKmPerMonth(
      validRecords[0],
      validRecords[2],
      daysPassedBetweenRecords
    );

    const matchesPlan = matchesMobilityPlan(kmDriven, vehicle.mobility);

    let nextRecordDate = addMonths(
      new Date(validRecords[0].timestamp),
      matchesPlan ? 6 : 1
    );
    // console.log({
    //   daysPassedBetweenRecords,
    //   kmDriven,
    //   matchesPlan,
    //   mobility: vehicle.mobility,
    //   nextRecordDate,
    // });
    return displayNextPictureDate(nextRecordDate);
  }
}

function getPeriodicityLabel(periodicity) {
  switch (periodicity) {
    case "month":
      return "al mes";
    case "3month":
      return "cada 3 meses";
    case "6month":
      return "cada 6 meses";
    case "year":
      return "al año";
    default:
      return "";
  }
}

const promedyAverageKm = {
  0: [0, 250],
  1: [250, 500],
  2: [500, 1200],
  3: [1200, 1600],
};

const mobilityDescription = (n) => {
  switch (n) {
    case 0:
      return "mínima";
    case 1:
      return "reducida";
    case 2:
      return "normal";
    case 3:
      return "intensiva";
    default:
      break;
  }
};

const getOdometer = async (userUID, vehicleID) => {
  const q = query(
    collection(db, `users/${userUID}/vehicles/${vehicleID}/odometer`),
    where("deleted", "==", false),
    orderBy("timestamp", "desc"),
    limit(3)
  );

  const querySnapshot = await getDocs(q);
  const odometersUrl = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return odometersUrl;
};

export default function ChangeMobilityDialog({
  isOpen,
  onClose,
  vehicle,
  userUID,
  vehicleID,
  userData,
}) {
  const [odometers, setOdometers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [quotations, setQuotations] = useState();
  const [vehiclesExtras, setVehiclesExtras] = useState([]);
  const [selectedMobility, setSelectedMobility] = useState({
    mobility: undefined,
    premium: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingData, setIsSendingData] = useState(false);
  const theme = useTheme();

  let messageWhats = `Hola, soy ${
    userData?.firstName?.split(" ")[0]
  }. Quiero actualizar la movilidad de mi vehículo ${vehicle?.brand} ${
    vehicle?.year
  } a la movilidad ${mobilityDescription(selectedMobility?.mobility)}`;
  const whatsappUrl = `https://wa.me/5215577460177?text=${messageWhats}.`;

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      Promise.all([
        getDocuments({
          collectionPath: `users/${userUID}/vehicles/${vehicleID}/extras`,
        })
          .then((data) => data.json())
          .then((data) => {
            const extras = data.docs.map((doc) => {
              return doc.doc;
            });
            setVehiclesExtras(extras);
          }),
        getOdometer(userUID, vehicleID)
          .then((data) => {
            setOdometers(data);
          })
          .catch((error) => {
            console.log(error);
          }),
        onMakeQuotation().then((data) => {
          if (data?.success === false) {
            logError({
              source: "Cambio de movilidad",
              error: data?.message,
              metadata: {
                description: "Error al cotizar las movilidades",
                user: userData,
                vehicle: vehicle,
              },
            });
            Swal.fire({
              icon: "error",
              text: "Ha ocurrido un error al intentar generar la cotización",
              confirmButtonText: "Ok",
              confirmButtonColor: theme.palette.primary.main,
            }).then(() => {
              onClose();
            });
            setQuotations(null);
          }
          setQuotations(data);
        }),
      ])
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [userUID, vehicleID, isOpen]);

  function calculateKm() {
    if (odometers.length === 3) {
      const totalKm = odometers[0].kmTotal - odometers[2].kmTotal;
      const totalDays =
        (new Date(odometers[0].timestamp).getTime() -
          new Date(odometers[2].timestamp).getTime()) /
        (1000 * 60 * 60 * 24);

      return Math.round(30 * (totalKm / totalDays));
    }
  }

  async function onMakeQuotation() {
    const dataToQuote = {
      brand: vehicle?.brand,
      model: vehicle?.model,
      year: vehicle?.year,
      type: vehicle?.type,
      period: vehicle?.period,
      mobility: selectedMobility?.mobility,
      value: vehicle?.value,
      extras: [...vehiclesExtras],
    };

    const response = await fetch(
      `https://clupp-api.web.app/v1/clupp/users/${userUID}/vehicles/${vehicleID}/quote-mobility-insurance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToQuote),
      }
    );

    return response.json();
  }

  function onCloseDialog() {
    setOpenDialog(false);
  }

  function onOpenDialog() {
    const promedyKm = calculateKm();
    if (odometers.length < 3) {
      Swal.fire({
        icon: "warning",
        text: "Debes registrar al menos 3 fotos de tu odómetro para poder actualizar la movilidad de tu vehículo.",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }

    if (!getNextOdometerPictureDate(odometers, vehicle)) {
      Swal.fire({
        icon: "warning",
        text: "Debes tomar la foto pendiente de tu odómetro para poder cambiar de movilidad.",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }

    const isUpgradingMobility = vehicle.mobility < selectedMobility.mobility;

    if (isUpgradingMobility) {
      setOpenDialog(true);
      return;
    }

    if (typeof vehicle?.lastMobilityCalculated === "undefined") {
      Swal.fire({
        icon: "warning",
        text: "No puedes cambiar de movilidad, aún no tenemos información suficiente sobre tus hábitos de conducción.",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }

    // The customer should have less than the amount of minimum km to be able to downgrade its plan
    const minKm = promedyAverageKm[selectedMobility.mobility][1];
    if (promedyKm > minKm) {
      Swal.fire({
        icon: "warning",
        text: `Tu promedio de kilómetros recorridos al mes es de ${promedyKm.toLocaleString(
          "en-US",
          { minimumFractionDigits: 0 }
        )} km, para poder cambiar la movilidad a ${mobilityDescription(
          selectedMobility.mobility
        )} debes recorrer menos de ${promedyAverageKm[
          selectedMobility.mobility
        ][1].toLocaleString("en-US", {
          minimumFractionDigits: 0,
        })} km al mes.`,
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }

    setOpenDialog(true);
  }

  function confirmQuotation() {
    if (selectedMobility.mobility >= 0) {
      setIsSendingData(true);
      const quotedData = {
        mobility: selectedMobility.mobility,
        period: vehicle.period,
      };

      fetch(
        `https://clupp-api.web.app/v1/clupp/users/${userUID}/vehicles/${vehicleID}/updateMobility`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(quotedData),
        }
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.success === true) {
            onCloseDialog(false);
            Swal.fire({
              icon: "success",
              text: "Movilidad actualizada correctamente, te hemos enviado tu nueva carátula de coberturas a tu correo. El correo puede tardar hasta 2 minutos en llegar",
              confirmButtonText: "Ok",
              confirmButtonColor: theme.palette.primary.main,
            }).then(() => {
              window.location.reload();
            });
          } else if (data.success === false) {
            onCloseDialog(false);
            Swal.fire({
              icon: "error",
              html: `<div>Algo salió mal. Contácta al departamento de atención a clientes para actualizar tu movilidad.<br/><button class="whatsapp-button"><span></span><a href=${whatsappUrl} rel="noreferrer" target="__blank">
                      Enviar mensaje
                    </a></button></div>`,
              confirmButtonText: "Ok",
              confirmButtonColor: theme.palette.primary.main,
            });
          }
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsSendingData(false);
        });
    } else {
      return undefined;
    }
  }

  function getTotalCostChange() {
    const totalPremium =
      quotations?.quotation.periods[vehicle.period]?.[
        `premium${selectedMobility?.mobility}`
      ].total +
      quotations?.quotation.periods[vehicle.period].extras.reduce(
        (sum, extra) => {
          return sum + extra.premium;
        },
        0
      );

    return `¿Confirmas que deseas cambiar de movilidad ${mobilityDescription(
      vehicle?.mobility
    )} a movilidad ${mobilityDescription(
      selectedMobility?.mobility
    )}, quedando tu pago ${getPeriodicityLabel(
      vehicle.period
    )} en $${totalPremium.toLocaleString("en-MX", {
      useGruping: true,
    })}`;
  }

  return (
    <>
      <Dialog
        isOpen={openDialog}
        onClose={onCloseDialog}
        textContent={openDialog && getTotalCostChange()}
        sx={{ textAlign: "center" }}
        Actions={
          <>
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={onCloseDialog}
              sx={{ textTransform: "capitalize" }}
            >
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              onClick={confirmQuotation}
              loading={isSendingData}
              sx={{ textTransform: "capitalize" }}
            >
              Aceptar
            </LoadingButton>
          </>
        }
      />
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        content={
          isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <ElegirMovilidad
              paymentPeriod={vehicle?.period}
              amounts={quotations}
              selectedMobility={selectedMobility}
              setSelectedMobility={setSelectedMobility}
              vehicleData={vehicle}
              vehicleType={vehicle?.type}
              vehicle={vehicle}
              whatsappUrl={whatsappUrl}
              isLoading={isLoading}
            />
          )
        }
        title="Elige la movilidad a la que quieres cambiar"
        textContent="Los costos incluyen los extras que tengas contratados."
        Actions={
          <>
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={onClose}
              sx={{ textTransform: "capitalize" }}
            >
              Cancelar
            </Button>
            <Button
              disabled={selectedMobility.mobility === -1}
              variant="contained"
              onClick={onOpenDialog}
              sx={{ textTransform: "capitalize" }}
            >
              Aceptar
            </Button>
          </>
        }
      />
    </>
  );
}
