import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";

import { format } from "date-fns";
import { es } from "date-fns/locale";
import { getContactPhone } from "../../utils";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function ClaimCard({
  claimId,
  accidentTimestamp,
  isActive = false,
  type = [],
  assistanceType = undefined,
  finalConclusion = undefined,
  typeAccident = "",
  reportTimestamp,
  status,
  providerTrackingURL = "",
}) {
  const containsVialAsistance = type.some((type) => type === "vialAssistance");
  const containsVehicleAccident = type.some(
    (type) => type === "vehicleAccident"
  );
  const whatappMessage = `https://wa.me/521${getContactPhone()}?text=Hola%2C%20quisiera%20dar%20seguimiento%20al%20reporte%20${claimId}`;
  const isCanceled = [
    "Cancelado sin contar dentro de las asistencias incluídas",
    "Cancelado contando dentro de las asistencias incluídas",
  ].includes(finalConclusion);

  const shouldDisplayAssistanceTrackingLink =
    isActive && Boolean(providerTrackingURL);
  return (
    <Accordion
      sx={{
        background: isActive ? "inherit" : "#efefef",
        color: isActive ? "inherit" : "gray",
        boxShadow:
          "0px 2px 0px -3px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{claimId}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Typography>
            <b> Estado:</b> {isActive ? "Abierto" : "Cerrado"}{" "}
            {isCanceled ? "(Cancelado)" : ""}
          </Typography>
          {isActive && Boolean(status) && (
            <Typography>
              <b> Fase:</b> {status}
            </Typography>
          )}
          <Typography>
            <b> Fecha de reporte:</b>
            <br /> {formatTimestamp(reportTimestamp)}
          </Typography>
          <Typography>
            <b> Fecha de accidente:</b>
            <br /> {formatTimestamp(accidentTimestamp)}
          </Typography>
          <Typography>
            <b> Tipo de evento:</b>
            <br /> {type.map(formatClaimType).join(", ")}{" "}
            {containsVialAsistance && (
              <>
                {typeof formatVialAssistanceType(assistanceType) === "string" &&
                  formatVialAssistanceType(assistanceType).includes(" ") && (
                    <br />
                  )}
                {`(${formatVialAssistanceType(assistanceType)})`}
              </>
            )}
            {containsVehicleAccident && (
              <>
                {typeAccident.includes(" ") && <br />}
                {`(${typeAccident})`}
              </>
            )}
          </Typography>

          {shouldDisplayAssistanceTrackingLink && (
            <Box>
              <a
                style={{ textDecoration: "none", width: "100%" }}
                href={providerTrackingURL}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  sx={{
                    textTransform: "none",
                    width: "100%",
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Ver seguimiento
                </Button>
              </a>
            </Box>
          )}

          {!isCanceled && isActive === true && (
            <Box>
              <a
                style={{ textDecoration: "none", width: "100%" }}
                href={whatappMessage}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    backgroundColor: "#128c7e",
                  }}
                  variant="contained"
                  startIcon={<WhatsAppIcon />}
                >
                  Dar seguimiento
                </Button>
              </a>
            </Box>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

function formatClaimType(type) {
  const CLAIM_TYPES = {
    vialAssistance: "Asistencia vial",
    vehicleAccident: "Accidente vehicular",
    personalAccident: "Accidente personal",
    carTheft: "Robo de auto",
    cellphoneTheft: "Robo de celular",
  };
  return CLAIM_TYPES[type];
}

function formatVialAssistanceType(assistance) {
  const ASSISTANCE_TYPES = {
    locksmith: "Envío de cerrajero",
    tow: "Grúa",
    battery: "Paso de corriente",
    gasoline: "Envío de gasolina",
    tire: "Cambio de llanta",
  };

  return ASSISTANCE_TYPES[assistance];
}

function formatTimestamp(timestamp) {
  if (!timestamp) return "";

  const date = new Date(timestamp);

  return format(date, "dd 'de' MMMM 'de' yyyy ", { locale: es });
}

export default ClaimCard;
