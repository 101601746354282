import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import es from "date-fns/locale/es";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Button, CircularProgress, Menu, useTheme } from "@mui/material";
import { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import Swal from "sweetalert2";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import ExpedienteDigitalForm from "./ExpedienteDigitalForm";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "../../Components/Dialog";
import { LoadingButton } from "@mui/lab";

export default function CustomizedTimeline({
  events = [],
  setSelectedEvent,
  fetchMoreDataPagination,
  isMorePagination,
  userId,
  startDateInsurance = new Date(),
}) {
  const [selectedEventState, setSelectedEventState] = useState({});
  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeletingEvent, setIsDeletingEvent] = useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event, selectedEvent) => {
    setAnchorEl(event.currentTarget);
    setSelectedEventState(selectedEvent);
  };

  function openFiles() {
    setSelectedEvent(selectedEventState);
    handleClose();
  }
  function openDialog() {
    setIsOpenFormDialog(true);
    handleClose();
  }

  function handleCloseDialog() {
    setIsOpenFormDialog(false);
  }

  function handleCloseConfirmDialog() {
    setIsOpenConfirmDialog(false);
  }
  async function deleteEvent() {
    setIsDeletingEvent(true);
    const saveData = {
      deleted: true,
      timestampLastUpdate: new Date().getTime(),
    };
    const docRef = doc(
      db,
      `users/${userId}/vehicles/${selectedEventState.vid}/events`,
      selectedEventState?.id
    );
    try {
      await updateDoc(docRef, saveData);
      handleCloseConfirmDialog();
      Swal.fire({
        icon: "success",
        text: "Se ha eliminado el evento exitosamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Se produjo un error al intentar guardar los datos, intentalo nuevamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
    } finally {
      setIsDeletingEvent(false);
    }
  }

  return (
    <>
      <ExpedienteDigitalForm
        handleClose={handleCloseDialog}
        isOpen={isOpenFormDialog}
        userId={userId}
        vehicleId={selectedEventState?.vid}
        eventData={selectedEventState}
        eventId={selectedEventState?.id}
        variant="update"
      />
      <MenuEvent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        openFiles={openFiles}
        openEditEvent={openDialog}
        openConfirmDialog={() => setIsOpenConfirmDialog(true)}
      />
      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        onClose={handleCloseConfirmDialog}
        deleteEvent={deleteEvent}
        isLoading={isDeletingEvent}
      />
      <Timeline
        position="alternate"
        sx={{
          paddingBottom: "20px",
          "&.MuiTimeline-root": { padding: "6px 14px" },
        }}
      >
        <InfiniteScroll
          dataLength={events.length}
          next={fetchMoreDataPagination}
          hasMore={isMorePagination}
          loader={
            <Box
              sx={{ width: "100%", display: "grid", justifyContent: "center" }}
            >
              <Box sx={{ padding: "10px" }}>
                <CircularProgress />
              </Box>
            </Box>
          }
        >
          {events.map((item, index) => (
            <>
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align={index % 2 === 0 ? "right" : "left"}
                  variant="body2"
                  color="text.secondary"
                >
                  <Box>
                    {format(
                      new Date(item?.timestamp || new Date()),
                      "dd 'de' MMMM 'del' yyyy",
                      {
                        locale: es,
                      }
                    )}
                  </Box>
                  {item?.editable === true && (
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ textTransform: "none", margin: "8px 0" }}
                      onClick={(e) => handleClick(e, item)}
                      endIcon={<ExpandMoreIcon />}
                      // onClick={() => setSelectedEvent(item)}
                    >
                      Ver más
                    </Button>
                  )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot
                    sx={{ background: index % 2 === 0 ? "#00bbee" : "#0058f4" }}
                  ></TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ fontSize: "18px" }}
                  >
                    {item?.event}
                  </Typography>
                  <Typography>
                    {item?.description || "Sin descripción"}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </>
          ))}
          {!isMorePagination && (
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align={events.length % 2 === 0 ? "right" : "left"}
                variant="body2"
                color="text.secondary"
              >
                {format(startDateInsurance || new Date(), "dd 'de' MMMM 'del' yyyy", {
                  locale: es,
                })}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ background: "#00ca7d" }}>
                  <SportsScoreIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ marginTop: "14px" }}>
                <Box>
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ fontSize: "18px" }}
                  >
                    Contratación
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          )}
        </InfiniteScroll>
      </Timeline>
    </>
  );
}

function MenuEvent({
  anchorEl,
  open,
  handleClose,
  openFiles,
  openEditEvent,
  openConfirmDialog,
}) {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuList>
        <MenuItem onClick={openFiles}>
          <ListItemIcon>
            <PermMediaIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Ver archivos</ListItemText>
        </MenuItem>
        <MenuItem onClick={openEditEvent}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Editar evento</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          openConfirmDialog()
          handleClose()
        }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Eliminar evento</ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function ConfirmDialog({ isOpen, onClose, deleteEvent, isLoading }) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      content={
        <Typography sx={{ color: "#000" }}>
          Deseas eliminar este evento de tu expediente digital
        </Typography>
      }
      Actions={
        <>
          <Button onClick={onClose} variant="outlined" sx={{textTransform: "none"}} >Cancelar</Button>
          <LoadingButton variant="contained" sx={{textTransform: "none"}} onClick={deleteEvent} loading={isLoading}>
            Confirmar
          </LoadingButton>
        </>
      }
    />
  );
}
