import { Box, Stack, Typography } from "@mui/material";
import cluppLogo from "../Assets/LogoCLUP.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";

export default function Footer({
  whatsAppMessage = "https://wa.me/5215577460177",
}) {
  return (
    <footer
      style={{
        background: "#00BBEE",
        padding: "30px 20px 120px 20px",
        display: "grid",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          maxWidth: "500px",
          margin: "0 auto",
          display: "grid",
          justifyContent: "center",
          gap: "20px",
          "& p, a": { color: "#fff" },
        }}
      >
        <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
          Muévete seguro. <b>Siempre.</b>
        </Typography>
        <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
          Consulta nuestro{" "}
          <a
            href="https://auto-clupp.web.app/privacy/aviso_privacidad_vigente.pdf"
            target="_blank"
            rel="noreferrer"
          >
            aviso de privacidad
          </a>{" "}
          y nuestros{" "}
          <a
            href="https://auto-clupp.web.app/privacy/condiciones_uso_app_clupp.pdf"
            target="_blank"
            rel="noreferrer"
          >
            términos de uso.
          </a>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ width: "66px" }} src={cluppLogo} alt="logo clupp" />
          <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
            &nbsp;
            {new Date().getFullYear()}. Todos los derechos reservados
          </Typography>
        </Box>
      </Stack>
      <Stack
        sx={{
          maxWidth: "500px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "25px",
          "& svg": { color: "#00BBEE", width: "80%", marginTop: "-2px" },
          "& p, a": { color: "#fff" },
        }}
      >
        <Box
          sx={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            background: "#fff",
            display: "grid",
            placeItems: "center",
          }}
        >
          <a
            href="https://www.facebook.com/cluppseguro"
            target="_blank"
            rel="noreferrer"
            style={{ display: "grid", placeItems: "center" }}
          >
            <FacebookIcon />
          </a>
        </Box>
        <Box
          sx={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            background: "#fff",
            display: "grid",
            placeItems: "center",
          }}
        >
          <a
            href="https://twitter.com/cluppseguro"
            target="_blank"
            rel="noreferrer"
            style={{ display: "grid", placeItems: "center" }}
          >
            <TwitterIcon />
          </a>
        </Box>
        <Box
          sx={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            background: "#fff",
            display: "grid",
            placeItems: "center",
          }}
        >
          <a
            href="https://www.instagram.com/cluppseguro/"
            target="_blank"
            rel="noreferrer"
            style={{ display: "grid", placeItems: "center" }}
          >
            <InstagramIcon />
          </a>
        </Box>
        <Box
          sx={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            background: "#fff",
            display: "grid",
            placeItems: "center",
          }}
        >
          <a
            style={{ display: "grid", placeItems: "center" }}
            href={whatsAppMessage}
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppIcon />
          </a>
        </Box>
      </Stack>
    </footer>
  );
}
