import { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Stack,
  useTheme,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LoadingButton from "@mui/lab/LoadingButton";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { es } from "date-fns/locale";
import Swal from "sweetalert2";
import { logError } from "../../utils";

export default function UpdateUserData() {
  const theme = useTheme();
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const userUID = params.get("userUID");
  const agentUID = params.get("agentUID");
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState();
  const [originalFetchedData, setOriginalFetchedData] = useState(null);
  const [agentDoc, setAgentDoc] = useState(null);
  const [updates, setUpdates] = useState({
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
    gender: undefined,
    birthday: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
    zipCode: undefined,
    rfc: undefined,
  });
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    gender: "",
    birthday: {
      day: "",
      month: "",
      year: "",
    },
    zipCode: "",
    rfc: "",
  });

  useEffect(() => {
    async function getAgentData() {
      const agentDocRef = doc(db, `agentProspects/${agentUID}`);
      const docSnap = await getDoc(agentDocRef);

      if (docSnap.exists()) {
        setAgentDoc(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }

    getAgentData();
  }, [agentUID]);

  useEffect(() => {
    const docRef = doc(db, `users/${userUID}`);
    setIsFetchingData(true);

    const unsubscribe = onSnapshot(docRef, async (doc) => {
      if (!doc.exists()) {
        setIsFetchingData(false);
        Swal.fire({
          icon: "error",
          text: "No se ha encontrado la información del usuario",
        });
        return;
      }
      const data = doc.data();
      setOriginalFetchedData({ ...data });
      setUserData((prev) => ({
        ...prev,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        gender: data.gender,
        birthday: {
          day: data?.birthday?.day,
          month: data?.birthday?.month,
          year: data?.birthday?.year,
        },
        zipCode: data?.zipCode,
        rfc: data.rfc,
      }));
      setIsFetchingData(false);
    });
    return unsubscribe;
  }, [userUID]);

  async function addComment() {
    await addDoc(collection(db, `users/${userUID}/comments`), {
      comment: `[Mensaje automático] El agente ${
        agentDoc?.email ? agentDoc?.email : ""
      } con uid ${agentUID} ha actualizado los siguientes datos: 
    ${
      updates?.firstName ? 
      `Nombre: ${updates?.firstName}` : ""
    }
    ${updates?.lastName ? 
      `Apellido: ${updates?.lastName}` : ""}
    ${
      updates?.phone ? 
      `Teléfono: ${updates?.phone}` : ""
    }
    ${updates?.email ? 
      `Correo: ${updates?.email}` : ""}
    ${
      updates?.gender ? 
      `Genero: ${updates?.gender}` : ""
    }
    ${
      updates?.birthday?.day &&
      updates?.birthday?.month &&
      updates?.birthday?.year
        ? `Fecha de nacimiento: ${updates?.birthday?.day}/${updates?.birthday?.month}/${updates?.birthday?.year}`
        : ""
    }
    ${updates?.zipCode ? `Código postal: ${updates?.zipCode}` : ""}
    ${
      updates?.rfc ? `RFC: ${updates?.rfc}` : ""
    }`,
      timestamp: new Date().getTime(),
    });
  }

  function onChange(e) {
    let value;
    if (e.target.name === "rfc") {
      value = e.target.value.toUpperCase();
    } else if (e.target.name === "email") {
      value = e.target.value.toLowerCase();
    } else if (e.target.name === "gender") {
      value = e.target.value
    } else {
      value = titlecase(e.target.value);
    }
    setUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: value,
      };
    });
    setUpdates((prev) => {
      return {
        ...prev,
        [e.target.name]: value,
      };
    });
  }

  function titlecase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  async function saveUserData() {
    const checkOriginalFieldsEmptiness = Object.values(originalFetchedData).every(
      (x) => x.trim() !== "" || x !== undefined || x !== null
    );
    if (!checkOriginalFieldsEmptiness) {
      Swal.fire({
        icon: "error",
        text: "Todos los datos ya se encuentran registrados.",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    }
    const checkUserFieldsEmptiness = Object.values(userData).every(
      (x) => x.trim() !== "" || x !== undefined || x !== null
    );

    if (!checkUserFieldsEmptiness) {
      Swal.fire({
        icon: "warning",
        text: "Debes ingresar todos los datos faltantes para poder registrarlos.",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    }
    if (userData.phone.toString().length !== 10) {
      Swal.fire({
        icon: "error",
        text: "El número de teléfono debe tener 10 dígitos",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    }
    if (
      userData.rfc.toString().length !== 10 &&
      userData.rfc.toString().length !== 13
    ) {
      Swal.fire({
        icon: "error",
        text: "El RFC debe tener 10 o 13 dígitos",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    }

    setIsSaving(true);
    const newData = {
      firstName: userData.firstName.trim(),
      lastName: userData.lastName.trim(),
      phone: userData.phone.trim(),
      email: userData.email.trim(),
      gender: userData.gender,
      birthday: {
        day: userData.birthday.day,
        month: userData.birthday.month,
        year: userData.birthday.year,
      },
      zipCode: userData.zipCode.trim(),
      rfc: userData.rfc.trim(),
      updatedBy: agentUID,
      clientStatus: 2,
    };
    try {
      const userUpdatesDocRef = collection(db, `users/${userUID}/userUpdates`);
      const userDocRef = doc(db, "users", userUID);
      await updateDoc(userDocRef, newData);
      await addDoc(userUpdatesDocRef, {
        timestamp: new Date().getTime(),
        ...newData,
      });
      await addComment();
      Swal.fire({
        icon: "success",
        text: "Tus datos se han actualizado correctamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      setIsSaving(false);
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Se ha presentado un error al guardar los datos, intentalo más tarde.",
      });
      logError({
        error: e?.message,
        source: "Datos de usuario",
        metadata: {
          description: "Error al guardar los primeros datos del usuario",
          userUID,
          agentUID,
          newData,
          userAgent: navigator.userAgent
        }
      });
    }
  }

  if (isFetchingData)
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          margin: "0 auto",
          padding: theme.spacing(3),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
      </Box>
    );

  return (
    <main
      style={{
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Stack>
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Ingresa los datos del usuario
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
            }}
          >
            Desde esta sección podrás ingresar los datos faltantes del usuario.
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <TextField
            disabled={Boolean(originalFetchedData?.email)}
            label="Correo"
            variant="outlined"
            fullWidth
            name="email"
            value={userData.email}
            onChange={onChange}
            helperText=" "
          />
          <TextField
            disabled={Boolean(originalFetchedData?.firstName)}
            label="Nombre"
            value={userData.firstName}
            onChange={onChange}
            name="firstName"
            helperText=" "
          />
          <TextField
            disabled={Boolean(originalFetchedData?.lastName)}
            label="Apellidos"
            value={userData.lastName}
            onChange={onChange}
            name="lastName"
            helperText=" "
          />
          <TextField
            disabled={Boolean(originalFetchedData?.phone)}
            label="Celular"
            value={userData.phone}
            onChange={onChange}
            name="phone"
            error={Boolean(
              userData.phone.length !== 10 && userData.phone.length !== 0
            )}
            helperText={
              userData.phone.length !== 10 && userData.phone.length !== 0
                ? "El número de celular debe tener 10 dígitos"
                : " "
            }
          />
          <FormControl fullWidth>
            <InputLabel id="biologicalGender">Sexo biológico</InputLabel>
            <Select
              disabled={Boolean(originalFetchedData?.gender)}
              sx={{marginBottom: "20px"}}
              onChange={onChange}
              name="gender"
              labelId="biologicalGender"
              id="biologicalGender"
              label="Sexo biológico"
              value={userData.gender}
            >
              <MenuItem value="Masculino">Hombre</MenuItem>
              <MenuItem value="Femenino">Mujer</MenuItem>
              <MenuItem value="Prefiero no decir">Prefiero no decir</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            disabled={Boolean(
              originalFetchedData?.birthday?.year &&
                originalFetchedData?.birthday?.month &&
                originalFetchedData?.birthday?.day
            )}
            sx={{marginBottom: "12px"}}
            label="Fecha de nacimiento"
            value={
              Boolean(userData?.birthday?.year && userData?.birthday?.day)
                ? new Date(
                    Number(userData?.birthday?.year),
                    Number(userData?.birthday?.month - 1),
                    Number(userData?.birthday?.day)
                  ).getTime()
                : null
            }
            onChange={(newValue) => {
              setUserData((prev) => {
                return {
                  ...prev,
                  birthday: {
                    day: new Date(newValue).getUTCDate(),
                    month: new Date(newValue).getUTCMonth() + 1,
                    year: new Date(newValue).getUTCFullYear(),
                  },
                };
              });
            }}
            renderInput={(params) => <TextField sx={{marginBottom: "8px"}} {...params} helperText=" " />}
          />
          <TextField
            disabled={Boolean(originalFetchedData?.zipCode)}
            onChange={onChange}
            value={userData.zipCode}
            name="zipCode"
            label="Código postal"
            required
          />
          <TextField
            disabled={Boolean(originalFetchedData?.rfc)}
            onChange={onChange}
            value={userData.rfc}
            name="rfc"
            label="RFC"
            error={Boolean(
              userData?.rfc.length !== 13 &&
                userData?.rfc.length !== 10 &&
                userData.rfc.length !== 0
            )}
            helperText={
              userData.rfc.length !== 13 &&
              userData.rfc.length !== 10 &&
              userData.rfc.length !== 0
                ? "El RFC debe tener 12 o 13 dígitos"
                : " "
            }
          />
          <LoadingButton
            sx={{ textTransform: "none" }}
            loading={isSaving}
            onClick={saveUserData}
            variant="contained"
            helperText=" "
          >
            Actualizar
          </LoadingButton>
        </Stack>
      </LocalizationProvider>
    </main>
  );
}
