const options = {
  enableHighAccuracy: true,
};
export default function getDeviceCurrentPosition(onSuccess, onError) {
  navigator.geolocation.getCurrentPosition(
    ({ coords }) => {
      const lat = coords.latitude;
      const long = coords.longitude;

      onSuccess({
        lat,
        long,
      });
    },
    onError,
    options
  );
}
