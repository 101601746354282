import { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, collection, setDoc, addDoc } from "firebase/firestore";
import {
  Stack,
  Chip,
  Button,
  Box,
  useTheme,
  IconButton,
  Link,
  Typography,
  Divider,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SaveIcon from "@mui/icons-material/Save";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Swal from "sweetalert2";
import Dialog from "../../Components/Dialog";
import happyCluppcito from "../../Assets/Clupp_Normal.png";
import FileBox from "../Filebox/Filebox";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import DocDescription from "./DocDescription";

function toLowerCaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

const UploadDoc = ({
  docName,
  statusDoc,
  setStatusDoc,
  userData,
  claimId,
  filesData,
  requiredDocuments = [],
}) => {
  const [file, setFile] = useState();
  const [multiFile, setMultiFile] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const theme = useTheme();

  const formatStatus = {
    pending: "Pendiente",
    review: "En revisión",
    approved: "Validado",
    declined: "No aprobado",
    doNotApply: "No aplica",
  };

  const currentRequiredDoc = requiredDocuments.find(
    (doc) => doc.label === docName
  );

  const currentFile = filesData?.find(
    (doc) => doc.id === currentRequiredDoc.id
  );

  setStatusDoc(formatStatus[currentFile?.fileStatus]);

  useEffect(() => {
    file && handleUploadFile();
  }, [file]);
  const estatusDoc = statusDoc ? `Estatus: ${statusDoc}` : "Estatus: Pendiente";
  const colorEstatus =
    statusDoc === "En revisión"
      ? "primary"
      : statusDoc === "Validado"
      ? "success"
      : statusDoc === "No aprobado"
      ? "error"
      : "default";

  const textColor = statusDoc === "Pendiente" ? "black" : "white";

  async function uploadFiles(filesParam) {
    const urls = [];

    setIsSending(true);
    for (let i = 0; i < filesParam.length; i++) {
      const fileItem = filesParam[i];
      const storageRef = ref(
        storage,
        `users/${userData.uid}/claims/${claimId}/${currentRequiredDoc.id}/${fileItem.name}`
      );

      // Upload the file to Firebase Storage
      uploadBytes(storageRef, fileItem);

      // Get the download URL of the uploaded file
      await uploadBytes(storageRef, fileItem, {
        contentType: fileItem.type,
      });
      const downloadURL = await getDownloadURL(storageRef);
      urls.push({
        url: downloadURL,
        name: fileItem.name,
      });
    }

    return urls;
  }

  const handleUploadFile = () => {
    if (multiFile.length > 0) {
      uploadFiles(multiFile).then((urls) => {
        handleSaveFile(urls);
      });
    } else {
      if (file.file) {
        setIsSending(true);
        const storageRef = ref(
          storage,
          `users/${userData.uid}/claims/${claimId}/${
            currentRequiredDoc.id
          }/${file.fileName.replace(/[.,]/g, "")}`
        );
        uploadBytes(storageRef, file.file, {
          contentType: "application/pdf",
        }).then(() => {
          getDownloadURL(storageRef).then((url) => {
            handleSaveFile(url);
          });
        });
      }
    }
  };

  const handleSaveFile = (url) => {
    const usersCollection = collection(db, "users");
    const claimsCollecion = collection(usersCollection, userData.uid, "claims");
    const documentsCollecion = collection(
      claimsCollecion,
      claimId,
      "documents"
    );
    const documentsDoc = doc(documentsCollecion, currentRequiredDoc.id || "");

    setDoc(documentsDoc, {
      fileURL: url,
      fileStatus: "review",
      timestamp: new Date().getTime(),
      updatedBy: userData.uid,
    })
      .then(() => {
        Swal.fire({
          imageUrl: happyCluppcito,
          imageWidth: "72px",
          title: `${
            multiFile && multiFile.length > 1
              ? "Se han guardado tus"
              : "Se ha guardado tu"
          } ${toLowerCaseFirstLetter(docName)}`,
          text: `Ahora ${
            multiFile && multiFile.length > 1
              ? "los archivos parasarán"
              : "el archivo parasará"
          } al estatus de En Revisión.`,
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          text: "Ocurrió un error al guardar. Intenta de nuevo más tarde",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
      })
      .finally(() => setIsSending(false));

    const updatesCollecion = collection(
      documentsCollecion,
      currentRequiredDoc.id,
      currentRequiredDoc.id + "-Updates"
    );

    addDoc(updatesCollecion, {
      fileURL: url,
      fileStatus: "review",
      timestamp: new Date().getTime(),
      updatedBy: userData.uid,
    });
    setFile();
    setMultiFile([]);
  };

  const handleChangeFile = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name;
      setMultiFile(Array.from(e.target.files));
      setFile({ file, fileName });
      // Swal.fire({
      //   icon: "info",
      //   text: "Por favor, da clic en Guardar archivo para guardar los documentos.",
      //   confirmButtonText: "Ok",
      //   confirmButtonColor: theme.palette.primary.main,
      // });
    }
  };

  return (
    <Box
      sx={{
        paddingTop: { xs: "66px", md: "74px" },
      }}
    >
      <Stack direction="column" spacing={2} paddingBottom="20px">
        <h1>{docName}</h1>
        <Chip
          label={estatusDoc}
          color={colorEstatus}
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: { textColor },
          }}
          sx={{
            width: { xs: "100%", sm: "100%", md: "30%", lg: "30%" },
          }}
        />
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        spacing={{ xs: 2, sm: 2, md: 5, lg: 10 }}
      >
        <Stack
          direction={{ xs: "column", sm: "column", md: "row", lg: "column" }}
          spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}
          style={{ width: "70%" }}
        >
          <h3>Archivo</h3>
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "column",
              lg: "column",
            }}
            spacing={{ xs: 2, sm: 1, md: 1, lg: 2 }}
          >
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "column",
                lg: "column",
              }}
              spacing={{ xs: 2, sm: 1, md: 1, lg: 1 }}
            >
              <Typography>
                {docName === "Documentos personales"
                  ? "Cargar archivos"
                  : "Cargar archivo"}
              </Typography>
              <Button
                component="label"
                onChange={handleChangeFile}
                endIcon={<FileUploadIcon />}
                sx={{
                  width: ["140%", "70%", "50%"],
                  border: "2px solid #00bbee",
                  paddingRight: "20px",
                }}
              >
                <LoadingButton
                  loading={isSending}
                  variant="contained"
                  component="label"
                  size="small"
                  // startIcon={<AttachFileIcon />}
                  style={{
                    fontWeight: "bold",
                    marginRight: "1rem",
                    color: "white",
                  }}
                  sx={{
                    textTransform: "none",
                    width: "80px",
                  }}
                >
                  Cargar
                  <input hidden accept=".pdf, .jpg" multiple type="file" />
                </LoadingButton>
                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {multiFile.length > 0
                    ? multiFile.map((it) => {
                        return `${it.name} `;
                      })
                    : "Sin archivos seleccionados"}
                </Typography>
                <Box
                  sx={{
                    background: "#00bbee",
                    borderRadius: "50%",
                    width: "45px",
                    display: "grid",
                    placeItems: "center",
                    color: "#fff",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                    {multiFile.length > 1 ? multiFile.length : ""}
                  </Typography>
                </Box>
              </Button>
              {/* {file ? (
                <>
                  <Box
                    style={{
                      backgroundColor: "#EEEEEE",
                      padding: "10px 0",
                      textAlign: "center",
                    }}
                    sx={{
                      height: { xs: "140%", sm: "70%", md: "40%", lg: "45%" },
                      width: { xs: "140%", sm: "70%", md: "40%", lg: "50%" },
                    }}
                  >
                    <p style={{ margin: "0" }}>
                      <InsertDriveFileIcon />
                      {file?.fileName}
                    </p>
                  </Box>
                </>
              ) : (
                ""
              )} */}
            </Stack>

            {/* <LoadingButton
              variant="contained"
              component="label"
              startIcon={<SaveIcon />}
              loading={isSending}
              style={{ fontWeight: "bold", color: "white" }}
              sx={{
                textTransform: "none",
                width: { xs: "140%", sm: "70%", md: "40%", lg: "50%" },
              }}
              onClick={() => {
                handleUploadFile();
              }}
            >
              Guardar archivo
            </LoadingButton> */}
            <MultiDocumentosModal
              currentFile={currentFile?.fileURL}
              fileName={docName}
            />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1} style={{ width: "90%" }}>
          <DocDescription
            docName={docName}
            description={currentRequiredDoc.description}
            documentOptions={currentRequiredDoc.documentOptions}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default UploadDoc;

function MultiDocumentosModal({ currentFile, fileName = "" }) {
  const [isOpen, setIsOpen] = useState(false);
  function hadleOnClose() {
    setIsOpen(false);
  }
  return (
    <>
      {((typeof currentFile === "string" && currentFile) ||
        (typeof currentFile === "object" && currentFile.length > 0)) && (
        <>
          <Divider sx={{ width: ["140%", "70%", "50%"] }} />
          {typeof currentFile === "object" ? (
            currentFile.map((item, index) => {
              return (
                <FileBox
                  key={index}
                  link={item.url}
                  description={item?.name || fileName}
                />
              );
            })
          ) : (
            <FileBox link={currentFile} description={fileName} />
          )}
          {/* <Button
            variant="contained"
            component="label"
            startIcon={<FileDownloadIcon />}
            disabled={
              (typeof currentFile === "string" && currentFile) ||
              (typeof currentFile === "object" && currentFile.length > 0)
                ? false
                : true
            }
            onClick={() => setIsOpen(true)}
            style={{
              fontWeight: "bold",
              marginRight: "1rem",
              color: "white",
            }}
            sx={{
              textTransform: "none",
              width: { xs: "140%", sm: "70%", md: "40%", lg: "50%" },
            }}
          >
            Descargar archivo
          </Button> */}
        </>
      )}
      <Dialog
        title={"Documentos"}
        isOpen={isOpen}
        onClose={hadleOnClose}
        sx={{
          "& .MuiPaper-root": {
            width: ["calc(100% - 24px)"],
            margin: 0,
          },
        }}
        content={
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {typeof currentFile === "object" ? (
              currentFile.map((item, index) => (
                <Box
                  sx={{
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  key={index}
                >
                  <Link href={item.url} target="_blank">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                        cursor: "pointer",
                        flexDirection: "row",
                        textDecoration: "underline",
                        color: "#0058f4",
                      }}
                    >
                      <InsertDriveFileIcon />
                      {item?.name || fileName}
                    </Box>
                  </Link>
                  <Box></Box>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Link href={currentFile} target="_blank">
                  <Box
                    sx={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                      cursor: "pointer",
                      flexDirection: "row",
                      textDecoration: "underline",
                      color: "#0058f4",
                    }}
                  >
                    <InsertDriveFileIcon />
                    {fileName}
                  </Box>
                </Link>
                <Box></Box>
              </Box>
            )}
          </Box>
        }
        Actions={
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={hadleOnClose}
          >
            Cerrar
          </Button>
        }
      />
    </>
  );
}
