import React, { useContext, useEffect, useState } from "react";
import UserContex from "../User/UserContex";
import TipoAsistencia from "./TipoAsistencia";
import { BotonInicio, ProggressVehiculo, VehiculoCancelado } from "./Element";
import RoboTracking from "./RoboTracking";
import Location from "./location";
import {
  Stack,
  Typography,
  Radio,
  Divider,
  RadioGroup,
  FormControl,
  FormControlLabel,
  MenuItem,
  Button,
  Select,
} from "@mui/material";
import Dialog from "./Dialog";
import { differenceInCalendarDays, format } from "date-fns";
import getContactPhone from "../utils/getContactPhone";
import ContactDialog from "./ContactDialog/ContactDialog";

import {
  GruaSolicitada,
  CorrienteSolicitada,
  LLantaSolicitada,
  CerrajeriaSolicitada,
  GasolinaSolicitada,
} from "./Forms";

import toleranceDay from "../utils/toleranceDay";
import GadgetReportForm from "./GadgetReportForm";
import Header from "./Header";
const QuePaso = ({
  setHideAsistencia,
  setTipoAsistencia,
  setHideVehiculo,
  setHideVehiculoCancelado,
  setShowRoboTracking,
  setHideTipo,
  setDayTranscurridos2,
  setDatePAgo,
  setHideProgressVehiculo,
}) => {
  const {
    nameAsegurado,
    lastAsegurado,
    emailAsegurado,
    report,
    setReport,
    clearSelect,
    gadgets,
    setSelectedReportType,
  } = useContext(UserContex);
  const [quePaso, setQuePaso] = useState("");
  const [queWhat, setWhat] = useState("");
  const [isMessageType, setIsMessageType] = useState(false);
  const whatsappUrl =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Soy%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    "%20mi%20correo%20" +
    emailAsegurado +
    ".%20Requiero%20su%20apoyo%20para%20realizar%20un%20reporte%20de%20un%20" +
    queWhat +
    ".";
  /*  const countVehicles = vehicles.filter(
    (vehicul) =>
      vehicul.isInsured &&
      vehicul.insuranceCompany == "Clupp" &&
      !vehicul.deleted
  );
*/
  const handleQuePaso = (event) => {
    const type = event.target.value;
    setQuePaso(event.target.value);
    setWhat(event.target.value);
    setHideTipo(false);
    setDayTranscurridos2(undefined);
    setDatePAgo(null);
    setReport({ ...report, vehicle: null });
    if (type === "accidente") {
      setIsMessageType(true);

      setShowRoboTracking(false);
    }
    if (type === "asistenciaVial") {
      setShowRoboTracking(false);
      setHideProgressVehiculo(true);
      //set Progress(true);
    }
    if (type === "robo") {
      setShowRoboTracking(true);
      setHideAsistencia(null);
      setTipoAsistencia("");
      setHideVehiculoCancelado(false);
      setHideVehiculo(false);
    }

    setSelectedReportType(type);

    if (type === "gadget-report") {
      setTimeout(() => {
        const formSection = document.querySelector("#gadget-report-form");
        if (formSection) {
          formSection.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 600);
    }
  };
  const cerrar = () => {
    setIsMessageType(false);
    setQuePaso("");
  };

  const hasGadgets = gadgets && gadgets.length > 0;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "80%",
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{ paddingTop: 2 }}
      >
        <Typography>¿Qué te pasó?</Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={quePaso}
            onChange={handleQuePaso}
          >
            <FormControlLabel
              value="robo"
              control={<Radio />}
              label="Me robaron"
            />
            <FormControlLabel
              value="accidente"
              control={<Radio />}
              label="Tuve un accidente"
            />
            <FormControlLabel
              value="asistenciaVial"
              control={<Radio />}
              label="Necesito asistencia vial "
            />

            {hasGadgets && (
              <FormControlLabel
                value="gadget-report"
                control={<Radio />}
                label="Le pasó algo a uno de mis dispositivos"
              />
            )}
          </RadioGroup>
        </FormControl>
        <ContactDialog
          cerrar={cerrar}
          isMessageType={isMessageType}
          setIsMessageType={setIsMessageType}
          setQuePaso={setQuePaso}
          whatsappUrl={whatsappUrl}
        />
      </Stack>
    </div>
  );
};
/*Datos de vehiculo */
const Vehiculo = ({
  setTipoAsistencia,
  setHideAsistencia,
  setHideTipo,
  dayTranscurridos2,
  setDayTranscurridos2,
  datePago,
  setDatePAgo,
  setUidVehiculo,
}) => {
  const {
    vehicles,
    report,
    setReport,
    getSelecction,
    selectV,
    idselect,
    nameAsegurado,
    lastAsegurado,
    emailAsegurado,
  } = useContext(UserContex);
  const vehiculosAsegurados = vehicles.filter(
    (vehicul) =>
      vehicul.isInsured &&
      vehicul.insuranceCompany == "Clupp" &&
      !vehicul.deleted
  );
  const countVehicle = vehiculosAsegurados.length;
  const [dataVehicle, setDataVehicle] = useState({});
  const [vehicle, setVehicle] = useState();

  const handleChange = (e) => {
    setTipoAsistencia("");
    setHideAsistencia("");
    setVehicle(e.target.value);
    setReport({ ...report, vehicle: e.target.value });
    setUidVehiculo(e.target.value);
  };
  useEffect(() => {
    if (idselect !== null && selectV !== null) {
      if (selectV == undefined) {
        setDatePAgo("2020-01-01");
      } else {
        //console.log(selectV.insuranceEndDay);
        const date = toleranceDay(
          selectV.insuranceEndDay,
          selectV.insuranceEndMonth,
          selectV.insuranceEndYear
        );
        console.log(date);
        setDatePAgo(date);
      }
    }
  }, [idselect]);

  useEffect(() => {
    if (idselect !== null) {
    }
  }, [idselect]);

  useEffect(() => {
    if (countVehicle === 1) {
      const dataVehicleOne = vehiculosAsegurados[0];
      setDataVehicle({
        brand: dataVehicleOne.brand,
        model: dataVehicleOne.model,
        year: dataVehicleOne.year,
      });
      setReport({ ...report, vehicle: dataVehicleOne.id });
      setUidVehiculo(dataVehicleOne.id);
    }
  }, [countVehicle, idselect]);
  console.log(datePago);

  useEffect(() => {
    if (datePago !== null && datePago != "2020-01-01") {
      const dateTimestamp2 = Date.now();
      const formatToday2 = format(new Date(dateTimestamp2), "yyyy-MM-dd");
      var result2 = differenceInCalendarDays(
        new Date(formatToday2),
        new Date(datePago)
      );
    }
    setDayTranscurridos2(result2);
  }, [datePago]);
  const [isMessagePago, setIsMessagePago] = useState(false);
  const handlePago = () => {
    setIsMessagePago(false);
  };

  useEffect(() => {
    if (dayTranscurridos2 == undefined) {
      setHideTipo(false);
    } else if (dayTranscurridos2 >= 1) {
      setIsMessagePago(true);
      setHideTipo(false);
    } else {
      setHideTipo(true);
    }
  }, [dayTranscurridos2]);

  useEffect(() => {
    if (report.vehicle !== null) {
      getSelecction();
    }
  }, [report.vehicle]);
  const whatsappUrl =
    "https://wa.me/5215577460177?text=Soy%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    ",%20mi%20correo%20registrado%20es%20" +
    emailAsegurado +
    ".%20Requiero%20hacer%20un%20reporte%20y%20me%20gustaría%20cubrir%20el%20adeudo%20que%20tengo%20pendiente%20para%20recibir%20la%20atención.";
  return (
    <div
      style={{ display: "flex", justifyContent: "flex-start", width: "80%" }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Typography>¿Para qué vehículo requieres la asistencia?</Typography>
        {countVehicle === 1 ? (
          <Typography>
            {dataVehicle.brand} {dataVehicle.model} {dataVehicle.year}
          </Typography>
        ) : (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              p: 3,
            }}
          >
            <Select
              variant="outlined"
              className="vehicle-select text-input"
              value={vehicle}
              onChange={handleChange}
              defaultValue={"0"}
              sx={{
                width: "250px",
              }}
            >
              <MenuItem value={"0"}>
                <em>Selecciona</em>
              </MenuItem>

              {vehiculosAsegurados &&
                vehiculosAsegurados.map((data) => (
                  <MenuItem
                    key={data.id}
                    value={data.id}
                  >{`${data.brand} ${data.model} ${data.year}`}</MenuItem>
                ))}
            </Select>
          </Stack>
        )}
        <Dialog
          isOpen={isMessagePago}
          onClose={handlePago}
          content={
            <Typography>
              Tienes un pago pendiente por lo que no te podemos brindar el
              servicio. Realiza tu pago o contacta a un asesor.
            </Typography>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsMessagePago(false);
                    setTipoAsistencia("");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Contactar a un asesor
                </Button>
              </a>
            </Stack>
          }
        />
      </Stack>
    </div>
  );
};
/**Componente general */
const UserClaims = () => {
  const {
    report,
    users,
    userLocation,
    getUsers,
    getVehicles,
    getSmartphones,
    vehicles,
    smartphones,
    getClaims,
    getUserLocation,
    uidAsegurado,
    setUidAsegurado,
    nameAsegurado,
    setNameAsegurado,
    lastAsegurado,
    setLastAsegurado,
    setPhoneAsegurado,
    emailAsegurado,
    setEmailAsegurado,
    selectedReportType,
  } = useContext(UserContex);
  useEffect(() => {
    getUsers();
    getVehicles();
    getClaims();
    getSmartphones();
  }, []);
  useEffect(() => {
    if (users !== null) {
      setUidAsegurado(report.id);
      setNameAsegurado(users.firstName);
      setLastAsegurado(users.lastName);
      if (!users.phone) {
        setPhoneAsegurado("5576430354");
      } else if (users.phone === "noPhoneProvided") {
        setPhoneAsegurado("5576430354");
      } else {
        setPhoneAsegurado(users.phone);
      }
      if (users.email != undefined) {
        setEmailAsegurado(users.email);
      } else {
        setEmailAsegurado("siniestros@clupp.com.mx");
      }
    }
  }, [users]);

  const [hideVehiculo, setHideVehiculo] = useState(false);
  const [hideProgressVehiculo, setHideProgressVehiculo] = useState(false);
  const [hideVehiculoCancelado, setHideVehiculoCancelado] = useState(false);
  const [hideTipo, setHideTipo] = useState(false);
  const [hideAsistencia, setHideAsistencia] = useState(null);
  const [tipoAsistecia, setTipoAsistencia] = useState("");
  const [dayTranscurridos2, setDayTranscurridos2] = useState(NaN);
  const [datePago, setDatePAgo] = useState(null);
  const [uidVehiculo, setUidVehiculo] = useState(undefined);
  const [showRoboTracking, setShowRoboTracking] = useState(false);
  const [openModal, setIsOpenModal] = useState(false);

  const whatsappURL =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola,%20mi%20nombre%20es%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    "%20con%20uid%20" +
    uidAsegurado +
    "%20necesito%20ayuda%20para%20realizar%20un%20reporte.";

  const cerrar = () => {
    setIsOpenModal(false);
  };

  const handleOpen = () => {
    setIsOpenModal((prev) => !prev);
  };

  const gadgetReportSelected = selectedReportType === "gadget-report";

  return (
    <>
      <Header />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        paddingBottom={9}
      >
        <ContactDialog
          cerrar={cerrar}
          isMessageType={openModal}
          whatsappUrl={whatsappURL}
          setIsMessageType={setIsOpenModal}
          setQuePaso={() => {}}
        />
        <Location whatsappUrl={whatsappURL} getUserLocation={getUserLocation} />
        <QuePaso
          setHideAsistencia={setHideAsistencia}
          setTipoAsistencia={setTipoAsistencia}
          setHideVehiculo={setHideVehiculo}
          setHideVehiculoCancelado={setHideVehiculoCancelado}
          hideTipo={hideTipo}
          setHideTipo={setHideTipo}
          setDayTranscurridos2={setDayTranscurridos2}
          datePago={datePago}
          setDatePAgo={setDatePAgo}
          setHideProgressVehiculo={setHideProgressVehiculo}
          setShowRoboTracking={setShowRoboTracking}
        />

        {gadgetReportSelected ? (
          <>
            <GadgetReportForm />
          </>
        ) : (
          <>
            {showRoboTracking &&
            vehicles &&
            (smartphones != undefined || smartphones != null) ? (
              <RoboTracking
                userUID={uidAsegurado}
                userLocation={userLocation}
                whatsappUrl={whatsappURL}
                nameAsegurado={nameAsegurado}
                lastNameAsegurado={lastAsegurado}
              />
            ) : null}

            {hideProgressVehiculo && (
              <ProggressVehiculo
                setHideProgressVehiculo={setHideProgressVehiculo}
                setHideVehiculo={setHideVehiculo}
                setHideVehiculoCancelado={setHideVehiculoCancelado}
                setHideAsistencia={setHideAsistencia}
              />
            )}

            {hideVehiculoCancelado && (
              <VehiculoCancelado
                nameAsegurado={nameAsegurado}
                lastAsegurado={lastAsegurado}
              />
            )}

            {hideVehiculo && (
              <Vehiculo
                setHideVehiculo={setHideVehiculo}
                setTipoAsistencia={setTipoAsistencia}
                setHideAsistencia={setHideAsistencia}
                setHideTipo={setHideTipo}
                dayTranscurridos2={dayTranscurridos2}
                setDayTranscurridos2={setDayTranscurridos2}
                datePago={datePago}
                setDatePAgo={setDatePAgo}
                uidVehiculo={uidVehiculo}
                setUidVehiculo={setUidVehiculo}
              />
            )}

            {hideTipo && (
              <TipoAsistencia
                setHideAsistencia={setHideAsistencia}
                tipoAsistecia={tipoAsistecia}
                setTipoAsistencia={setTipoAsistencia}
                uidVehiculo={uidVehiculo}
                setUidVehiculo={setUidVehiculo}
                userData={users}
              />
            )}

            {hideAsistencia === "grua" ? (
              <GruaSolicitada
                setTipoAsistencia={setTipoAsistencia}
                setHideVehiculo={setHideVehiculo}
                setHideTipo={setHideTipo}
                setHideAsistencia={setHideAsistencia}
              />
            ) : hideAsistencia === "corriente" ? (
              <CorrienteSolicitada
                setTipoAsistencia={setTipoAsistencia}
                setHideVehiculo={setHideVehiculo}
                setHideTipo={setHideTipo}
                setHideAsistencia={setHideAsistencia}
              />
            ) : hideAsistencia === "cerrajero" ? (
              <CerrajeriaSolicitada
                setTipoAsistencia={setTipoAsistencia}
                setHideVehiculo={setHideVehiculo}
                setHideTipo={setHideTipo}
                setHideAsistencia={setHideAsistencia}
              />
            ) : hideAsistencia === "gasolina" ? (
              <GasolinaSolicitada
                setTipoAsistencia={setTipoAsistencia}
                setHideVehiculo={setHideVehiculo}
                setHideTipo={setHideTipo}
                setHideAsistencia={setHideAsistencia}
              />
            ) : (
              hideAsistencia === "cambiollanta" && (
                <LLantaSolicitada
                  setTipoAsistencia={setTipoAsistencia}
                  setHideVehiculo={setHideVehiculo}
                  setHideTipo={setHideTipo}
                  setHideAsistencia={setHideAsistencia}
                />
              )
            )}
          </>
        )}

        <BotonInicio
          nameAsegurado={nameAsegurado}
          lastAsegurado={lastAsegurado}
          emailAsegurado={emailAsegurado}
          setTipoAsistencia={setTipoAsistencia}
          openDialog={handleOpen}
        />
      </Stack>
    </>
  );
};
export default UserClaims;
