import { getDocuments } from "../firestore";

async function getClaims({ userUID, limit = undefined }) {
  try {
    const response = await getDocuments({
      collectionPath: `users/${userUID}/claims`,
      orderBy: ["accidentTimestamp", "desc"],
      limit,
    });

    const json = await response.json();

    return json.docs.map(({ doc, id }) => ({ ...doc, claimId: id }));
  } catch (error) {
    throw new Error(error.toString());
  }
}

export default getClaims;
