import UserClaims from "../Components/UserClaims";
import UserState from "../User/UserState";

const Claims = (props) => {
  return (
    <UserState>
      <UserClaims />
    </UserState>
  );
};
export default Claims;
