import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import useQueryParams from "./useQueryParams";
import Swal from "sweetalert2";
import useUser from "./useUser";

function useExtraInsurances() {
  const params = useQueryParams();
  const uid = params.get("uid");
  const { user } = useUser();
  const [extraInsurances, setExtraInsurances] = useState();
  const [isLoadingInsurances, setIsLoadingInsurances] = useState(true);

  const fetchData = async () => {
    const extraInsq = query(
      collection(db, `users/${uid}/insurances`),
      where("deleted", "==", false),
      orderBy("timestamp", "desc")
    );

    const vehiclesInsq = query(
      collection(db, `users/${uid}/vehicles`),
      where("deleted", "==", false),
      where("isInsured", "==", true)
    );

    const smartphonesInsq = query(
      collection(db, `/users/${uid}/smartphones`),
      where("deleted", "==", false),
      where("isInsured", "==", true)
    );
    try {
      const extraSnapshot = await getDocs(extraInsq);
      const extraInsurances = extraSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const vehiclesSnapshot = await getDocs(vehiclesInsq);
      const vechiclesInsurances = vehiclesSnapshot.docs.map((doc) => {
        const data = doc.data();
        const end = new Date(
          data.insuranceEndYear,
          data.insuranceEndMonth - 1,
          data.insuranceEndDay
        ).getTime();
        const start = new Date(
          data.insuranceStartYear,
          data.insuranceStartMonth - 1,
          data.insuranceStartDay
        ).getTime();

        if (!data.urlPolicy) data.urlPolicy = user.policyURL;
        return {
          ...data,
          id: doc.id,
          insuranceEndTimestamp: end,
          insuranceStartTimestamp: start,
          isFromClupp: true,
          name: `Seguro de ${data.brand} ${data.model} ${data.year || ""}`,
          policyURL: data.urlPolicy,
          policyNumber: data.policyId,
          cluppType: data.type,
          type: "vehicle",
          phoneContact: "5592252185",
        };
      });

      const smartphonesSnapshot = await getDocs(smartphonesInsq);
      const smartphonesInsurances = smartphonesSnapshot.docs.map((doc) => {
        const data = doc.data();
        if (!data.urlPolicy) data.urlPolicy = user.policyURL;
        const end = new Date(
          data.insuranceEndYear,
          data.insuranceEndMonth - 1,
          data.insuranceEndDay
        ).getTime();
        const start = new Date(
          data.insuranceStartYear,
          data.insuranceStartMonth - 1,
          data.insuranceStartDay
        ).getTime();

        return {
          ...data,
          id: doc.id,
          insuranceEndTimestamp: end,
          insuranceStartTimestamp: start,
          isFromClupp: true,
          policyURL: data.urlPolicy,
          insuranceCompany: "Clupp",
          name: `Seguro de ${data.brand} ${data.model}`,
          type: data.type || "smartphone",
          phoneContact: "5592252185",
        };
      });
      const allInsurances = vechiclesInsurances.concat(
        smartphonesInsurances,
        extraInsurances
      );
      setExtraInsurances(allInsurances);
      setIsLoadingInsurances(false);
    } catch (error) {
      setIsLoadingInsurances(false);
      setExtraInsurances([]);
      Swal.fire({
        icon: "error",
        title: "Lo sentimos, no pudimos obtener tus seguros extras.",
        text: "Por favor, vuelva a intentarlo más tarde. Aún así, puedes agregar algún otro seguro. ",
      });
      console.error(error);
    }
  };
  useEffect(() => {
    if (uid && user && user.uid) fetchData();
  }, [uid, user]);

  return {
    fetchData,
    extraInsurances,
    isLoadingInsurances,
  };
}

export default useExtraInsurances;
