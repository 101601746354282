import { useEffect, useRef, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Box,
  Tooltip,
  Button,
  useTheme
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Swal from "sweetalert2";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, collection, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { LoadingButton } from "@mui/lab";
import { AttachFile } from "@mui/icons-material";
import Dialog from "../../Components/Dialog";
import FileBox from "./FileBox";

const DATA_INITIAL_STATE = {
  event: "",
  description: "",
  timestamp: new Date(),
};

async function saveData(userId, vehicleId, files, newData, theme) {
  const saveData = {
    ...newData,
    deleted: false,
    editable: true,
    timestamp: newData.timestamp.getTime(),
    files: files,
    vid: vehicleId,
    timestampLastUpdate: new Date().getTime(),
  };
  const docRef = doc(
    collection(db, `users/${userId}/vehicles/${vehicleId}/events`)
  );
  try {
    await setDoc(docRef, saveData);
  } catch (e) {
    Swal.fire({
      icon: "error",
      text: "Se produjo un error al intentar guardar los datos, intentalo nuevamente",
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: "Ok",
    });
  }
}

async function updateEventData(userId, vehicleId, newData, theme, eventId) {
  const saveData = {
    ...newData,
    timestampLastUpdate: new Date().getTime(),
  };
  const docRef = doc(db, `users/${userId}/vehicles/${vehicleId}/events`, eventId);
  try {
    await updateDoc(docRef, saveData);
  } catch (e) {
    Swal.fire({
      icon: "error",
      text: "Se produjo un error al intentar guardar los datos, intentalo nuevamente",
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: "Ok",
    });
  }
}

export default function ExpedienteDigitalForm({
  userId,
  vehicleId,
  isOpen,
  handleClose,
  variant="create",
  eventId=null,
  eventData=DATA_INITIAL_STATE
}) {
  const [newEnterData, setNewEnterData] = useState(eventData);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const fileInput = useRef();

  useEffect(() => {
    setNewEnterData(eventData)
  }, [eventData])

  function handleOnChange(e) {
    setNewEnterData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  function handleSaveData() {
    if (!newEnterData.event && !newEnterData.timestamp) {
      Swal.fire({
        icon: "warning",
        text: "Por favor ingresa el nombre y fecha del evento para poder registrar los datos.",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
    }
    setIsLoading(true);
    saveData(userId, vehicleId, files, newEnterData, theme)
      .then(() => {
        setFiles([]);
        setNewEnterData(DATA_INITIAL_STATE);
        setIsLoading(false);
        handleClose();
        Swal.fire({
          icon: "success",
          text: "Se ha guardado el registro exitosamente",
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        });
      })
      .catch(() => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          text: "Se ha producido un error al guardar los datos, intentalo más tarde",
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        });
      });
  }
  function handleOnDeleteImage(filename) {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== filename));
  }

  function handleFileInputOnChange(e) {
    const commingFiles = [...e.target.files];
    if (commingFiles.length > 0) {
      commingFiles.forEach((commingFile) => {
        let id = Math.random().toString();
        setFiles((prev) => [
          ...prev,
          {
            id,
            url: undefined,
            name: commingFile.name,
            type: commingFile.type,
            size: commingFile.size,
            progress: 0,
          },
        ]);
        const storageRef = ref(
          storage,
          `users/${userId}/vehicles/${vehicleId}/events/${commingFile.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, commingFile);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setFiles((prev) =>
              prev.map((file) => {
                if (file.id === id) {
                  file.progress = progress;
                }
                return file;
              })
            );
          },
          (err) => {
            console.warn(err);
          },
          async () => {
            const fileURL = await getDownloadURL(uploadTask.snapshot.ref);
            setFiles((prev) =>
              prev.map((file) => {
                if (file.id === id) {
                  file.url = fileURL;
                  delete file.progress;
                }
                return file;
              })
            );
          }
        );
      });
    }
    e.target.value = null;
  }

  function handleUpdateData() {
    if (!newEnterData.event && !newEnterData.timestamp) {
      Swal.fire({
        icon: "warning",
        text: "Por favor ingresa el nombre y fecha del evento para poder registrar los datos.",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
    }
    const updateData = {
      event: newEnterData.event,
      description: newEnterData.description,
      timestamp: new Date(newEnterData.timestamp).getTime(),
    }
    setIsLoading(true);
    updateEventData(userId, vehicleId, updateData, theme, eventId)
      .then(() => {
        setFiles([]);
        setNewEnterData(DATA_INITIAL_STATE);
        setIsLoading(false);
        handleClose();
        Swal.fire({
          icon: "success",
          text: "Se ha actualizado el evento",
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        });
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false);
        handleClose();
        Swal.fire({
          icon: "error",
          text: "Se ha producido un error al guardar los datos, intentalo más tarde",
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        });
      });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Dialog
        sx={{
          width: "100%",
          "&.MuiDialogContent-root": {
            padding: "0px 5px",
          },
          "& .MuiPaper-root": {
            margin: "12px",
            width: "100%",
          },
        }}
        isOpen={isOpen}
        onClose={handleClose}
        title={
          <Typography fontWeight="bold" variant="h6">
            {variant ==="create" ? "Agregar nuevo evento" : "Editar evento"}
          </Typography>
        }
        content={
          <Stack
            sx={{
              display: "grid",
              gap: "12px",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <Stack gap={2}>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ color: "#000" }}>
                  Nombre del evento
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="event"
                  placeholder="Cambio de aceite, cambio de llantas..."
                  onChange={handleOnChange}
                  value={newEnterData.event}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ color: "#000" }}>Fecha del evento</Typography>
                <DatePicker
                  onChange={(e) =>
                    setNewEnterData((prev) => ({
                      ...prev,
                      timestamp: e,
                    }))
                  }
                  value={newEnterData.timestamp}
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Fecha del evento"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ color: "#000" }}>
                  Descripción del evento
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="description"
                  placeholder="Escribe aquí la descripción (opcional)"
                  onChange={handleOnChange}
                  value={newEnterData.description}
                  rows={5}
                  multiline
                />
              </Box>
              {variant === "create" && (<>
                <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1}>
                  {files.map((file) => (
                    <FileBox
                      file={file}
                      onDelete={handleOnDeleteImage}
                      key={file.id}
                    />
                  ))}
                </Box>
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Adjuntar Archivos">
                        <Button
                          onClick={() => fileInput.current.click()}
                          endIcon={<AttachFile />}
                          variant="outlined"
                          sx={{ textTransform: "none" }}
                          fullWidth
                        >
                          Cargar archivos o fotos
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </>)}
              <input
                style={{ display: "none" }}
                type="file"
                ref={fileInput}
                multiple
                onChange={handleFileInputOnChange}
              />
            </Stack>
            <Stack>
              {variant==="create" ? <LoadingButton
                onClick={handleSaveData}
                loading={isLoading}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Registrar Evento
              </LoadingButton> : <LoadingButton
                onClick={handleUpdateData}
                loading={isLoading}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Actualizar Evento
              </LoadingButton> }
            </Stack>
          </Stack>
        }
      />
    </LocalizationProvider>
  );
}