import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import getVehicleQuotation from "../../Services/vehicles/getVehicleQuotation";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Swal from "sweetalert2";
import { LocalOffer } from "@mui/icons-material";
import discountImg from "../../Assets/etiqueta-de-descuento.png";
import getTotalPremium from "../../utils/getTotalPremium";
import MobileHeader from "../../Components/MobileHeader";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useUser from "../../hooks/useUser";

const MSIRenewal = () => {
  const navigate = useNavigate();
  const params = useQueryParams();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});
  const [basePremium, setBasePremium] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [extras, setExtras] = useState([]);
  const [premiumPaymentPeriod, setPremiumPaymentPeriod] = useState({
    period: "year",
    msi: false,
  });

  const uid = params.get("uid");
  const vid = params.get("vid");

  function getPremiumPropertyName(mobility) {
    const NAMES = {
      0: "premium0",
      1: "premium1",
      2: "premium2",
      3: "premium3",
    };
    return NAMES[mobility] || "";
  }
  const buttonStyles = {
    margin: 1,
    color: "#F8FFFE",
    fontSize: "small",
    fontWeight: "bold",
    width: "7rem",
    textTransform: "capitalize",
    borderRadius: "2rem",
    textAlign: "center",
    "&:hover, &:active": {
      backgroundColor: "#0058f4",
      color: "#F8FFFE",
    },
  };

  const PAYMENT_PERIODS = [
    { label: "Anual", value: "year" },
    { label: "Semestral", value: "6month" },
    { label: "Trimestral", value: "3month" },
    { label: "Mensual", value: "month" },
  ];

  useEffect(() => {
    const getQuotation = async () => {
      try {
        const vehicleDocRef = doc(db, `users/${uid}/vehicles/${vid}`);
        const vehicleDoc = await getDoc(vehicleDocRef);

        if (!vehicleDoc.exists) {
          console.error("Vehicle not found in the database");
          Swal.fire({
            icon: "warning",
            showConfirmButton: false,
            title: "Lo sentimos, no encontramos este vehículo.",
            text: "Por favor, vuelva a intentarlo más tarde",
          });
        }

        const vehicleData = vehicleDoc.data();
        const { quotation } = await getVehicleQuotation(uid, vid);
        const monthlyPeriod = quotation.periods.month;

        setBasePremium(
          monthlyPeriod[getPremiumPropertyName(vehicleData.mobility)]["total"]
        );
        if (monthlyPeriod.extras) setExtras(monthlyPeriod.extras);

        setBasePremium(
          monthlyPeriod[getPremiumPropertyName(vehicleData.mobility)]["total"]
        );
        const policyExpired = new Date(
          vehicleData.insuranceEndYear,
          vehicleData.insuranceEndMonth - 1,
          vehicleData.insuranceEndDay
        ).getTime();
        setExpireDate(
          format(policyExpired, "dd'/'MM'/'yyyy", {
            locale: es,
          })
        );
        setVehicle(vehicleData);
      } catch (error) {
        console.error("Error while getting quotation:", error);
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          title: "Lo sentimos, ocurrio un error inesperado.",
          text: "Por favor, vuelva a intentarlo más tarde.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (uid && vid) getQuotation();
  }, [uid, vid]);

  return (
    <>
      <MobileHeader user={null} showGradientHeader={false} />
      {isLoading ? (
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack
          sx={{
            justifyContent: "center",
            textAlign: "center",
            alignSelf: "center",
            p: 2,
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Renovación del seguro de tu {vehicle.brand} {vehicle.model}{" "}
            {vehicle?.year ? vehicle?.year : ""}
          </h2>
          {expireDate && (
            <Alert severity="info">{`Tu seguro vence el ${expireDate} pero puedes realizar tu pago de manera anticipada.`}</Alert>
          )}

          {extras && extras.length > 0 && (
            <Stack maxWidth="450px" alignSelf={"center"} marginTop={"10px"}>
              <Typography
                sx={{ textAlign: "left", color: "grey", marginLeft: "21px" }}
              >
                Coberturas extra
              </Typography>
              <Box
                sx={{
                  marginTop: "-10px",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul>
                  {extras.map((extra) => (
                    <li style={{ color: "grey" }}>
                      <Typography>{extra.name}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Stack>
          )}
          <h3 style={{ textAlign: "center" }}>
            Elige tu periodicidad de pago:
          </h3>
          {basePremium && (
            <>
              {vehicle?.financingMonths && (
                <p>
                  Debido al financiamiento de tu vehículo, la periodicidad de
                  pago del seguro no puede ser menor a los meses que quedan de
                  financiamiento.
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  marginBottom: "20px",
                }}
              >
                {vehicle?.financingMonths &&
                !vehicle?.utm_medium === "eriOKuTfDFP2oEnrm1Iq" ? (
                  <Button
                    sx={{
                      backgroundColor:
                        premiumPaymentPeriod.period === "year"
                          ? "#0058f4"
                          : "#00CA7D",
                      ...buttonStyles,
                    }}
                    variant="contained"
                    onClick={() =>
                      setPremiumPaymentPeriod({ period: "year", msi: false })
                    }
                  >
                    Anual
                  </Button>
                ) : (
                  PAYMENT_PERIODS.map((payment) => {
                    if (
                      (vehicle.type === "auto" && vehicle.valueUser < 100000) ||
                      (vehicle.type === "moto" && vehicle.valueUser < 30000)
                    ) {
                      if (payment.label !== "Mensual") {
                        return (
                          <Button
                            endIcon={
                              payment.value === "year" ? <LocalOffer /> : null
                            }
                            key={payment.value}
                            sx={{
                              backgroundColor:
                                premiumPaymentPeriod.period === payment.value
                                  ? "#0058f4"
                                  : "#00CA7D",
                              ...buttonStyles,
                            }}
                            variant="contained"
                            onClick={() =>
                              setPremiumPaymentPeriod({
                                period: payment.value,
                                msi: false,
                              })
                            }
                          >
                            {payment.label}
                          </Button>
                        );
                      }
                    } else {
                      return (
                        <Button
                          key={payment.value}
                          endIcon={
                            payment.value === "year" ? <LocalOffer /> : null
                          }
                          sx={{
                            backgroundColor:
                              premiumPaymentPeriod.period === payment.value
                                ? "#0058f4"
                                : "#00CA7D",
                            ...buttonStyles,
                          }}
                          variant="contained"
                          onClick={() =>
                            setPremiumPaymentPeriod({
                              period: payment.value,
                              msi: false,
                            })
                          }
                        >
                          {payment.label}
                        </Button>
                      );
                    }
                  })
                )}
              </div>
              <PaymentView
                msi={premiumPaymentPeriod.msi}
                paymentPeriod={premiumPaymentPeriod.period}
                premium={basePremium}
                extras={extras}
              />

              <Box
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {premiumPaymentPeriod.period === "year" && (
                  <Stack direction="row" gap={2} alignItems="center">
                    <Typography>Pagar a Meses sin intereses</Typography>
                    <Checkbox
                      defaultChecked={premiumPaymentPeriod.msi}
                      onChange={(e) => {
                        setPremiumPaymentPeriod({
                          period: "year",
                          msi: e.target.checked,
                        });
                      }}
                    />
                  </Stack>
                )}
              </Box>
              <Button
                startIcon={<WhatsAppIcon />}
                onClick={() =>
                  window.open(
                    `https://wa.me/5215577460177?text=Hola%2C%20quiero%20realizar%20la%20renovaci%C3%B3n%20de%20mi%20seguro.%20Mi%20correo%20es%3A%20${user?.email}`,
                    "__blank"
                  )
                }
                variant="contained"
                sx={{
                  width: "95%",
                  margin: "20px auto",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                Contactar agente
              </Button>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

const PaymentView = ({
  premium: basePremium,
  paymentPeriod,
  extras,
  msi = false,
}) => {
  const TITLES = {
    month: "Pago mensual",
    "3month": "Pago trimestral",
    "6month": "Pago semestral",
    year: "Pago anual",
  };

  const MSI_DISCOUNT = 6;
  const PAYMENT_DISCOUNTS = {
    month: 0,
    "3month": 3,
    "6month": 6,
    year: 10,
  };

  const discount = msi ? MSI_DISCOUNT : PAYMENT_DISCOUNTS[paymentPeriod];
  const [premiumWithDiscount, , totalPremium] = getTotalPremium(
    basePremium,
    paymentPeriod,
    extras,
    {
      msi,
    }
  );

  const formatter = new Intl.NumberFormat("es-MX", {
    maximumFractionDigits: 0,
  });
  const formatNumber = (number) => formatter.format(number);
  const hasDiscount = paymentPeriod !== "month";

  const isYearPayment = paymentPeriod === "year";
  const isSpecialOffer = isYearPayment && !msi;

  return (
    <Stack
      sx={{
        maxWidth: "450px",
        minWidth: ["200px", "450px"],
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f9f9f9",
        borderRadius: "10px",
        minHeight: "216px",
        width: "100%",
        border: "1px solid #D9D9D9",
        margin: "0px auto",
        position: "relative",
      }}
    >
      {hasDiscount && (
        <>
          <p
            style={{
              background: "#FF6060",
              color: "white",
              textAlign: "center",
              position: "absolute",
              top: 0,
              right: 0,
              margin: 0,
              width: "100%",
              borderRadius: "10px 10px 0px 0px",
              padding: "8px 0px",
              fontSize: "large",
            }}
          >
            <b>{discount}%</b> de descuento
          </p>
          {isSpecialOffer && (
            <img
              alt="img"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: "70px",
                marginRight: "-20px",
                transformOrigin: "30px 0px",

                animation:
                  "rotate 10s cubic-bezier(0.73, 0, 0.21, 0.97) infinite",
                zIndex: 1000,
              }}
              src={discountImg}
            />
          )}
        </>
      )}

      <div
        style={{
          display: "flex",
          gap: ".3rem",
          padding: "3rem 2rem 2rem 2rem",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {hasDiscount && (
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
              color: "gray",
              textDecoration: "line-through",
              margin: "0px",
            }}
          >
            MX ${formatNumber(totalPremium)}
          </p>
        )}
        <p
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            textAlign: "center",
            margin: "0px",
          }}
        >
          MX ${formatNumber(premiumWithDiscount)}
        </p>
        <p
          style={{
            fontWeight: " bold",
            fontSize: " medium",
            color: "#3f75cf",
            textAlign: " center",
            margin: "0px",
          }}
        >
          {TITLES[paymentPeriod]}
        </p>
        {isSpecialOffer && (
          <p
            style={{
              margin: 0,
              color: "#FF6060",
              padding: "2px 10px",
              fontSize: "large",
            }}
          >
            <b>¡Mejor oferta!</b>
          </p>
        )}
      </div>
    </Stack>
  );
};

export default MSIRenewal;
