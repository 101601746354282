const moment = require('moment-timezone');

function getRestrictedDays(month, year, hologram, lastPlateDigit) {
    const restrictedDays = [];
    const monthSetted = month.toString().length === 1 ? `0${month}` : `${month}`;

    // Rules for weekdays
    const weekdayRestrictions = {
        1: [5, 6], // Monday
        2: [7, 8], // Tuesday
        3: [3, 4], // Wednesday
        4: [1, 2], // Thursday
        5: [9, 0]  // Friday
    };

    // Rules for Saturdays
    const saturdayRestrictions = {
        1: [1, 3, 5, 7, 9], // First and third Saturday
        2: [0, 2, 4, 6, 8]  // Second and fourth Saturday
    };

    // Get the first day of the month
    const firstDay = moment.tz(`${year}-${monthSetted}-01`, 'America/Mexico_City');

    // Get the last day of the month
    const lastDay = firstDay.clone().endOf('month').date();

    // Iterate over each day of the month
    for (let day = 1; day <= lastDay; day++) {
        const date = moment.tz(`${year}-${monthSetted}-${day}`, 'America/Mexico_City');
        const weekday = date.day(); // Get the number of the weekday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

        // Check restrictions for weekdays
        if (weekdayRestrictions[weekday] && weekdayRestrictions[weekday].includes(lastPlateDigit)) {
            restrictedDays.push(date.format('YYYY-MM-DD')); // Add the date to the restricted days array
        }

        // Check restrictions for Saturdays
        if (weekday === 6) {
            const weekOfMonth = Math.ceil(day / 7);
            if (hologram === 1 && (
                (saturdayRestrictions[1].includes(lastPlateDigit) && (weekOfMonth === 1 || weekOfMonth === 3)) ||
                (saturdayRestrictions[2].includes(lastPlateDigit) && (weekOfMonth === 2 || weekOfMonth === 4))
            )) {
                restrictedDays.push(date.format('YYYY-MM-DD')); // Add the date to the restricted days array
            } else if (hologram === 2) {
                restrictedDays.push(date.format('YYYY-MM-DD')); // Add all Saturdays for hologram 2
            }
        }
    }

    return restrictedDays;
}


export default getRestrictedDays