const DocDescription = ({
  docName,
  description = "",
  documentOptions = [],
}) => {
  return (
    <>
      {description && (
        <p
          style={{
            fontSize: "15px",
            color: "black",
            whiteSpace: "break-spaces",
          }}
        >
          <b
            style={{
              fontWeight: "bold",
            }}
          >
            Descripción:{" "}
          </b>

          {description}
        </p>
      )}

      {documentOptions.length > 0 && (
        <>
          <p
            style={{
              fontWeight: "bold",
              color: "black",
              fontSize: "15px",
            }}
          >
            Opciones
          </p>
          <ul>
            {documentOptions.map((option, index) => (
              <li key={index}>{option}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default DocDescription;
