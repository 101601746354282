import { useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  ListItemButton,
  Collapse,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const DocState = ({
  text,
  setIsHide,
  setDocName,
  filesData,
  toggleDrawer,
  requiredDocuments = [],
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const formatStatus = {
    pending: "Pendiente",
    review: "En revisión",
    approved: "Validado",
    declined: "No aprobado",
    doNotApply: "No aplica",
  };

  const getPendingDocs = () =>
    requiredDocuments.filter(
      (doc) => filesData.map((file) => file.id).indexOf(doc.id) === -1
    );

  const getDocsByStatus = (status) =>
    requiredDocuments.filter((doc) => {
      const index = filesData.map((file) => file.id).indexOf(doc.id);

      return (
        index !== -1 && formatStatus[filesData[index].fileStatus] === status
      );
    });

  const hideArrows = (text) =>
    text === "Pendiente"
      ? getPendingDocs().length
      : getDocsByStatus(text).length;

  return (
    <>
      <List>
        <ListItem key={text} disablePadding>
          <ListItemButton
            onClick={() => {
              handleClick();
            }}
          >
            <ListItemIcon>
              <FolderIcon
                color={
                  text === "Pendiente"
                    ? "action"
                    : text === "En revisión"
                    ? "primary"
                    : text === "Validado"
                    ? "success"
                    : "error"
                }
              />
            </ListItemIcon>
            <ListItemText primary={text + ` (${hideArrows(text)})`} />
            {!!hideArrows(text) && (
              <>{open ? <ExpandLess /> : <ExpandMore />}</>
            )}
          </ListItemButton>
        </ListItem>
        {text === "Pendiente"
          ? getPendingDocs().map((doc) => (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      toggleDrawer();
                      setIsHide(true);
                      setDocName(doc.label);
                    }}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={doc.label} />
                  </ListItemButton>
                </List>
              </Collapse>
            ))
          : getDocsByStatus(text).map((doc) => (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      toggleDrawer();
                      setIsHide(true);
                      setDocName(doc.label);
                    }}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={doc.label} />
                  </ListItemButton>
                </List>
              </Collapse>
            ))}
      </List>
    </>
  );
};

export default DocState;
