const ENDPOINT =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc";

const getDocument = ({ docPath }) =>
  new Promise(async (resolve, reject) => {
    const url = new URL(ENDPOINT);
    url.searchParams.append("docPath", docPath);

    try {
      const response = await fetch(url);
      const json = await response.json();
      if (response.ok) {
        resolve(json);
      }
      throw new Error(json.message);
    } catch (error) {
      reject(error);
    }
  });

export default getDocument;
