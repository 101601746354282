import { getDocuments } from "../firestore";

async function fetchUserInvoiceProfiles({
  userUID,
  limit = 1,
  orderBy = ["timestamp", "desc"],
}) {
  try {
    const response = await getDocuments({
      collectionPath: `users/${userUID}/invoiceProfiles`,
      limit,
      orderBy,
    });
    const json = await response.json();

    return json.docs.map(({ doc, id }) => ({ ...doc, invoiceProfileId: id }));
  } catch (error) {
    console.error(error);
    throw new Error("No pudimos obtener la información");
  }
}

export default fetchUserInvoiceProfiles;
