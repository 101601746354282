async function updateDocument(path, documentData) {
  const url = new URL(
    "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc"
  );

  url.searchParams.append("docPath", path);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ update: documentData }),
    });
    const document = await response.json();
    return document;
  } catch (error) {
    console.log(error);
    throw new Error("not/found");
  }
}

export default updateDocument;
