import { Button } from "@mui/material";
import getContactPhone from "../../utils/getContactPhone";
import postMessage from "../../Services/slack/postMessage"
const BotonInicio = ({
  nameAsegurado,
  lastAsegurado,
  emailAsegurado,
  setTipoAsistencia,
  openDialog
}) => {
  const whatsappUrl =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola,%20mi%20nombre%20es%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    ",%20mi%20correo%20registrado%20es%20" +
    emailAsegurado +
    ".%20Requiero%20su%20ayuda%20para%20hacer%20un%20reporte.";
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        minHeight: "20px",
        padding: "1rem",
        width: "100%",
        left: 0,
        backgroundColor: "white",
        zIndex: 100,
        boxShadow: "#dfdfdf 1px 1px 5px 0px",
      }}
    >
      {/* <a
        href={whatsappUrl}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      > */}
        <Button
          variant="text"
          onClick={() => {
            openDialog()
            postMessage(
              `[Solicitud de reporte] El asegurado ${nameAsegurado} ${lastAsegurado} con correo ${emailAsegurado} a solicitado contactar con un agente a través de WhatsApp.`
            );
            setTipoAsistencia("");
          }}
          sx={{
            textTransform: "initial",
            color: "gray",
          }}
        >
          Prefiero hablar con alguien
        </Button>
      {/* </a> */}
    </div>
  );
};
export default BotonInicio;
