import {
  GET_USERS,
  GET_VEHICLES,
  GET_SELECTVEHICLES,
  GET_CLAIMS,
  GET_IDSELECT,
  GET_IDUSER,
} from "../Contex/types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case "GET_USER_LOCATION":
      return {
        ...state,
        userLocation: payload
      }
    case GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case GET_IDUSER:
      return {
        ...state,
        iduser: payload,
      };
    case "GET_SMARTPHONES":
      return{
        ...state,
        smartphones: payload
      };
    case GET_VEHICLES:
      return {
        ...state,
        vehicles: payload,
      };
    case GET_SELECTVEHICLES:
      return {
        ...state,
        selectVehicle: payload,
      };
    case GET_CLAIMS:
      return {
        ...state,
        claims: payload,
      };
    case GET_IDSELECT:
      return {
        ...state,
        idSelect: payload,
      };

    default:
      return state;
  }
};
