import { compareAsc } from "date-fns";

function getPaidVehicles(vehicles, userUID) {

  let paidDays = vehicles.filter((vehicle) => {
    const insuranceEndDate = new Date(
      vehicle.insuranceEndYear,
      // Substract 1 to the month because months on JS start at 0
      // And we save the month on the database starting at 1
      vehicle.insuranceEndMonth - 1,
      vehicle.insuranceEndDay
    );
    // console.log(insuranceEndDate, new Date());
    const result = compareAsc(insuranceEndDate, new Date());
    if (result == 1) return true;
    else {
      // postMessageInClaimsChannel({
      //   text: alertInClaimsAndComments,
      // });
      // addDocument(`users/${userUID}/comments`, {
      //   comment: `Mensaje automático: ${alertInClaimsAndComments}`,
      //   timestamp: Date.now()
      // });
      return false;
    }
  });
  return paidDays;
}

export { getPaidVehicles };
