import {
  Stack,
  TextField,
  Typography,
  Input,
  FormControl,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UserState from "../User/UserState";
import { useNavigate } from "react-router";
import { getDocuments } from "../Services/firestore";
import { useState } from "react";
import Swal from "sweetalert2";

function AssistanceForm() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const handleOnSubmit = async () => {
    setLoading(true);
    const cleanEmail = email.trim();
    try {
      const response = await getDocuments({
        collectionPath: "users",
        collectionGroupQuery: false,
        where: ["email", "==", cleanEmail],
        limit: 1,
      });
      const json = await response.json();
      if (json.docs.length == 0) {
        Swal.fire(
          "",
          "El correo ingresado no es correcto, inténtalo nuevamente",
          "error"
        );
        return;
      }
      navigate(`/claims?uid=${json.docs[0].id}`);
    } catch (e) {
      Swal.fire("", "Algo salió mal, inténtalo nuevamente", "error");
    } finally {
      setEmail("");
      setLoading(false);
    }
  };

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Stack
      sx={{
        height: "auto",
        padding: "1.5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant={"h5"}
        sx={{ textAlign: "center", fontWeight: "bold", margin: "3rem 0" }}
      >
        Solicitar Asistencia
      </Typography>
      <Stack spacing={2} sx={{ maxWidth: "450px" }}>
        <Typography variant={"p"}>
          Ingresa tu correo electrónico para ser redirigido a nuestro portal de
          asistencias.
        </Typography>
        <FormControl component={"form"} type="submit" onSubmit={handleOnSubmit}>
          <Stack spacing={2}>
            <TextField
              type="text"
              size={"small"}
              placeholder="Escribe tu correo electrónico"
              variant="outlined"
              disabled={loading}
              value={email}
              onChange={handleOnChange}
            />
            <LoadingButton
              type="submit"
              elevation={0}
              onClick={handleOnSubmit}
              variant="contained"
              loading={loading}
              sx={{ width: "35%", alignSelf: "end", textTransform: "none" }}
            >
              Aceptar
            </LoadingButton>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
}

export default AssistanceForm;
