import Swal from "sweetalert2";

async function getAddressFromCoords(lng, lat) {
  if(!lng || !lat) return
  let URL = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/googleMaps/geocode?lng=${lng}&lat=${lat}`;
  try {
    const response = await fetch(URL, {
      method: "GET",
    });
    const json = await response.json();
    const formatedAddress = TRANSFORMER_response_address(json.response);
    return formatedAddress;
  } catch (e) {
    Swal.fire({
      icon: "error",
      text: "No se ha encontrado una ubicación valida. Asegurate de ingresar coordenadas correctas.",
    });
    return "Error"
  }
}

function TRANSFORMER_response_address(response) {
  return response.results[0].formatted_address;
}
export { getAddressFromCoords };
