import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Skeleton, Stack, Button } from "@mui/material";

import getClaims from "../../Services/claims/getClaims";
import useQueryParams from "../../hooks/useQueryParams";

import Swal from "sweetalert2";
import ClaimCard from "./ClaimCard";

function ClaimsHistory(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [claims, setClaims] = useState([]);

  const navigate = useNavigate();

  const query = useQueryParams();
  const uid = query.get("uid");

  useEffect(() => {
    (async () => {
      try {
        const claims = await getClaims({ userUID: uid, limit: 20 });
        setClaims(claims);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setClaims([]);
        Swal.fire("¡Ups!", "Algo salió mal, inténtalo nuevamente.", "error");
      }
    })();
  }, [uid]);

  function redirectToAsistancePlatform() {
    navigate(`/claims?uid=${uid}`);
  }

  return (
    <Container
      sx={{ p: 4, display: "flex", flexDirection: "column", gap: 4 }}
      maxWidth="sm"
    >
      <Typography variant="h6">Historial de siniestros</Typography>

      <Button
        sx={{ textTransform: "none" }}
        onClick={redirectToAsistancePlatform}
        variant="contained"
        disableElevation
        color="error"
      >
        Reportar siniestro o solicitar asistencia
      </Button>

      {isLoading ? (
        <Stack>
          {Array(6)
            .fill(0)
            .map((_, idx) => (
              <Skeleton key={idx} animation="wave" height="90px" />
            ))}
        </Stack>
      ) : claims.length > 0 ? (
        claims.map((claim) => (
          <ClaimCard {...{ ...claim }} key={claim.claimId} />
        ))
      ) : (
        <Stack>
          <Typography>
            No tienes siniestros ni asistencias hasta el momento. ¡Sigue así, ya
            que harás que tu prima baje por nuestro{" "}
            <a
              style={{ color: "#00bbee" }}
              without
              rel="noreferrer"
              target="_blank"
              href={`https://clupp-rewards.web.app/users/${uid}`}
            >
              programa de lealtad
            </a>
            !
          </Typography>
        </Stack>
      )}
    </Container>
  );
}

export default ClaimsHistory;
