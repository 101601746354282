import { useState } from "react";
import {
  Drawer,
  Toolbar,
  IconButton,
  Box,
  Divider,
  AppBar as MuiAppBar,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import DocState from "./DocState";
import { styled } from "@mui/material/styles";
import logoclup from "../../Assets/LogoCLUP.png";

const drawerWidth = "300px";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const MenuRepository = ({
  setIsHide,
  setDocName,
  setStatusDoc,
  filesData,
  children,
  requiredDocuments = [],
  isClaimActive,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {isClaimActive ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          <img
            src={logoclup}
            alt="clupp-logo"
            style={{
              width: "120px",
              height: "65px",
              objectFit: "scale-down",
            }}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: "300px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={() => setOpen(!open)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {["Pendiente", "En revisión", "Validado", "No aprobado"].map(
          (text, index) => (
            <DocState
              requiredDocuments={requiredDocuments}
              text={text}
              toggleDrawer={() => setOpen(!open)}
              setIsHide={setIsHide}
              setDocName={setDocName}
              setStatusDoc={setStatusDoc}
              key={index}
              filesData={filesData}
            />
          )
        )}
      </Drawer>
      <Main open={open}>{children}</Main>
    </Box>
  );
};

export default MenuRepository;
