import { Button, Stack, Typography } from "@mui/material";
import getContactPhone from "../../utils/getContactPhone";
const VehiculoCancelado = ({ nameAsegurado, lastAsegurado }) => {
  const whatsappUrl =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola%20mi%20nombre%20es %20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    "%20estoy%20tratando%20de%20solicitar%20una%20asistencia%20víal.";
  return (
    <Stack
      direction="column"
      justifyContent="flex-center"
      alignItems="flex-center"
      spacing={2}
      sx={{ padding: "10px", width: "80%", textAlign: "justify" }}
    >
      <Typography>
        No cuentas con ningún vehículo asegurado. Ponte en contacto con nosotros
        para cualquier aclaración.
      </Typography>
      <Stack
        direction="column"
        justifyContent="flex-center"
        alignItems="flex-center"
        spacing={2}
        sx={{ padding: "60px" }}
      >
        <a
          href={whatsappUrl}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "initial",
            }}
          >
            Contactar a un asesor
          </Button>
        </a>
      </Stack>
    </Stack>
  );
};
export default VehiculoCancelado;
