import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, doc, onSnapshot } from "firebase/firestore";
import useQueryParams from "../../hooks/useQueryParams";
import { fetchUser } from "../../Services/users";

import MenuRepository from "../../Components/Repository/MenuRepository";
import UploadDoc from "../../Components/Repository/UploadDoc";
import InitialViewRepository from "./InitialViewRepository";
import getRequiredDocuments from "./getRequiredDocuments";

const RepositoryInfo = () => {
  const [statusDoc, setStatusDoc] = useState();
  const [docName, setDocName] = useState();
  const [isHide, setIsHide] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const [filesData, setFilesData] = useState([]);
  const [claimData, setClaimData] = useState(undefined);

  const query = useQueryParams();
  const uid = query.get("uid");
  const claimId = query.get("claimId");

  useEffect(() => {
    if (uid) {
      fetchUser(uid).then((user) => {
        setUserData(user);
      });
      return;
    }
  }, [uid]);

  useEffect(() => {
    console.debug("userData", userData);

    if (userData) {
      const claimsCollection = doc(
        db,
        "users",
        userData.uid,
        "claims",
        claimId
      );
      const documentsCollecion = collection(
        db,
        "users",
        userData.uid,
        "claims",
        claimId,
        "documents"
      );
      const unsubClaim = onSnapshot(claimsCollection, (querySnapshot) => {
        if (querySnapshot.exists()) {
          setClaimData(querySnapshot.data());
        }
      });

      const unsub = onSnapshot(documentsCollecion, (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) =>
          docs.push({ ...doc.data(), id: doc.id })
        );
        setFilesData([...docs]);
      });

      return () => {
        unsub();
        unsubClaim();
      };
    }
  }, [userData]);

  const requiredDocuments = Object.entries(
    getRequiredDocuments(claimData?.type?.[0])
  ).map(([key, value]) => {
    return {
      id: key,
      ...value,
    };
  });

  const isClaimActive = claimData?.isActive;
  return (
    <>
      <MenuRepository
        isClaimActive={isClaimActive}
        requiredDocuments={requiredDocuments}
        setIsHide={setIsHide}
        setDocName={setDocName}
        filesData={filesData}
      >
        {isHide ? (
          <UploadDoc
            requiredDocuments={requiredDocuments}
            docName={docName}
            statusDoc={statusDoc}
            setStatusDoc={setStatusDoc}
            userData={userData}
            claimId={claimId}
            filesData={filesData}
          />
        ) : (
          <InitialViewRepository
            isClaimActive={isClaimActive}
            userData={userData}
            setUserData={setUserData}
            claimId={claimId}
          />
        )}
      </MenuRepository>
    </>
  );
};

export default RepositoryInfo;
