async function postMessageInChannel(channelWebhook, messageBody) {
  try {
    const response = await fetch(channelWebhook, {
      method: "POST",
      // omit Content-Type header to avoid CORS error from Slack API
      body: JSON.stringify(messageBody),
    });
    if (!response.ok) {
      throw new Error("Cannot post message to Slack channel");
    }
  } catch (error) {
    console.error(error);
  }
}

export default postMessageInChannel;
