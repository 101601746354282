import { Typography } from "@mui/material";
import "./AdditionalFeatures.css";
import ExtraItemField from "../../Components/ExtraItemField";
const VEHICLE_TYPES = {
  bike: "bicicleta",
  eBike: "bicicleta eléctrica",
  scooter: "monopatín",
  auto: "auto",
  moto: "moto",
};
function DefaultAdditionalFeatures({
  vehicleFeatures = [],
  vehicleType,
  vehicleValue,
  vehiclePremium,
  payPeriod,
  userUID,
}) {
  const vehicleTypeFormatted = VEHICLE_TYPES[vehicleType] || "vehículo";

  const object = {
    auto: 2000000,
    moto: 2000000,
    bike: 20000,
    eBike: 20000,
    scooter: 20000,
    other: 20000,
  };
  const coberturasConductorDePlataforma = [
    {
      title: "Daños al vehículo",
      content: `Cubre daños materiales de tu ${vehicleTypeFormatted} en caso de que tengas un accidente. Esta cobertura tiene un deducible de 10%. Esto significa que en caso de siniestro, a ti te toca cubrir el 10% del monto contratado, y a nosotros el resto`,
      montoCubierto: vehicleValue,
      deducible: 10,
      allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "other"],
    },
    {
      title: "Robo total del vehículo",
      content: `Cubre el robo total de tu ${vehicleTypeFormatted}. Esta cobertura tiene un deducible de 20%. Esto significa que en caso de robo total, nos toca cubrir el 80%`,
      montoCubierto: vehicleValue,
      deducible: 20,
      allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "other"],
    },
    {
      title: "Daños que ocasiones a terceros",
      content: `Cubre los daños que ocasiones a otros con tu ${vehicleTypeFormatted} a causa de un accidente. Esto incluye tanto a personas como objetos. Pero no incluye los daños que se ocasionen a los ocupantes de tu ${vehicleTypeFormatted}. Esta cobertura no tiene deducible. Esto significa que no tienes nada de qué preocuparte, nosotros cubrimos todo`,
      montoCubierto: 3000000,
      deducible: "50 UMAs",
      allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "other"],
    },
    {
      title: "Asistencia vial",
      content:
        vehicleType === "moto"
          ? `Cubre los servicios de asistencia que requiera tu ${vehicleTypeFormatted}. Por ejemplo, si necesitas grúa, te quedaste sin gasolina, o si te quedaste sin batería`
          : `Cubre los servicios de asistencia que requiera tu ${vehicleTypeFormatted}. Por ejemplo, si necesitas grúa, te quedaste sin gasolina, requieres cambio de llanta, te quedaste sin batería, o si  requieres un cerrajero`,
      montoCubierto: "Incluido",
      deducible: 0,
      allowedVehicle: ["auto", "moto"],
    },
    {
      title: "Asistencia legal",
      content: `En caso de que lo necesites, si causaste daños con tu ${vehicleTypeFormatted} o fue robado, cubrimos tus gastos de defensa jurídica a través de los abogados que nosotros designamos. En el caso de que ocasiones daños con cualquier otro vehículo, no podemos ayudarte con los gastos legales`,
      montoCubierto: "Incluido",
      deducible: 0,
      allowedVehicle: ["auto", "moto"],
    },
    {
      title: "Gastos médicos al contratante",
      content: `Cubre los gastos médicos a tu persona a causa de un accidente en tu ${vehicleTypeFormatted}`,
      montoCubierto: 20000,
      deducible: 0,
      allowedVehicle: ["bike", "eBike", "scooter", "other"],
    },
    {
      title: "Gastos médicos a ocupantes",
      content: `Cubre los gastos médicos a tu persona a causa de un accidente en tu ${vehicleTypeFormatted}`,
      montoCubierto: 200000,
      deducible: 0,
      allowedVehicle: ["auto", "moto"],
    },
    // {
    //   title: "Accidentes Personales",
    //   content: `Cubre los gastos médicos que se generen a causa de que tengas un accidente que te cause lesiones físicas en cualquier momento.  No importa si ocurre en el carro de un amigo, en el transporte público, en tu casa o en cualquier lugar. Esta cobertura tiene un deducible de $500 por evento.`,
    //   montoCubierto: 20000,
    //   deducible: "$500",
    //   allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "others"],
    // },
    // {
    //   title: "Chofer privado en caso de accidente",
    //   content: `En caso de accidente y que tu ${
    //     vehicleTypeFormatted
    //   } se tenga que ir al taller, te damos saldo en Uber, ¡para que te sigas moviendo como normalmente lo haces!`,
    //   montoCubierto: "Incluido",
    //   deducible: 0,
    //   allowedVehicle: ["auto", "moto"],
    // },
  ];
  const coberturas = [
    {
      title: "Daños al vehículo",
      content: `Cubre daños materiales de tu ${vehicleTypeFormatted} en caso de que tengas un accidente. Esta cobertura tiene un deducible de 5%. Esto significa que en caso de siniestro, a ti te toca cubrir el 5% del monto contratado, y a nosotros el resto`,
      montoCubierto: vehicleValue,
      deducible: 5,
      allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "other"],
    },
    {
      title: "Robo total del vehículo",
      content: `Cubre el robo total de tu ${vehicleTypeFormatted}. Esta cobertura tiene un deducible de 10%. Esto significa que en caso de robo total, nos toca cubrir el 90%`,
      montoCubierto: vehicleValue,
      deducible: 10,
      allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "other"],
    },
    {
      title: "Daños que ocasiones a terceros",
      content: `Cubre los daños que ocasiones a otros con tu ${vehicleTypeFormatted} a causa de un accidente. Esto incluye tanto a personas como objetos. Pero no incluye los daños que se ocasionen a los ocupantes de tu ${vehicleTypeFormatted}. Esta cobertura no tiene deducible. Esto significa que no tienes nada de qué preocuparte, nosotros cubrimos todo`,
      montoCubierto: object[vehicleType],
      deducible: 0,
      allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "other"],
    },
    {
      title: "Asistencia vial",
      content:
        vehicleType === "moto"
          ? `Cubre los servicios de asistencia que requiera tu ${vehicleTypeFormatted}. Por ejemplo, si necesitas grúa, te quedaste sin gasolina, o si te quedaste sin batería`
          : `Cubre los servicios de asistencia que requiera tu ${vehicleTypeFormatted}. Por ejemplo, si necesitas grúa, te quedaste sin gasolina, requieres cambio de llanta, te quedaste sin batería, o si  requieres un cerrajero`,
      montoCubierto: "Incluido",
      deducible: 0,
      allowedVehicle: ["auto", "moto"],
    },
    {
      title: "Asistencia legal",
      content: `En caso de que lo necesites, si causaste daños con tu ${vehicleTypeFormatted} o fue robado, cubrimos tus gastos de defensa jurídica a través de los abogados que nosotros designamos. En el caso de que ocasiones daños con cualquier otro vehículo, no podemos ayudarte con los gastos legales`,
      montoCubierto: "Incluido",
      deducible: 0,
      allowedVehicle: ["auto", "moto"],
    },
    {
      title: "Gastos médicos al contratante",
      content: `Cubre los gastos médicos a tu persona a causa de un accidente en tu ${vehicleTypeFormatted}`,
      montoCubierto: 20000,
      deducible: 0,
      allowedVehicle: ["bike", "eBike", "scooter", "other"],
    },
    {
      title: "Gastos médicos a ocupantes",
      content: `Cubre los gastos médicos a tu persona a causa de un accidente en tu ${vehicleTypeFormatted}`,
      montoCubierto: 200000,
      deducible: 0,
      allowedVehicle: ["auto", "moto"],
    },
    {
      title: "Accidentes Personales",
      content: `Cubre los gastos médicos que se generen a causa de que tengas un accidente que te cause lesiones físicas en cualquier momento.  No importa si ocurre en el carro de un amigo, en el transporte público, en tu casa o en cualquier lugar. Esta cobertura tiene un deducible de $500 por evento.`,
      montoCubierto: 20000,
      deducible: "$500",
      allowedVehicle: ["auto", "moto", "bike", "eBike", "scooter", "others"],
    },
    {
      title: "Chofer privado en caso de accidente",
      content: `En caso de accidente y que tu ${vehicleTypeFormatted} se tenga que ir al taller, te damos saldo en Uber, ¡para que te sigas moviendo como normalmente lo haces!`,
      montoCubierto: "Incluido",
      deducible: 0,
      allowedVehicle: ["auto", "moto"],
    },
  ];

  function getAmountValue(cobertura) {
    const type = typeof cobertura;
    const value =
      type === "string" ? cobertura : `$ ${cobertura.toLocaleString("en-US")}`;
    return value;
  }

  const isPlatformDriver = vehicleFeatures.includes(
    "Soy conductor de plataforma"
  );

  const array = isPlatformDriver ? coberturasConductorDePlataforma : coberturas;
  return (
    <>
      <Typography style={{ textAlign: "center", fontSize: "1rem" }}>
        Coberturas Base
      </Typography>

      {array.map((cobertura, index) => {
        if (cobertura.allowedVehicle.includes(vehicleType)) {
          const { deducible, montoCubierto, content, title } = cobertura;
          const deductiblePercentage =
            typeof deducible === "string" ? ` ${deducible}` : ` ${deducible}%`;

          const deductibleValue =
            deducible === "$500"
              ? deducible
              : ` $${Math.ceil(vehicleValue * (deducible / 100)).toLocaleString(
                  "en-US"
                )}`;

          const isCarOrMoto = ["auto", "moto"].includes(vehicleType);
          const isPersonalAccidentOrMedicalExpenses = [
            "Gastos médicos al contratante",
            "Accidentes Personales",
          ].includes(cobertura.title);

          const isCoverageThatNeedsCorrectDeductible =
            !isCarOrMoto && isPersonalAccidentOrMedicalExpenses;

          return (
            <ExtraItemField
              key={index}
              message={content}
              label={title}
              checked={true}
              monto={
                <>
                  <p>Monto incluido</p>
                  <p>{getAmountValue(montoCubierto)}</p>
                </>
              }
              price={
                <>
                  <Typography variant="span" style={{ fontSize: "0.9rem" }}>
                    Lo que tú pagarías es
                    {isCoverageThatNeedsCorrectDeductible
                      ? " $500"
                      : deductiblePercentage}{" "}
                    de deducible.{" "}
                  </Typography>
                  {!isCoverageThatNeedsCorrectDeductible ? (
                    deducible === 0 ? null : (
                      <p>{deductibleValue}</p>
                    )
                  ) : null}
                </>
              }
            />
          );
        }
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "6px",
          flex: "1",
        }}
      >
        <Typography
          variant="body1"
          style={{ fontSize: "0.95rem", fontWeight: "bold" }}
        >
          Subtotal Coberturas Base:
        </Typography>
        <Typography variant="body1" style={{ fontSize: "0.95rem" }}>
          $ {vehiclePremium.toLocaleString("en-US")}
          <p style={{ fontSize: "0.7rem", textAlign: "end" }}>/{payPeriod}</p>
        </Typography>
      </div>
    </>
  );
}

export default DefaultAdditionalFeatures;
