import { useState } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { Stack } from "@mui/material";
import { getAddressFromCoords } from "../../utils/getAddressFromCoords";
import { CircularProgress } from "@mui/material";
import "./Map.css";

const GOOGLE_API_KEY = "AIzaSyDbjyprEQYhsmO0Tuw7LsKhM6SnLlBzX9A";
const DEFAULT_COORDS = {
  lat: 19.42847,
  lng: -99.12766,
};

function Map({
  setPlaceId = () => {},
  setDirectionId = () => {},
  callBack,
  position,
  children,
  disabled,
  style = { width: "300px", height: "300px" },
}) {
  const { lat, lon: lng } = position;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const [isLoading, setIsLoading] = useState();
  const containerStyle = {
    ...style,
    opacity: `${isLoading ? "0.5" : "1"}`,
  };

  async function handleDirection(e) {
    setIsLoading(true);
    try {
      let zipCode = "";
      const geocoder = new window.google.maps.Geocoder();
      await geocoder.geocode(
        { location: { lat: e.latLng.lat(), lng: e.latLng.lng() } },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              const addressComponents = results[0].address_components;
              const postalCodeComponent = addressComponents.find((component) =>
                component.types.includes("postal_code")
              );
              zipCode = postalCodeComponent.long_name;

              setPlaceId(results[0].place_id);
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        }
      );
      const direction = await getAddressFromCoords(
        e.latLng.lng(),
        e.latLng.lat()
      );
      callBack(e, direction, zipCode);
      setDirectionId(direction)
      setIsLoading(false);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }
  function validatePositionValues() {
    if (
      !lat ||
      !lng ||
      isNaN(lat) ||
      typeof lat != "number" ||
      isNaN(lng) ||
      typeof lng != "number"
    ) {
      return false;
    }
    return true;
  }

  if (!isLoaded) return <div>Cargando...</div>;

  const isValidPosition = validatePositionValues();
  const mapCenterCoords = isValidPosition ? { lat, lng } : DEFAULT_COORDS;
  const mapZoom = isValidPosition ? 18 : 10;

  return (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenterCoords}
        zoom={mapZoom}
        onClick={(e) => {
          if (!disabled) {
            handleDirection(e);
          }
        }}
      >
        {lat && lng && <MarkerF position={{ lat, lng }} />}
        {isLoading && (
          <Stack
            sx={{ width: "100%", height: "100%" }}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        )}
      </GoogleMap>
      {children}
    </div>
  );
}

export default Map;
