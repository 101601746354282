import { Stack, TextField, Typography } from "@mui/material";

import verifyNumber from "../../Assets/verifyNumber.jpg";
import { LoadingButton } from "@mui/lab";
import SwipeableEdgeDrawer from "../../Components/CustomDrawer";
import { useState } from "react";

export default function AuthPhoneForm({
  onSubmit = () => {},
  isLoading,
  setPhoneNumber,
  phoneNumber,
  onSubmitConfirmCodeNumberHandler,
  isPhoneNumberError,
  setIsPhoneNumberError,
}) {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  async function submitHander(e) {
    e.preventDefault();
    try {
      const response = await onSubmit();
      response && setIsOpenDrawer(true);
    } catch (e) {
      console.error(e);
    }
  }

  function onChange(e) {
    if (e.target.value.length !== 10) {
      setIsPhoneNumberError(true);
    } else {
      setIsPhoneNumberError(false);
    }
    setPhoneNumber(e.target.value);
  }

  return (
    <Stack>
      <SwipeableEdgeDrawer
        open={isOpenDrawer}
        setOpen={setIsOpenDrawer}
        onSubmit={onSubmitConfirmCodeNumberHandler}
        number={phoneNumber}
        isLoading={isLoading}
      />

      <Stack>
        <img
          src={verifyNumber}
          alt=""
          style={{ width: "100%", maxWidth: "210px", margin: "12px auto" }}
        />
        <Typography
          id="start-verification"
          variant="h5"
          sx={{ color: "black", textAlign: "center" }}
        >
          Comencemos verificando tu teléfono
        </Typography>
        <Typography sx={{ textAlign: "center", fontSize: "16px" }}>
          Ingresa tu número de WhatsApp, se enviará un código de verificación.
        </Typography>
        <form
          style={{
            display: "grid",
            gap: "18px",
            marginTop: "24px",
            width: "100%",
          }}
          onSubmit={submitHander}
        >
          <TextField
            placeholder="Ingresa tu número de WhatsApp"
            fullWidth
            variant="standard"
            type="number"
            name="phone"
            value={phoneNumber}
            onChange={onChange}
            error={isPhoneNumberError}
            helperText={
              isPhoneNumberError
                ? "El número telefónico debe tener 10 dígitos"
                : ""
            }
          />
          <LoadingButton
            sx={{ textTransform: "none" }}
            fullWidth
            variant="contained"
            onClick={submitHander}
            loading={isLoading}
            type="submit"
          >
            Verificar
          </LoadingButton>
        </form>
      </Stack>
    </Stack>
  );
}
