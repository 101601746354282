import { Stack, Box, Typography, IconButton, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import logoClupp from "../../Assets/logo-clupp.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import tiktokIcon from "../../Assets/tik-tok.png";
import berealIcon from "../../Assets/bereal.webp";
import userIcon from "../../Assets/userIcon.png";
import { logError } from "../../utils";
import BusinessCard from "../BusinessCard";
import { useAnonymusAuth } from "../../context/anonymusUserContext";
import { getDocument, getDocuments } from "../../Services/firestore";

export default function SmartCard() {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const userUID = params.get("userUID");
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [userData, setUserData] = useState({});
  const whatsappUrl = `https://wa.me/${userData?.whatsapp?.number}`;

  useAnonymusAuth();

  useEffect(() => {
    try {
      Promise.all([
        getDocument({
          docPath: `users/${userUID}`,
        }).then((result) => {
          const data = result.doc;
          const user = {
            firstName: data?.firstName ? data.firstName : "",
            lastName: data?.lastName ? data.lastName : "",
            email: data?.email ? data.email : "",
            phone: data?.phone ? data.phone : "",
            profileURL: data?.profileURL ? data.profileURL : "",
          };
          setUserData((prev) => ({ ...prev, ...user }));
        }),
        getDocuments({
          collectionPath: `users/${userUID}/socialMediaProfiles`,
          limit: 1,
          orderBy: ["timestamp", "desc"],
        }).then(async (response) => {
          const json = await response.json();

          const results = json.docs;

          if (results?.length > 0) {
            setUserData((prev) => ({ ...prev, ...results[0].doc }));
          }
        }),
      ])
        .then(() => {
          setIsFetchingData(false);
        })
        .catch((error) => {
          console.error(error);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    } catch (error) {
      console.log(error);
      setIsFetchingData(false);
      logError({
        error: error?.message,
        source: "Plataforma vCard",
        metadata: {
          description: "Error al obtener datos de la vCard",
          userUID,
        },
      });
    }
  }, [userUID]);

  if (isFetchingData)
    return (
      <div
        style={{
          width: "100%",
          maxWidth: "540px",
          margin: "0 auto",
        }}
      >
        <header style={{ display: "grid", placeItems: "center" }}>
          <a href="https://clupp.com.mx/" target="_blank" rel="noreferrer">
            <img
              src={logoClupp}
              alt="logo de clupp"
              style={{ width: "110px" }}
            />
          </a>
        </header>

        <Box
          sx={{
            display: "grid",
            gap: "10px",
            margin: "0 20px",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={600}
            sx={{
              borderRadius: "10px",
            }}
          />
        </Box>
      </div>
    );

  return (
    <>
      <header style={{ display: "grid", placeItems: "center" }}>
        <a href="https://clupp.com.mx/" target="_blank" rel="noreferrer">
          <img src={logoClupp} alt="logo de clupp" style={{ width: "110px" }} />
        </a>
      </header>
      <main
        style={{
          margin: "0 auto",
          display: "grid",
          gap: "3rem",
          padding: "3rem",
        }}
      >
        <BusinessCard
          style={{
            padding: "2rem",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Stack alignItems="center">
            <Box>
              {userData?.profileURL ? (
                <img
                  style={{
                    height: "200px",
                    width: "200px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  src={userData.profileURL}
                  alt="imagen de perfil"
                />
              ) : (
                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <img
                    style={{
                      height: "200px",
                      width: "200px",
                      objectFit: "contain",
                    }}
                    src={userIcon}
                    alt="imagen de perfil"
                  />
                </Box>
              )}
            </Box>
          </Stack>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", fontWeight: "700" }}
          >
            {userData.firstName} {userData.lastName}
          </Typography>
          <Stack gap={2}>
            {userData?.email && (
              <Typography sx={{ textAlign: "center" }}>
                <a
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  href={`mailto:${userData?.email}`}
                >
                  {userData.email}
                </a>
              </Typography>
            )}
            {userData?.whatsapp?.number && userData?.whatsapp?.show && (
              <Typography sx={{ textAlign: "center" }}>
                <a
                  href={`tel:${userData.whatsapp.number}`}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {userData?.whatsapp?.number}
                </a>
              </Typography>
            )}
          </Stack>
          <Stack>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {userData?.linkedIn?.url && userData?.linkedIn?.show && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={userData?.linkedIn?.url}
                >
                  <IconButton>
                    <LinkedInIcon sx={{ color: "black" }} fontSize="large" />
                  </IconButton>
                </a>
              )}
              {userData?.instagram?.url && userData?.instagram?.show && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={userData?.instagram?.url}
                >
                  <IconButton>
                    <InstagramIcon sx={{ color: "black" }} fontSize="large" />
                  </IconButton>
                </a>
              )}
              {userData?.facebook?.url && userData?.facebook?.show && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={userData?.facebook?.url}
                >
                  <IconButton>
                    <FacebookIcon sx={{ color: "black" }} fontSize="large" />
                  </IconButton>
                </a>
              )}
              {userData?.whatsapp?.number && userData?.whatsapp?.show && (
                <a target="_blank" rel="noreferrer" href={whatsappUrl}>
                  <IconButton>
                    <WhatsAppIcon sx={{ color: "black" }} fontSize="large" />
                  </IconButton>
                </a>
              )}
              {userData?.twitter?.url && userData?.twitter?.show && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={userData?.twitter?.url}
                >
                  <IconButton>
                    <TwitterIcon sx={{ color: "black" }} fontSize="large" />
                  </IconButton>
                </a>
              )}
              {userData?.tiktok?.url && userData?.tiktok?.show && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={userData?.tiktok?.url}
                >
                  <IconButton>
                    <img src={tiktokIcon} style={{ width: "30px" }} alt="" />
                  </IconButton>
                </a>
              )}
              {userData?.beReal?.url && userData?.beReal?.show && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={userData?.beReal?.url}
                >
                  <IconButton>
                    <img
                      src={berealIcon}
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                      }}
                      alt=""
                    />
                  </IconButton>
                </a>
              )}
            </Box>
          </Stack>
        </BusinessCard>
        <footer>
          <Box sx={{ display: "grid", placeItems: "center" }}>
            <Typography sx={{ color: "#00bbee" }}>
              <a
                style={{ color: "#00bbee" }}
                href="https://clupp.com.mx/"
                target="_blank"
                rel="noreferrer"
              >
                Saber más de Clupp
              </a>
            </Typography>
          </Box>
        </footer>
      </main>
    </>
  );
}
