async function getAddressInfoFromCoords({ lon, lat }) {
  if (!lon || !lat)
    return {
      postalCode: "",
      address: "",
    };
  let URL = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/googleMaps/geocode?lng=${lon}&lat=${lat}`;
  try {
    const response = await fetch(URL, {
      method: "GET",
    });
    const json = await response.json();

    const formatedAddress = json.response.results[0].formatted_address || "";
    const postalCodeComponent =
      json.response.results[0].address_components.find((component) =>
        component.types.includes("postal_code")
      );
    const postalCode = postalCodeComponent?.long_name || "";

    return {
      postalCode,
      address: formatedAddress,
    };
  } catch (e) {
    return {
      postalCode: "",
      address: "",
    };
  }
}

export { getAddressInfoFromCoords };
