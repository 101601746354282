import { isAfter, isBefore, addHours } from "date-fns";

function getContactPhone() {
  const dateString = new Date().toDateString();
  const today = new Date(dateString);
  const day = today.getDay();

  const startDate = addHours(today, 9);
  const endDate = addHours(today, 18);

  const isFriday = day === 5;

  const isAfter9AM = isAfter(new Date(), startDate);
  const isBefore8PM = isBefore(new Date(), endDate);

  if (isFriday) {
    return "5539718386";
  }

  if (isAfter9AM && isBefore8PM) {
    return "5576430354";
  } else {
    return "5539718386";
  }
}

export default getContactPhone;
