import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import { fetchUser } from "../Services/users";
import logoclup from "../Assets/logo-clupp.png";
import { Stack, Typography, Divider } from "@mui/material";

const Header = ({ sx = { height: "" }, showName = true, linksComponent }) => {
  const query = useQueryParams();
  const [userData, setUserData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  // user uid
  const uid = query.get("uid");

  useEffect(() => {
    if (uid) {
      fetchUser(uid).then((user) => {
        setUserData(user);
        setIsLoading(false);
      });
      return;
    }
    setIsLoading(false);
  }, [uid]);

  return (
    <Stack sx={{ ...sx, width: "99vw", maxWidth: "99vw" }}>
      <Stack
        direction="row"
        py={1}
        px={[2, 4]}
        sx={{
          position: "fixed",
          background: "#fff",
          width: "99vw",
          maxWidth: "99vw",
          borderBottom: "1px solid #a3a3a3",
          zIndex: "1000",
          maxHeigth: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* <div style={{ flex: 1 }} /> */}
        {!isLoading && Boolean(userData) && showName ? (
          <Typography
            variant={["h6", "h4"]}
            sx={{
              textAlign: "center",
            }}
          >
            Hola
            {userData?.firstName ? `, ${userData.firstName.split(" ")[0]}` : ""}
          </Typography>
        ) : null}

        <img
          src={logoclup}
          alt="clupp-logo"
          style={{
            marginRight: "20px",
            width: "170px",
            height: "65px",
            objectFit: "scale-down",
          }}
        />
        {typeof linksComponent !== "undefined" && (
          <Stack
            sx={{
              pr: 2,
              justifySelf: "flex-end",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {linksComponent}
          </Stack>
        )}
      </Stack>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ marginBottom: "86px" }}
      />
      <Stack sx={{ flex: "1" }}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
export default Header;
