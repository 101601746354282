import {
  Stack,
  ImageList,
  ImageListItem,
  Typography,
  Box,
  Tab,
  Tabs,
  Button,
  Tooltip,
  useTheme,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Dialog from "../../Components/Dialog";
import { useState, useRef, useEffect } from "react";
import PreviewDialog from "./PreviewDialog";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { storage, db } from "../../firebase";
import { AttachFile } from "@mui/icons-material";
import FileBox from "./FileBox";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function FilesDialog({
  isOpen,
  onClose,
  files = [],
  userId,
  vehicleId,
  event,
}) {
  const [itemData, setItemData] = useState([])
  const [filesData, setFilesData] = useState([])
  const [value, setValue] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [isSavingFiles, setIsSavingFiles] = useState(false);
  const theme = useTheme();
  const fileInput = useRef();

  useEffect(() => {
    setItemData(files.filter((item) => item?.type.includes("image")))
    setFilesData(files.filter((item) => !item?.type.includes("image")))
  }, [files])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleDialogClose() {
    setIsOpenModal(false);
  }

  function handleSelectFile(file) {
    setSelectedFile(file);
    setIsOpenModal(true);
  }

  function handleOnDeleteImage(filename) {
    setNewFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== filename)
    );
  }

  function handleFileInputOnChange(e) {
    const commingFiles = [...e.target.files];
    if (commingFiles.length > 0) {
      commingFiles.forEach((commingFile) => {
        let id = Math.random().toString();
        setNewFiles((prev) => [
          ...prev,
          {
            id,
            url: undefined,
            name: commingFile.name,
            type: commingFile.type,
            size: commingFile.size,
            progress: 0,
          },
        ]);
        const storageRef = ref(
          storage,
          `users/${userId}/vehicles/${vehicleId}/events/${commingFile.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, commingFile);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setNewFiles((prev) =>
              prev.map((file) => {
                if (file.id === id) {
                  file.progress = progress;
                }
                return file;
              })
            );
          },
          (err) => {
            console.warn(err);
          },
          async () => {
            const fileURL = await getDownloadURL(uploadTask.snapshot.ref);
            setNewFiles((prev) =>
              prev.map((file) => {
                if (file.id === id) {
                  file.url = fileURL;
                  delete file.progress;
                }
                return file;
              })
            );
          }
        );
      });
    }
    e.target.value = null;
  }

  async function updateEventData() {
    if(newFiles.length <= 0) {
      Swal.fire({
        icon: "warning",
        text: "No hay archivos nuevos para guardar",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      })
      return
    }
    if(newFiles.some(item => Boolean(item?.progress))) {
      Swal.fire({
        icon: "warning",
        text: "No se ha subido completamente alguno de los archivos, por favor, intentalo en un momento más.",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      })
      return
    }
    setIsSavingFiles(true);
    const saveData = {
      files: files.concat(newFiles),
      timestampLastUpdate: new Date().getTime(),
    };
    const docRef = doc(
      db,
      `users/${userId}/vehicles/${vehicleId}/events`,
      event?.id
    );
    try {
      await updateDoc(docRef, saveData);
      setNewFiles([]);
      Swal.fire({
        icon: "success",
        text: "Se han guardado los archivos",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Se produjo un error al intentar guardar los datos, intentalo nuevamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
    } finally {
      setIsSavingFiles(false);
    }
  }

  return (
    <>
      <PreviewDialog
        file={selectedFile}
        isOpen={isOpenModal}
        onClose={handleDialogClose}
      />
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title="Archivos adjuntos"
        Actions={
          <Box sx={{ width: "100%", margin: "8px" }}>
              <Box marginBottom={"8px"} display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1}>
                {newFiles.map((file) => (
                  <FileBox
                    file={file}
                    onDelete={handleOnDeleteImage}
                    key={file.id}
                  />
                ))}
              </Box>
              <input
                style={{ display: "none" }}
                type="file"
                ref={fileInput}
                multiple
                onChange={handleFileInputOnChange}
              />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Tooltip title="Adjuntar Archivos">
                  <Button
                    onClick={() => fileInput.current.click()}
                    endIcon={<AttachFile />}
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    fullWidth
                    size="small"
                  >
                    Cargar archivos o fotos
                  </Button>
                </Tooltip>
                <LoadingButton
                  fullWidth
                  sx={{ textTransform: "none" }}
                  loading={isSavingFiles}
                  variant="contained"
                  onClick={updateEventData}
                  size="small"
                >
                  Guardar archivos
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        }
        sx={{
          width: "100%",
          "&.MuiDialogContent-root": {
            padding: "0px 5px",
          },
          "& .MuiPaper-root": {
            margin: "12px",
            width: "100%",
          },
        }}
        content={
          <Stack>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab sx={{ textTransform: "none" }} label="Imágenes" />
                <Tab sx={{ textTransform: "none" }} label="Documentos" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box sx={{ height: 450 }}>
                {itemData.length > 0 ? (
                  <ImageList sx={{ width: "100%" }} cols={2} rowHeight={164}>
                    {itemData.map((item, index) => (
                      <ImageListItem key={index}>
                        <img
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            height: "100px",
                            cursor: "pointer",
                          }}
                          src={item.url}
                          srcSet={item.url}
                          alt={item.title}
                          loading="lazy"
                          onClick={() => handleSelectFile(item)}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                ) : (
                  <Box sx={{ margin: "10px 0" }}>
                    <Typography>Sin imágenes registradas</Typography>
                  </Box>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box sx={{ height: 450 }}>
                {filesData.length <= 0 ? (
                  <Box sx={{ margin: "10px 0" }}>
                    <Typography>Sin documentos registrados</Typography>
                  </Box>
                ) : (
                  filesData.map((item, index) => (
                    <Box sx={{ padding: "12px" }} key={index}>
                      <Box
                        onClick={() => handleSelectFile(item)}
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          cursor: "pointer",
                          flexDirection: "row",
                          textDecoration: "underline",
                          color: "#0058f4",
                        }}
                      >
                        <InsertDriveFileIcon />
                        {item.name}
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </TabPanel>
          </Stack>
        }
      />
    </>
  );
}
