import "./ElegirMovilidad.css";
import CircularProgress from '@mui/material/CircularProgress';

const ElegirMovilidad = ({
  amounts,
  selectedMobility,
  setSelectedMobility,
  vehicleData,
  vehicle,
  whatsappUrl,
  isLoading
}) => {
  const handleMobility = (mobility, premium) => {
    setSelectedMobility({ mobility, premium });
  };

  function getPeriodicityLabel(periodicity) {
    switch (periodicity) {
      case "month":
        return "al mes";
      case "3month":
        return "cada 3 meses";
      case "6month":
        return "cada 6 meses";
      case "year":
        return "al año";
      default:
        return "";
    }
  }
  const mobilityDescription = (n) => {
    if (vehicleData?.features?.includes("Soy conductor de plataforma")) {
      if (n === 0) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "si recorres menos de 1,500 km al mes";
        else return "mínima";
      } else if (n === 1) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "si recorres de 1,500 a 2,500 km al mes";
        else return "reducida";
      } else if (n === 2) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "si recorres de 2,501 a 5,000 km al mes";
        else return "normal";
      } else if (n === 3) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "si recorres de 5,001 a 7,500 km al mes";
        else return "intensiva";
      }
    } else {
      if (n === 0) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "si recorres menos de 250 km al mes";
        else return "mínima";
      } else if (n === 1) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "si recorres de 250 a 500 km al mes";
        else return "reducida";
      } else if (n === 2) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "si recorres de 500 a 1,200 km al mes";
        else return "normal";
      } else if (n === 3) {
        if (vehicleData?.type === "auto" || vehicleData?.type === "moto")
          return "Más de 1,200 km al mes";
        else return "intensiva";
      }
    }
  };

  const periodicityLabel = getPeriodicityLabel(vehicle.period);
  if(isLoading === true) return <div style={{width: "100%"}}><CircularProgress /></div>
  return (
    <div className="elegir-movilidad">
      <div className="elegir-movilidad-content">
        <div className="cards-container">
          {/* 
            // Refactorizar el código para mostrar las tarjetas de manera recursiva
            // Crear un componente que abstraiga la lógica  de las tarjetas para no mostar el mismo código 4 veces
          */}
          {vehicleData.mobility !== 0 && (
            <div
              className={
                selectedMobility.mobility === 0 ? "selected-plan" : "plan"
              }
              onClick={() => {
                handleMobility(0, 1);
              }}
            >
              <h4>Mínima</h4>
              {selectedMobility.mobility !== 0 && (
                <p className="kilometers">{mobilityDescription(0)}</p>
              )}
              <p className="premium">
                {amounts &&
                  `$ ${(
                    amounts?.quotation?.periods[vehicle.period]?.premium0.total +
                    amounts?.quotation?.periods[vehicle.period]?.extras.reduce(
                      (sum, extra) => sum + extra.premium,
                      0
                    )
                  ).toLocaleString("es-MX", {
                    useGruping: true,
                  })}`}
              </p>
              <p className="normal-plan month">{amounts && periodicityLabel}</p>
              {selectedMobility.mobility === 0 && (
                <>
                  <p className="selected-kilometers">
                    {mobilityDescription(0)}
                  </p>
                  <p className="normal-plan">
                    5% de nuestros clientes eligen este plan
                  </p>
                </>
              )}
            </div>
          )}
          {vehicleData.mobility !== 1 && (
            <div
              className={
                selectedMobility.mobility === 1 ? "selected-plan" : "plan"
              }
              onClick={() => {
                handleMobility(1, 1);
                // saveMobility(1, amounts.premium1);
              }}
            >
              <h4>Reducida</h4>
              {selectedMobility.mobility !== 1 && (
                <p className="kilometers">{mobilityDescription(1)}</p>
              )}
              <p className="premium">
                {amounts &&
                  `$ ${(
                    amounts?.quotation?.periods[vehicle.period]?.premium1.total +
                    amounts?.quotation?.periods[vehicle.period]?.extras.reduce(
                      (sum, extra) => sum + extra.premium,
                      0
                    )
                  ).toLocaleString("es-MX", {
                    useGruping: true,
                  })}`}
              </p>
              <p className="normal-plan month">{amounts && periodicityLabel}</p>
              {selectedMobility.mobility === 1 && (
                <>
                  <p className="selected-kilometers">
                    {mobilityDescription(1)}
                  </p>
                  <p className="normal-plan">
                    41% de nuestros clientes eligen este plan
                  </p>
                </>
              )}
            </div>
          )}
          {vehicleData.mobility !== 2 && (
            <div
              className={
                selectedMobility.mobility === 2 ? "selected-plan" : "plan"
              }
              onClick={() => {
                handleMobility(2, 1);
                // saveMobility(2, amounts.premium2);
              }}
            >
              <h4>Normal</h4>
              {selectedMobility.mobility !== 2 && (
                <p className="kilometers">{mobilityDescription(2)}</p>
              )}
              <p className="premium">
                {amounts &&
                  `$ ${(
                    amounts?.quotation?.periods[vehicle.period]?.premium2.total +
                    amounts?.quotation?.periods[vehicle.period]?.extras.reduce(
                      (sum, extra) => sum + extra.premium,
                      0
                    )
                  ).toLocaleString("es-MX", {
                    useGruping: true,
                  })}`}
              </p>
              <p className="normal-plan month">{amounts && periodicityLabel}</p>
              {selectedMobility.mobility === 2 && (
                <>
                  <p className="selected-kilometers">
                    {mobilityDescription(2)}
                  </p>
                  <p className="normal-plan">
                    46% de nuestros clientes eligen este plan
                  </p>
                </>
              )}
            </div>
          )}
          {vehicleData.mobility !== 3 && (
            <div
              className={
                selectedMobility.mobility === 3 ? "selected-plan" : "plan"
              }
              onClick={() => {
                handleMobility(3, 1);
                // saveMobility(3, amounts.premium3);
              }}
            >
              <h4>
                {vehicleData?.features?.includes("Soy conductor de plataforma")
                  ? "Frecuente"
                  : "Intensiva"}
              </h4>
              {selectedMobility.mobility !== 3 && (
                <p className="kilometers">{mobilityDescription(3)}</p>
              )}
              <p className="premium">
                {amounts &&
                  `$ ${(
                    amounts?.quotation?.periods[vehicle.period]?.premium3.total +
                    amounts?.quotation?.periods[vehicle.period]?.extras.reduce(
                      (sum, extra) => sum + extra.premium,
                      0
                    )
                  ).toLocaleString("es-MX", {
                    useGruping: true,
                  })}`}
              </p>
              <p className="normal-plan month">{amounts && periodicityLabel}</p>
              {selectedMobility.mobility === 3 && (
                <>
                  <p className="selected-kilometers">
                    {mobilityDescription(3)}
                  </p>
                  <p className="normal-plan">
                    8% de nuestros clientes eligen este plan
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElegirMovilidad;
