import { Button, Stack, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Dialog from "./Dialog";
import { getContactPhone } from "../utils";
const SuccessRoboReportModal = ({
  openModal,
  idReport,
  nameAsegurado,
  lastNameAsegurado,
  userUID,
}) => {
  const whatsappURL =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola,%20mi%20nombre%20es%20" +
    nameAsegurado +
    "%20" +
    lastNameAsegurado +
    "%20con%20uid%20" +
    userUID +
    "%20necesito%20ayuda%20para%20realizar%20el%20seguimiento%20de%20un%20reporte%20de%20robo%20con%20el%20siguiente%20ID:" +
    idReport;
  return (
    <Dialog
      isOpen={openModal}
      title={
        <Typography sx={{ textAlign: "center" }} variant={"h6"}>
          ¡Reporte generado con éxito!
        </Typography>
      }
      content={
        <Typography sx={{ textAlign: "center" }}>
          El número de tu reporte es: {idReport}. Con este número podrás dar
          seguimiento a tu reporte vía WhatsApp o por llamada.
        </Typography>
      }
      Actions={
        <Stack sx={{width: "100%"}} spacing={2} direction={["column"]}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<PhoneIcon />}
            sx={{
              textTransform: "none",
              width: "100%",
              height: "50px",
              textAlign: "center",
              fontSize: "13px",
            }}
            href={`tel:+525592252185`}
          >
            Llamar por teléfono
          </Button>
          <Button
            fullWidth
            color="success"
            startIcon={<WhatsAppIcon />}
            variant="contained"
            sx={{
              textTransform: "none",
              height: "50px",
              textAlign: "center",
              fontSize: "12px",
            }}
            href={whatsappURL}
            target="_blank"
          >
            Enviar mensaje por WhatsApp
          </Button>
        </Stack>
      }
    />
  );
};

export default SuccessRoboReportModal;
