import React, { useContext, useEffect, useState } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import UserContex from "../../User/UserContex";
import Swal from "sweetalert2";
import { whatReport, whatUrl, ClaveReport, whatDatos } from "../../utils";
import {
  Stack,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Button,
  Select,
  TextField,
} from "@mui/material";
import AutoCompletado from "../AutoCompletado/AutoCompletado";
import Dialog from "../Dialog";
import getContactPhone from "../../utils/getContactPhone";
import getDistance from "../../Services/GoogleMaps/getDistance";
import CircularProgress from "@mui/material/CircularProgress";
import Map from "../Map/Map";
import { configPrueba } from "../../Services/Avi";
/**Grua Solicitada */
const GruaSolicitada = ({
  setTipoAsistencia,
  setHideVehiculo,
  setHideTipo,
  setHideAsistencia,
}) => {
  const {
    report,
    setReport,
    loginData,
    selectV,
    modelData,
    headers,
    nameAsegurado,
    lastAsegurado,
    uidAsegurado,
    inputEl,
  } = useContext(UserContex);
  useEffect(() => {
    setReport({
      ...report,
      startReportTimestamp: Date.now(),
      neutralTransmission: "Si",
      swivelTires: "Si",
    });
  }, []);
  const urlPrueba = configPrueba();

  const [neutral, setNeutral] = useState("Si");
  const handleNeutral = (event) => {
    setNeutral(event.target.value);
    setReport({ ...report, neutralTransmission: event.target.value });
  };
  const [tires, setTires] = useState("Si");
  const handleTires = (event) => {
    setTires(event.target.value);
    setReport({ ...report, swivelTires: event.target.value });
  };
  const [ubicacion, setUbicacion] = useState(0);
  const [latDestino, setLatDestino] = useState(undefined);
  const handleChangeUbicacion = (e) => {
    setUbicacion(e.target.value);
    setReport({ ...report, typeOfPlace: e.target.value });
  };
  useEffect(() => {
    if (report.destinationLocation !== null) {
      setLatDestino({
        latidud: report.destinationLocation.lat,
        longitud: report.destinationLocation.lon,
      });
      //  console.log('Hola')
    }
  }, [report.destinationLocation]);
  const [isMessageGuardar, setIsMessageGuardar] = useState(false);
  const [isMessageDatos, setIsMessageDatos] = useState(false);
  const [isProgreess, setIsProgreess] = useState(false);
  const [isErrorKm, setIsErrorKm] = useState(false);
  const [clear, setClear] = useState(false);
  const [onFoc, setOnFoc] = useState(true);
  const [hideGuarda, setHideGuarda] = useState(false);
  const [kilometros, setKilometros] = useState(undefined);
  const [registerCarData, setRegisterCarData] = useState();
  const [quoteData, setQuoteData] = useState();
  const [tripData, setTripData] = useState({ cost: 0, distance: 0 });
  const [trip, setTrip] = useState(undefined);
  const [destinationPlaceId, setDestinationPlaceId] = useState("");
  const [destinationDirectionId, setDestinationDirectionId] = useState("");
  const [currentPlaceId, setCurrentPlaceId] = useState("");
  const [currentDirectionId, setCurrentDirectionId] = useState("");

  const [quote, setQuote] = useState(undefined);
  const [hideButton, setHideButton] = useState(false);
  useEffect(() => {
    if (modelData !== undefined) {
      registerVehicle();
      // console.log(modelData);
    }
  }, [modelData]);

  useEffect(() => {
    if (modelData !== undefined) {
      // registerVehicle();
      registerVehicle();
    }
  }, [modelData]);

  useEffect(() => {
    if (report.currentLocation && report.destinationLocation) {
      calculateDistance();
      async function calculateDistance() {
        // obtener la distancia entre el dos puntos
        const response = await getDistance({
          origin: [report.currentLocation.lat, report.currentLocation.lon].join(
            ","
          ),
          destination: [
            report.destinationLocation.lat,
            report.destinationLocation.lon,
          ].join(","),
        });
        const json = await response.json();
        const km = json.rows[0].elements[0].distance.text;
        const value = json.rows[0].elements[0].distance.value;
        if (value > 80000) {
          setIsErrorKm(true);
          setKilometros(km);
          setHideGuarda(false);
        } else {
          setHideGuarda(true);
        }
      }
    }
  }, [report.currentLocation, report.destinationLocation]);

  useEffect(() => {
    if (
      report.currentLocation &&
      report.destinationLocation &&
      report.typeOfPlace &&
      report.environmentDescription
    ) {
      setHideButton(true);
    }
  }, [
    report.currentLocation,
    report.destinationLocation,
    report.typeOfPlace,
    report.environmentDescription,
  ]);
  const whatsappDatos = whatDatos(nameAsegurado, lastAsegurado);
  /*Rgistro de Vehiculo en Avi */
  const registerVehicle = () => {
    const vehicleParameters = {
      year: selectV.year,
      color: selectV.color,
      plate: selectV.plates,
      vehicleModelId: modelData.id,
    };
    const urlRegisterVehicle = urlPrueba
      ? `https://zazu.aviapp.mx/sandbox/vehicle/`
      : `https://zazu.aviapp.mx/vehicle/`;
    fetch(urlRegisterVehicle, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(vehicleParameters),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setIsMessageDatos(true);
        } else {
          setRegisterCarData(data);
          setQuote(data.id);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: "Ha ocurrido un error: " + err,
        });
      });
  };

  // Obtener cotización
  const getQuote = (CarId) => {
    if (!report.currentLocation) {
    } else {
      const quoteParameters = {
        startLatitude: report.currentLocation
          ? report.currentLocation.lat
          : null,
        startLongitude: report.currentLocation
          ? report.currentLocation.lon
          : null,
        endLatitude: report.destinationLocation
          ? report.destinationLocation.lat
          : null,
        endLongitude: report.destinationLocation
          ? report.destinationLocation.lon
          : null,
        service: 1,
        questions: JSON.stringify([
          {
            answers: [
              { answer: report.neutralTransmission, id: 1, questionId: 1 },
            ],
            id: 1,
            parentAnswer: 0,
            parentId: 0,
            question: "¿Se puede poner en neutral?",
            service: 1,
            type: 1,
          },
          {
            answers: [{ answer: report.swivelTires, id: 1, questionId: 2 }],
            id: 2,
            parentAnswer: 0,
            parentId: 0,
            question: "¿Giran las 4 llantas?",
            service: 1,
            type: 1,
          },
          {
            answers: [{ answer: report.typeOfPlace, id: 1, questionId: 3 }],
            id: 3,
            parentAnswer: 0,
            parentId: 0,
            question: "¿Donde se encuentra?",
            service: 1,
            type: 1,
          },
        ]),
        vehicleId: CarId,
      };
      const urlQuote = urlPrueba
        ? `https://zazu.aviapp.mx/sandbox/quote/`
        : `https://zazu.aviapp.mx/quote/`;
      fetch(urlQuote, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(quoteParameters),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message || !data) {
            Swal.fire({
              icon: "error",
              text: "Ha ocurrido un error: " + data.message,
            });
          } else {
            console.log();
            setQuoteData(data);
            guardarGrua(data.cost);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: "error: " + error,
          });
        });
    }
  };

  //console.log(report)
  const guardarGrua = async (costo) => {
    const id = report.id;
    const idv = report.vehicle;
    const URIV = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${id}/vehicles/${idv}`;
    const responsev = await fetch(URIV, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //   body: JSON.stringify(data)
    });
    const dataV = await responsev.json();
    //console.log(dataV);
    //const dataA = dataV.doc;
    //const docId = dataV.doc.id;
    const type = "E";
    const uidReportBack = ClaveReport(type);
    setReport({ ...report, claveReport: uidReportBack });
    const data = {
      user: id,
      type: [report.type],
      isActive: report.isActive,
      source: report.source,
      vehicle: report.vehicle,
      reportTimestamp: Date.now(),
      accidentTimestamp: report.startReportTimestamp,
      startReportTimestamp: report.startReportTimestamp,
      assistanceType: "tow",
      neutralTransmission: report.neutralTransmission === "Si" ? true : false,
      swivelTires: report.swivelTires === "Si" ? true : false,
      typeOfPlace:
        (report.typeOfPlace === "A pie de calle" && "street") ||
        (report.typeOfPlace === "Estacionamiento techado" &&
          "coveredParking") ||
        (report.typeOfPlace === "Estacionamiento sin techo" && "openParking"),
      currentAddress: report.currentAddress ? report.currentAddress : "",
      destinationAddress: report.destinationAddress
        ? report.destinationAddress
        : "",
      environmentDescription: report.environmentDescription
        ? report.environmentDescription
        : "",
      currentLocation: report.currentLocation
        ? report.currentLocation
        : { lat: 0, lon: 0 },
      destinationLocation: report.destinationLocation
        ? report.destinationLocation
        : { lat: 0, lon: 0 },
      currentZipcode: report.postalCurrent ? report.postalCurrent : "",
      destinationZipcode: report.postalDestination
        ? report.postalDestination
        : "",
      userReport: id,
    };
    const URI = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${id}/claims/${uidReportBack}`;
    const response = await fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ update: data }),
    });
    const resultado = await response.json();
    registerTrip(uidReportBack, costo);
  };
  const registerTrip = (uidReport, costo) => {
    const tripParameters = {
      startLatitude: report.currentLocation ? report.currentLocation.lat : null,
      startLongitude: report.currentLocation
        ? report.currentLocation.lon
        : null,
      startAddress: report.currentAddress ? report.currentAddress : null,
      endLatitude: report.destinationLocation
        ? report.destinationLocation.lat
        : null,
      endLongitude: report.destinationLocation
        ? report.destinationLocation.lon
        : null,
      endAddress: report.destinationAddress ? report.destinationAddress : null,
      service: 1,
      questions: JSON.stringify([
        {
          answers: [
            {
              answer: report.neutralTransmission && report.neutralTransmission,
              id: 1,
              questionId: 1,
            },
          ],
          id: 1,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Se puede poner en neutral?",
          service: 1,
          type: 1,
        },
        {
          answers: [
            {
              answer: report.swivelTires && report.swivelTires,
              id: 1,
              questionId: 2,
            },
          ],
          id: 2,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Giran las 4 llantas?",
          service: 1,
          type: 1,
        },
        {
          answers: [
            {
              answer: report.typeOfPlace && report.typeOfPlace,
              id: 1,
              questionId: 3,
            },
          ],
          id: 3,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Donde se encuentra?",
          service: 1,
          type: 1,
        },
      ]),
      vehicleId: registerCarData.id,
    };
    const urlTrip = urlPrueba
      ? `https://zazu.aviapp.mx/sandbox/trip/${loginData.user.id}`
      : `https://zazu.aviapp.mx/trip/${loginData.user.id}`;
    fetch(urlTrip, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(tripParameters),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          Swal.fire({
            icon: "error",
            text: "Ha ocurrido un error: " + data.message,
          });
        } else {
          const folio = data.folio;
          setTripData(data);
          // setTrip(data);
          guardarMov(uidReport, costo, folio);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "error: " + error,
        });
      });
  };
  const guardarMov = async (uidReportBack, costo, folio) => {
    const id = report.id;
    const folioAvi = {
      providerReportId: folio,
    };
    //    console.log(folioAvi);
    const URIFOLIO = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${id}/claims/${uidReportBack}`;
    try {
      const responseFolio = await fetch(URIFOLIO, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ update: folioAvi }),
      });
      const resultadoFolio = await responseFolio.json();
    } catch (error) {
      console.log(error);
    }
    const movimiento = {
      type: "Gastos",
      concept: "Asistencia vial",
      description: "automático",
      amount: Number(costo),
      reserve: true,
      timestampMovement: Date.now(),
      deleted: false,
      userUid: id,
    };
    const URIMOV = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/addDoc?collectionPath=users/${id}/claims/${uidReportBack}/movements`;
    try {
      const responseMov = await fetch(URIMOV, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(movimiento),
      });
      const resultadomov = await responseMov.json();
      const dataA4 = resultadomov.doc;
      const docId4 = resultadomov.doc.id;
    } catch (error) {
      console.log(error);
    }
    setIsProgreess(false);
    setIsMessageGuardar(true);
  };
  const whatsappUrl = whatUrl(
    nameAsegurado,
    lastAsegurado,
    uidAsegurado,
    report.vehicle
  );
  const whatsappReport = whatReport(
    nameAsegurado,
    lastAsegurado,
    report.claveReport
  );
  const [progress, setProgress] = useState(false);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      <Typography
        sx={{
          paddingLeft: 3,
        }}
      >
        ¿Cuál es la ubicación exacta del vehículo?
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        width="100%"
        sx={{
          paddingLeft: 3,
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          La dirección, debe tener calle, número, colonia y estado.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        width="100%"
        sx={{
          paddingLeft: 3,
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          Ejemplo: Reforma 222, Avenida Paseo de la Reforma, Juárez, Ciudad de
          México, CDMX, México
        </Typography>
      </Stack>
      <Stack
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
          width="100%"
          sx={{
            paddingLeft: 3,
          }}
        >
          <LocationOnOutlinedIcon
            sx={{ paddingLeft: "25px", paddingTop: "15px" }}
          />
          <AutoCompletado
            description={currentDirectionId}
            placeId={currentPlaceId}
            clear={clear}
            setClear={setClear}
            datos={report}
            setDatos={setReport}
            typeAddress={"currentAddress"}
            typeLocation={"currentLocation"}
            postal={"postalCurrent"}
            onFoc={onFoc}
            setOnFoc={setOnFoc}
          />
        </Stack>
        <Stack style={{ marginLeft: "77px" }}>
          <Map
            setPlaceId={setCurrentPlaceId}
            setDirectionId={setCurrentDirectionId}
            disabled={false}
            position={
              report.currentLocation
                ? report.currentLocation
                : { lat: "", lon: "" }
            }
            callBack={(e, direction) => {
              setReport({
                ...report,
                currentLocation: {
                  lat: e.latLng.lat(),
                  lon: e.latLng.lng(),
                },
                currentAddress: direction,
              });
            }}
          />
        </Stack>
      </Stack>
      <Typography
        sx={{
          paddingLeft: 3,
        }}
      >
        En donde se encuentra
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ paddingLeft: "97px" }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Ubicación</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={ubicacion}
            label="Ubicación"
            onChange={handleChangeUbicacion}
            sx={{ width: "300px" }}
          >
            <MenuItem value="0" disabled>
              <em>Selecciona</em>
            </MenuItem>
            <MenuItem value="A pie de calle">A pie de calle</MenuItem>
            <MenuItem value="Estacionamiento techado">
              Estacionamiento techado
            </MenuItem>
            <MenuItem value="Estacionamiento sin techo">
              Estacionamiento sin techo
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Typography sx={{ paddingLeft: 3 }}>
        Brinda alguna referencia y si tiene algun tipo de complejidad de acceso
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        sx={{
          paddingLeft: 3,
        }}
      >
        <MapsUgcOutlinedIcon sx={{ paddingLeft: "25px", paddingTop: "15px" }} />
        <TextField
          placeholder="Ejemplo; Antes de caseta por el lado derecho"
          variant="outlined"
          style={{ width: "300px" }}
          onChange={(e) =>
            setReport({ ...report, environmentDescription: e.target.value })
          }
        />
      </Stack>
      <Typography sx={{ paddingLeft: 3 }}>
        ¿A que dirección se transportará el vehículo?
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        width="100%"
        sx={{
          paddingLeft: 3,
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          La dirección, debe tener calle, número, colonia y estado.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        width="100%"
        sx={{
          paddingLeft: 3,
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          Ejemplo: Reforma 222, Avenida Paseo de la Reforma, Juárez, Ciudad de
          México, CDMX, México
        </Typography>
      </Stack>
      <Stack>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
          sx={{ paddingLeft: 3 }}
        >
          <LocationOnOutlinedIcon
            sx={{ paddingLeft: "25px", paddingTop: "15px" }}
          />
          <AutoCompletado
            description={destinationDirectionId}
            placeId={destinationPlaceId}
            clear={clear}
            setClear={setClear}
            datos={report}
            setDatos={setReport}
            typeAddress={"destinationAddress"}
            typeLocation={"destinationLocation"}
            postal={"postalDestination"}
          />
        </Stack>
        <Stack style={{ marginLeft: "97px" }}>
          <Map
            setPlaceId={setDestinationPlaceId}
            setDirectionId={setDestinationDirectionId}
            disabled={false}
            position={
              report.destinationLocation
                ? report.destinationLocation
                : { lat: "", lon: "" }
            }
            callBack={(e, direction) => {
              console.log(e);
              setReport({
                ...report,
                destinationLocation: {
                  lat: e.latLng.lat(),
                  lon: e.latLng.lng(),
                },
                destinationAddress: direction,
              });
            }}
          />
        </Stack>
      </Stack>
      <Typography sx={{ paddingLeft: 3 }}>
        ¿El vehículo se puede poner en neutral?
      </Typography>
      <FormControl sx={{ paddingLeft: 3 }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={neutral}
          onChange={handleNeutral}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              paddingLeft: 3,
            }}
          >
            <FormControlLabel value="Si" control={<Radio />} label="Si" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </Stack>
        </RadioGroup>
      </FormControl>
      <Typography sx={{ paddingLeft: 3 }}>¿Giran todas las llantas?</Typography>
      <FormControl sx={{ paddingLeft: 6 }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={tires}
          onChange={handleTires}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <FormControlLabel value="Si" control={<Radio />} label="Si" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </Stack>
        </RadioGroup>
      </FormControl>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{paddingLeft: "26px"}}
      >
        {hideGuarda ? (
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              getQuote(quote);
              //CircularIndeterminate();
              setIsProgreess(true);
            }}
            sx={{
              textTransform: "initial",
            }}
          >
            Solicitar asistencia
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="success"
            disabled
            sx={{
              textTransform: "initial",
            }}
          >
            Solicitar asistencia
          </Button>
        )}
        <Dialog
          isOpen={isErrorKm}
          content={
            <Typography>
              ¡La distancia no debe exceder 80km! La distancia actual es de{" "}
              {kilometros}
            </Typography>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <Button
                onClick={() => {
                  setIsErrorKm(false);
                }}
                variant="contained"
              >
                Regresar
              </Button>
            </Stack>
          }
        />
        <Dialog
          isOpen={isProgreess}
          content={
            <Stack spacing={2} direction="column">
              <CircularProgress sx={{ marginLeft: "120px" }} />
              <Typography sx={{ paddingLeft: "50px", fontWeight: "bold" }}>
                Guardando datos
              </Typography>
            </Stack>
          }
        />
        <Dialog
          isOpen={isMessageGuardar}
          title="¡Asistencia solicitada con éxito!"
          content={
            <Stack spacing={2} direction="column">
              <Typography>
                En este momento estamos localizando al proveedor más cercano
                para asignarlo.
              </Typography>
              <Typography>El número de tu asistencia es:</Typography>
              <Typography>{report.claveReport}</Typography>
              <Typography>
                Con este número podrás dar seguimiento a tu solicitud vía
                WhatsApp o por llamada.
              </Typography>
            </Stack>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <Button
                onClick={() => {
                  setIsMessageGuardar(false);
                  window.open("tel:" + getContactPhone());
                  setHideVehiculo(false);
                  setHideTipo(false);
                  setHideAsistencia("");
                }}
                color="success"
                variant="contained"
                sx={{
                  textTransform: "initial",
                }}
              >
                Llamar
              </Button>
              <a
                href={whatsappReport}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsMessageGuardar(false);
                    setHideVehiculo(false);
                    setHideTipo(false);
                    setHideAsistencia("");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Escribir por WhatsApp
                </Button>
              </a>
            </Stack>
          }
        />
        <Dialog
          isOpen={isMessageDatos}
          content={
            <Stack spacing={2} direction="column">
              <Typography>
                Ha ocurrido un error en tu solicitud, por favor ponte en
                contacto con un asesor.
              </Typography>
            </Stack>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <Button
                onClick={() => {
                  setIsMessageDatos(false);
                  setHideVehiculo(false);
                  setHideTipo(false);
                  setHideAsistencia("");
                  window.open("tel:" + getContactPhone());
                  /* setHideVehiculo(false);
                    setHideTipo(false);
                    setHideAsistencia("");*/
                }}
                color="success"
                variant="contained"
                sx={{
                  textTransform: "initial",
                }}
              >
                Llamar
              </Button>
              <a
                href={whatsappDatos}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsMessageDatos(false);
                    setHideVehiculo(false);
                    setHideTipo(false);
                    setHideAsistencia("");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Escribir por WhatsApp
                </Button>
              </a>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};
export default GruaSolicitada;
