import { useState, useEffect } from "react";
import { Stack, Box } from "@mui/material";
import cluppNormal from "../../Assets/Clupp_Normal.png";
// import { db } from "../../firebase";

const InitialViewRepository = ({ userData, claimId, isClaimActive }) => {
  const getGender = () => {
    if (userData.gender === "Femenino") {
      return `¡Bienvenida${
        userData?.firstName ? ", " + userData.firstName.split(" ")[0] : ""
      }!`;
    } else if (userData.gender === "Masculino") {
      return `¡Bienvenido${
        userData?.firstName ? ", " + userData.firstName.split(" ")[0] : ""
      }!`;
    } else {
      return `¡Bienvenid@${
        userData?.firstName ? ", " + userData.firstName.split(" ")[0] : ""
      }!`;
    }
  };

  return (
    <Box
      sx={{
        paddingTop: { xs: "66px", md: "74px" },
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        paddingBottom="20px"
        alignItems="center"
      >
        <h1>{!userData ? "" : getGender()}</h1>
        <img
          src={cluppNormal}
          alt="clupp-logo"
          style={{
            minWidth: "150px",
            maxWidth: "200px",
            objectFit: "scale-down",
          }}
        />

        <Stack direction="column" spacing={3} alignItems="center">
          {isClaimActive ? (
            <>
              <h3 style={{ textAlign: "center" }}>
                En esta plataforma podrás dar de alta los documentos
                correspondientes al reporte {claimId}.
              </h3>
              <p
                style={{
                  fontSize: "15px",
                  textJustify: "auto",
                }}
              >
                Da clic en el menú superior para ver el estatus de los
                documentos.
              </p>
            </>
          ) : (
            <>
              <h3 style={{ textAlign: "center" }}>
                Este reporte ya no se encuentra activo.
              </h3>

              <p
                style={{
                  fontSize: "15px",
                  textJustify: "auto",
                }}
              >
                Si tienes alguna duda, por favor comunícate con nosotros.
              </p>
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default InitialViewRepository;
