import { addDocument } from "../Services/firestore";

async function logError({
  error,
  metadata,
  source = "Plataforma de asistencias",
}) {
  //create record in firestore
  const docRef = await addDocument("IT/errors/docs", {
    error,
    source,
    metadata,
    timestamp: Date.now(),
  });

  //post message in slack
  return fetch(
    "https://hooks.slack.com/services/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",
    {
      method: "POST",
      body: JSON.stringify({
        text: `[${source}] Error detectado: ${error}`,
        blocks: [
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: `Error en la ${source}: <https://console.firebase.google.com/u/1/project/auto-clupp/firestore/data/~2FIT~2Ferrors~2Fdocs~2F${docRef.id} | \`${error}\` >`,
            },
          },
        ],
      }),
    }
  );
}

export default logError;
