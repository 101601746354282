function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
 function PadLeft(value, length) {
    return (value.toString().length < length) ? PadLeft("0" + value, length) : 
    value;
}
//console.log(PadLeft(9,2))
// console.log(makeid(3));
const  ClaveReport = (type)=>{
    let año = new Date().getFullYear().toString(); 
    let final = año.slice(-2);
    let hoy = new Date().getDate();
    let ceros = PadLeft(hoy,2); 
    let mesActual = new Date().getMonth() + 1;
    console.log(mesActual);  
    let charact = makeid(3); 
    //const [mes, setMes] = useState("");
    if(mesActual === 1){
        type+= final+"A"+ceros+charact; 
    }else if(mesActual === 2){
        type+= final+"B"+ceros+charact; 
    }else if(mesActual === 3){
        type+= final+"C"+ceros+charact; 
    }else if(mesActual === 4){
    type+= final+"D"+ceros+charact; 
    }else if(mesActual === 5){
        type+= final+"E"+ceros+charact; 
    }else if(mesActual === 6){
        type+= final+"F"+ceros+charact; 
    }else if(mesActual === 7){
        type+= final+"G"+ceros+charact; 
    }else if(mesActual === 8){
        type+= final+"H"+ceros+charact; 
    }else if(mesActual === 9){
        type+= final+"I"+ceros+charact; 
    }else if(mesActual === 10){
        type+= final+"J"+ceros+charact; 
    }else if(mesActual === 11){
        type+= final+"K"+ceros+charact; 
    }else{
        type+= final+"L"+ceros+charact;
    }

   return type; 
}
export default ClaveReport;  