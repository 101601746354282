import React from "react";

import { Typography, Stack, Button } from "@mui/material";
import { WhatsApp, Phone } from "@mui/icons-material";
import logError from "../utils/logError";
import image from "../Assets/Clupp_Triste.png";

import getContactPhone from "../utils/getContactPhone";
import fetchUser from "../Services/users/fetchUser";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, user: undefined };
  }

  async componentDidMount() {
    const uid = new URL(window.location.href).searchParams.get("uid");
    if (uid) {
      try {
        const doc = await fetchUser(uid);
        this.setState({ user: doc, uid });
      } catch (error) {
        if (error === "not/found") {
          console.log("No user");
        }
      }
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Get the error into and save it to the database
    console.log(errorInfo);
    const errorData = {
      error: error.toString(),
      metadata: {
        stackTrace: errorInfo.componentStack.toString(),
      },
    };

    if (this.state.user) {
      errorData["metadata"]["user"] = this.state.user;
    }
    if (this.state.uid) {
      errorData["metadata"]["uid"] = this.state.uid;
    }

    logError(errorData);
  }

  render() {
    const whatsAppMessage = this.state.user
      ? `Hola soy ${this.state.user.firstName}, con uid ${this.state.uid} tuve problemas para solicitar mi asistencia en la app.`
      : "Hola, tuve problemas para solicitar mi asistencia en la app.";
    if (this.state.hasError) {
      // You can render the fallback UI
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          padding={2}
        >
          <img
            alt=""
            style={{ height: "200px", width: "auto", objectFit: "scale-down" }}
            src={image}
          />
          <Typography variant="h6">Algo salió mal</Typography>
          <Typography>
            Sentimos mucho el inconveniente. Estamos trabajando para resolverlo
            lo más pronto posible.
          </Typography>
          <Typography>Por favor ponte en contacto con un agente.</Typography>

          <Stack spacing={2} flexWrap="wrap">
            <Button
              startIcon={<WhatsApp />}
              onClick={() =>
                window.open(
                  `https://wa.me/521${getContactPhone()}?text=${whatsAppMessage}`,
                  "__blank"
                )
              }
              variant="contained"
              style={{
                minWidth: "230px",
                textTransform: "none",
                background: "#23c861",
                color: "white",
              }}
            >
              Enviar mensaje a un agente
            </Button>
            <Button
              startIcon={<Phone />}
              onClick={() => window.open(`tel:${getContactPhone()}`)}
              variant="contained"
              style={{
                minWidth: "230px",
                textTransform: "none",
              }}
            >
              Llamar a un agente
            </Button>
          </Stack>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
