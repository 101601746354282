import { LocationOn } from "@mui/icons-material";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export default function LocationInput({ value, onChange }) {
  const [address, setAddress] = useState(value?.address || "");

  const {
    suggestions: {
      data: placesData,
      status: placesQueryStatus,
      loading: isPlacesOptionsLoading,
    },
    setValue: setPlacesQuery,
    // init,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["mx"] },
      types: ["geocode", "establishment"],
    },
    debounce: 300,
  });

  async function getLangAndLng(placeId) {
    if (placeId) {
      try {
        const results = await getGeocode({ placeId });
        const postalCodeComponent = results[0].address_components.find(
          (component) => component.types.includes("postal_code")
        );
        const postalCode = postalCodeComponent?.long_name || "";

        const { lat: latitude, lng: longitude } = await getLatLng(results[0]);
        return { latitude, longitude, postalCode };
      } catch (error) {
        console.log(error);
      }
    }
  }
  async function handleOnCustomerAdressInputChange(event, newValue) {
    if (newValue) {
      const { latitude, longitude, postalCode } = await getLangAndLng(
        newValue.place_id
      );

      setAddress(newValue);

      onChange(event, {
        address: newValue,
        coords: { lat: latitude, lng: longitude },
        postalCode,
      });
    }
  }

  const getPlaceOptionLabel = (option) => option.description || "";

  const isOptionEqualToValue = (option, value) =>
    option.description === value.description;

  const customerAdressInputOptions =
    placesQueryStatus === "OK"
      ? placesData.map(({ description, place_id }) => ({
          description,
          place_id,
        }))
      : [];

  return (
    <Autocomplete
      loading={isPlacesOptionsLoading}
      value={address}
      disablePortal
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setPlacesQuery(value);
        }
      }}
      onChange={handleOnCustomerAdressInputChange}
      options={customerAdressInputOptions}
      getOptionLabel={getPlaceOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn />
              </InputAdornment>
            ),
          }}
          required
          label={null}
        />
      )}
      clearText="Borrar dirección"
      openText="Abrir menú de opciones"
      closeText="Cerrar"
      loadingText="Cargando..."
      noOptionsText="Escribe tu dirección"
    />
  );
}
