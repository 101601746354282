import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import MobileHeader from "../../Components/MobileHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useQueryParams from "../../hooks/useQueryParams";
import { useEffect, useState } from "react";
import {
  collectionGroup,
  doc,
  getDoc,
  query as firestoreQuery,
  getDocs,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import Swal from "sweetalert2";
import { getDeviceName, getDeviceTitle } from "../../utils/getDeviceName";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import Cluppcito from "../../Assets/Clupp_SeñaAbajo.png";
import WhatsappService from "./WhatsappService";

const InitiateRegister = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, isLoadingUser } = useUser({
    redirectOnNotFound: false,
  });
  const [device, setDevice] = useState({});
  const [formData, setFormData] = useState({
    checkData: false,
    typeDetected: isTablet
      ? "tablet"
      : isMobile
      ? "smartphone"
      : isDesktop
      ? "laptop"
      : undefined,
  });

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const query = useQueryParams();
  const uid = query.get("uid");
  const ig = query.get("ig");

  useEffect(() => {
    const getDevices = async () => {
      if (!uid && !ig) return console.debug("No uid or ig found 1");
      try {
        if (ig && !uid) {
          const snapshot = await getDocs(
            firestoreQuery(
              collectionGroup(db, "smartphones"),
              where("id", "==", ig),
              limit(1)
            )
          );
          if (snapshot.empty)
            return console.debug(
              "No devices found",
              snapshot.docs.map((doc) => doc.data())
            );

          const smartphone = snapshot.docs.map((doc) => ({
            ...doc.data(),
            uid: doc.ref.parent.parent.id,
            path: doc.ref.path,
          }))[0];

          setSearchParams({
            uid: smartphone.uid,
            ig: smartphone.id,
          });
          setDevice(smartphone);
        } else {
          const deviceRef = doc(db, `users/${uid}/smartphones/${ig}`);
          const deviceSnapshot = await getDoc(deviceRef);
          const deviceData = deviceSnapshot.data();
          deviceData?.id
            ? setDevice({ ...deviceData, id: deviceData.id })
            : navigate("/device-notfound");
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          text: "Lo sentimos, ocurrio un error inesperado.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    getDevices();
  }, [uid, ig]);

  const handelNoResponse = () =>
    Swal.fire({
      icon: "warning",
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      text: `Es necesario que ingreses desde tu ${getDeviceName(
        device.type
      )} a la liga mágica que te enviamos a tu correo electrónico para finalizar su registro.`,
    });

  const handelYesResponse = () => {
    if (!formData.checkData) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        confirmButtonColor: "#3085d6",
        text: 'Por favor, haz clic en "Estoy de acuerdo" para avanzar.',
      });
    }
    formData.typeDetected === device.type
      ? navigate(`/registro/gadgets/dispositivo?uid=${uid}&ig=${ig}`)
      : handelNoResponse();
  };

  const hasCompletedRegistration =
    device?.pictureDeviceBack && device?.pictureDeviceFront;

  return isLoading && isLoadingUser ? (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      <CircularProgress />
    </Stack>
  ) : (
    <>
      <MobileHeader user={user} showGradientHeader={false} />
      <Stack
        sx={{
          position: "relative",
          height: ["90vh", "100%"],
          width: ["95%", "50%"],
          margin: ["0px auto 40px auto", "0px auto 140px auto"],
        }}
      >
        <Box
          sx={{
            p: 2,
            marginTop: "20px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {hasCompletedRegistration ? (
            <Alert severity="success" sx={{ borderRadius: "6px" }}>
              ¡Listo! Ya has registrado tu {getDeviceName(device.type)}.
            </Alert>
          ) : (
            <>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                {`Háblanos de tu ${getDeviceName(device.type)}`}
              </Typography>
              <img
                src={Cluppcito}
                alt="cluppcito"
                width={"30%"}
                style={{ marginTop: "10px", marginBottom: "10px" }}
              />

              <Alert severity="warning" sx={{ borderRadius: "6px" }}>
                Clupp tiene acceso a la información del dispositivo. Es
                importante que esta información coincida con los datos que
                proporcionas. En caso contrario, cualquier reclamación de
                siniestros será rechazada.
                <FormGroup>
                  <FormControlLabel
                    required
                    value={formData.checkData}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        checkData: e.target.checked,
                      }))
                    }
                    control={
                      <Checkbox
                        sx={{
                          color: "#E7B91F",
                          "&.Mui-checked": {
                            color: "#E7B91F",
                          },
                        }}
                      />
                    }
                    label="Estoy de acuerdo"
                  />
                </FormGroup>
              </Alert>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {`¿Estás en ${getDeviceTitle(device.type)} que vas a asegurar?`}
              </Typography>

              <Stack
                sx={{
                  width: "60%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "5px auto 0px auto",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handelYesResponse}
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  Si
                </Button>
                <Button
                  onClick={handelNoResponse}
                  variant="outlined"
                  sx={{
                    width: "100%",
                  }}
                >
                  No
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Stack>
      <WhatsappService uid={uid} />
    </>
  );
};

export default InitiateRegister;
