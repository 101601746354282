 async function getAddressFromCoords(lng, lat){
    let URL = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/googleMaps/geocode?lng=${lng}&lat=${lat}`
    const response = await fetch(URL, {
     method: 'GET'
    })
    const json = await response.json()
    console.log(json)
    return json
   }

   export {getAddressFromCoords}