import {
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import MobileHeader from "../../Components/MobileHeader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AttachFile } from "@mui/icons-material";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { esES } from "@mui/x-date-pickers/locales";
import { es } from "date-fns/locale";
import { doc, getDoc, setDoc, addDoc, collection } from "@firebase/firestore";
import useUser from "../../hooks/useUser";

const ExtraInsuranceForm = ({}) => {
  const navigate = useNavigate();
  const fileInput = useRef();
  const query = useQueryParams();
  const uid = query.get("uid");
  const iid = query.get("ins");
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSubLoading, setIsSubLoading] = useState(false);
  const { user, isLoadingUser } = useUser();
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    insuranceCompany: "",
    policyNumber: "",
    insuranceStartTimestamp: "",
    insuranceEndTimestamp: "",
    period: "",
    phoneContact: "",
    policyURL: "",
  });

  useEffect(() => {
    const getIsuranceData = async () => {
      const insuranceRef = await doc(db, `users/${uid}/insurances/${iid}`);
      const docSnap = await getDoc(insuranceRef);
      if (docSnap.exists()) {
        setFormData(docSnap.data());
      } else {
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          text: "Lo sentimos, ocurrio un error inesperado.",
        });
        navigate("/usuario-notfound");
      }
    };
    if (iid) getIsuranceData(iid);
  }, [iid]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateSelect = (date, field) => {
    const toTimestamp = new Date(date).getTime();
    const time = {
      [field]: toTimestamp,
    };
    if (field === "insuranceStartTimestamp") {
      const startDate = new Date(toTimestamp);
      startDate.setFullYear(startDate.getFullYear() + 1);
      const endTimestamp = startDate.getTime();
      time.insuranceEndTimestamp = endTimestamp;
    }
    setFormData((prevData) => ({
      ...prevData,
      ...time,
    }));
  };

  const handleSubmit = async (event) => {
    setIsSubLoading(true);
    event.preventDefault();
    const { policyURL, ...requiredFields } = formData;
    const checkFieldsEmptiness = Object.values(requiredFields).every(
      (x) => x !== ""
    );

    if (checkFieldsEmptiness) {
      if (!(formData.phoneContact.length === 10)) {
        Swal.fire({
          icon: "warning",
          showConfirmButton: false,
          text: "El número de siniestros debe contener 10 dígitos.",
        });
      } else {
        try {
          const docData = {
            ...formData,
            deleted: false,
            timestamp: Date.now(),
          };
          Boolean(iid)
            ? await setDoc(
                doc(db, `users/${uid}/insurances/${iid}`),
                { ...docData },
                { merge: true }
              )
            : await addDoc(collection(db, `users/${uid}/insurances`), {
                ...docData,
              });

          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            text: "Seguro guardado correctamente.",
          });
          navigate(`/mis-seguros?uid=${uid}`);
        } catch (err) {
          console.log("error saving new Insurance -->", err);
          Swal.fire({
            icon: "error",
            showConfirmButton: false,
            text: "Lo sentimos, ocurrio un error inesperado.",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        confirmButtonColor: "rgb(0, 187, 238)",
        text: "Por favor, completa todos los campos",
      });
    }
    setIsSubLoading(false);
  };

  async function handleFileInputOnChange(e) {
    setIsLoadingFile(true);
    return new Promise((resolve, reject) => {
      let id = Math.random().toString();
      const commingFiles = [...e.target.files][0];
      const path = `users/${uid}/insurences/policyDoc/${commingFiles.name}/${id}.pdf`;

      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, commingFiles);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          reject(error);
        },
        async () => {
          const imgURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData((prevData) => ({
            ...prevData,
            policyURL: imgURL,
            fileName: commingFiles.name.toLowerCase().replace(/\s+/g, "-"),
          }));
          setIsLoadingFile(false);
          resolve(imgURL);
        }
      );
    });
  }

  return isLoadingUser ? (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Stack>
  ) : user ? (
    <>
      <MobileHeader user={user} />
      <Stack
        sx={{
          position: "relative",
          height: "100vh",
          width: "100%",
        }}
      >
        <Stack spacing={2} p={1} width={"88vw"} margin={"0 auto"}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Agregar seguro
          </Typography>
          <Stack sx={{ gap: "20px" }}>
            <TextField
              name="name"
              variant="standard"
              label="Nombre del titular"
              autoFocus
              value={formData.name}
              onChange={handleChange}
              required
            />
            <FormControl variant="standard">
              <InputLabel>Tipo de Seguro</InputLabel>
              <Select
                name="type"
                value={formData.type}
                variant="standard"
                label="Tipo de Seguro"
                onChange={handleChange}
                required
              >
                <MenuItem value="vehicle">Seguro de vehículo</MenuItem>
                <MenuItem value="smartphone">Seguro de celular</MenuItem>
                <MenuItem value="medical">Seguro de gastos médicos</MenuItem>
                <MenuItem value="house">Seguro de hogar</MenuItem>
                <MenuItem value="pet">Seguro de mascotas</MenuItem>
                <MenuItem value="life">Seguro de vida</MenuItem>
                <MenuItem value="medical-olderly">Seguro de gastos médicos para personas mayores</MenuItem>
                <MenuItem value="sports">Seguro deportivo</MenuItem>
                <MenuItem value="diabetes">Diabetes, hipertensión y obesidad</MenuItem>
                <MenuItem value="health">Seguro de salud</MenuItem>
                <MenuItem value="travel">Seguro de viajes</MenuItem>
                <MenuItem value="other">Otro</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="insuranceCompany"
              label="Aseguradora"
              variant="standard"
              value={formData.insuranceCompany}
              onChange={handleChange}
              required
            />
            <TextField
              name="policyNumber"
              label="Número de póliza"
              variant="standard"
              value={formData.policyNumber}
              onChange={handleChange}
              required
            />
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "5px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={
                  esES.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                adapterLocale={es}
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      error:
                        formData.insuranceStartTimestamp === "" ? false : "",
                    },
                  }}
                  sx={{ width: "48%" }}
                  label="Inicio vigencia"
                  name="insuranceStartTimestamp"
                  value={formData.insuranceStartTimestamp}
                  onChange={(date) =>
                    handleDateSelect(date, "insuranceStartTimestamp")
                  }
                />

                <DatePicker
                  slotProps={{
                    textField: {
                      error:
                        formData.insuranceStartTimestamp === "" ? false : "",
                    },
                  }}
                  sx={{ width: "48%" }}
                  variant="standard"
                  value={formData.insuranceEndTimestamp}
                  minDate={formData.insuranceStartTimestamp}
                  label="Fin vigencia"
                  name="insuranceEndTimestamp"
                  onChange={(date) =>
                    handleDateSelect(date, "insuranceEndTimestamp")
                  }
                />
              </LocalizationProvider>
            </FormGroup>
            <FormControl variant="standard">
              <InputLabel>Frecuencia de Pago</InputLabel>
              <Select
                name="period"
                variant="standard"
                label="Frecuencia de Pago"
                value={formData.period}
                onChange={handleChange}
                required
              >
                <MenuItem value="month">Mensual</MenuItem>
                <MenuItem value="3month">Trimestral</MenuItem>
                <MenuItem value="6month">Semestral</MenuItem>
                <MenuItem value="year">Anual o contado</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="phoneContact"
              label="Teléfono de siniestros"
              value={formData.phoneContact}
              helperText="Debe contener 10 dígitos"
              variant="standard"
              onChange={handleChange}
              type="number"
              inputProps={{ inputMode: "numeric" }}
              required
            />
            <Stack
              onClick={() => fileInput.current.click()}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{ width: "95%", borderBottom: "1px solid #b8b8b8" }}
              >
                {formData?.fileName
                  ? formData.fileName
                  : "Cargar póliza (opcional)"}
              </Typography>
              <input
                style={{ display: "none" }}
                type="file"
                ref={fileInput}
                multiple
                onChange={handleFileInputOnChange}
                accept="application/pdf"
              />
              {isLoadingFile ? (
                <CircularProgress />
              ) : (
                <IconButton
                  sx={{
                    backgroundColor: "#e6e6e695",
                  }}
                >
                  <AttachFile />
                </IconButton>
              )}
            </Stack>
            <LoadingButton
              type="submit"
              loading={isSubLoading}
              variant="contained"
              sx={{
                textTransform: "none",
                fontSize: "18px",
                marginTop: "10px",
              }}
              component="button"
              onClick={handleSubmit}
            >
              Guardar
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  ) : (
    <></>
  );
};

export default ExtraInsuranceForm;