import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import useQueryParams from "../../hooks/useQueryParams";
import Footer from "../../Components/Footer";
import downloadGooglePlay from "../../Assets/downloadGooglePlay.png";
import downloadAppStore from "../../Assets/downloadAppStore.png";
import Header from "../../Components/Header";

export default function Bienvenida() {
  const queryParam = useQueryParams();

  const uid = queryParam.get("uid");
  const email = queryParam.get("email");
  const firstName = queryParam.get("firstName");
  const tempPassword = queryParam.get("tempPassword");
  const displayHeader = queryParam.get("displayHeader");

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isDesktop = !isAndroid && !isIOS;

  const whatsAppMessage = `https://wa.me/5215577460177?text=Hola.+Acabo+de+contratar%2C+pero+necesito+ayuda.+Mi+correo+es+${email}.`;

  return (
    <>
      {!(displayHeader === "false") && (
        <Header showName={false} sx={{ height: "" }} />
      )}
      <main
        style={{
          maxWidth: "500px",
          margin: "0 auto",
          padding: "15px 20px",
          display: "grid",
          gap: "12px",
        }}
      >
        <Stack>
          <Typography
            sx={{
              textAlign: "center",
              color: "#000",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            ¡Hola {firstName}!
          </Typography>
        </Stack>
        <Stack sx={{ gap: "24px" }}>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              color: "#FF5722",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            ¡Te damos la bienvenida!
          </Typography>
          <ThankYouMessage />
          <Typography sx={{ fontSize: "20px", color: "#000" }}>
            El siguiente paso para iniciar tu cobertura es que descargues la app
            Clupp y <b>des de alta los datos de tu vehículo.</b>
          </Typography>
        </Stack>
        <LoginDetailsSection />
        {(isAndroid || isDesktop) && (
          <Stack sx={{ gap: "18px" }}>
            <Typography
              sx={{ fontSize: "20px", color: "#000", textAlign: "center" }}
            >
              Descarga la app dando clic aquí desde tu Android
            </Typography>
            <Box sx={{ display: "grid", placeItems: "center" }}>
              <a
                href="https://play.google.com/store/apps/details?id=mx.com.clupp.autoclupp"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: "150px" }}
                  src={downloadGooglePlay}
                  alt="descargar google play"
                />
              </a>
            </Box>
          </Stack>
        )}
        {(isIOS || isDesktop) && (
          <Stack sx={{ gap: "18px" }}>
            <Typography
              sx={{ fontSize: "20px", color: "#000", textAlign: "center" }}
            >
              Descarga la app dando clic aquí desde tu iPhone
            </Typography>
            <Box sx={{ display: "grid", placeItems: "center" }}>
              <a
                href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1451145803&mt=8"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: "150px" }}
                  src={downloadAppStore}
                  alt="descargar app store"
                />
              </a>
            </Box>
          </Stack>
        )}
        <Stack sx={{ gap: "24px" }}>
          <Typography
            variant="h3"
            sx={{
              color: "#FF5722",
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            ¿Necesitas ayuda?
          </Typography>
          <Typography sx={{ fontSize: "20px", color: "#000" }}>
            Si necesitas asistencia con cualquier cosa relacionada con tu
            seguro, da clic en el siguiente botón para ponerte ayudarte por
            WhatsApp.
          </Typography>
          <a
            style={{ textDecoration: "none" }}
            href={whatsAppMessage}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              fullWidth
              sx={{
                background: "#00CA7D",
                color: "#FFF",
                borderRadius: "20px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
                "&:hover": {
                  background: "#35d396",
                },
              }}
            >
              Asistencia por whatsapp
            </Button>
          </a>
        </Stack>
      </main>
      <Footer whatsAppMessage={whatsAppMessage} />
    </>
  );
}

function LoginDetailsSection() {
  const queryParam = useQueryParams();

  const version = queryParam.get("v");
  const email = queryParam.get("email");
  const tempPassword = queryParam.get("tempPassword");

  const isWoowVersion = version === "woow";

  if (isWoowVersion) {
    return <WoowLoginDetailsSection email={email} />;
  }

  return (
    <DefaultLoginDetailsSection email={email} tempPassword={tempPassword} />
  );
}

function DefaultLoginDetailsSection({ email, tempPassword }) {
  if (!Boolean(email)) return null;

  return (
    <Stack>
      <Typography sx={{ fontSize: "20px", color: "#000" }}>
        Puedes iniciar sesión con los siguientes datos:
      </Typography>
      <ul>
        <li>
          <Typography sx={{ fontSize: "20px", color: "#000" }}>
            Correo: {email}
          </Typography>
        </li>
        <li>
          <Typography sx={{ fontSize: "20px", color: "#000" }}>
            Contraseña temporal: {tempPassword}
          </Typography>
        </li>
      </ul>
    </Stack>
  );
}

function WoowLoginDetailsSection({ email }) {
  return (
    <Stack gap={2}>
      <Typography sx={{ fontSize: "20px", color: "#000" }}>
        Te hemos enviado un correo a{" "}
        <u>
          <i>{email}</i>
        </u>{" "}
        con tu contraseña temporal para que puedas iniciar sesión en la app y
        completar tu registro.
      </Typography>

      <Alert severity="info">
        Si no encuentras el correo en tu bandeja de entrada, revisa la carpeta
        de spam o correo no deseado.
      </Alert>
    </Stack>
  );
}

function ThankYouMessage() {
  const queryParam = useQueryParams();

  const version = queryParam.get("v");

  const isWoowVersion = version === "woow";

  if (isWoowVersion) {
    return (
      <>
        <Typography sx={{ fontSize: "20px", color: "#000" }}>
          <b>¡Gracias por elegir a WOOW!</b>
        </Typography>
        <Typography sx={{ fontSize: "20px", color: "#000" }}>
          Es momento de rodar protegidos.
          <br />
          Estamos contigo en las curvas y en las rectas
        </Typography>
      </>
    );
  }

  return (
    <Typography sx={{ fontSize: "20px", color: "#000" }}>
      <b>Gracias por tu pago.</b> Te recordamos que el cobro estará llegando de
      forma automática a tu tarjeta de manera recurrente hasta que canceles tu
      membresía.
    </Typography>
  );
}
