import { Close, InsertDriveFile } from "@mui/icons-material";
import {
  Button,
  LinearProgress,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const FileBox = ({ file = {}, onDelete, description = "", link = "" }) => {
  const isUploaded = Boolean(file.url);

  return (
    <Link
      href={link}
      target="_blank"
      download={true}
      sx={{textDecorationColor: "black"}}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          border: "2px solid #00bbee",
          borderRadius: "5px",
          padding: 0.5,
          width: ["135%", "67%", "48%"],
        }}
      >
        <Button
          size="small"
          endIcon={<CloudDownloadIcon />}
          sx={{
            width: "100%",
            textTransform: "none",
            paddingRight: "12px",
          }}
        >
          <InsertDriveFile sx={{ color: "#707070" }} />
          <Stack sx={{ flex: 1 }}>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                maxWidth: "170px",
                textAlign: "left",
                paddingLeft: "4px",
                color: "black",
                textDecorationColor: "black"
              }}
            >
              {file.name || description}
            </Typography>
            {/* {!isUploaded && (
              <LinearProgress variant="determinate" value={file.progress} />
            )} */}
          </Stack>

          {onDelete && (
            <Close
              sx={{ fontSize: "20px", cursor: "pointer", color: "#707070" }}
              onClick={() => onDelete(file.name)}
            />
          )}
        </Button>
      </Stack>
    </Link>
  );
};

export default FileBox;
