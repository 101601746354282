import { baseURL } from "./config";

export default async function linkEmail({ email, password, phone }) {
  const response = await fetch(baseURL + "/validation/link-email", {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJSQVNUUkVBVE9SIn0.OGp_G6Lref09-YkoRsY3vBUEndMpAbhUR-tHHu-nlm4",
    },
    method: "POST",
    body: JSON.stringify({
      email,
      password,
      phone,
    }),
  });

  const data = await response.json();

  return data;
}
