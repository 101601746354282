import { Box, Checkbox, Typography } from "@mui/material";
import { useState } from "react";

import Dialog from "../Components/Dialog";

const ExtraItemField = ({
  label,
  checked = false,
  controlled = true,
  onChange,
  price,
  children,
  disabled,
  hideCheckbox = false,
  dialogTitle = null,
  message = null,
  monto,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <Box
      style={{
        display: "flex",
        gap: "12px",
      }}
    >
      <Box
        style={{
          display: "flex",
          gap: "6px",
          flex: "1",
        }}
      >
        {!hideCheckbox && (
          <Checkbox
            checked={controlled ? checked : isChecked}
            onClick={onChange}
            disabled={disabled}
            style={{
              color: "#00ca7d",
              alignSelf: "flex-start",
              margin: "-9px",
            }}
          />
        )}

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: ".5rem",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".1rem",
              maxWidth: "150px",
            }}
          >
            <Typography style={{ textAlign: "left", fontSize: "0.9rem" }}>
              {label}
            </Typography>
            {message && (
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                  color: "#00bbee",
                  cursor: "pointer",
                }}
                onClick={() => setIsOpen((prev) => !prev)}
              >
                <u>¿Qué cubre?</u>
              </Typography>
            )}
            {monto && (
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                {monto}
              </Typography>
            )}
          </Box>

          {children &&
            children({
              checked: controlled ? checked : isChecked,
            })}
        </Box>
      </Box>

      <Typography
        style={{
          textAlign: "right",
          alignSelf: "flex-start",
          width: "35%",
        }}
      >
        {price}
      </Typography>

      {message && label && (
        <Dialog
          isOpen={isOpen}
          onClose={() => setIsOpen((prev) => !prev)}
          title={dialogTitle || label}
          content={message}
        />
      )}
    </Box>
  );
};

export default ExtraItemField;
