const transformerTypeItem = (item) => {
  const type =
    item.type == "auto" ||
    item.type == "moto" ||
    item.type == "scooter" ||
    item.type == "eBike" ||
    item.type == "bike" ||
    item.type == "other"
      ? "vehículo"
      : "celular";

  return type;
};
export { transformerTypeItem };
