import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material";
import Claims from "./Views/Claims";
import InsuranceStatus from "./Views/InsuranceStatus/InsuranceStatus.jsx";
import Refer from "./Views/Refer/Refer.jsx";
import Payments from "./Views/Payments";
import Report from "./Views/Report";
import Invoice from "./Views/Invoice/Invoice";
import StickerForm from "./Views/StickerForm";
import ClaimsHistory from "./Views/ClaimsHistory/ClaimsHistory";
import PaymentOptions from "./Views/PaymentOptions";
import CoveredSmartphones from "./Views/CoveredSmartphones/CoveredSmartphones";
import Tutoriales from "./Views/Tutoriales/Tutoriales";
import AddFirstPhotos from "./Views/AddFirstPhotos/AddFirstPhotos";
import RepositoryInfo from "./Views/RepositoryInfo/RepositoryInfo";
import ContactInfo from "./Views/ContactInfo/ContactInfo";
import SmartCard from "./Views/SmartCard/SmartCard";
import UpdateUserData from "./Views/UpdateUserData/UpdateUserData";
import SignPdf from "./Views/SignPdf/SignPdf";
import EnlacesUtiles from "./Views/EnlacesUtiles/EnlacesUtiles";
import ExternalRedirect from "./Components/ExternalRedirect";
import ExternalSourceRedirect from "./Components/ExternalSourceRedirect";
import ExpedienteDigital from "./Views/ExpedienteDigital/ExpedienteDigital";
import Bienvenida from "./Views/Bienvenida/Bienvenida";

import cluppTheme from "./cluppTheme";
import { AuthAnonymusProvider } from "./context/anonymusUserContext";

import "./index.css";
import ErrorBoundary from "./Components/ErrorBoundary";
import AdditionalFeatures from "./Views/AdditionalFeatures/AdditionalFeatures";
import AssistanceForm from "./Views/AssistanceForm";
import ExtraInsuranceList from "./Views/ExtraInsurances/ExtraInsuranceList";
import ExtraInsuranceForm from "./Views/ExtraInsurances/ExtraInsuranceForm";
import NoUserFound from "./Views/ExtraInsurances/NoUserFound";
import ExtraInsurancesTypes from "./Views/ExtraInsurances/ExtraInsurancesTypes";
import ExtraInsurancesOptions from "./Views/ExtraInsurances/ExtraInsurancesOptions";
import Register from "./Views/Register/Register";

import * as Sentry from "@sentry/react";
import MSIRenewal from "./Views/MSIRenewal/index.jsx";
import InitiateRegister from "./Views/DevicesRegister/InitiateRegister.jsx";
import NoDeviceFound from "./Views/DevicesRegister/noDeviceFound.jsx";
import DeviceRegister from "./Views/DevicesRegister/DeviceRegister.jsx";
import DevicesPhotosRegister from "./Views/DevicesRegister/DevicesPhotosRegister.jsx";
import SuccessfullDeviceRegister from "./Views/DevicesRegister/SuccessfullDeviceRegister.jsx";
import PersonalInformation from "./Views/DevicesRegister/PersonalInformation.jsx";
import AdditionalFeaturesDevices from "./Views/AdditionalFeatures/AdditionalFeaturesDevices.jsx";

try {
  Sentry.init({
    dsn: "https://6f0cd3278caee6a1665fc55d8aa9dd68@o4505711137914880.ingest.sentry.io/4505728561840128",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/cluppseguro/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} catch (error) {
  console.log(error);
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={cluppTheme}>
      <AuthAnonymusProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<ExternalRedirect href="http://clupp.com.mx/" />}
            />
            <Route
              path="/claims"
              element={
                <ErrorBoundary>
                  <Claims />
                </ErrorBoundary>
              }
            />
            <Route
              path="/content/external/:sourceName"
              element={<ExternalSourceRedirect />}
            />
            <Route path="/report" element={<Report />} />
            <Route path="/sticker-form" element={<StickerForm />} />
            <Route path="/status" element={<InsuranceStatus />} />
            <Route path="/tutoriales" element={<Tutoriales />} />
            <Route path="/refiere" element={<Refer />} />
            <Route path="/coberturas" element={<AdditionalFeatures />} />
            <Route
              path="/coberturas/dispositivos"
              element={<AdditionalFeaturesDevices />}
            />
            <Route path="/pagos" element={<Payments />} />
            <Route path="/formas-de-pago" element={<PaymentOptions />} />
            <Route path="/facturacion" element={<Invoice />} />
            <Route path="/siniestros" element={<ClaimsHistory />} />
            <Route path="/solicitar-asistencia" element={<AssistanceForm />} />
            {/* <Route path="/coberturas" element={<Coverages />} /> */}
            <Route path="/agregar-fotos" element={<AddFirstPhotos />} />
            <Route path="/smartphones" element={<CoveredSmartphones />} />
            <Route path="/repositorio" element={<RepositoryInfo />} />
            <Route path="/info-contacto" element={<ContactInfo />} />
            <Route path="/tarjeta-presentacion" element={<SmartCard />} />
            <Route path="/datos-usuario" element={<UpdateUserData />} />
            <Route path="/firmar-pdf" element={<SignPdf />} />
            <Route path="/enlaces-utiles" element={<EnlacesUtiles />} />
            <Route path="/expediente-digital" element={<ExpedienteDigital />} />
            <Route path="/bienvenida" element={<Bienvenida />} />
            <Route path="/mis-seguros" element={<ExtraInsuranceList />} />
            <Route path="/agregar-seguro" element={<ExtraInsuranceForm />} />
            <Route path="/renovar" element={<MSIRenewal />} />
            <Route
              path="/registro/gadgets/inicio"
              element={<InitiateRegister />}
            />
            <Route
              path="/registro/gadgets/dispositivo"
              element={<DeviceRegister />}
            />
            <Route
              path="/registro/gadgets/fotos"
              element={<DevicesPhotosRegister />}
            />
            <Route
              path="/registro/gadgets/final"
              element={<SuccessfullDeviceRegister />}
            />
            <Route
              path="/registro/gadgets/datos-personales"
              element={<PersonalInformation />}
            />

            <Route
              path="/tipos-de-seguros"
              element={<ExtraInsurancesOptions />}
            />
            <Route
              path="/opciones-seguros"
              element={<ExtraInsurancesTypes />}
            />
            <Route path="/usuario-notfound" element={<NoUserFound />} />
            <Route path="/device-notfound" element={<NoDeviceFound />} />
            <Route path="/registro" element={<Register />} />
          </Routes>
        </BrowserRouter>
      </AuthAnonymusProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
