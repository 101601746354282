import { Stack, Typography, Button } from "@mui/material";
import Dialog from "../Dialog";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';

export default function ContactDialog({
  cerrar,
  setIsMessageType,
  isMessageType,
  setQuePaso,
  whatsappUrl,
}) {
  return (
    <Dialog
      isOpen={isMessageType}
      onClose={cerrar}
      content={
        <Typography sx={{ paddingTop: "20px" }}>
          ¿Quieres llamarnos por teléfono o prefieres hacer tu reporte vía
          WhatsApp?
        </Typography>
      }
      Actions={
        <Stack sx={{width: "100%"}} spacing={2} direction={["column"]}>
          <Button
            variant="contained"
            startIcon={<PhoneIcon/>}
            fullWidth
            onClick={() => {
              setIsMessageType(false);
              window.open("tel:5592252185");
              setQuePaso("");
            }}
            sx={{
              textTransform: "initial",
            }}
          >
            Llamar por teléfono
          </Button>
          <a
            href={whatsappUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              color="success"
              variant="contained"
              fullWidth
              startIcon={<WhatsAppIcon/>}
              onClick={() => {
                setIsMessageType(false);
                //setQuePaso("");
              }}
              sx={{
                textTransform: "initial",
              }}
            >
              Escribir por WhatsApp
            </Button>
          </a>
        </Stack>
      }
    />
  );
}
