import { isBefore } from "date-fns";

// return the body that represents a report message on Slack
const getReportMessageTemplate = ({ user, vehicle }) => {
  let template = {
    blocks: [
      {
        type: "header",
        text: {
          type: "plain_text",
          text: "🟡 Se reportó un siniestro desde la página web 🟡",
          emoji: true,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Contactar al asegurado *${user.firstName} ${
            user.lastName || ""
          }* \n Su teléfono es <tel:${user.phone}| *${user.phone}*>`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Consultar en base de datos:\n Usuario: <https://console.firebase.google.com/u/2/project/auto-clupp/firestore/data/~2Fusers/${user.uid} |*${user.uid}*> \n Vehículo: <https://console.firebase.google.com/u/2/project/auto-clupp/firestore/data/~2Fusers/${user.uid}/vehicles/${vehicle.vehicleId}|*${vehicle.vehicleId}*> `,
        },
      },
    ],
  };

  const hasEmergencyContact = Boolean(
    user.emergency_contact &&
      user.emergency_contact.phoneNumber &&
      user.emergency_contact.phoneNumber.length === 10 &&
      user.emergency_contact.name
  );

  const insuranceEndDate = new Date(
    vehicle.insuranceEndYear,
    vehicle.insuranceEndMonth - 1,
    vehicle.insuranceEndDay
  );

  const today = new Date();

  // the insurance is paid if the current date is before the insuranceEnd date
  const isInsurancePaid = isBefore(today, insuranceEndDate);

  let preview = `Se reportó un siniestro desde la página web\nContactar al asegurado ${
    user.firstName
  } ${user.lastName || ""}\nSu teléfono es ${user.phone || ""}`;

  if (!isInsurancePaid) {
    template.blocks.push({
      type: "section",
      text: {
        type: "mrkdwn",
        text: `🟡 Asegurado con *seguro inactivo* por falta de pago (en *periodo de gracia*).`,
      },
    });

    // update message preview
    preview += "\n🟡 Asegurado con seguro inactivo por falta de pago";
  }

  if (hasEmergencyContact) {
    template.blocks.push({
      type: "section",
      text: {
        type: "mrkdwn",
        text: `En caso de no poder contactar al asegurado, contactar a *${user.emergency_contact.name}* al teléfono <tel:${user.emergency_contact.phoneNumber}| *${user.emergency_contact.phoneNumber}*>`,
      },
    });
  }

  template.text = preview;

  // add a divider block at the end of the template
  template.blocks.push({
    type: "divider",
  });

  return template;
};

export default getReportMessageTemplate;
