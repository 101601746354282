async function fetchInvoicePDF(uid, paymentId) {
  try {
    const response = await fetch(
      `https://clupp-api.web.app/v1/clupp/users/${uid}/cfdis/${paymentId}`,
      {
        method: "POST",
        headers: {
          Origin: "clupp",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJSQVNUUkVBVE9SIn0.OGp_G6Lref09-YkoRsY3vBUEndMpAbhUR-tHHu-nlm4",
        },
      }
    );
    const json = await response.json();
    if (json?.error) {
      return json;
    }
    if (!json.pdfURL) {
      throw new Error("not/found");
    }
    return json;
  } catch (error) {
    console.log(error);
    throw new Error("not/found");
  }
}

export default fetchInvoicePDF;
