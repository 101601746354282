import {
  Stack,
  Typography,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import { Close, InsertDriveFile } from "@mui/icons-material";

const FileBox = ({ file, onDelete, key }) => {
  const isUploaded = Boolean(file.url);

  return (
    <Stack
      key={key}
      direction="row"
      alignItems="center"
      sx={{ border: "2px solid lightgray", borderRadius: "5px", padding: 0.5 }}
    >
      <InsertDriveFile sx={{ color: "#707070" }} />
      <Stack sx={{ flex: 1 }}>
        <Tooltip
          arrow
          title={<Typography variant="body2"> {file.name}</Typography>}
        >
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100px",
            }}
          >
            {file.name}
          </Typography>
        </Tooltip>
        {!isUploaded && (
          <LinearProgress variant="determinate" value={file.progress} />
        )}
      </Stack>

      <Tooltip title="Eliminar archivo">
        <Close
          sx={{ fontSize: "20px", cursor: "pointer", color: "#707070" }}
          onClick={() => onDelete(file.name)}
        />
      </Tooltip>
    </Stack>
  );
};

export default FileBox;