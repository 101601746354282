import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Box,
  Drawer,
} from "@mui/material";
import {
  ExpandMore,
  FolderCopy,
  Link,
  RequestQuote,
  Topic,
  Menu,
  PrivacyTip,
  Info,
  WhatsApp,
} from "@mui/icons-material";
import { useState } from "react";
import { db } from "../../firebase";
import StepperCustom from "../../Components/StepperCustom/StepperCustom";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import VehicleDataForm from "./VehicleDataForm";
import UserDataForm from "./UserDataForm";
import { useTheme } from "@emotion/react";
import MailAuth from "./MailAuth";
import AuthPhoneForm from "./AuthPhoneForm";
import Header from "../../Components/Header";
import {
  createUser,
  linkEmail,
  sendVerificationCode,
  verifyCode,
} from "../../Services/users/Auth";

import cluppcitoFestejoIcon from "../../Assets/Clupp_Festejo.png";

function extractBirthdateFromRFC(rfc) {
  const rfcRegex = /^[A-Z]{4}(\d{2})(\d{2})(\d{2})/;
  const matches = rfc.match(rfcRegex);

  if (matches && matches.length === 4) {
    const year = parseInt(matches[1], 10);
    const month = parseInt(matches[2], 10);
    const day = parseInt(matches[3], 10);

    // Assuming year in the format 'yy' represents 1900s and '20' represents 2000s
    const century = year < 40 ? 2000 : 1900;
    const birthdate = new Date(century + year, month - 1, day);

    return birthdate;
  }

  return null; // Invalid RFC format
}

const steps = [
  "Verificar número",
  "Correo electrónico",
  "Datos personales",
  "Datos del vehículo",
];
const USERDATA = {
  firstName: "Nombre",
  lastName: "Apellidos",
  rfc: "RFC",
  gender: "Sexo biológico",
  zipCode: "Código postal",
};
const VEHICLEDATA = {
  type: "Tipo de vehículo",
  brand: "Marca",
  model: "Modelo",
  name: "Nombre",
  year: "Año",
};

const USER_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  rfc: "",
  gender: "",
  zipCode: "",
};

const VEHICLE_INITIAL_STATE = {
  type: "",
  brand: "",
  model: "",
  year: 0,
  name: "",
};

async function saveUser(dataToSave) {
  const uid = localStorage.getItem("cl_uid");
  if (!uid) return;
  await updateDoc(doc(db, "users", uid), {
    ...dataToSave,
  });
}
async function saveVehicle(vehicleData) {
  const uid = localStorage.getItem("cl_uid");
  if (!uid) return;
  const todayDay = new Date();
  const ref = collection(db, `users/${uid}/vehicles`);
  const { year, ...rest } = vehicleData;
  await addDoc(ref, {
    ...rest,
    isInsured: false,
    deleted: false,
    year: Number(year),
    insuranceStartDay: todayDay.getDay(),
    insuranceStartMonth: todayDay.getMonth() + 1,
    insuranceStartYear: todayDay.getFullYear(),
    insuranceEndDay: todayDay.getDay(),
    insuranceEndMonth: todayDay.getMonth() + 1,
    insuranceEndYear: todayDay.getFullYear(),
    //status: "pendiente"
  });
}

function keysWithFalseValue(obj) {
  return Object.keys(obj).filter((key) => !Boolean(obj[key]));
}

export default function Register() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepSection, setStepSection] = useState(() => {
    return Number(localStorage.getItem("cl_status")) || 0;
  });
  const [isRfcError, setIsRefError] = useState(false);
  const [vehicleData, setVehicleData] = useState(VEHICLE_INITIAL_STATE);
  const [userData, setUserData] = useState(USER_INITIAL_STATE);
  const [isZipCodeError, setIsZipCodeError] = useState(false);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const theme = useTheme();

  async function onSubmitUserHadler(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const missingData = keysWithFalseValue(userData);
      if (missingData.length > 0) {
        Swal.fire({
          icon: "warning",
          html: `<p style="font-size: 18px; color: black;">Para continuar, por favor, ingresa los siguientes datos faltantes:<br/>
            ${missingData
              .map(
                (item) =>
                  `<li style="text-align: left; margin-left: 12px; color: black; font-size: 18px">${USERDATA[item]}</li>`
              )
              .join("")}
          </p>`,
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
      } else if (isRfcError) {
        Swal.fire({
          icon: "warning",
          text: "Por favor, verifica que el RFC tenga el formato correcto",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
      } else if (isZipCodeError) {
        Swal.fire({
          icon: "warning",
          text: "Por favor, verifica que el código postal tenga el formato correcto",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
      } else {
        const birthday = extractBirthdateFromRFC(userData?.rfc);
        const birthdateObj = {
          day: birthday ? birthday.getDate() : 0,
          month: birthday ? birthday.getMonth() + 1 : "",
          year: birthday ? birthday.getFullYear() : "",
        };
        const dataToSave = {
          ...userData,
          birthday: {
            ...birthdateObj,
          },
        };
        await saveUser(dataToSave);
        Swal.fire({
          icon: "success",
          text: "Se han guardado tus datos exitosamente",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        setStepSection(3);
        localStorage.setItem("cl_status", 3);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }
  async function onSubmitVehicleHadler(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const missingData = keysWithFalseValue(vehicleData).filter((item) => {
        if (["auto", "moto"].includes(vehicleData.type)) {
          return true;
        } else {
          return item !== "year";
        }
      });
      if (missingData.length > 0) {
        Swal.fire({
          icon: "warning",
          html: `<p style="font-size: 18px; color: black;">Para continuar, por favor, ingresa los siguientes datos faltantes:<br/>
            ${missingData
              .map(
                (item) =>
                  `<li style="text-align: left; margin-left: 12px; color: black; font-size: 18px">${VEHICLEDATA[item]}</li>`
              )
              .join("")}
          </p>`,
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
      } else {
        await saveVehicle({ ...vehicleData, status: 2 });
        Swal.fire({
          icon: "success",
          text: "Se han guardado tus datos exitosamente",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        setStepSection(4);

        localStorage.setItem("cl_status", 4);
        setTimeout(async () => {
          await saveUser({
            clientStatus: 0,
          });

          localStorage.removeItem("cl_phone");
          localStorage.removeItem("cl_uid");
          localStorage.removeItem("cl_status");
        }, 5000);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmitNumberHandler() {
    setIsLoading(true);
    if (isPhoneNumberError) {
      Swal.fire({
        icon: "warning",
        text: "Por favor, verifica que tu número telefónico tenga 10 dígitos",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      setIsLoading(false);

      return false;
    } else if (!phoneNumber) {
      Swal.fire({
        icon: "warning",
        text: "Por favor, ingresa tu número telefónico para continuar",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      setIsLoading(false);

      return false;
    }

    try {
      const response = await createUser({
        phone: phoneNumber,
      });

      if (!response.success) {
        Swal.fire({
          icon: "warning",
          text: response.message,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Entiendo",
        });
        setIsLoading(false);
        return false;
      }

      const verificationResponse = await sendVerificationCode({
        phone: phoneNumber,
      });

      if (!verificationResponse.success) {
        Swal.fire({
          icon: "warning",
          text: verificationResponse.message,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Entiendo",
        });
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        text: error.message,
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Entiendo",
      });
      setIsLoading(false);
      return false;
    }

    setIsLoading(false);

    return true;
  }
  async function onSubmitConfirmCodeNumberHandler(e, code) {
    e.preventDefault();
    setIsLoading(true);
    try {
      // await savePhoneRegistered(phoneNumber, currentUser.uid);
      console.debug("phoneNumber", phoneNumber);
      console.debug("Code", code);

      const result = await verifyCode({
        phone: phoneNumber,
        code: code,
      });

      if (result?.code) {
        Swal.fire({
          icon: "error",
          text: result.message,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        });
      } else {
        localStorage.setItem("cl_uid", result.uid);
        localStorage.setItem("cl_phone", phoneNumber);
        Swal.fire({
          icon: "success",
          text: result.message,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        }).then(() => {
          setStepSection(1);
          localStorage.setItem("cl_status", 1);
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: e.message,
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmitMail(emailData) {
    setIsLoading(true);
    try {
      const phone = localStorage.getItem("cl_phone", phoneNumber);
      const result = await linkEmail({
        email: emailData.email,
        password: emailData.password,
        phone: phone,
      });

      if (result.code) {
        Swal.fire({
          icon: "error",
          text: result.message,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        });
        return;
      }
      Swal.fire({
        icon: "success",
        text: "Se han guardado tus datos correctamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      }).then(() => {
        localStorage.setItem("cl_status", 2);
        setStepSection(2);
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Ha ocurrido un error la registrar tus datos, por intentalo nuevamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
      <Header
        sx={{ height: "80px" }}
        linksComponent={
          <>
            <IconButton onClick={() => setIsSidebarOpen(true)}>
              <Menu />
            </IconButton>

            <Drawer
              variant="temporary"
              PaperProps={{
                sx: {
                  height: "100vh",
                },
                style: {
                  height: "100vh",
                },
              }}
              anchor="right"
              sx={{
                zIndex: "2000",
              }}
              open={isSidebarOpen}
              onClose={() => setIsSidebarOpen(false)}
              onOpen={() => setIsSidebarOpen(true)}
            >
              <Box>
                <List>
                  {[
                    {
                      icon: <PrivacyTip />,
                      url: "https://auto-clupp.web.app/privacy/aviso_privacidad_vigente.pdf",
                      label: "Aviso de privacidad",
                    },
                    {
                      icon: <Info />,
                      url: "https://clupp.com.mx/",
                      label: "Sobre Clupp",
                    },
                    {
                      icon: <WhatsApp />,
                      url:
                        "https://wa.me/525577460177?text=" +
                        encodeURIComponent(
                          "Hola tengo problemas para completar mi registro en la app de Clupp"
                        ),
                      label: "¿Problemas?",
                    },
                  ].map(({ label, url, icon }, index) => (
                    <ListItem key={{ label }} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText
                          onClick={() => window.open(url, "_blank")}
                          primary={label}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </>
        }
      />

      <main
        style={{
          padding: "80px 24px 24px 24px",
          maxWidth: "1120px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          width: "100%",
        }}
        n
      >
        {stepSection === 0 && (
          <Stack
            sx={{
              minHeight: "100svh",
            }}
          >
            <Stack
              width="100%"
              paddingX={["0rem", "8rem", "10rem"]}
              marginX="auto"
            >
              <Typography textAlign="center" variant="h6">
                ¿Acabas de contratar tu seguro?
              </Typography>
              <Typography mt={2} textAlign="center" variant="body2">
                Si acabas de contratar tu seguro, no es necesario que realices
                este proceso de registro. Inicia sesión en nuestra app con el
                correo que usaste al contratar y la contraseña temporal que
                hemos enviado al mismo correo.
              </Typography>
            </Stack>
            <Stack
              width="100%"
              mt={4}
              paddingX={["0rem", "8rem", "10rem"]}
              marginX="auto"
            >
              <Typography mb={2} textAlign="center" variant="h6">
                ¿Por qué registrarse en Clupp de manera gratuita?
              </Typography>

              <Stack
                sx={{
                  mb: 2,
                }}
              >
                <Accordion
                  sx={{
                    ".Mui-expanded svg": {
                      color: "lightgreen",
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Topic />
                      Expediente digital
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    Tendrás un expediente digital en el que puedes llevar un
                    registro de las cosas que le has hecho, como cambio de
                    llantas, mantenimiento, servicio, entre otros.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    ".Mui-expanded svg": {
                      color: "lightgreen",
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <FolderCopy />
                      Carpeta de seguros
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    Tendrás una carpeta en donde podrás tener a la mano tu
                    seguros, sin importar con qué aseguradora los tienes.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    ".Mui-expanded svg": {
                      color: "lightgreen",
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Link />
                      Enlaces útiles
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    Podrás saber si se tiene multas, tener a la mano todo lo
                    relacionado con hoy no circula y verificación vehicular, y
                    saber qué hacer si te detienen en ciudad de México.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    ".Mui-expanded svg": {
                      color: "lightgreen",
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <RequestQuote />
                      Cotización de seguros
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    Cotizar no solo el seguro para tu auto o moto, sino también
                    para casa, gastos médicos, mascota, viajes y más.
                  </AccordionDetails>
                </Accordion>
              </Stack>

              <Button
                href="#start-verification"
                sx={{ textTransform: "none" }}
                fullWidth
                variant="contained"
              >
                Registrarme ahora
              </Button>
            </Stack>
          </Stack>
        )}
        {stepSection > 0 && (
          <Stack width="100%" gap={2} flex="1">
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Registro
            </Typography>
            <StepperCustom activeStep={stepSection} steps={steps} />
          </Stack>
        )}

        {stepSection === 0 && (
          <Stack
            sx={{
              minHeight: "90svh",
              alignItems: "center",
            }}
          >
            <Stack width="100%" gap={2} flex="1">
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Registro
              </Typography>
              <StepperCustom activeStep={stepSection} steps={steps} />
            </Stack>
            <AuthPhoneForm
              isLoading={isLoading}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              onSubmit={onSubmitNumberHandler}
              onSubmitConfirmCodeNumberHandler={
                onSubmitConfirmCodeNumberHandler
              }
              setIsPhoneNumberError={setIsPhoneNumberError}
              isPhoneNumberError={isPhoneNumberError}
            />
          </Stack>
        )}
        {stepSection === 1 && (
          <MailAuth isLoading={isLoading} onSubmit={onSubmitMail} />
        )}
        {stepSection === 2 && (
          <UserDataForm
            onSubmit={onSubmitUserHadler}
            userData={userData}
            setUserData={setUserData}
            isLoading={isLoading}
            setIsRefError={setIsRefError}
            isRfcError={isRfcError}
            setIsZipCodeError={setIsZipCodeError}
            isZipCodeError={isZipCodeError}
          />
        )}
        {stepSection === 3 && (
          <VehicleDataForm
            vehicleData={vehicleData}
            setVehicleData={setVehicleData}
            onSubmit={onSubmitVehicleHadler}
            isLoading={isLoading}
          />
        )}
        {stepSection === 4 && (
          <Stack maxWidth={["100%", "70%", "50%"]} marginX="auto" gap={2}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              ¡Gracias por registrarte!
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Ya puedes disfrutar de todos los beneficios de tu app de Clupp.
            </Typography>

            <Typography variant="" sx={{ textAlign: "center" }}>
              Ya puedes cerrar esta ventana e iniciar sesión en la app.
            </Typography>
            <img
              style={{
                width: "100%",
                maxHeight: "250px",
                objectFit: "scale-down",
                margin: "0 auto",
              }}
              alt=""
              src={cluppcitoFestejoIcon}
            />
          </Stack>
        )}
      </main>
    </div>
  );
}
