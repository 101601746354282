import { useEffect, useState } from "react";

const useCountdown = (callback) => {
  const [secondsLeft, setSecondsLeft] = useState(null);
  useEffect(() => {
    if (secondsLeft < 0) return;
    if (secondsLeft === 0) {
      callback();
      return;
    }
    const timeout = setTimeout(() => {
      setSecondsLeft(secondsLeft - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [secondsLeft]);

  const start = (seconds) => setSecondsLeft(seconds);

  return { secondsLeft, start };
};

export default useCountdown;
