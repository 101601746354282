import { useEffect, useState } from "react";
import useQueryParams from "./useQueryParams";
import { fetchUser } from "../Services/users";
import { useNavigate } from "react-router-dom";

function useUser(
  { redirectOnNotFound = true } = {
    redirectOnNotFound: true,
  }
) {
  const navigate = useNavigate();
  const params = useQueryParams();
  const uid = params.get("userUID") || params.get("uid");
  const [user, setUser] = useState(undefined);
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  useEffect(() => {
    const getUserAndInsurances = async (uid) => {
      setIsLoadingUser(true);
      try {
        const user = await fetchUser(uid);
        setUser(user);
      } catch (err) {
        console.log("Error get User data>", err);
        redirectOnNotFound && navigate("/usuario-notfound");
      }
      setIsLoadingUser(false);
    };
    uid
      ? getUserAndInsurances(uid)
      : redirectOnNotFound && navigate("/usuario-notfound");
  }, [uid]);

  return {
    user,
    isLoadingUser,
  };
}

export default useUser;
