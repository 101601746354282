import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import './QueCubreModal.css';

const PREFIX = 'QueCubreModal';

const classes = {
  paper: `${PREFIX}-paper`,
  cerrar: `${PREFIX}-cerrar`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.paper}`]: {
    position: 'absolute',
    width: '70%',
    height: 'auto',
    backgroundColor: '#ffff',
    borderRadius: '0.5rem',
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  },

  [`& .${classes.cerrar}`]: {
    textAlign: 'right',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    cursor: 'pointer',
  }
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const QueCubreModal = ({title, content}) => {

    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    const body = (
      <Root style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">{title}</h2>
        <p id="simple-modal-description">
          {content}
        </p>
        <p className={classes.cerrar} onClick={handleClose}>Cerrar</p>
      </Root>
    );
  
    return (
      <div>
        <p className='cobertura' onClick={handleOpen}>¿Qué cubre?</p>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>
   );
}

export default QueCubreModal;