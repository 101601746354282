import { Container, Typography, Button, Stack, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getDocument, getDocuments } from "../../Services/firestore";
import DefaultAdditionalFeatures from "./DefaultAdditionalFeatures";
import "./AdditionalFeatures.css";
import Dialog from "../../Components/Dialog";
import ExtraItemField from "../../Components/ExtraItemField";
import ChangeMobilityDialog from "./ChangeMobilityDialog";
import { useNavigate } from "react-router";
import { ContactMailSharp, WhatsApp } from "@mui/icons-material/";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import Header from "../../Components/Header";
import HoyNoCircula from "../../Components/HoyNoCircula.jsx";
import gobMex from "../../Assets/GobCDMX.png";
import getLastNumericDigit from "../../utils/getLastNumericDigit.js";
import getRestrictedDays from "../../utils/getRestrictedDayHoyNoCircula.js";
import moment from "moment-timezone";
import isZipCodeInCdmxOrEdomex from "../../utils/isACmdxOrEdomexZipCode.js";

let date = new Date();
let year = date.getFullYear();
const PAY_PERIODS = {
  month: "mensual",
  "3month": "trimestral",
  "6month": "semestral",
  year: "anual",
};

const MOBILITY_TYPES = {
  0: { content: "Puedes recorrer menos de 250 km al mes", type: "Mínima" },
  1: { content: "Puedes recorrer de 250 a 500 km al mes", type: "Reducida" },
  2: { content: "Puedes recorrer de 500 a 1,200 km al mes", type: "Normal" },
  3: {
    content: "Puedes recorrer más de 1,200 km al mes.",
    type: "Intensiva",
  },
};
const ADDITIONAL_FEATURES = [
  {
    title: "Reparacion en agencia",
    description:
      "Garantiza reparar en la agencia automotriz correspondiente los daños materiales de tu vehículo en caso de accidente",
    type: "agencyRepair",
    for: ["Auto", "Moto"],
    years: [year - 5, year - 4, year - 3], //disponible de 3 a 5 años de vigencia
    selected: false,
    premium: 0,
  },
  {
    title: "adicionales en gastos médicos a ocupantes",
    description:
      "Aumenta $100,000 al valor cubierto de la cobertura de gastos médicos ocupantes. Aplica por reembolso",
    for: ["Auto", "Moto"],
    type: "extraMedicalExpensesUnits",
    years: ["Any"],
    selected: false,
    premium: 0,
  },
  {
    title: "Pago a valor de factura",
    description: `Garantiza que el valor cubierto de tu vehículo en las
    coberturas de daños materiales y robo sea igual al monto
    de la factura de origen, siempre
    que hayas contratado con dicho monto`,
    for: ["Auto", "Moto"],
    type: "invoiceValuePayment",
    years: [year + 1, year, year - 1, year - 2, year - 3], // disponible de -1 (para modelos del siguiente año del actual ) a 3 años de vigencia.
    selected: false,
    premium: 0,
  },
  {
    title: "Rotura de cristales",
    description:
      "Cubre el cambio y/o reparación de los cristales por rotura accidental. Aplica un 20% de deducible del costo de reparación",
    for: ["Auto"],
    type: "glassBreakage",
    years: ["Any"],
    selected: false,
    premium: 0,
  },
  {
    title: "Cobertura para casco",
    description:
      "Cubre la reposición de tu casco en caso de que se dañe en un accidente automovilístico",
    for: ["Moto"],
    type: "motorcycleHelmet",
    years: ["Any"],
    selected: false,
    montoCubierto: 0,
    premium: 0,
  },
  {
    title: "Cobertura para accesorios",
    description:
      "Cubre la reposición de tus accesorios personales en caso de que se dañen en un accidente automovilístico",
    for: ["Moto"],
    type: "personalAccessories",
    years: ["Any"],
    selected: false,
    montoCubierto: 0,
    premium: 0,
  },
  {
    title: "Cobertura para equipo especial",
    description:
      "Cubre el equipo especial instalado en tu vehículo. Se requiere factura original del equipo a cubrir",
    for: ["Moto"],
    type: "specialEquipment",
    years: ["Any"],
    selected: false,
    montoCubierto: 0,
    premium: 0,
  },
];

function AdditionalFeatures() {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const userUID = params.get("userUID");
  const vehicleUID = params.get("vehicleId");
  const [allowedAdditionalFeatures, setAllowed] = useState([]);
  const [vehicle, setVehicle] = useState(null);
  const [extras, setExtras] = useState(undefined);
  const [checkboxOpen, setcheckbox] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isHoyNoCirculaModalOpen, setIsHoyNoCirculaModalOpen] = useState(false);
  const [thisVehicleCirculates, setThisVehicleCirculates] = useState(true);
  const [userData, setUserData] = useState([]);
  let navigate = useNavigate();

  const [additionalFeatures, setAdditionalFeatures] = useState("");
  useEffect(() => {
    calls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDocument({
      docPath: `users/${userUID}`,
    }).then((data) => {
      setUserData(data.doc);
    });
  }, []);

  useEffect(() => {
    if (vehicle) {
      searcheExtraAllowed(vehicle);
    } else {
      return;
    }
    console.log({ additionalFeatures });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalFeatures]);

  useEffect(() => {
    if (vehicle) {
      const { hologram, plates } = vehicle;
      if (hologram && plates) {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDay = moment(currentDate).format("YYYY-MM-DD");
        const restrictedDays = getRestrictedDays(
          month,
          year,
          parseInt(hologram),
          getLastNumericDigit(plates)
        );
        if (restrictedDays.includes(formattedDay))
          setThisVehicleCirculates(false);
      }
    }
  }, [vehicle]);

  // TODO:
  // Obtener los extra del asegurado
  // Agregar al array de extras adquiridos los extras que puede adquirir.
  //    - Agregar solo los extras que correspondan
  //    - Agregar a cada extra los valores necesarios (deducible, monto cubierto, prima, descripción etc.)
  // Agregar los extras al estado.
  // Usar funciones puras para crear modificar el array de extras
  // y agregar la lista de extras al estado solo cuando ya tengan la información necesaria.

  function handleCloseModal() {
    setOpenModal(false);
  }

  async function calls() {
    await getCustomerVehicle();
    await getExtras();
  }

  async function getExtras() {
    try {
      const response = await getDocuments({
        collectionPath: `/users/${userUID}/vehicles/${vehicleUID}/extras/`,
        limit: 100,
      });
      const extras = await response.json();
      if (extras.docs.length === 0) {
        setAdditionalFeatures(ADDITIONAL_FEATURES);
        setExtras(extras);
      } else {
        let a = [...ADDITIONAL_FEATURES];
        extras.docs.forEach((doc) => {
          a.forEach((item) => {
            if (doc.doc?.isActive && item.type === doc.doc.type) {
              item.selected = true;
              item.premium = doc.doc.premium;
            }
          });
        });

        setAdditionalFeatures(a);
        setExtras(extras);
        // Ejecutar esta función después de actualizar el estado NO GARANTIZA que la
        // functión pueda acceder al estado que se establece en las functiones setState
        // ya que los cambios de estado NO se ejecutan de manera síncrona

        // Se le pasan las coberturas adicionales
        calculateInsuredAmounts(extras, a);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getCustomerVehicle() {
    try {
      const response = await getDocument({
        docPath: `/users/${userUID}/vehicles/${vehicleUID}`,
      });
      setVehicle(response.doc);
      console.log("Vehicle");
    } catch (error) {
      console.log(error);
    }
  }

  function searcheExtraAllowed(vehicle) {
    let tmp = [];
    if (vehicle.type === "auto") {
      tmp = additionalFeatures.filter((extra) => {
        console.log("extra", extra);
        console.log(extra.years, vehicle.year);

        return (
          extra.for.includes("Auto") &&
          (extra.years.includes(vehicle.year) || extra.years.includes("Any"))
        );
      });
    } else if (vehicle.type === "moto") {
      tmp = additionalFeatures.filter((extra) => {
        console.log("extra", extra);
        console.log(extra.years, vehicle.year);

        return (
          extra.for.includes("Moto") &&
          (extra.years.includes(vehicle.year) || extra.years.includes("Any"))
        );
      });
    }
    setAllowed(tmp);
  }
  function calculateInsuredAmounts(extras, additionalFeatures) {
    // extras o additionalFeatures puede ser undefined
    let tmp = [...additionalFeatures].map((coverage) => {
      if (
        [
          "motorCycleHelmet",
          "personalAccessories",
          "specialEquipment",
        ].includes(coverage.type)
      ) {
        const extraDoc = extras.docs.find(
          (extra) => extra.doc.type === coverage.type
        );
        console.log("Coverage " + coverage.type, { extraDoc });
        if (extraDoc) {
          const montoCubierto = extraDoc.doc.items[0].value;
          return {
            ...coverage,
            montoCubierto,
          };
        }
      }
      return coverage;
    });
    // ¿Qué pasa si se agregan nuevos extras en el futuro?
    // for (let extra of extras.docs) {
    //   if (extra.doc.type === "motorCycleHelmet")
    //     tmp[4].montoCubierto = extra.doc.items[0].quantity;
    //   if (extra.doc.type === "personalAccessories")
    //     tmp[5].montoCubierto = extra.doc.items[0].quantity;
    //   if (extra.doc.type === "specialEquipment")
    //     tmp[6].montoCubierto = extra.doc.items[0].quantity;
    // }

    setAdditionalFeatures(tmp);
  }

  function calculateSubtotalExtras() {
    let subtotal = 0;
    for (let feature of allowedAdditionalFeatures) {
      subtotal += feature.premium;
    }
    return subtotal;
  }

  function calculateCoveredMedicalExpenses() {
    let amount;
    extras.docs.forEach((extra) => {
      if (extra.doc.type === "extraMedicalExpensesUnits")
        amount = 100000 * extra.doc.items[0].quantity;
    });
    return amount.toLocaleString("en-US");
  }

  function handleCheckboxChange(e) {
    setcheckbox(!checkboxOpen);
  }

  function handleRedirect() {
    navigate(`/expediente-digital?uid=${userUID}&vid=${vehicleUID}`);
  }

  return (
    <>
      <Header sx={{ height: "80px" }} />
      <Container className="main-container">
        <ChangeMobilityDialog
          userData={userData}
          vehicleID={vehicleUID}
          userUID={userUID}
          vehicle={vehicle}
          isOpen={openModal}
          onClose={handleCloseModal}
        />
        <Container
          className="default-extras-container"
          style={{ display: "flex", padding: "0px" }}
        >
          {vehicle ? (
            <>
              <VehicleData vehicle={vehicle} />
              <Button
                endIcon={<ViewTimelineIcon />}
                onClick={handleRedirect}
                variant="outlined"
                sx={{ textTransform: "none" }}
              >
                Expediente digital
              </Button>
              {vehicle.type === "auto" && isZipCodeInCdmxOrEdomex(userData.zipCode)}
              <Button
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  backgroundColor: "#F1F1F1",
                  width: "100%",
                  margin: "0 auto",
                  padding: "10px 15px",
                }}
                startIcon={<img src={gobMex} alt="gobMex" />}
                endIcon={
                  vehicle.hologram && (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ marginLeft: "5px" }}
                    >
                      <Box
                        sx={{
                          width: "25px",
                          height: "25px",
                          minWidth: "25px",
                          minHeight: "25px",
                          backgroundColor: thisVehicleCirculates
                            ? "#87E0A0"
                            : "#EE7E6B",
                          borderRadius: "4px",
                          marginRight: "8px",
                        }}
                      ></Box>
                      <Box sx={{ textAlign: "left" }}>
                        <Typography sx={{ fontWeight: 400 }}>
                          Hoy tu vehículo
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            marginTop: "-3px",
                            fontWeight: 600,
                            color: "#000",
                          }}
                        >
                          {`${
                            thisVehicleCirculates ? "Circula" : "No circula"
                          }`}
                        </Typography>
                      </Box>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "#000" }}
                      ></Typography>
                    </Box>
                  )
                }
                onClick={() => setIsHoyNoCirculaModalOpen(true)}
              >
                <Box sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontWeight: 600, color: "#000" }}>
                    Consultar
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: "-3px" }}>
                    Hoy no circula
                  </Typography>
                </Box>
                {vehicle.hologram && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      fontSize: "20px",
                    }}
                  >
                    |
                  </span>
                )}
              </Button>
              <HoyNoCircula
                vehicleHologram={vehicle.hologram}
                plate={vehicle.plates}
                vid={vehicleUID}
                uid={userUID}
                isOpen={isHoyNoCirculaModalOpen}
                handleClose={() => setIsHoyNoCirculaModalOpen(false)}
              />
              {vehicle.plates && ["auto", "moto"].includes(vehicle.type) && (
                <Button
                  onClick={() => setOpenModal((prev) => !prev)}
                  variant="contained"
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                    textTransform: "capitalize",
                  }}
                >
                  Cambiar movilidad
                </Button>
              )}
              <DefaultAdditionalFeatures
                userUID={userUID}
                vehicleFeatures={vehicle.features || []}
                vehicleType={vehicle.type}
                vehicleValue={vehicle.value}
                vehiclePremium={vehicle.premium}
                payPeriod={PAY_PERIODS[vehicle.period]}
              />
              {(vehicle.type === "auto" || vehicle.type === "moto") && (
                <>
                  {allowedAdditionalFeatures.length === 0 ? (
                    <p style={{ textAlign: "center" }}>Cargando...</p>
                  ) : extras != undefined ? (
                    <>
                      <Typography
                        style={{ textAlign: "center", fontSize: "1rem" }}
                      >
                        Coberturas Extra
                      </Typography>
                      {allowedAdditionalFeatures.map((extraItem, index) => {
                        const extraLabelValue =
                          extraItem.type === "extraMedicalExpensesUnits"
                            ? extraItem.selected
                              ? `$${calculateCoveredMedicalExpenses()} ${
                                  extraItem.title
                                }`
                              : `$${(100000).toLocaleString("en-US")} ${
                                  extraItem.title
                                }`
                            : extraItem.title;

                        const extraAmountValue = extraItem.montoCubierto ? (
                          <>
                            <p>Cobertura total: </p>
                            <p>
                              $
                              {extraItem.montoCubierto === 0
                                ? " "
                                : extraItem.montoCubierto.toLocaleString(
                                    "en-US"
                                  )}
                            </p>
                          </>
                        ) : null;

                        const extraPriceValue = !(extraItem.premium === 0) ? (
                          <>
                            <p>${extraItem.premium}</p>
                            <p style={{ fontSize: "0.7rem" }}>
                              /{PAY_PERIODS[vehicle.period]}
                            </p>
                          </>
                        ) : null;
                        return (
                          <ExtraItemField
                            key={index}
                            message={extraItem.description}
                            onChange={handleCheckboxChange}
                            checked={extraItem.selected}
                            label={extraLabelValue}
                            monto={extraAmountValue}
                            price={extraPriceValue}
                          />
                        );
                      })}
                      <section>
                        <div className="subtotal-container">
                          <Typography
                            variant="body1"
                            style={{ fontSize: "0.95rem", fontWeight: "bold" }}
                          >
                            Subtotal Coberturas Extras:
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ fontSize: "0.95rem" }}
                          >
                            ${" "}
                            {calculateSubtotalExtras().toLocaleString("en-US")}
                            <p style={{ fontSize: "0.7rem", textAlign: "end" }}>
                              /{PAY_PERIODS[vehicle.period]}
                            </p>
                          </Typography>
                        </div>
                      </section>

                      <Dialog
                        isOpen={checkboxOpen}
                        onClose={handleCheckboxChange}
                        content={
                          <Typography component={"span"}>
                            Si deseas agregar o cancelar una cobertura extra
                            comunícate con el departamento de atención a
                            clientes.
                          </Typography>
                        }
                        title="Contrata coberturas extras."
                        Actions={
                          <Stack spacing={2} direction="row">
                            <a
                              href={`https://wa.me/5215577460177`}
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                variant="outlined"
                                color="success"
                                startIcon={<WhatsApp />}
                                sx={{ textTransform: "none" }}
                              >
                                Enviar mensaje
                              </Button>
                            </a>
                          </Stack>
                        }
                      />
                    </>
                  ) : null}
                </>
              )}
              <div className="subtotal-container subtotal-container--margin">
                <Typography
                  variant="body1"
                  style={{ fontSize: "1.05rem", fontWeight: "bold" }}
                >
                  Total:
                </Typography>
                <Typography variant="body1" style={{ fontSize: "0.95rem" }}>
                  ${" "}
                  {(calculateSubtotalExtras() + vehicle.premium).toLocaleString(
                    "en-US"
                  )}
                  <p style={{ fontSize: "0.7rem", textAlign: "end" }}>
                    /{PAY_PERIODS[vehicle.period]}
                  </p>
                </Typography>
              </div>
            </>
          ) : null}
        </Container>
      </Container>
    </>
  );
}

function VehicleData({ vehicle }) {
  return (
    <>
      <h3
        style={{ textAlign: "center", margin: "0" }}
      >{`${vehicle.brand} ${vehicle.model} ${vehicle.year}`}</h3>
      <div>
        <img
          src={vehicle.pictureCarFront}
          alt="imagen del auto"
          style={{
            objectFit: "cover",
            width: ["moto", "scooter", "bike", "eBike"].includes(vehicle.type)
              ? "250px"
              : "75%",
            height: ["moto", "scooter", "bike", "eBike"].includes(vehicle.type)
              ? "300px"
              : "75%",
            borderRadius: "10px",
            textAlign: "center",
            margin: "0 auto",
            marginBottom: ["moto", "scooter", "bike", "eBike"].includes(
              vehicle.type
            )
              ? "0"
              : "20px",
            marginTop: ["moto", "scooter", "bike", "eBike"].includes(
              vehicle.type
            )
              ? "-20px"
              : "auto",
            display: "block",
            transform: vehicle?.type === "moto" ? "rotate(90deg)" : "",
          }}
        />
        <Typography style={{ textAlign: "center" }}>
          Movilidad contratada:{" "}
          <span style={{ fontWeight: "bold" }}>
            {`${MOBILITY_TYPES[vehicle.mobility].type} `}
          </span>
        </Typography>
        <Typography style={{ textAlign: "center", fontSize: "0.9rem" }}>
          {MOBILITY_TYPES[vehicle.mobility].content}
        </Typography>
      </div>
    </>
  );
}

export default AdditionalFeatures;
