import MobileHeader from "../../Components/MobileHeader";
import useUser from "../../hooks/useUser";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import getTypeImgAndTitle from "../../utils/getExtraInsImgAndTitle";
import Swal from "sweetalert2";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import fetchCoupon from "../../Services/users/fetchCoupon";
import logoClupp from "../../Assets/logo-clupp.png";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const ExtraInsurancesTypes = () => {
  const { user } = useUser();
  const insurancesTypes = [
    "vehicle",
    "devices",
    "medical",
    "house",
    "pet",
    "life",
    "medical-olderly",
    "sports",
    "diabetes",
    "travel",
  ];
  const [isDialogOpen, setIsDialogOpen] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [isBrackdropOpen, setIsBackdropOpen] = useState(false);

  const GATriggerEvent = (uid, type) => {
    window.dataLayer.push({
      event: `insurance_quote`,
      uid,
      type,
    });
  };

  const handleSelect = async (type) => {
    if (type === "vehicle") {
      setIsBackdropOpen(true);
      try {
        const vehiclesRef = collection(db, `/users/${user.uid}/vehicles/`);
        const q = query(
          vehiclesRef,
          where("isInsured", "==", true),
          where("deleted", "==", false)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
          window.open(
            getTypeImgAndTitle({
              type: "vehicle",
              uid: user.uid,
              email: user.email,
            }).link
          );
        } else {
          if (!coupon) {
            const cuoponData = await fetchCoupon({
              uid: user.uid,
              coupon: "76gyvaJd",
              numberOfUses: 1,
            });
            setCoupon(cuoponData.promotionCode.code);
          }
          setIsBackdropOpen(false);
          setIsDialogOpen(true);
        }
      } catch (err) {
        console.log("Error generating coupon -->>", err);
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          title: "Lo sentimos, ocurrio un error inesperado.",
          text: "Por favor, vuelva a intentarlo más tarde",
        });
      }
    } else if (type === "devices") {
      window.open(
        getTypeImgAndTitle({
          type: "devices",
          uid: user.uid,
          email: user.email,
        }).link
      );
    } else {
      Swal.fire({
        title: "Considera que...",
        text: `Estas a punto de salir de Clupp y entrar a otro sitio web.`,
        showCancelButton: true,
        confirmButtonColor: "#00BBEE",
        denyButtonColor: "#fff",
        confirmButtonText: "De acuerdo",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.open(
            getTypeImgAndTitle({ type, uid: user.uid, email: user.email }).link
          );
        }
      });
    }
    GATriggerEvent(user.uid, type);
    setIsBackdropOpen(false);
  };

  async function handleClick() {
    setIsCopied(true);
    try {
      await navigator.clipboard.writeText(coupon);
    } catch (error) {
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        text: "Este navegador no soporta esta función , copiar manualmente.",
      });
    }
    setTimeout(() => setIsCopied(false), 1000);
  }

  return user ? (
    <>
      <MobileHeader user={user} showGradientHeader={false} />
      <Stack
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            padding: "20px 20px 0px 20px",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          ¿Qué tipo de seguro te gustaría cotizar?
        </Typography>

        <Box
          sx={{
            margin: "20px auto",
            paddingBottom: "50px",
            width: "100vw",
            overflowY: "scroll",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {insurancesTypes.map((type) => (
            <Card
              className="insurance-button"
              name={`${getTypeImgAndTitle({ type })
                .title.toLowerCase()
                .replace(/\s+/g, "-")}`}
              sx={{
                width: "10%",
                minWidth: "150px",
                minHeight: "200px",
                margin: "10px",
                p: 1,
                borderRadius: "8px",
                border: "1px solid #cecece",
                boxShadow: 3,
                position: "relative",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              onClick={() => handleSelect(type)}
            >
              <img
                src={getTypeImgAndTitle({ type }).img}
                style={{
                  maxWidth: type === "devices" ? "85px" : "60px",
                  minWidth: type === "devices" ? "85px" : "60px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
                alt="type"
              />
              <Typography variant="h6" width={"100%"}>
                {getTypeImgAndTitle({ type }).title}
              </Typography>
            </Card>
          ))}
        </Box>
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          sx={{ textAlign: "center" }}
        >
          <DialogTitle>
            <img
              style={{
                height: "70px",
                width: "auto",
                objectFit: "contain",
              }}
              src={logoClupp}
              alt="logo clupp"
            />
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "5px" }}
            >
              ¡Genial!
            </Typography>
            <Typography variant="subtitle1">
              Te vamos a redirigir a nuestro cotizador en línea para que puedas
              cotizar y contratar tu seguro para otro vehículo. Asegúrate de
              obtener tu descuento de 15% con el código de promoción:
            </Typography>
            <Tooltip title="Copiado" onClick={handleClick} open={isCopied}>
              <strong>{coupon}</strong>
              <IconButton>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              sx={{ width: "85%", marginBottom: "10px" }}
              onClick={() =>
                window.open(
                  getTypeImgAndTitle({
                    type: "vehicle",
                    uid: user.uid,
                    email: user.email,
                  }).link
                )
              }
            >
              ¡Vamos!
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isBrackdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Stack>
    </>
  ) : (
    <></>
  );
};

export default ExtraInsurancesTypes;
