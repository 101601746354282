export default async function postMessageInClaimsChannel(
  messageBody,
  isTest = false
) {
  try {
    const response = await fetch(
      isTest
        ? "https://hooks.slack.com/services/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"
        : "https://hooks.slack.com/services/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",
      {
        method: "POST",
        // omit Content-Type header to avoid CORS error from Slack API
        body: JSON.stringify(messageBody),
      }
    );
    if (!response.ok) {
      throw new Error("Cannot post message to Slack channel");
    }
  } catch (error) {
    console.error(error);
  }
}
