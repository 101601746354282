import { useReducer, useEffect } from "react";

import {
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  Collapse,
  TextField,
  Skeleton,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import Swal from "sweetalert2";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NoCedulaModal from "./NoCedulaModal";
import PaymentIcon from '@mui/icons-material/Payment';

import { useSearchParams } from "react-router-dom";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { reducer, initialValue, ACTIONS } from "./state";
import { fetchUser, fetchUserInvoiceProfiles } from "../../Services/users";
import { capitalize } from "../../utils";
import {
  addDocument,
  updateDocument,
  getDocuments,
} from "../../Services/firestore";
import { storage } from "../../firebase";
import ImageForm from "./ImageForm";
import useReadImage from "../../hooks/useReadImage";
import { useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { Box } from "@mui/system";

const FISCAL_REGIMES = [
  "601 - General de Ley Personas Morales",
  "603 - Personas Morales con Fines no Lucrativos",
  "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
  "606 - Arrendamiento",
  "607 - Régimen de Enajenación o Adquisición de Bienes",
  "608 - Demás ingresos",
  "609 - Consolidación",
  "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
  "611 - Ingresos por Dividendos (socios y accionistas)",
  "612 - Personas Físicas con Actividades Empresariales y Profesionales",
  "614 - Ingresos por intereses",
  "615 - Régimen de los ingresos por obtención de premios",
  "616 - Sin obligaciones fiscales",
  "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  "621 - Incorporación Fiscal",
  "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  "623 - Opcional para Grupos de Sociedades",
  "624 - Coordinados",
  "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Electrónicas",
  "626 - Régimen Simplificado de Confianza",
  "628 - Hidrocarburos",
  "629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  "630 - Enajenación de acciones en bolsa de valores",
];

const INVOICE_TYPES = [
  "CP01 - Pagos",
  "CN01 - Nómina",
  "G01 - Adquisición de mercancías",
  "G02 - Devoluciones, descuentos o bonificaciones",
  "G03 - Gastos generales",
  "D01 - Honorarios médicos, dentales y gastos hospitalarios",
  "D02 - Gastos médicos por incapacidad o discapacidad",
  "D03 - Gastos funerales",
  "D04 - Donativos",
  "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
  "D06 - Aportaciones voluntarias al SAR",
  "D07 - Primas por seguros de gastos médicos",
  "D08 - Gastos de transportación escolar obligatoria",
  "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
  "D10 - Pagos por servicios educativos (colegiaturas)",
  "I01 - Construcciones",
  "I02 - Mobilario y equipo de oficina por inversiones",
  "I03 - Equipo de transporte",
  "I04 - Equipo de computo y accesorios",
  "I05 - Dados, troqueles, moldes, matrices y herramental",
  "I06 - Comunicaciones telefónicas",
  "I07 - Comunicaciones satelitales",
  "I08 - Otra maquinaria y equipo",
  "S01 - Sin efectos fiscales",
];

const Invoice = (props) => {
  const [searchParams] = useSearchParams();
  const [fetchedCIFUrl, setFetchedCIFUrl] = useState();
  const userUID = searchParams.get("uid");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const theme = useTheme();
  // const [selectValue, setSelectValue] = useState("");

  const [state, dispatch] = useReducer(reducer, initialValue);
  const {
    requiresInvoices,
    customer,
    isLoading,
    firstName,
    lastName,
    address,
    rfc,
    fiscalRegime,
    isSaving,
    hasInvoiceProfile,
    usage,
  } = state;

  const { imgRef, inputImgRef, inputImage, setInputImage } = useReadImage();

  useEffect(() => {
    const q = query(
      collection(db, `users/${userUID}/invoiceProfiles`),
      orderBy("timestamp", "desc"),
      limit(1)
    );

    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        setFetchedCIFUrl(data.CIFURL);
      });
    });
  }, [userUID]);

  useEffect(() => {
    fetchUser(userUID)
      .then(async (customer) => {
        const [lastInvoiceProfile] = await fetchUserInvoiceProfiles({
          userUID: userUID,
          limit: 1,
        });
        dispatch({
          type: ACTIONS.FETCH_CUSTOMER_SUCCESS,
          payload: { customer, lastInvoiceProfile },
        });
      })
      .catch((error) => {
        Swal.fire("Ups! Algo salió mal", "", "error");
        dispatch({ type: ACTIONS.FETCH_CUSTOMER_ERROR });
      });
  }, [userUID]);

  const isValidRFC = rfc.length === 13;
  async function handleToggleRequiresInvoice(event) {
    const { checked } = event.target;

    if (hasInvoiceProfile) {
      if (!checked) {
        // ths customer already had an invoice profile but wants to unsubscribe
        Swal.fire({
          title: "Cambios guardados",
          text: "Dejaremos de enviarte las facturas fiscales",
          icon: "success",
          confirmButtonColor: theme.palette.primary.main,
        });
      }

      // customer unsusbcribed in the past but wants to receive invoices again
      await updateDocument(`users/${customer.uid}`, {
        requiresInvoices: checked,
      });
    }

    dispatch({ type: ACTIONS.TOGGLE_REQUIRES_INVOICES });
  }

  function updateFormField(field, update) {
    dispatch({
      type: ACTIONS.UPDATE_FORM_FIELD,
      payload: {
        field,
        update,
      },
    });
  }

  function handleNameChange(nameField) {
    return (event) => {
      const value = event.target.value;
      updateFormField(nameField, value);
    };
  }

  function handleRFCChange(event) {
    const value = event.target.value.toUpperCase();
    if (/\s/g.test(value)) {
      return;
    }
    const containsSpecialCharacters = /[^A-Z|0-9]+/gi.test(value);
    if (value.length > 13 || containsSpecialCharacters) return;
    updateFormField("rfc", value);
  }

  function handleAddressChange(event) {
    const value = event.target.value;
    updateFormField("address", value);
  }

  function handleFiscalRegime(event) {
    // if(event.target.value === "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios") {
    //   updateFormField("fiscalRegime", fiscalRegime);
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Lo sentimos",
    //     text: "Las primas de seguro de auto no son deducibles para el el régimen de sueldos y salarios.",
    //   });
    // } else {
    const value = event.target.value;
    updateFormField("fiscalRegime", value);
  }
  function handleInvoiceUse(event) {
    const value = event.target.value;
    updateFormField("usage", value);
  }

  // function to validate if a string have a image extension

  function checkURLIsImage(url) {
    return (
      url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !== null
    );
  }

  function normilizeStr(str) {
    return str
      .toUpperCase()
      // .normalize("NFD")
      // .replace(/[\u0300-\u036f]/g, "");
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  async function uploadImage(inputImage) {
    return new Promise((resolve, reject) => {
      const storageRef = ref(
        storage,
        `users/${customer.uid}/invoiceInformation/CIF_${formatDate(
          new Date()
        )}.${inputImage.name.split(".").pop()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, inputImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          reject(error);
        },
        async () => {
          const imgURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(imgURL);
        }
      );
    });
  }

  async function handleOnSubmit(event, continueWithoutCedula = false) {
    event !== null && event.preventDefault();
    if (!isValidRFC) {
      Swal.fire({
        title: "Ups!",
        text: "El RFC debe tener 13 caracteres",
        type: "warning",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }
    if ( !FISCAL_REGIMES.includes(fiscalRegime) ) {
      Swal.fire({
        title: "Ups!",
        text: "Debes de especificar tu Regimen de situacion fiscal",
        type: "warning",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }

    if( !INVOICE_TYPES.includes(usage) ) {
      Swal.fire({
        title: "Ups!",
        text: "Debes de especificar el uso de la factura",
        type: "warning",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }

    if (!inputImage && !fetchedCIFUrl && !continueWithoutCedula) {
      setIsOpenModal(true);
      return;
    }

    await updateDocument(`users/${customer.uid}`, { requiresInvoices: true });

    dispatch({ type: ACTIONS.TOGGLE_IS_SAVING });

    try {
      const cleanText = (text) => capitalize(text.trim());

      const urlImage = inputImage ? await uploadImage(inputImage) : null;

      await addDocument(`users/${customer.uid}/invoiceProfiles`, {
        address: address.trim(),
        firstName: cleanText(firstName),
        lastName: cleanText(lastName),
        rfc: rfc.toUpperCase().trim(),
        fiscalRegime: fiscalRegime,
        fiscalRegimeCode: fiscalRegime.split(" - ")[0],
        usageCode: usage.split(" - ")[0],
        usage: usage,

        timestamp: Date.now(),
        CIFURL: inputImage ? urlImage : fetchedCIFUrl,
      });

      Swal.fire({
        title: "¡Listo!",
        text: "Tu factura te estará llegando en automático a más tardar 5 días hábiles de realizado el pago.",
        type: "success",
        confirmButtonColor: theme.palette.primary.main,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "¡Ups!",
        text: "Hubo un error al guardar la información. Inténtalo nuevamente o contácta al soporte técnico.",
        type: "error",
        confirmButtonColor: theme.palette.primary.main,
      });
    } finally {
      dispatch({ type: ACTIONS.TOGGLE_IS_SAVING });
    }
  }

  return (
    <Stack p={4} spacing={2}>
      <NoCedulaModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        inputImgRef={inputImgRef}
        sendInformation={handleOnSubmit}
        isLoading={isSaving}
      />
      <Typography variant="h6">Facturación</Typography>
      <Typography variant="body2">
        Debido a que ya migramos a la nueva facturación 4.0 del SAT, es
        necesario que haya una coincidencia EXACTA de tu nombre, RFC, Código
        Postal y régimen fiscal, con los datos que aparecen en tu Cédula de
        Identificación Fiscal (CIF), por lo cual te pedimos cargar tu CIF para
        poder facturar.
      </Typography>
      <Box>
        <a style={{textDecoration: "none", width: "100%"}} href={`https://cluppseguro.web.app/pagos?uid=${userUID}`} >
          <Button sx={{textTransform: "none", width: "100%"}} startIcon={<PaymentIcon/>} variant="contained">Pagos y facturas</Button>
        </a>
      </Box>
      {!isLoading ? (
        !customer ? (
          <Stack>
            <Typography>
              Lo sentimos no pudimos obtener tu información, contácta a soporte
              técnico.
            </Typography>
          </Stack>
        ) : (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={requiresInvoices}
                  onChange={handleToggleRequiresInvoice}
                />
              }
              label="Requiero factura"
            />

            {hasInvoiceProfile && requiresInvoices && (
              <Typography>
                Si tus datos son correctos te enviaremos tu factura por correo en cuanto realices cada pago. Siempre las puedes consultar en tu app, en el menú, en el historial de pagos 🤓.
              </Typography>
            )}

            <Collapse orientation="vertical" in={requiresInvoices}>
              <form onSubmit={(e) => handleOnSubmit(e, false)}>
                <Stack spacing={2}>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2">Nombre</Typography>
                    <TextField
                      required
                      value={normilizeStr(firstName)}
                      onChange={handleNameChange("firstName")}
                      size="small"
                      inputProps={{
                        autoCapitalize: "characters",
                        autoComplete: "given-name",
                      }}
                    />
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2">Apellidos</Typography>
                    <TextField
                      required
                      value={normilizeStr(lastName)}
                      onChange={handleNameChange("lastName")}
                      size="small"
                      inputProps={{
                        autoCapitalize: "characters",
                        autoComplete: "family-name",
                      }}
                    />
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2">RFC</Typography>
                    <TextField
                      required
                      value={rfc}
                      onChange={handleRFCChange}
                      size="small"
                      type="text"
                      inputProps={{
                        autoCapitalize: "characters",
                      }}
                      error={rfc.length > 1 && !isValidRFC}
                      helperText="Ingresa los 13 caracteres de tu RFC. Por el momento no podemos facturar a personas morales."
                    />
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2">
                      Código postal conforme a constancia de situación fiscal
                    </Typography>
                    <TextField
                      required
                      value={address}
                      onChange={handleAddressChange}
                      size="small"
                      type="address"
                      inputProps={{
                        autoComplete: "postal-code",
                      }}
                    />
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2">Régimen Fiscal</Typography>
                    <Select
                      required
                      value={fiscalRegime}
                      size="small"
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleFiscalRegime}
                    >
                      {FISCAL_REGIMES.map((option, index) => {
                        if (
                          option ===
                          "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios"
                        ) {
                          return <MenuItem
                            key={index}
                            sx={{ whiteSpace: "initial" }}
                            value={option}
                          >
                            {option}
                          </MenuItem>;
                        } else {
                          return (
                            <MenuItem
                              key={index}
                              sx={{ whiteSpace: "initial" }}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2">Uso de factura</Typography>
                    <Select
                      required
                      value={usage}
                      size="small"
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleInvoiceUse}
                    >
                      {INVOICE_TYPES.map((option, index) => {
                        if (
                          fiscalRegime ===
                          "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios"
                        ) {
                          if (["CP01 - Pagos", "S01 - Sin efectos fiscales"].includes(option)) {
                            return (
                              <MenuItem
                                key={index}
                                sx={{ whiteSpace: "initial" }}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            );
                          }
                        } else if(fiscalRegime ===
                          "616 - Sin obligaciones fiscales") {
                            if (option === "S01 - Sin efectos fiscales" || option === "CP01 - Pagos") {
                              return (
                                <MenuItem
                                  key={index}
                                  sx={{ whiteSpace: "initial" }}
                                  value={option}
                                >
                                  {option}
                                </MenuItem>
                              );
                            }
                          } else {
                          return (
                            <MenuItem
                              key={index}
                              sx={{ whiteSpace: "initial" }}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </Stack>
                  <Stack>
                    {fetchedCIFUrl ? (
                      <Box>
                        <Typography variant="subtitle2">
                          Cédula de Identificación Fiscal (CIF) actual
                        </Typography>
                        <Box width={"100%"} margin={"10px 0"}>
                          <a
                            href={fetchedCIFUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {checkURLIsImage(fetchedCIFUrl) ? (
                              <img
                                src={fetchedCIFUrl}
                                alt="CIF"
                                style={{ width: "250px" }}
                              />
                            ) : (
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  color: "#000",
                                }}
                              >
                                <PictureAsPdfIcon />
                                Ver CIF
                              </Typography>
                            )}
                          </a>
                        </Box>
                      </Box>
                    ) : (
                      <Typography variant="subtitle2">
                        Cédula de Identificación Fiscal (CIF)
                      </Typography>
                    )}
                  </Stack>
                  <ImageForm
                    imgRef={imgRef}
                    inputImgRef={inputImgRef}
                    inputImage={inputImage}
                    setInputImage={setInputImage}
                    confirmButtonTitle={
                      fetchedCIFUrl ? "Actualizar CIF" : "Cargar CIF"
                    }
                  />
                  <LoadingButton
                    loading={isSaving}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Guardar Cambios
                  </LoadingButton>
                </Stack>
              </form>
            </Collapse>
          </>
        )
      ) : (
        <Stack spacing={1}>
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
          <Skeleton animation="wave" height={70} />
        </Stack>
      )}
    </Stack>
  );
};

export default Invoice;
