export default function getRequiredDocuments(type) {
  if (type === "carTheft") {
    return {
      facturaOriginal: {
        label: "Factura original",
        documentOptions: [
          "En su caso, copia de la factura original y original de segunda factura endosada",
          "En su caso, contrato de compraventa de dueños previos y copia de identificaciones",
        ],
      },
      facturaElectronica: {
        label: "Carta finiquito",
        description: "Carta finiquito válida ante el SAT.",
        documentOptions: [
          "Carta de consentimiento autorizando a Clupp realizar la expedición de la Carta finiquito de la transmisión de la propiedad en su nombre.",
        ],
      },
      registroVehicular: {
        label: "Registro vehicular",
        documentOptions: [
          "Tarjeta de circulación",
          "Tenencias desde el año de venta hasta la fecha",
          "Alta de vehículo a México (pedimento), en el caso de vehículos legalizados.",
        ],
      },
      verificacion: {
        label: "Verificación",
        description:
          "Verificación de emisiones no contaminantes del año corriente, si aplica.",
      },
      denuncia: {
        label: "Denuncia",
        description:
          "Denuncia ante el Ministerio Público por robo del vehículo y constancia REPUVE",
      },
      acreditacionDePropiedad: {
        label: "Acreditación de propiedad",
        description: "Documento que acredite la propiedad del vehículo",
      },
      bajaDePlacas: {
        label: "Baja de placas",
        description: "Documento original de baja de placas",
      },
      documentosPersonales: {
        label: "Documentos personales",
        documentOptions: [
          "Copia Identificación vigente por ambos lados.",
          "Constancia de Situación Fiscal ante el SAT.",
          "Estado de cuenta bancario con datos de cuenta.",
          "Copia de comprobante de domicilio vigente no mayor a tres meses.",
        ],
      },
      reporteDeRoboRepuve: {
        label: "Reporte de Robo REPUVE",
        description:
          "Descarga de consulta al REPUVE con el registro de robo del vehículo.",
      },
      contratoDeCompraVenta: {
        label: "Contrato de compra venta",
        description:
          "Contrato de compra venta entre el último dueño del vehículo previo al robo y Clupp.",
      },
    };
  }

  if (type === "cellphoneTheft") {
    return {
      denuncia: {
        label: "Denuncia",
        description: "Documento de denuncia ante el Ministerio Público",
        documentOptions: ["Denuncia de robo"],
      },
      comprobanteDeCompra: {
        label: "Comprobante de compra",
        description:
          "Comprobante de compra de tu dispositivo en el que se vea claramente la fecha, los datos del dispositivo y el monto de compra",
        documentOptions: [
          "Factura de compra",
          "Nota de compra",
          "Captura de pantalla de recibo de compra en línea",
        ],
      },
      denunciaRegulador: {
        label: "Denuncia IFT",
        description: (
          <span>
            Captura de pantalla de la página del{" "}
            <a
              href="https://www.ift.org.mx/usuarios-y-audiencias/consulta-de-imei"
              target="_blank"
            >
              Instituto Federal de Telecomunicaciones (IFT)
            </a>{" "}
            en la que el IMEI sea identificado como robado.
          </span>
        ),
        documentOptions: ["Reporte de robo ante el IFT"],
      },
      documentosPersonales: {
        label: "Documentos personales",
        description:
          "Identificación vigente por ambos lados.\nEstado de cuenta bancario con datos de la cuenta en la que se realizará el depósito. \nComprobante de domicilio vigente no mayor a tres meses.",
        documentOptions: [
          "Identificación, cuenta bancaria y comprobante de domicilio.",
        ],
      },
    };
  }

  if (["screen-breakage", "breakdown", "accidental-damage"].includes(type)) {
    return {
      fotos: {
        label: "Fotos",
        description:
          "Fotografías de todos los perfiles de tu dispositivo.\nSi el daño se aprecia mejor con la pantalla encendida también carga las fotografías.",
        documentOptions: ["Fotos de los daños"],
      },
      comprobanteDeCompra: {
        label: "Comprobante de compra",
        description:
          "Comprobante de compra de tu dispositivo en el que se vea claramente la fecha, los datos del dispositivo y el monto de compra.",
        documentOptions: [
          "Factura de compra",
          "Nota de compra",
          "Pantallazo de compra en línea",
        ],
      },
      facturaDeReparacion: {
        label: "Factura de reparación",
        description:
          "Factura fiscal (PDF y XML) de reparación de los daños de tu dispositivo. Esta debe ser emitida a:\n\nNombre: Clupp Mutualidad\nRFC: CMU201120EEA\nRégimen fiscal: Persona Física con Actividad Empresarial\nDirección: Dragón 21, Prado Churubusco, Coyoacán, CDMX. \nC.P. 04230\nUso de CFDI: Gastos en general\n\nTe recordamos que el monto de la factura no deberá exceder el valor comercial de tu dispositivo y su fecha de emisión no deberá tener más de 30 días.",
        documentOptions: ["Factura fiscal en PDF y XML"],
      },
      documentosPersonales: {
        label: "Documentos personales",
        description:
          "Identificación vigente por ambos lados.\nEstado de cuenta bancario con datos de la cuenta en la que se realizará el depósito. \nComprobante de domicilio vigente no mayor a tres meses.",
        documentOptions: [
          "Identificación, cuenta bancaria y comprobante de domicilio.",
        ],
      },
    };
  }

  return {};
}
