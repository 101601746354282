async function getVehicleQuotation(uid, vid) {
  try {
    const response = await fetch(
      `https://clupp-api.web.app/v1/clupp/users/${uid}/vehicles/${vid}/quote-mobility-insurance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJSQVNUUkVBVE9SIn0.OGp_G6Lref09-YkoRsY3vBUEndMpAbhUR-tHHu-nlm4",
        }
      }
    );

    if (!response.ok) {
      console.log("Fetch error:", response.statusText);
    }

    const responseData = await response.json();
    console.log("Fetch response quote Vehicle:", responseData);
    return responseData;
  } catch (error) {
    console.error("Fetch error quote Vehicle:", error);
  }
}

export default getVehicleQuotation;
