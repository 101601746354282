import React, { useRef, useState, createRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import Dialog from "../../Components/Dialog";
import cluppLogo from "../../Assets/logo-clupp.png";
import useQueryParams from "../../hooks/useQueryParams";
import format from "date-fns/format";
import es from "date-fns/locale/es";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}

function uploadImage({ userUID, claimID, body, imageUrl, setIsLoading = () => {} }) {
  const blob = dataURItoBlob(imageUrl);

    const storageRef = ref(
      storage,
      `users/${userUID}/signatures/${new Date().getTime()}`
    );
    const uploadTask = uploadBytesResumable(storageRef, blob);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.error(error);
      },
      async () => {
        try {
          const imgURL = await getDownloadURL(uploadTask.snapshot.ref);
          const response = await fetch(
            `https://clupp-api.web.app/v1/clupp/users/${userUID}/claims/${claimID}/generate-payment-receipt`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ...body,
                signatureUrl: imgURL,
              }),
            }
          );
          const pdfUrl = await response.json();
          if(pdfUrl.success === true) {
            if(pdfUrl?.documentURL) {
              window.open(pdfUrl?.documentURL, "_blank")
            } else if (pdfUrl?.url) {
              window.open(pdfUrl?.url, "_blank")
            }
            Swal.fire({
              icon:"success",
              text: "Se ha generado el recibo exitosamente. Si no se ha descargado el recibo, por favor deshabilita el bloqueador de ventanas emergentes y vuelve a hacer clic en Enviar información.",
              confirmButtonText: "Ok",
              confirmButtonColor: "#00bbee"
            })
            setIsLoading(false)
          } else {
            Swal.fire({
              icon:"error",
              text: "Ha ocurrido un error, por favor intentalo más tarde",
              confirmButtonText: "Ok",
              confirmButtonColor: "#00bbee"
            })
            console.error(pdfUrl);
          }
        } catch (e) {
          console.error(e);
        }
      }
    );
}
export default function SignPdf() {
  const [imageURL, setImageURL] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sigCanvas = useRef();
  const query = useQueryParams();
  const claimId = query.get("claimId");
  const userUID = query.get("userId");
  const name = query.get("name");
  const rfc = query.get("rfc");
  const amount = query.get("amount");
  const transactionId = query.get("transactionId");
  const accidentTimestamp = query.get("accidentTimestamp");
  const movementId = query.get("movementId");

  const screenRef = createRef(null);

  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/jpg");
    setImageURL(URL);
  };

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleUploadImage() {
    setIsLoading(true)
    if (!imageURL) {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa tu firma al dar clic sobre el documento",
        confirmButtonText: "Ok",
        confirmButtonColor: "#00bbee",
      });
      setIsLoading(false)
      return;
    }
    const body = {
      ...((transactionId && transactionId !== "undefined") && { transactionId: transactionId }),
      movementId: movementId,
      claimId: claimId,
      currentDate: format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: es }),
      accidentDate:
        Number(accidentTimestamp) &&
        format(
          new Date(Number(accidentTimestamp)),
          "dd 'de' MMMM 'del' yyyy 'aproximadamente a las' HH:mm 'horas'",
          { locale: es }
        ),
      name: name,
      amount: Number(amount).toLocaleString("en-US"),
      rfc: rfc,
    };
    uploadImage({
      userUID: userUID,
      claimID: claimId,
      body: body,
      imageUrl: imageURL,
      setIsLoading: setIsLoading
    });
  }

  return (
    <div>
      <main style={{ padding: "10px" }} ref={screenRef}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img style={{ width: "100px" }} src={cluppLogo} alt="logo clupp" />
          <Box>
            <Typography>Recibo de cobro de deducible</Typography>
            <Typography textAlign={"right"}>Reporte {claimId}</Typography>
          </Box>
        </Box>
        <hr />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "18px" }}>
          <Typography sx={{ textAlign: "right", width: "100%" }}>
            A {format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: es })},
            Ciudad de México
          </Typography>
          <Typography>A quién corresponda,</Typography>
          <Typography>
            Por medio de la presente, se hace constar que Yo {name} con RFC{" "}
            {rfc}, realicé voluntariamente vía liga de stripe el pago con mi
            tarjeta de crédito/débito{" "} la cantidad de MX $
            {Number(amount).toLocaleString("en-US")} a CLUPP MUTUALIDAD S.C. DE
            R.L. DE C.V. con RFC CMU201120EEA, con el motivo del pago de
            deducible derivado de un accidente automovilístico con número de
            reporte {claimId} ocurrido el{" "}
            {Number(accidentTimestamp) &&
              format(
                new Date(Number(accidentTimestamp)),
                "dd 'de' MMMM 'del' yyyy 'aproximadamente a las' HH:mm 'horas'",
                { locale: es }
              )}
            .{" "}
          </Typography>
          <Typography>Atentamente:</Typography>
        </Box>
        <Box
          sx={{
            marginBottom: "14px",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
          }}
        >
          {imageURL ? (
            <Box
              onClick={() => {
                setOpenModal((prev) => !prev);
              }}
            >
              <img src={imageURL} alt="signature" className="signature" />
            </Box>
          ) : (
            <Box
              onClick={() => {
                setOpenModal((prev) => !prev);
              }}
              sx={{
                height: "200px",
                width: "320px",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                Da clic aquí para firmar
              </Typography>
            </Box>
          )}
          <Typography>___________________________</Typography>
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              marginTop: "6px",
            }}
          >
            <Typography>{name}</Typography>
          </Box>
        </Box>
      </main>
      <Box sx={{ padding: "10px" }}>
        <LoadingButton
          loading={isLoading}
          size="small"
          variant="contained"
          sx={{ width: "100%", textTransform: "none" }}
          onClick={handleUploadImage}
        >
          Enviar información
        </LoadingButton>
      </Box>

      <Dialog
        isOpen={openModal}
        onClose={handleCloseModal}
        sx={{
          margin: "0",
          width: "auto",
          "& .MuiDialogContent-root": {
            padding: "10px",
            margin: "0 auto",
          },
          "& .MuiPaper-root": {
            margin: "20px 10px",
            width: "100%",
          },
        }}
        content={
          <Box sx={{ display: "grid", gap: "10px" }}>
            <Box
              sx={{
                border: "1px solid black",
                width: "320px",
                height: "200px",
              }}
            >
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{ width: "320px", height: "200px" }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                gap: "14px",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                sx={{ color: "#000", border: "1px solid black" }}
                onClick={() => sigCanvas.current.clear()}
              >
                Borrar
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{ background: "#000", border: "1px solid black" }}
                onClick={() => {
                  create();
                  handleCloseModal();
                }}
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        }
      />
    </div>
  );
}
