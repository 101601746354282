async function addDocument(path, documentData) {
  const url = new URL(
    "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/addDoc"
  );

  url.searchParams.append("collectionPath", path);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(documentData),
    });
    const document = await response.json();
    return document;
  } catch (error) {
    console.log(error);
    throw new Error("not/found");
  }
}

export default addDocument;
