import { Box, Button, Stack, Typography, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import {
  doc,
  collection,
  getDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase";
import useQueryParams from "../../hooks/useQueryParams";
import CustomizedTimeline from "./CustomTimeline";
import ExpedienteDigitalForm from "./ExpedienteDigitalForm";
import AddIcon from "@mui/icons-material/Add";
import FilesDialog from "./FilesDialog";
import Header from "../../Components/Header";

async function getVehicleDoc(userId, vehicleId) {
  const ref = doc(db, `users/${userId}/vehicles`, vehicleId);
  const docSnap = await getDoc(ref);

  return docSnap;
}

export default function ExpedienteDigital() {
  const queryParam = useQueryParams();
  const userId = queryParam.get("uid");
  const vehicleId = queryParam.get("vid");
  const [vehicleData, setVehicleData] = useState({});
  const [isOpenFormModal, setIsOpenFormModal] = useState();
  const [isOpenFilesDialog, setIsOpenFilesDialog] = useState(false);
  const [events, setEvents] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [lastElement, setLastElement] = useState(0);
  const [isMorePagination, setIsMorePagination] = useState(true);
  const startDateInsurance = new Date(
    vehicleData?.insuranceStartYear,
    vehicleData?.insuranceStartMonth - 1,
    vehicleData?.insuranceStartDay
  );
  const paginationEvent = 3;

  useEffect(() => {
    setIsFetchingData(true);
    getVehicleDoc(userId, vehicleId).then((data) => {
      setVehicleData(data.data());
      setIsFetchingData(false);
    });

    const eventRef = collection(
      db,
      `users/${userId}/vehicles/${vehicleId}/events`
    );
    const q = query(
      eventRef,
      where("vid", "==", vehicleId),
      where("deleted", "==", false),
      orderBy("timestamp", "desc"),
      limit(paginationEvent)
    );

    const unsubEvents = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => docs.push({ ...doc.data(), id: doc.id }));
      setEvents([...docs]);
      setLastElement(querySnapshot.docs[querySnapshot.docs.length - 1]);
      if (querySnapshot.size < paginationEvent) {
        setIsMorePagination(false);
      } else {
        setIsMorePagination(true);
      }
    });

    return () => {
      unsubEvents();
    };
  }, [userId, vehicleId]);

  useEffect(() => {
    selectedEvent &&
      setSelectedEvent(
        events.find((item) => {
          return selectedEvent.id === item.id;
        })
      );
  }, [events]);

  function handleClose() {
    setIsOpenFormModal(false);
  }

  function handleSelectEvent(event) {
    setSelectedEvent(event);
    setIsOpenFilesDialog(true);
  }

  function handleOnCloseFilesDialog() {
    setIsOpenFilesDialog(false);
  }

  function fetchMoreDataPagination() {
    const nextQuery = query(
      collection(db, `users/${userId}/vehicles/${vehicleId}/events`),
      orderBy("timestamp", "desc"),
      startAfter(lastElement),
      limit(paginationEvent)
    );

    onSnapshot(nextQuery, (querySnapshot) => {
      const docs = [];
      setLastElement(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      let nextEvents = events.concat(docs);
      setEvents(nextEvents);
      if (querySnapshot.size < paginationEvent) {
        setIsMorePagination(false);
      } else {
        setIsMorePagination(true);
      }
    });
  }

  return (
    <>
      <Header sx={{ height: "80px" }} />
      <main style={{ maxWidth: "800px", margin: "0 auto", width: "100%" }}>
        <Box sx={{ padding: "12px", display: "grid", gap: "8px" }}>
          <ExpedienteDigitalForm
            handleClose={handleClose}
            isOpen={isOpenFormModal}
            userId={userId}
            vehicleId={vehicleId}
            vehicleData={vehicleData}
          />
          <FilesDialog
            files={selectedEvent?.files || []}
            onClose={handleOnCloseFilesDialog}
            isOpen={isOpenFilesDialog}
            userId={userId}
            vehicleId={vehicleId}
            event={selectedEvent}
          />
          <Stack>
            <Typography variant="h5" fontWeight="bold" textAlign="center">
              Expediente Digital
            </Typography>
          </Stack>
          {isFetchingData ? (
            <>
              <Skeleton variant="rectangular" width={"100%"} height={220} />
              <Typography component="div" variant="h6">
                <Skeleton />
              </Typography>
            </>
          ) : (
            <Stack>
              <Box sx={{width: "100%", display: "grid", placeItems: "center"}}>
                <img
                  style={{
                    width: ["moto","scooter","bike", "eBike"].includes(vehicleData.type) ? "330px" : "100%",
                    height: ["moto","scooter","bike", "eBike"].includes(vehicleData.type) ? "350px" : "",
                    borderRadius: "4px",
                    objectFit: "cover",
                    transform: ["moto","scooter","bike", "eBike"].includes(vehicleData.type) ? "rotate(90deg)" : ""
                  }}
                  src={vehicleData?.pictureCarFront}
                  alt="Foto frontal del vehiculo"
                />
              </Box>
              <Box>
                <Typography variant="h6" textAlign="center">
                  {vehicleData?.name}
                </Typography>
              </Box>
            </Stack>
          )}
          <Stack>
            <Button
              endIcon={<AddIcon />}
              sx={{ textTransform: "none" }}
              variant="outlined"
              onClick={() => setIsOpenFormModal((prev) => !prev)}
            >
              Agregar nuevo registro
            </Button>
            {events.length <= 0 && (
              <Box sx={{ margin: "10px 0 " }}>
                <Typography sx={{ textAlign: "center" }}>
                  Te damos la bienvenida al expediente digital de tu{" "}
                  {vehicleData?.name}. Agrega registros que te ayuden a tener un
                  mejor control, como cambio de llantas, servicios, visitas al
                  taller, etc.
                </Typography>
              </Box>
            )}
            <CustomizedTimeline
              events={events}
              setSelectedEvent={handleSelectEvent}
              fetchMoreDataPagination={fetchMoreDataPagination}
              isMorePagination={isMorePagination}
              userId={userId}
              startDateInsurance={startDateInsurance}
            />
          </Stack>
        </Box>
      </main>
    </>
  );
}
