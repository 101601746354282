import React, { useReducer, useState, useEffect, useRef } from "react";
import UserContex from "./UserContex";
import UserReducer from "./UserReducer";
import jsSHA from "jssha";
import { useSearchParams } from "react-router-dom";
import { configPrueba } from "../Services/Avi";
import { getDocuments } from "../Services/firestore";
import { getAddressInfoFromCoords } from "../utils/getAddressInfoFromCoords";

const UserState = (props) => {
  const [searchParams] = useSearchParams();
  const userUID = searchParams.get("uid");
  const urlPrueba = configPrueba();
  const initialState = {
    iduser: null,
    users: null,
    userLocation: null,
    vehicles: null,
    selectVehicle: null,
    claims: [],
    idSelect: null,
    smartphones: [],
  };
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const [report, setReport] = useState({
    id: userUID,
    type: "vialAssistance",
    isActive: true,
    source: "app",
    vehicle: null,
    destinationLocation: null,
  });
  const getUsers = async () => {
    const URI = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${userUID}`;
    const response = await fetch(URI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const user = await response.json();
    const dataUser = user.doc;
    const docIdUser = user.id;
    dispatch({
      type: "GET_USERS",
      payload: dataUser,
    });
    dispatch({
      type: "GET_IDUSER",
      payload: docIdUser,
    });
  };
  /**Listado de Vehicles **/
  const getVehicles = async () => {
    const URI = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/collection?collectionPath=users/${userUID}/vehicles`;
    const response = await fetch(URI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const vehicle = await response.json();
    const data = vehicle.docs;
    const docId = vehicle.id;
    const docs = [];
    data.forEach((doc) => docs.push({ id: doc.id, ...doc.doc }));
    dispatch({
      type: "GET_VEHICLES",
      payload: docs,
    });
  };

  /*******Listado de Smartphones *******/
  const getSmartphones = async () => {
    const response = await getDocuments({
      collectionPath: `users/${userUID}/smartphones`,
    });
    const smartphones = await response.json();
    const data = smartphones.docs;
    let docs = [];
    data.forEach((doc) => {
      const data = doc.doc;
      if (data.isInsured && !data.deleted) {
        docs.push({ id: doc.id, type: data.type || "smartphone", ...data });
      }
    });
    dispatch({
      type: "GET_SMARTPHONES",
      payload: docs,
    });
  };

  /**Listado de Vehicles **/
  const getClaims = async () => {
    const URIClaims = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/collection?collectionPath=users/${userUID}/claims`;
    const responseClaims = await fetch(URIClaims, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const claimsdata = await responseClaims.json();
    const data = claimsdata.docs;
    const docs = [];
    data.forEach((doc) => docs.push({ id: doc.id, ...doc.doc }));
    dispatch({
      type: "GET_CLAIMS",
      payload: docs,
    });
  };
  /**Seleccion de vehicle */
  const clearSelect = () => {
    const va = null;
    dispatch({
      type: "GET_SELECTVEHICLES",
      payload: null,
    });
  };
  const getUserLocation = async (lat, long) => {
    const { postalCode, address } = await getAddressInfoFromCoords({
      lon: long,
      lat,
    });
    dispatch({
      type: "GET_USER_LOCATION",
      payload: {
        lat: lat,
        lon: long,
        postalCode,
        address,
      },
    });
  };
  const getSelecction = async () => {
    const id = report.id;
    const idv = report.vehicle;
    const URIV = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${id}/vehicles/${idv}`;
    const responsev = await fetch(URIV, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dataV = await responsev.json();
    const dataSelect = dataV.doc;
    const idSelect = dataV.id;
    dispatch({
      type: "GET_SELECTVEHICLES",
      payload: dataSelect,
    });
    dispatch({
      type: "GET_IDSELECT",
      payload: idSelect,
    });
  };
  const [loginData, setLoginData] = useState();
  const [uidAsegurado, setUidAsegurado] = useState();
  const [nameAsegurado, setNameAsegurado] = useState();
  const [lastAsegurado, setLastAsegurado] = useState();
  const [phoneAsegurado, setPhoneAsegurado] = useState();
  const [emailAsegurado, setEmailAsegurado] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("");
  const loginParameters = {
    name: nameAsegurado,
    lastName: lastAsegurado,
    telephone: phoneAsegurado,
    email: emailAsegurado,
  };
  const appkey = "63912e9d65763d034421_1ceb7be12205466a355b";
  const secretkey = "0a1a85321a071f2d55d9_b08eb1cd8a50384fbce3";
  let requestId = new Date().getTime();
  let sha256 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
  sha256.update(JSON.stringify(loginParameters));
  let contentDigest = sha256.getHash("B64");
  let hmac = new jsSHA("SHA-256", "TEXT", {
    hmacKey: { value: secretkey, format: "TEXT" },
  });
  hmac.update(contentDigest + requestId + appkey);
  let signature = hmac.getHash("B64");
  const [headers, setHeaders] = useState({
    "x-avi-request-id": requestId,
    "x-avi-content-digest": contentDigest,
    "x-avi-signature": signature,
    "x-avi-app-key": appkey,
    "x-avi-version": "2021-04-30",
    "x-avi-token": null,
  });
  const loginAvi = () => {
    const url = urlPrueba
      ? "https://zazu.aviapp.mx/sandbox/login"
      : "https://zazu.aviapp.mx/login";
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(loginParameters),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoginData(data);
        getBrands(data.token);
        setHeaders({ ...headers, "x-avi-token": data.token });
      });
  };
  const [brandsAVI, setBrandsAVI] = useState();
  const [modelsAVI, setModelsAVI] = useState();
  const [brandData, setBrandData] = useState(undefined);
  // get AVI brands
  const getBrands = (token) => {
    const url = urlPrueba
      ? "https://zazu.aviapp.mx/sandbox/vehicleBrand/"
      : "https://zazu.aviapp.mx/vehicleBrand/";
    fetch(url, {
      method: "GET",
      headers: { ...headers, "x-avi-token": token },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setBrandsAVI(data);
      })
      .catch((err) => console.log(err));
  };
  const [nameBrandVehicle, setNameBrandVehicle] = useState(true);
  const [modelData, setModelData] = useState(undefined);
  useEffect(() => {
    if (state.selectVehicle && brandsAVI) {
      const vehicleData = state.selectVehicle;
      let brandInfo = "";
      if (vehicleData.type === "auto") {
        if (vehicleData.brand === "KIA") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1092);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Chevrolet ") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1035);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Cadillac") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1028);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "HONDA") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1076);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Ducati") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3152);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Buick") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1025);
          setBrandData(brandInfo);
        } else if (
          vehicleData.brand === "Mercedes Benz" ||
          vehicleData.brand === "Mercedes Benz "
        ) {
          brandInfo = brandsAVI.find((brand) => brand.id === 1116);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Mini") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1142);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Suzuki") {
          brandInfo = brandsAVI.find((brand) => brand.id === 47);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Porsche") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1139);
          setBrandData(brandInfo);
        } else if (
          vehicleData.brand === "Renault" ||
          vehicleData.brand === "RENAULT"
        ) {
          brandInfo = brandsAVI.find((brand) => brand.id === 1315);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Mercury") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1117);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Mini Cooper") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1122);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Honda") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1076);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Infiniti") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1080);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "General Motors") {
          brandInfo = brandsAVI.find((brand) => brand.id === 4822);
          setBrandData(brandInfo);
        } else if (
          vehicleData.brand === "Otro" ||
          vehicleData.brand === "Endeavor" ||
          vehicleData.brand === "Veloci"
        ) {
          brandInfo = brandsAVI.find((brand) => brand.id === 4822);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Tsuru") {
          brandInfo = brandsAVI.find((brand) => brand.id === 4822);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Ford ") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1063);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Saturn") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1155);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Ducati") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3152);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Hummer") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1078);
          setBrandData(brandInfo);
        } else if (
          vehicleData.brand === "BAIC" ||
          vehicleData.brand === "Baic"
        ) {
          brandInfo = brandsAVI.find((brand) => brand.id === 5147);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Mitsubishi") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1123);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "JAC") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1087);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Acura") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1002);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Smart") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1160);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Faw" || vehicleData.brand === "FAW") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1059);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "MG Motors") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1119);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Volkswagen") {
          brandInfo = brandsAVI.find((brand) => brand.id === 1184);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Ram") {
          brandInfo = brandsAVI.find((brand) => brand.id === 5142);
          setBrandData(brandInfo);
        } else {
          if (nameBrandVehicle == true) {
            brandInfo = brandsAVI.find((brand) => brand.id === 4822);
            setBrandData(brandInfo);
          } else {
            brandInfo = brandsAVI.find((brand) => brand.id === 4822);
            setBrandData(brandInfo);
          }
        }
      } else if (vehicleData.type === "moto") {
        if (vehicleData.brand === "Aprilia") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3126);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Arctic Cat") {
          brandInfo = brandsAVI.find((brand) => brand.id === 4835);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Veloci") {
          brandInfo = brandsAVI.find((brand) => brand.id === 582);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Bajaj") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3166);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Benelli") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3186);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Bimota") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3211);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "BMW") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3236);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Buell") {
          brandInfo = brandsAVI.find((brand) => brand.id === 8231);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Cagiva") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3291);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "CF Moto") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3311);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Ducati") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3152);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Honda") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3317);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Husaberg") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3332);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Husqvarna") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3337);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Hyundai") {
          brandInfo = brandsAVI.find((brand) => brand.id === 21);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Indian") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3347);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Kawasaki") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3153);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "KTM") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3188);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Kymco") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3198);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Moto Guzzi") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3298);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "MV Agusta") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3348);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Peugeot") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3154);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Piaggio") {
          brandInfo = brandsAVI.find((brand) => brand.id === 5021);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Polaris") {
          brandInfo = brandsAVI.find((brand) => brand.id === 5024);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Suzuki") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3349);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Triumph") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3130);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "TVS") {
          brandInfo = brandsAVI.find((brand) => brand.id === 583);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Vento") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3155);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Vespa") {
          brandInfo = brandsAVI.find((brand) => brand.id === 5091);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Victory") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3170);
          setBrandData(brandInfo);
        } else if (vehicleData.brand === "Yamaha") {
          brandInfo = brandsAVI.find((brand) => brand.id === 3245);
          setBrandData(brandInfo);
        } else {
          if (nameBrandVehicle == true) {
            brandInfo = brandsAVI.find((brand) => brand.id === 582);
            setBrandData(brandInfo);
          } else {
            brandInfo = brandsAVI.find((brand) => brand.id === 582);
            setBrandData(brandInfo);
          }
        }
      } else {
        if (nameBrandVehicle == true) {
          brandInfo = brandsAVI.find((brand) => brand.id === 582);
          setBrandData(brandInfo);
        } else {
          brandInfo = brandsAVI.find((brand) => brand.id === 3245);
          setBrandData(brandInfo);
        }
      }
      const urlBrand = urlPrueba
        ? `https://zazu.aviapp.mx/sandbox/vehicleModel/?vehicleBrandId=${brandInfo.id}`
        : `https://zazu.aviapp.mx/vehicleModel/?vehicleBrandId=${brandInfo.id}`;
      fetch(urlBrand, {
        method: "GET",
        headers: { ...headers, "x-avi-token": loginData.token },
      })
        .then((response) => response.json())
        .then((data) => {
          setModelsAVI(data);
          const modelData = data.find((d) =>
            d.name.toUpperCase().includes(vehicleData.model.toUpperCase())
          );
          if (!modelData) setModelData(data[0]);
          else setModelData(modelData);
        })
        .catch((err) => console.log(err));
    }
  }, [state.selectVehicle, brandsAVI]);
  const inputEl = useRef();
  const onButtonClick = () => {
    inputEl.current.focus();
  };
  return (
    <UserContex.Provider
      value={{
        iduser: state.iduser,
        users: state.users,
        vehicles: state.vehicles,
        smartphones: state.smartphones,
        gadgets: state.smartphones,
        selectV: state.selectVehicle,
        claims: state.claims,
        idselect: state.idSelect,
        userLocation: state.userLocation,
        inputEl,
        clearSelect,
        getUserLocation,
        onButtonClick,
        getUsers,
        getSmartphones,
        getVehicles,
        report,
        setReport,
        loginAvi,
        loginData,
        modelData,
        headers,
        uidAsegurado,
        setUidAsegurado,
        nameAsegurado,
        setNameAsegurado,
        lastAsegurado,
        setLastAsegurado,
        setPhoneAsegurado,
        emailAsegurado,
        setEmailAsegurado,
        getSelecction,
        getClaims,
        setSelectedReportType,
        selectedReportType,
      }}
    >
      {props.children}
    </UserContex.Provider>
  );
};
export default UserState;
