
function getInsuredVehicles(vehicles) {
  const insuredVehicles = vehicles.filter((vehicle) => {
    return (
      vehicle.isInsured &&
      !vehicle.deleted
    );
  });
  return insuredVehicles;
}





export { getInsuredVehicles };
