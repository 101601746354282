import getContactPhone from "./getContactPhone";

const whatUrl = (nameAsegurado, lastAsegurado, uidAsegurado, vehiculo) => {
  //console.log(nameAsegurado);
  const url =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola%20mi%20nombre%20es%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    "%20con%20uid%20" +
    uidAsegurado +
    "%20y%20vehículo%20" +
    vehiculo +
    "%20solicito%20servicio%20de%20asistencía%20víal.";
  return url;
};
export default whatUrl;
