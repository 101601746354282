import { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Typography,
  TextField,
  useTheme,
  Alert,
} from "@mui/material";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
  getDoc,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { LoadingButton } from "@mui/lab";
import { db } from "../../firebase";
import Swal from "sweetalert2";
import cluppLogo from "../../Assets/logo-clupp.png";
import LoadingSkeleton from "./LoadingSkeleton";
import { logError } from "../../utils/index";
import PhotoCard from "./PhotoCard";

function getVehicleData(vehicleUID, userUID) {
  const vehicleDocRef = doc(db, `users/${userUID}/vehicles/${vehicleUID}/`);
  return getDoc(vehicleDocRef);
}

async function getOdometerPhoto(vehicleUID, userUID) {
  const vehicleDocRef = await collection(
    db,
    `users/${userUID}/vehicles/${vehicleUID}/odometer`
  );
  const q = query(
    vehicleDocRef,
    where("deleted", "==", false),
    orderBy("timestamp", "desc"),
    limit(1)
  );

  const querySnapshot = getDocs(q);

  return querySnapshot;
}

export default function AddFirstPhotos() {
  const theme = useTheme();
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const userUID = params.get("userUID");
  const vehicleUID = params.get("vehicleId");
  const agentUID = params.get("agentUID");
  const [agentDoc, setAgentDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [vehicleDoc, setVehicleDoc] = useState(null);
  const [userData, setUserData] = useState(null);
  const [updatedVehicleData, setUpdatedVehicleData] = useState({
    color: "",
    version: "",
    plates: "",
    serialNumber: "",
    motorNumber: "",
  });
  const [odometerDoc, setOdometerDoc] = useState();
  const [imageData, setimageData] = useState({
    pictureCarFront: "",
    pictureCarBack: "",
    pictureCarLeft: "",
    pictureCarRight: "",
    pictureSerialNumberURL: "",
    odometer: {
      pictureURL: "",
      kmTotal: "",
    },
  });

  useEffect(() => {
    async function getAgentData() {
      const agentDocRef = doc(db, `agentProspects/${agentUID}`);
      const docSnap = await getDoc(agentDocRef);

      if (docSnap.exists()) {
        setAgentDoc(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }

    async function getUser() {
      const userDocRef = doc(db, `users/${userUID}`);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    getUser();
    getAgentData();
    getOdometerPhoto(vehicleUID, userUID).then((doc) => {
      const docs = doc.docs.map((doc) => doc.data());
      setOdometerDoc(docs);
    });
    getVehicleData(vehicleUID, userUID).then((doc) => {
      if (doc.exists()) {
        setVehicleDoc(doc.data());
        setUpdatedVehicleData((prev) => ({
          ...prev,
          color: doc.data()?.color,
          version: doc.data()?.version,
          plates: doc.data()?.plates,
          serialNumber: doc.data()?.serialNumber,
          motorNumber: doc.data()?.motorNumber,
        }));
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
      setFetchingUserData(false);
    });
  }, [agentUID]);

  function onChange(e) {
    setUpdatedVehicleData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  async function addUploadImageComment({
    pictureCarFront,
    pictureCarBack,
    pictureCarLeft,
    pictureCarRight,
    pictureSerialNumberURL,
    odometer,
    color,
    version,
    plates,
    serialNumber,
    motorNumber,
  }) {
    const photoDescriptions = [];
    if (pictureCarBack) photoDescriptions.push("trasera");
    if (pictureCarFront) photoDescriptions.push("frontal");
    if (pictureCarLeft) photoDescriptions.push("izquierda");
    if (pictureCarRight) photoDescriptions.push("derecha");
    if (pictureSerialNumberURL) photoDescriptions.push("número de serie");
    if (odometer) photoDescriptions.push("odómetro");

    const updatedFeatures = [];
    if (color) updatedFeatures.push(`Color: ${color}`);
    if (version) updatedFeatures.push(`Versión: ${version}`);
    if (plates) updatedFeatures.push(`Placas: ${plates}`);
    if (serialNumber) updatedFeatures.push(`Número de serie: ${serialNumber}`);
    if (motorNumber) updatedFeatures.push(`Número de motor: ${motorNumber}`);

    const commentText = `[Mensaje automático] El agente ${
      agentDoc?.email ? agentDoc.email : ""
    } con uid ${agentUID} ha subido las siguientes fotos del vehículo, con id ${vehicleUID}, al sistema: ${photoDescriptions.join(
      ", "
    )}. Se han actualizado las siguientes características: ${updatedFeatures.join(
      ", "
    )}`;

    await addDoc(collection(db, `users/${userUID}/comments`), {
      comment: commentText,
      timestamp: new Date().getTime(),
    });
  }

  async function addPictureUpdatesDoc(pictureType, pictureURL) {
    const vehiclePicturesDocRef = collection(
      db,
      `users/${userUID}/vehicles/${vehicleUID}/pictureUpdates/`
    );

    await addDoc(vehiclePicturesDocRef, {
      pictureType: pictureType,
      pictureURL: pictureURL,
      timestamp: Date.now(),
      timestampServer: serverTimestamp(),
      updatedBy: agentUID,
    });
  }

  async function uploadImages() {
    setIsLoading(true);

    const vehicleDocRef = doc(db, `users/${userUID}/vehicles/${vehicleUID}/`);
    const odometerDocRef = collection(
      db,
      `users/${userUID}/vehicles/${vehicleUID}/odometer`
    );

    try {
      (vehicleDoc?.type === "auto" || vehicleDoc?.type === "moto") &&
        typeof imageData?.odometer.pictureURL === "string" &&
        (await addDoc(odometerDocRef, {
          deleted: false,
          kmTotal: Number(imageData?.odometer.kmTotal),
          pictureURL: imageData?.odometer.pictureURL,
          kmTotalOriginal: 0,
          review: false,
          timestamp: Date.now(),
        }));
      typeof imageData?.pictureCarFront === "string" &&
        (await addPictureUpdatesDoc(
          "pictureCarFront",
          imageData?.pictureCarFront
        ));
      typeof imageData?.pictureCarBack === "string" &&
        (await addPictureUpdatesDoc(
          "pictureCarBack",
          imageData?.pictureCarBack
        ));
      typeof imageData?.pictureCarLeft === "string" &&
        (await addPictureUpdatesDoc(
          "pictureCarLeft",
          imageData?.pictureCarLeft
        ));
      typeof imageData?.pictureCarRight === "string" &&
        (await addPictureUpdatesDoc(
          "pictureCarRight",
          imageData?.pictureCarRight
        ));
      typeof imageData?.pictureSerialNumberURL === "string" &&
        (await addPictureUpdatesDoc(
          "pictureSerialNumberURL",
          imageData?.pictureSerialNumberURL
        ));

      await updateDoc(vehicleDocRef, {
        ...(typeof imageData?.pictureCarFront === "string" && {
          pictureCarFront: imageData?.pictureCarFront,
        }),
        ...(typeof imageData?.pictureCarBack === "string" && {
          pictureCarBack: imageData?.pictureCarBack,
        }),
        ...(typeof imageData?.pictureCarLeft === "string" && {
          pictureCarLeft: imageData?.pictureCarLeft,
        }),
        ...(typeof imageData?.pictureCarRight === "string" && {
          pictureCarRight: imageData?.pictureCarRight,
        }),
        ...(typeof imageData?.pictureSerialNumberURL === "string" && {
          pictureSerialNumberURL: imageData?.pictureSerialNumberURL,
        }),
        status: 2,
        updatedBy: agentUID,
      });
      Swal.fire({
        icon: "success",
        text: "Fotos subidas correctamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      }).then(() => {
        window.location.reload();
      });
      setIsLoading(false);
    } catch (e) {
      console.error("Error adding document: ", e);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error al subir las fotos",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      logError({
        source: "Agregar datos de vehiculo agentes",
        error: e.message,
        metadata: {
          description: "Error al subir las fotos del vehiculo",
          userUID: userUID || "No user",
          vehicleUID: vehicleUID || "No vehicle",
          images: {
            ...(typeof imageData?.pictureCarFront === "string" && {
              pictureCarFront: imageData?.pictureCarFront,
            }),
            ...(typeof imageData?.pictureCarBack === "string" && {
              pictureCarBack: imageData?.pictureCarBack,
            }),
            ...(typeof imageData?.pictureCarLeft === "string" && {
              pictureCarLeft: imageData?.pictureCarLeft,
            }),
            ...(typeof imageData?.pictureCarRight === "string" && {
              pictureCarRight: imageData?.pictureCarRight,
            }),
            ...(typeof imageData?.odometer.pictureURL === "string" && {
              odometer: {
                pictureURL: imageData?.odometer.pictureURL,
                kmTotal: imageData?.odometer.kmTotal,
              },
            }),
          },
        },
      });
      setIsLoading(false);
    }
  }

  console.log(imageData.odometer.kmTotal);

  async function updateVehicleData(e) {
    e.preventDefault();
    if (imageData.pictureCarFront === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor sube la foto frontal del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (imageData.pictureCarBack === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor sube la foto trasera del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (imageData.pictureCarLeft === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor sube la foto izquierda del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (imageData.pictureCarRight === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor sube la foto derecha del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (
      imageData.odometer.pictureURL === "" &&
      (vehicleDoc.type === "auto" || vehicleDoc.type === "moto")
    ) {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor sube la foto del odometro",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (
      (imageData.odometer.kmTotal === "" ||
        imageData.odometer.kmTotal === undefined) &&
      (vehicleDoc.type === "auto" || vehicleDoc.type === "moto")
    ) {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor el kilometraje del odometro",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (updatedVehicleData.color.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa el color del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (updatedVehicleData.version.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa la versión del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (updatedVehicleData.plates.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa la placa del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (updatedVehicleData.serialNumber.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa el número de serie del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (imageData.pictureSerialNumberURL === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor sube la foto del número de serie del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (updatedVehicleData.motorNumber.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa el número de motor del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    }
    if (
      vehicleDoc?.pictureCarRight &&
      vehicleDoc?.pictureCarLeft &&
      vehicleDoc?.pictureCarFront &&
      vehicleDoc?.pictureCarBack &&
      vehicleDoc?.type === "moto" &&
      vehicleDoc?.pictureSerialNumberURL &&
      vehicleDoc?.pictureSerialNumberURL(
        vehicleDoc?.type === "auto" || vehicleDoc?.type === "moto"
          ? odometerDoc[0]?.pictureURL
          : true
      ) &&
      vehicleDoc?.plates &&
      vehicleDoc?.serialNumber &&
      vehicleDoc?.motorNumber &&
      vehicleDoc?.color &&
      vehicleDoc?.version
    ) {
      Swal.fire({
        icon: "warning",
        text: "Solo puedes actualizar una vez los datos del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    }

    setIsLoading(true);
    const vehicleDocRef = doc(db, `users/${userUID}/vehicles/${vehicleUID}/`);
    try {
      const updateData = {
        color: updatedVehicleData.color.trim(),
        version: updatedVehicleData.version.trim(),
        plates: updatedVehicleData.plates.trim(),
        serialNumber: updatedVehicleData.serialNumber.trim(),
        motorNumber: updatedVehicleData.motorNumber.trim(),
        updatedBy: agentUID,
        status: 1,
      };
      await updateDoc(vehicleDocRef, updateData);

      await setTimeout(async () => {
        await uploadImages();
      }, 1000);
      await addUploadImageComment({
        pictureCarFront: typeof imageData.pictureCarFront === "string",
        pictureCarBack: typeof imageData.pictureCarBack === "string",
        pictureCarLeft: typeof imageData.pictureCarLeft === "string",
        pictureCarRight: typeof imageData.pictureCarRight === "string",
        pictureSerialNumberURL:
          typeof imageData.pictureSerialNumberURL === "string",
        odometer: typeof imageData.odometer.pictureURL === "string",
        color: updatedVehicleData?.color,
        version: updatedVehicleData?.version,
        plates: updatedVehicleData?.plates,
        serialNumber: updatedVehicleData?.serialNumber,
        motorNumber: updatedVehicleData?.motorNumber,
      });
      Swal.fire({
        icon: "success",
        text: "Datos del vehículo actualizados correctamente",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      }).then(() => {
        window.location.reload();
      });
      setIsLoading(false);
    } catch (e) {
      console.error("Error updating document: ", e);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error al actualizar los datos del vehículo",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      setIsLoading(false);
      logError({
        source: "Agregar datos de vehiculo agentes",
        error: e.message,
        metadata: {
          description: "Error al actualizar los datos del vehiculo",
          userUID: userUID || "No user",
          vehicleUID: vehicleUID || "No vehicle",
          vehicleData: vehicleDoc,
        },
      });
    }
  }

  if (fetchingUserData) return <LoadingSkeleton />;

  return (
    <main
      style={{
        width: "100%",
        maxWidth: "800px",
        margin: "26px auto",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {userData.clientStatus === 3 ||
      (!vehicleDoc.isInsured && vehicleDoc.deleted) ? (
        <>
          <img
            style={{ margin: "0 auto", maxWidth: "200px" }}
            src={cluppLogo}
          />
          <Alert
            severity="error"
            sx={{ margin: "5px 10px 0px 10px", fontSize: "24px" }}
          >
            La póliza de este producto ha sido cancelada
          </Alert>
        </>
      ) : (
        <>
          <Stack
            sx={{
              padding: "0 20px",
            }}
          >
            <Stack sx={{ display: "grid", gap: "30px" }}>
              <Box>
                <Typography variant="h6">Fotos del vehículo</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  En el siguiente apartado, podrás cargar las fotos faltantes
                  del vehículo.
                </Typography>
                {(vehicleDoc.type === "auto" || vehicleDoc.type === "moto") && (
                  <Box>
                    <Typography sx={{ textAlign: "center", marginTop: "10px" }}>
                      Foto de odómetro
                    </Typography>
                    <Box
                      sx={{
                        margin: "3px auto",
                        width: "120px",
                        height: "120px",
                      }}
                    >
                      <PhotoCard
                        setImage={setimageData}
                        uid={userUID}
                        vid={vehicleUID}
                        type="odometer"
                        photoUrl={
                          odometerDoc ? odometerDoc[0]?.pictureURL || "" : ""
                        }
                      />
                    </Box>
                    <Typography variant="body2" sx={{ margin: "10px 0" }}>
                      Ingresa el número de kilómetros en tu odómetro
                    </Typography>
                    <TextField
                      fullWidth
                      label="Kilometraje"
                      type="number"
                      size="small"
                      name="kmTotal"
                      disabled={
                        odometerDoc
                          ? odometerDoc[0]?.pictureURL
                            ? true
                            : false
                          : false
                      }
                      value={
                        odometerDoc
                          ? odometerDoc[0]?.kmTotal
                            ? odometerDoc[0]?.kmTotal
                            : imageData.odometer.kmTotal
                          : imageData.odometer.kmTotal
                      }
                      onChange={(e) =>
                        setimageData({
                          ...imageData,
                          odometer: {
                            ...imageData.odometer,
                            kmTotal: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr 1fr"],
                  gap: "18px",
                  margin: "0 auto",
                }}
              >
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  <Typography>Foto delantera</Typography>
                  <PhotoCard
                    setImage={setimageData}
                    imageType="pictureCarFront"
                    uid={userUID}
                    vid={vehicleUID}
                    type="vehicle"
                    photoUrl={vehicleDoc?.pictureCarFront || ""}
                  />
                </Box>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  <Typography>Foto trasera</Typography>
                  <PhotoCard
                    setImage={setimageData}
                    imageType="pictureCarBack"
                    uid={userUID}
                    vid={vehicleUID}
                    type="vehicle"
                    photoUrl={vehicleDoc?.pictureCarBack || ""}
                  />
                </Box>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  <Typography>Foto derecha</Typography>
                  <PhotoCard
                    setImage={setimageData}
                    imageType="pictureCarRight"
                    uid={userUID}
                    vid={vehicleUID}
                    type="vehicle"
                    photoUrl={vehicleDoc?.pictureCarRight || ""}
                  />
                </Box>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  <Typography>Foto izquierda</Typography>
                  <PhotoCard
                    setImage={setimageData}
                    imageType="pictureCarLeft"
                    uid={userUID}
                    vid={vehicleUID}
                    type="vehicle"
                    photoUrl={vehicleDoc?.pictureCarLeft || ""}
                  />
                </Box>
              </Box>
            </Stack>
          </Stack>
          <Stack
            sx={{
              padding: "0 20px",
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: "14px" }}>
              Datos del vehículo
            </Typography>
            <Stack>
              <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                En el siguiente apartado, podrás agregar los datos faltantes del
                vehículo.
              </Typography>
              <form
                onSubmit={updateVehicleData}
                style={{ display: "grid", gap: "20px" }}
              >
                <TextField
                  fullWidth
                  disabled={vehicleDoc?.color ? true : false}
                  label="Color"
                  name="color"
                  value={updatedVehicleData?.color}
                  onChange={onChange}
                />
                <TextField
                  fullWidth
                  disabled={vehicleDoc?.version ? true : false}
                  label="Versión"
                  name="version"
                  value={updatedVehicleData?.version}
                  onChange={onChange}
                />
                <TextField
                  fullWidth
                  disabled={vehicleDoc?.plates ? true : false}
                  label="Placas"
                  name="plates"
                  value={updatedVehicleData?.plates}
                  onChange={onChange}
                />
                <TextField
                  fullWidth
                  disabled={vehicleDoc?.serialNumber ? true : false}
                  label="Número de serie"
                  name="serialNumber"
                  value={updatedVehicleData?.serialNumber}
                  onChange={onChange}
                />

                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography>Foto número de serie</Typography>
                  <PhotoCard
                    setImage={setimageData}
                    imageType="pictureSerialNumberURL"
                    uid={userUID}
                    vid={vehicleUID}
                    type="vehicle"
                    photoUrl={vehicleDoc?.pictureSerialNumberURL || ""}
                  />
                </Box>

                <TextField
                  fullWidth
                  disabled={vehicleDoc?.motorNumber ? true : false}
                  label="Número de motor"
                  name="motorNumber"
                  value={updatedVehicleData?.motorNumber}
                  onChange={onChange}
                />
                <LoadingButton
                  onClick={updateVehicleData}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  loading={isLoading}
                >
                  Guardar datos
                </LoadingButton>
              </form>
            </Stack>
          </Stack>
        </>
      )}
    </main>
  );
}
