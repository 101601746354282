const addOrUpdateMember = ({ email, uid }) =>
  new Promise(async (resolve, reject) => {
    const baseURL =
      "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/mailChimp/addOrUpdateMember";

    try {
      const response = await fetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid,
          email,
        }),
      });

      const json = response.json();

      if (response.ok) {
        return resolve(json);
      }
      reject(json);
    } catch (error) {
      reject(error);
    }
  });

export { addOrUpdateMember };
