import { Container, Typography, Skeleton, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import SmartphoneCard from "./SmartphoneCard";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import AddSmartphoneDialog from "./AddSmartphoneDialog";
import useUser from "../../hooks/useUser";

const getSmartphone = async (userUID) => {
  const q = query(
    collection(db, `users/${userUID}/smartphones`),
    where("deleted", "==", false)
  );

  const querySnapshot = await getDocs(q);
  const smartphones = [];
  querySnapshot.forEach((doc) => {
    smartphones.push({ ...doc.data(), id: doc.id });
  });
  return smartphones;
};

export default function CoveredSmartphones() {
  const { user } = useUser();
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const userUID = params.get("userUID");
  const [smartphoneDocs, setSmartphoneDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getSmartphone(userUID)
      .then((data) => {
        setSmartphoneDocs(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [userUID]);

  function handleCloseDialog() {
    setOpen(false);
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "450px", margin: "0 auto" }}>
          <Skeleton variant="rectangular" width={"100%"} height={190} />
        </Box>
        <Box sx={{ width: "100%", maxWidth: "450px", margin: "0 auto" }}>
          <Skeleton variant="rectangular" width={"100%"} height={190} />
        </Box>
      </Box>
    );
  }
  return (
    <Container
      sx={{
        width: "100%",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "40px",
      }}
    >
      <AddSmartphoneDialog
        onClose={handleCloseDialog}
        open={open}
        userUID={userUID}
        smartphone={smartphoneDocs[0]}
      />
      {smartphoneDocs.length <= 0 && !isLoading ? (
        <>
          <Typography
            variant="body1"
            fontWeight="600"
            textAlign="center"
            color={"#000"}
          >
            Cotiza y contrata en segundos tu seguro de celular contra robo con
            violencia.
          </Typography>
          <Typography
            variant="body1"
            fontWeight="600"
            textAlign="center"
            alignSelf={"center"}
            maxWidth={"500px"}
            marginTop={"-10px"}
          >
            Nuestro seguro es el único en el mercado SIN DEDUCIBLE siempre y
            cuando tengas activado un trayecto en nuestra app al momento del
            robo. De otro modo, aplica un deducible del 40%.
          </Typography>
        </>
      ) : (
        smartphoneDocs.map((smartphone, index) => (
          <SmartphoneCard key={index} smartphone={smartphone} />
        ))
      )}
      <Box
        sx={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          width: "100%",
          maxWidth: "450px",
        }}
      >
        <Button
          sx={{ textTransform: "none" }}
          variant="contained"
          onClick={() =>
            window.open(
              `https://cotizador-clupp.web.app/?userUID=${userUID}&email=${user.email}&vType=devices&utm_source=app&utm_campagin=upsell&utm_medium=web&utm_content=${userUID}`
            )
          }
        >
          Cotizar mi seguro de celular
        </Button>
      </Box>
    </Container>
  );
}
