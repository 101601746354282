import { Box, Stack, Typography } from "@mui/material";
import MobileHeader from "../../Components/MobileHeader";

const NoDeviceFound = () => {
  return (
    <Box>
      <MobileHeader showGradientHeader={false}  />
      <Stack
        height={"40vh"}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <Typography variant="h6">
          Lo sentimos, no hemos podido reconocer el dispositivo al cual deseas acceder.
        </Typography>
      </Stack>
    </Box>
  );
};

export default NoDeviceFound;
