import {
  Button,
  FormControl,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function ImageForm({imgRef, inputImgRef, inputImage, setInputImage, confirmButtonTitle}) {

  return (
    <div style={{ width: "100%" }}>
      <FormControl sx={{ width: "100%" }}>
        <br />
        {inputImage &&
          (inputImage.name.split(".").pop() === "pdf" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <PictureAsPdfIcon />
              <p>{inputImage.name}</p>
            </div>
          ) : (
            <img
              ref={imgRef}
              style={{ width: "100%", maxWidth: "400px", margin: "0 auto 20px" }}
              alt="imagen cedula"
            />
          ))}
        <input
          type="file"
          onChange={(e) => {
            setInputImage(e.target.files[0]);
          }}
          accept="image/png, image/jpeg, image/jpg, application/pdf"
          ref={inputImgRef}
          style={{
            width: "0.1px",
            height: "0.1px",
            opacity: "0",
            overflow: "hidden",
            position: "absolute",
            zIndex: "-1",
          }}
        />
        <Button
          onClick={() => inputImgRef.current.click()}
          type="button"
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {confirmButtonTitle}
        </Button>
      </FormControl>
    </div>
  );
}
