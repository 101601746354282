import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import Swal from "sweetalert2";

import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
  getLatLng,
} from "use-places-autocomplete";

import "./AutoCompletado.css";

const AutoCompletado = ({ setDatos, typeAddress, typeLocation, postal, placeId, description }) => {
  const [address, setAddress] = useState("");
  const [coord, setCoord] = useState();
  const [codePostal, setCodePostal] = useState();

  useEffect(() => {
    if(placeId && description) {
      const newValue = {
        "place_id": placeId,
        description: description
      }
      handleOnInputChange(null, newValue)
    }
  } , [placeId, description])
  useEffect(() => {
    setDatos((prevDatos) => ({
      ...prevDatos,
      [typeAddress]: address.description,
      ...coord,
      ...codePostal,
    }));
  }, [codePostal, coord, address]);

  const searchOptions = {
    componentRestrictions: { country: ["mx"] },
    types: ["geocode", "establishment"],
  };

  const usePlaces = usePlacesAutocomplete({
    requestOptions: searchOptions,
    debounce: 200,
  });

  const {
    suggestions: {
      data: placesData,
      status: placesQueryStatus,
      loading: isPlacesOptionsLoading,
    },
    setValue: setPlacesQuery,
  } = usePlaces;

  async function getLangAndLng(placeId) {
    if (placeId) {
      try {
        const results = await getGeocode({ placeId });
        // console.log(results[0]);
        const { lat: latitude, lng: longitude } = await getLatLng(results[0]);
        return { latitude, longitude };
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function handleOnInputChange(event, newValue) {
    if (!!newValue) {
      try {
        const [result] = await getGeocode({ placeId: newValue.place_id });
        const type = result.address_components;
        if (type[0].types == "route" || type[1].types == "route") {
          const { latitude, longitude } = await getLangAndLng(
            newValue.place_id
          );
          setAddress(newValue);
          setCoord({ [typeLocation]: { lat: latitude, lon: longitude } });
          const zipCode = await getZipCode(result, false);
          setCodePostal({ [postal]: zipCode || "" });
        } else {
          Swal.fire({
            icon: "error",
            text: "La dirección no es válida. Ingresa una dirección válida. Ejemplo: Avenida Insurgentes Sur, Hipódromo, Ciudad de México, CDMX, México",
          });
          console.log("error");
        }
      } catch (error) {
        setAddress("");
      }
    }
  }
  const isOptionEqualToValue = (option, value) =>
    option.description === value.description;
  const getPlaceOptionLabel = (option) => option.description || "";
  const inputOptions =
    placesQueryStatus === "OK"
      ? placesData.map(({ description, place_id }) => ({
          description,
          place_id,
        }))
      : [];

  return (
    <Autocomplete
      fullWidth
      loading={isPlacesOptionsLoading}
      value={address}
      disablePortal
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          setPlacesQuery(value);
        }
      }}
      freeSolo
      onChange={handleOnInputChange}
      options={inputOptions}
      getOptionLabel={getPlaceOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          sx={{ width: "300px" }}
          label={null}
        />
      )}
      clearText="Borrar dirección"
      openText="Abrir menú de opciones"
      closeText="Cerrar"
      loadingText="Cargando..."
      noOptionsText="Escribe una dirección"
    />
  );
};
export default AutoCompletado;
