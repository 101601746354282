import { useEffect, useRef, useState } from "react";

export default function useReadImage () {
  const [inputImage, setInputImage] = useState(null);
  const imgRef = useRef();
  const inputImgRef = useRef();

  useEffect(() => {
    if (inputImage?.name.split(".").pop() !== "pdf" && inputImage) {
      readUrlImage(inputImage);
    }
  }, [inputImage]);

  const readUrlImage = (file) => {
    if (imgRef) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imgRef.current.setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return {
    imgRef,
    inputImgRef,
    setInputImage,
    inputImage
  }
}