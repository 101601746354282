import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { signInAnonymously, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();
export const useAnonymusAuth = () => useContext(AuthContext);

export const AuthAnonymusProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  const loginAuth = () => signInAnonymously(auth);

  useEffect(() => {
    loginAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        try {
          await loginAuth();
        } catch (error) {
          console.error(error);
          if (error.code === "auth/network-request-failed") {
            loginAuth().catch((error) => {
              console.error(error);
              window.location.reload();
            });
          }
        }
      } else {
        setCurrentUser(user);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
