import Dialog from "../../Components/Dialog";

export default function PreviewDialog({ file, isOpen, onClose }) {
  return (
    <Dialog
      sx={{
        zIndex: 1301,
        width: "100%",
        "&.MuiDialogContent-root": {
          padding: "0px 5px",
        },
        "& .MuiPaper-root": {
          margin: "12px",
          width: "100%",
        },
      }}
      isOpen={isOpen}
      onClose={onClose}
      content={
        <>
          {file && file.type.includes("image") ? (
            <img src={file?.url} alt="preview" style={{ width: "100%" }} />
          ) : (
            <iframe
              src={file?.url}
              width="100%"
              height="600px"
              title="pdf preview"
            ></iframe>
          )}
        </>
      }
    />
  );
}
