import { IconButton, Stack, Box } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Fragment, useState } from "react";
import { Outlet, useMatch, Link } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import logoclup from "../Assets/logo-clupp.png";
/* import Sidebar from './Sidebar' */

const URL_NAME = {
  "/perfil": "Mi Perfil",
  "/pagos": "Pagos",
  "/facturacion": "Facturación",
  "/smartphones": "Mis Celulares",
  "/status": "Estatus de cobertura",
  "/odometro": "Odómetro",
  "/editar-vehiculo": "Editar Vehículo",
  "/refiere": "Refiere",
  "/coberturas": "Coberturas",
  "/tutoriales": "Tutoriales",
  "/siniestros": "Historial de siniestros",
  "/claims": "Reportar siniestro",
  "/coberturas/extras": "Modificar coberturas",
};

const MobileHeader = ({ user, showGradientHeader = true }) => {
  return (
    <Box
      sx={{
        paddingTop: showGradientHeader ? "11rem" : "5rem",
        position: "relative",
        background: "#fff",
        boxShadow: showGradientHeader ? "" : "0 2px 4px 0 rgba(0,0,0,.2)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          padding: "0 16px",
          backgroundColor: "#fff",
          position: "fixed",
          zIndex: "10",
          top: 0,
          left: 0,
          width: "95%",
          background: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "1400px",
            backgroundColor: "#fff",
            margin: "0 auto",
          }}
        >
          <Stack>
            <Box sx={{ display: "grid", placeItems: "center" }}>
              <Link
                to="/inicio"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  style={{
                    height: "70px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                  src={logoclup}
                  alt="logo clupp"
                />
              </Link>
            </Box>
          </Stack>
        </Box>
      </Stack>
      {Boolean(user) && showGradientHeader && (
        <Stack
          sx={{
            p: 1,
            position: "fixed",
            zIndex: 10,
            top: "4.2rem",
            left: 0,
            width: "96%",
            borderRadius: "0 0 20px 20px",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
          style={{
            background:
              "-webkit-linear-gradient(left, #0480b1, #0099ff, #00eeff)",
          }}
        >
          <h1
            style={{
              color: "white",
              fontSize: "110%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >{`¡Hola, ${user.firstName}!`}</h1>
        </Stack>
      )}

      <Outlet/>
    </Box>
  );
};

export default MobileHeader;
