import { Box, Stack, Typography } from "@mui/material";
import MobileHeader from "../../Components/MobileHeader";
import Confetti from "react-confetti";
import useQueryParams from "../../hooks/useQueryParams";
import { useEffect, useState } from "react";
import Cluppcito from "../../Assets/Clupp_Festejo.png";
import Swal from "sweetalert2";
import WhatsappService from "./WhatsappService";

const SuccessfullDeviceRegister = () => {
  const query = useQueryParams();
  const [run, setRun] = useState(true);
  const type = query.get("type");
  const uid = query.get("uid");
  const ig = query.get("ig");

  useEffect(() => {
    setTimeout(() => setRun((r) => !r), 5000);
    const fetchData = async () => {
      const URL = `https://clupp-api.web.app/v1/clupp/users/${uid}/items/${ig}/registration:finish`;
      try {
        const response = await fetch(URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJSQVNUUkVBVE9SIn0.OGp_G6Lref09-YkoRsY3vBUEndMpAbhUR-tHHu-nlm4",
          },
        });
        if (!response.ok) {
          Swal.fire({
            icon: "error",
            text: "Ha ocurrido un error",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: "Ha ocurrido un error",
        });
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          zIndex: 10,
          top: 0,
          height: ["90vh", "100%"],
          width: ["95%", "50%"],
          margin: ["0px auto 40px auto", "0px auto 140px auto"],
        }}
      >
        <Confetti tweenDuration={10000} recycle={run} />
      </Box>
      <Box>
        <MobileHeader showGradientHeader={false} />

        <Stack
          height={["40vh", "10vh"]}
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
          marginTop={["0px", "170px"]}
        >
          <img
            src={Cluppcito}
            alt="cluppcito"
            style={{
              marginTop: "80px",
              marginBottom: "10px",
              width: "50%",
              maxWidth: "200px",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            ¡Felicidades!
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {`¡Tu ${type ? type : "dispositivo"} está asegurad${
              type === "celular" || type === null ? "o" : "a"
            }!`}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            En unos minutos enviaremos a tu correo tu carátula de coberturas.
          </Typography>
        </Stack>
      </Box>
      <WhatsappService uid={uid} />
    </>
  );
};

export default SuccessfullDeviceRegister;
