import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function LoadingSkeleton() {
  return (
    <Stack sx={{
      width: "100%",
      maxWidth: "560px",
      margin: "30px auto",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Stack sx={{width: '100%', display: 'grid', gap: '20px'}}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
        <Skeleton variant="rectangular" width={120} height={120} sx={{margin: '0 auto', borderRadius: '10px'}} />
        <Skeleton variant="text" sx={{ fontSize: "2.6rem" }} />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr 1fr"],
            placeItems: "center",
          }}
        >
          <Skeleton variant="rectangular" width={120} height={120} sx={{ borderRadius: '10px' }} />
          <Skeleton variant="rectangular" width={120} height={120} sx={{ borderRadius: '10px' }} />
          <Skeleton variant="rectangular" width={120} height={120} sx={{ borderRadius: '10px' }} />
          <Skeleton variant="rectangular" width={120} height={120} sx={{ borderRadius: '10px' }} />
        </Box>
      </Stack>
      </Stack>
  );
}
