import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import {
  Typography,
  Stack,
  Paper,
  Skeleton,
  Button,
  Divider,
  IconButton,
  useTheme,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import { CreditCard, Description } from "@mui/icons-material";

import { format } from "date-fns";
import { es } from "date-fns/locale";
import { fetchUser } from "../Services/users";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import xmlIcon from "../Assets/xml-file-icon.png";
import { LoadingButton } from "@mui/lab";
import PaidIcon from "@mui/icons-material/Paid";
import cluppcitoFestejoIcon from "../Assets/Clupp_Festejo.png";
import cluppcitoTristeIcon from "../Assets/Clupp_Triste.png";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import fetchInvoicePDF from "../Services/users/fetchInvoicePDF";
import { db } from "../firebase";
import { collection, onSnapshot } from "firebase/firestore";

const INVOICE_STATUS = {
  draft: "Programado",
  paid: "Pagado",
  open: "Pendiente",
};

const CluppAdminAPI =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI";

const initialValue = {
  invoices: {
    upcommingInvoice: null,
    list: [],
  },
  cfdis: [],
  isLoading: true,
  user: undefined,
};

const ACTIONS = {
  FETCH_INVOICES: "fetch-invoices",
  FETCH_INVOICES_SUCCESS: "fetch-invoices-success",
  SET_USER: "set-user",
  FETCH_CFDIS: "fetch-cfdis",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ACTIONS.FETCH_INVOICES:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        invoices: action.payload,
      };
    case ACTIONS.FETCH_CFDIS:
      return {
        ...state,
        cfdis: action.payload,
      };
    default:
      return state;
  }
}

const Payments = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userUID = searchParams.get("uid");
  const [isLoadingUpdatePayment, setIsLoadingUpdatePayment] = useState(false);
  const theme = useTheme();
  const [{ user, ...state }, dispatch] = useReducer(reducer, initialValue);

  useEffect(() => {
    if (!userUID) return;
    fetchUser(userUID)
      .then((user) => {
        dispatch({
          type: ACTIONS.SET_USER,
          payload: user,
        });
      })
      .catch((error) => {
        Swal.fire(
          "Ups! Algo salió mal",
          "Comunicate con soporte técnico",
          "error"
        );
      });
  }, [userUID]);

  async function fetchInvoices() {
    dispatch({
      type: ACTIONS.FETCH_INVOICES,
    });

    try {
      const URI = `${CluppAdminAPI}/customers/stripe/${user.stripeId}/invoices`;
      const response = await fetch(URI);

      if (response.ok) {
        const { invoices, upcomingInvoices: upcomingInvoice } =
          await response.json();

        let list = invoices.data.filter((invoice) => {
          const isPaid = invoice.status === "paid";
          const isOpen = invoice.status === "open";
          const isValid = invoice?.metadata?.invalid !== "true";
          return (isPaid || isOpen) && isValid;
        });

        if (upcomingInvoice) {
          list = [upcomingInvoice, ...list];
        }

        dispatch({
          type: ACTIONS.FETCH_INVOICES_SUCCESS,
          payload: {
            list: list,
            upcomingInvoice,
          },
        });
      } else {
        dispatch({
          type: ACTIONS.FETCH_INVOICES_SUCCESS,
          payload: {
            list: [],
            upcomingInvoice: null,
          },
        });
      }
    } catch (error) {
      Swal.fire(
        "Ups! No pudimos obtener información de tus pagos",
        "Inténtalo nuevamente más tarde",
        "error"
      );
    }
  }

  useEffect(() => {
    if (!user?.stripeId) return;
    const unsubscribeCFDIS = onSnapshot(
      collection(db, `/users/${userUID}/CFDIS`),
      (snapshot) => {
        const cfdis = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({
          type: ACTIONS.FETCH_CFDIS,
          payload: cfdis,
        });
      },
      (error) => {
        console.error(error);
        Swal.fire(
          "Ups! No pudimos obtener información de tus facturas",
          "Inténtalo nuevamente más tarde",
          "error"
        );
      }
    );

    fetchInvoices();

    return () => {
      unsubscribeCFDIS();
    };
  }, [user?.stripeId]);

  function updatePaymentType() {
    setIsLoadingUpdatePayment(true);
    fetch(
      `https://clupp-api.web.app/v1/clupp/payments/session?uid=${userUID}d&customer=${user?.stripeId}`
    )
      .then(async (data) => {
        const res = await data.json();
        if (data.ok) {
          window.open(res.url, "_self");
        } else {
          Swal.fire({
            icon: "error",
            text: res?.message,
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: "Ok",
          });
          setIsLoadingUpdatePayment(false);
        }
      })
      .catch((e) => {
        setIsLoadingUpdatePayment(false);
      });
  }

  ////////////////////////////////////////////////////////
  return (
    <Stack p={4} spacing={2}>
      <Typography variant="h6">Historial de pagos</Typography>
      <LoadingButton
        loading={isLoadingUpdatePayment}
        onClick={() => updatePaymentType()}
        disabled={state.isLoading}
        variant="contained"
        disableElevation
        startIcon={<CreditCard />}
        sx={{
          textTransform: "none",
        }}
      >
        Actualizar tarjeta de pago
      </LoadingButton>
      <Button
        onClick={() => navigate(`/facturacion?uid=${user.uid}`)}
        disabled={state.isLoading}
        variant="contained"
        disableElevation
        startIcon={<Description />}
        sx={{
          textTransform: "none",
        }}
      >
        Facturación fiscal
      </Button>

      <Stack>
        <Divider />
      </Stack>

      <Stack spacing={2}>
        {state.isLoading ? (
          [0, 1, 2, 3, 4, 5].map((id) => (
            <Skeleton
              variant="rectangular"
              height={250}
              key={id}
              sx={{ borderRadius: 2 }}
            />
          ))
        ) : state.invoices?.list.length > 0 ? (
          state.invoices?.list.map((invoice, idx) => (
            <PaymentListItem
              key={invoice.id || idx}
              invoice={invoice}
              cfdis={state.cfdis}
              userUID={userUID}
              fetchInvoices={fetchInvoices}
            />
          ))
        ) : (
          <Typography>No hay ningún pago aún</Typography>
        )}
      </Stack>
    </Stack>
  );
};

const PaymentListItem = ({ invoice, cfdis, userUID, fetchInvoices }) => {
  const [isPayment, setIsPaymet] = useState(false);
  const [isGeneratingBill, setIsGeneratingBill] = useState(false);
  const cfdiDoc = cfdis.filter((cfdi) => cfdi?.invoiceId === invoice?.id)[0];
  const haveAFiscalBill = cfdiDoc?.invoiceId === invoice?.id;
  const theme = useTheme();
  const navigate = useNavigate();

  const checkFiscalMonth = (timestamp) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const timestampPayed = new Date(timestamp * 1000);
    const payedYear = timestampPayed.getFullYear();
    const payedMonth = timestampPayed.getMonth() + 1;
    return currentYear === payedYear && currentMonth === payedMonth;
  };

  async function handleEmitSingleBilling(invoice) {
    setIsGeneratingBill(true);
    try {
      const response = await fetchInvoicePDF(userUID, invoice.id);
      const pdfURL = response.pdfURL;
      if (pdfURL) {
        Swal.fire({
          icon: "success",
          text: "Factura emitida correctamente.",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        fetchInvoices();
        return;
      }

      switch (response.error) {
        case "invalid-zip-code":
          return Swal.fire({
            icon: "error",
            text: "El código postal que proporcionaste no es válido. Por favor, asegúrate de que este coincida con tu domicilio fiscal registrado en el SAT.",
            confirmButtonText: "Actualizar datos fiscales",
            confirmButtonColor: theme.palette.primary.main,
            cancelButtonText: "Cerrar",
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/facturacion?uid=${userUID}`);
            }
          });

        case "invalid-name":
          return Swal.fire({
            icon: "error",
            text: "El RFC que proporcionaste no es válido. Por favor, asegúrate de que este coincida con tu RFC registrado en el SAT.",
            confirmButtonText: "Actualizar datos fiscales",
            confirmButtonColor: theme.palette.primary.main,
            cancelButtonText: "Cerrar",
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/facturacion?uid=${userUID}`);
            }
          });

        case "not-invoice-profile":
          return Swal.fire({
            icon: "error",
            text: "Aún no has cargado tus datos fiscales. Por favor, carga tus datos fiscales",
            confirmButtonText: "Cargar datos fiscales",
            confirmButtonColor: theme.palette.primary.main,
            cancelButtonText: "Cerrar",
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/facturacion?uid=${userUID}`);
            }
          });

        case "missing-invoice-profile-data":
          return Swal.fire({
            icon: "error",
            text: "Tus datos fiscales están incompletos. Por favor, completa tus datos fiscales.",
            confirmButtonText: "Cargar datos fiscales",
            confirmButtonColor: theme.palette.primary.main,
            cancelButtonText: "Cerrar",
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/facturacion?uid=${userUID}`);
            }
          });
      }

      return Swal.fire({
        icon: "error",
        text: "No se pudo completar la factura en este momento. Por favor, inténtalo de nuevo más tarde.",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "No se pudo completar la factura en este momento. Por favor, inténtalo de nuevo más tarde.",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      console.error("Error al actualizar el documento en Firebase:", error);
    } finally {
      setIsGeneratingBill(false);
    }
  }

  function handleDoPayment(invoiceId) {
    setIsPaymet(true);
    fetch("https://clupp-api.web.app/v1/clupp/payments/payment.pay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoiceId: invoiceId,
      }),
    })
      .then(async (data) => {
        const response = await data.json();
        if (data.ok) {
          Swal.fire({
            iconHtml: `<img src=${cluppcitoFestejoIcon} style="width: 76px;">`,
            text: response?.message,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src=${cluppcitoTristeIcon} style="width: 76px;">`,
            text: response?.message,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
        }
        setIsPaymet(false);
      })
      .catch((err) => {
        Swal.fire({
          iconHtml: `<img src=${cluppcitoTristeIcon} style="width: 76px;">`,
          text: err?.message,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        setIsPaymet(false);
      });
  }

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
      }}
    >
      <Stack spacing={1}>
        {invoice?.lines?.data.map((lineItem) => (
          <Typography variant="subtitle2" key={lineItem.id}>
            {lineItem.description}
          </Typography>
        ))}
      </Stack>

      <Stack direction={["column", "row"]} gap={[0, 2]}>
        <Typography variant="subtitle2">
          <b>Inicio de vigencia:</b>{" "}
          {format(
            new Date(invoice?.lines.data[0]?.period?.start * 1000),
            "dd 'de' MMMM 'de' yyyy",
            {
              locale: es,
            }
          )}
        </Typography>
      </Stack>
      <Stack direction={["column", "row"]} gap={[0, 2]}>
        <Typography variant="subtitle2">
          <b>Fin de vigencia:</b>{" "}
          {format(
            new Date(invoice?.lines.data[0]?.period?.end * 1000),
            "dd 'de' MMMM 'de' yyyy",
            {
              locale: es,
            }
          )}
        </Typography>
      </Stack>
      {invoice?.status === "paid" && (
        <Stack direction={["column", "row"]} gap={[0, 2]}>
          <Typography variant="subtitle2">
            <b>Pago realizado el:</b>{" "}
            {format(
              new Date(invoice?.status_transitions.paid_at * 1000),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: es,
              }
            )}
          </Typography>
        </Stack>
      )}

      {cfdiDoc ? (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Stack sx={{ marginRight: "20px" }}>
            <Typography sx={{ color: "black", fontWeight: 700 }}>
              Ver factura:
            </Typography>
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <IconButton onClick={() => window.open(cfdiDoc?.pdf, "_blank")}>
                <PictureAsPdfIcon fontSize="large" sx={{ color: "black" }} />
              </IconButton>
              <IconButton onClick={() => window.open(cfdiDoc?.xml, "_blank")}>
                <img
                  style={{ width: "35px" }}
                  src={xmlIcon}
                  alt="xml document"
                />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <>
          {!haveAFiscalBill &&
          invoice.total > 0 &&
          invoice.status === "paid" ? (
            checkFiscalMonth(invoice.status_transitions.paid_at) ? (
              <Button
                variant="contained"
                startIcon={<ReceiptLongIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => handleEmitSingleBilling(invoice)}
              >
                Emitir factura fiscal
              </Button>
            ) : (
              <Alert severity="info">
                Este pago no puede ser facturado. Solo puedes facturar los pagos
                realizados en el mes actual.
              </Alert>
            )
          ) : (
            <></>
          )}
        </>
      )}

      {isGeneratingBill && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle1" sx={{ fontWeight: 900, flex: 1 }}>
          {invoice.total &&
            `$${invoice.total.toString().slice(0, -2).toLocaleString("en", {
              useGrouping: true,
            })}`}
        </Typography>
        <Typography sx={{ fontWeight: 700 }}>
          {INVOICE_STATUS[invoice.status]}
        </Typography>
      </Stack>

      <Stack>
        {invoice.status === "open" && (
          <LoadingButton
            loading={isPayment}
            startIcon={<PaidIcon />}
            fullWidth
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => {
              handleDoPayment(invoice?.id);
            }}
          >
            Realizar pago
          </LoadingButton>
        )}
      </Stack>
    </Paper>
  );
};

export default Payments;
