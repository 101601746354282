import {
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function LinkListItem({text="", href=""}) {
  return (
    <ListItemButton
    component="a"
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    <ListItemText
      sx={{ "& .MuiTypography-root": { fontSize: "15px" } }}
    >
      {text}
    </ListItemText>
    <ListItemIcon sx={{ minWidth: "auto" }}>
      <KeyboardArrowRightIcon sx={{ color: "#000" }} />
    </ListItemIcon>
  </ListItemButton>
  )
}
