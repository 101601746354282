import React, { useContext, useEffect, useState } from "react";
import Dialog from "./Dialog";
import { getContactPhone } from "../utils";
import { differenceInCalendarDays, format } from "date-fns";
import {
  Stack,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import UserContex from "../User/UserContex";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';

const LEALTAD_INFO = {
  2: "plata",
  3: "oro",
  1: "azul",
};

const TipoAsistencia = ({
  setHideAsistencia,
  tipoAsistecia,
  setTipoAsistencia,
  uidVehiculo,
  setUidVehiculo,
  userData,
}) => {
  const {
    loginAvi,
    selectV,
    claims,
    nameAsegurado,
    lastAsegurado,
    uidAsegurado,
    emailAsegurado,
    report,
  } = useContext(UserContex);
  const [marca, setMarca] = useState({
    brand: undefined,
    model: undefined,
    year: undefined,
  });
  //console.log(claims);
  useEffect(() => {
    if (selectV !== null && selectV !== undefined) {
      setMarca({
        brand: selectV.brand,
        model: selectV.model,
        year: selectV.year,
      });
    }
  }, [selectV]);
  const handleTipoAsistencia = (event) => {
    const valor = event.target.value;
    if (ondayTree <= 365) {
      setIsMessageTree(true);
    } else {
      setTipoAsistencia(event.target.value);

      if (valor == "cerrajero") {
        if (selectV.type === "auto") {
          setIsMessageOpen(true);
        } else {
          setIsMessageType(true);
        }
      } else if (valor == "grua") {
        if (selectV.type === "auto" || selectV.type === "moto") {
          if (countClaims == 0) {
            setTipoAsistencia(event.target.value);
            setIsMessageOpen(true);
          } else {
            if (dayTranscurridos < 365) {
              setIsMessageErrorGrua(true);
            } else {
              setIsMessageOpen(true);
            }
          }
        } else {
          setIsMessageType(true);
        }
      } else if (
        valor == "corriente" ||
        valor == "cambiollanta" ||
        valor == "gasolina"
      ) {
        if (selectV.type === "auto" || selectV.type === "moto") {
          setIsMessageOpen(true);
        } else {
          setIsMessageType(true);
        }
      }
      // console.log(selectV.type);
    }
    //console.log(ondayTree);
    //console.log(valor);
    /*if (valor == "grua") {
      if (selectV.type === "auto" || selectV.type === "moto") {
        if (ondayTree <= 365) {
          setIsMessageTree(true);
          setHideAsistencia("");
        } else {
          console.log(selectV.type);
        }
      }
    } else if (valor == "cambiollanta") {
      console.log(ondayTree);
      if (selectV.type === "auto" || selectV.type === "moto") {
        if (ondayTree <= 365) {
          setIsMessageTree(true);
        } else {
          console.log(selectV.type);
        }
      }
    } else if ("cerrajero") {
      if (selectV.type === "auto") {
        if (ondayTree <= 365) {
          setIsMessageTree(true);
        } else {
          console.log(selectV.type);
        }
      }
    } else if ("gasolina") {
      if (selectV.type === "auto" || selectV.type === "moto") {
        if (ondayTree <= 365) {
          setIsMessageTree(true);
        } else {
          console.log(selectV.type);
        }
      }
    } else if ("corriente") {
      if (selectV.type === "auto" || selectV.type === "moto") {
        if (ondayTree <= 365) {
          setIsMessageTree(true);
        } else {
          console.log(selectV.type);
        }
      }
    }
    /*if (valor == "cambiollanta") {
      if (countClaims == 0) {
        setTipoAsistencia(event.target.value);
        setIsMessageOpen(true);
      } else {
        setTipoAsistencia(event.target.value);
        setIsMessageOpen(true);
      }
    } else {
      setTipoWhat(valor);
    }
    if (selectV == null) {
      setIsMessageError(true);
    } else if (selectV.type === "auto" || selectV.type === "moto") {
      if (ondayTree <= 365) {
        setIsMessageTree(true);
      } else {
        if (valor == "grua") {
          if (countClaims == 0) {
            setTipoAsistencia(event.target.value);
            setIsMessageOpen(true);
          } else {
            setTipoAsistencia(event.target.value);
            if (dayTranscurridos < 365) {
              setTipoAsistencia(event.target.value);
              setIsMessageErrorGrua(true);
            } else {
              setIsMessageOpen(true);
              setTipoAsistencia(event.target.value);
            }
          }
        } else if (valor == "corriente") {
          if (countClaims == 0) {
            setTipoAsistencia(event.target.value);
            setIsMessageOpen(true);
          } else {
            setTipoAsistencia(event.target.value);
            setIsMessageOpen(true);

            //            console.log(treeAsistencia);
          }
        } else if (valor == "cerrajero") {
          console.log(selectV.type);
          if (selectV.type === "auto") {
            if (countClaims == 0) {
              setTipoAsistencia(event.target.value);
              setIsMessageOpen(true);
            }
          } else {
            setTipoAsistencia(event.target.value);
            setIsMessageOpen(true);
          }
        } else {
          console.log("Hola");
        }
      }
    } else {
      setIsMessageType(true);
    }*/
  };
  const [isMessageError, setIsMessageError] = useState(false);
  const [isMessageType, setIsMessageType] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [isMessageTree, setIsMessageTree] = useState(false);

  const handleTree = () => {
    setIsMessageTree(false);
  };

  const [countClaims, setCountClaims] = useState(undefined);
  const [timestampClaims, setTimestampClaims] = useState(undefined);
  const [dayTranscurridos, setDayTranscurridos] = useState(undefined);
  const [tipoWhat, setTipoWhat] = useState(undefined);
  const [treeAsistencia, setTreeAsistencia] = useState(undefined);
  const [ondayTree, setOnDayTree] = useState(undefined);
  // console.log(claims);
  const whatsappUrlServicio =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola%20soy%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    "%20con%20correo%20" +
    emailAsegurado +
    "%20.%20Requiero%20su%20apoyo%20para%20solicitar%20una%20asistencia%20de%20" +
    tipoWhat +
    "%20para%20mi%20" +
    marca.brand +
    "%20" +
    marca.model +
    "%20" +
    marca.year +
    ".";
  //  console.log(uidVehiculo);
  const complementeWhatsappMessage =
    userData?.level > 1
      ? "%20Estoy%20en%20el%20nivel%20" +
        LEALTAD_INFO[userData?.level] +
        "%20del%20programa%20de%20lealtad."
      : "";
  const whatsappUrl =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola%20mi%20nombre%20es%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    "%20con%20uid%20" +
    uidAsegurado +
    "%20y%20vehículo%20" +
    report.vehicle +
    "%20solicito%20servicio%20de%20asistencía%20víal." +
    complementeWhatsappMessage;
  useEffect(() => {
    if (uidVehiculo !== null || uidVehiculo !== undefined) {
      const claimsAsistent = claims.filter(
        (claim) =>
          claim.vehicle === uidVehiculo && claim.type[0] === "vialAssistance"
      );
      const orderAsistent = claimsAsistent.sort(
        (el1, el2) => el2.reportTimestamp - el1.reportTimestamp
      );
      console.log(orderAsistent.length);
      if (orderAsistent.length >= 3) {
        const data2 = orderAsistent.map((data12) => data12.reportTimestamp);
        setTreeAsistencia(data2[2]);
      } else {
        setTreeAsistencia(undefined);
      }

      const claimsVehicles = claims.filter(
        (claim) =>
          claim.vehicle === report.vehicle && claim.assistanceType === "tow"
      );
      setCountClaims(claimsVehicles.length);
      const orderByClaims = claimsVehicles.sort(
        (el1, el2) => el2.reportTimestamp - el1.reportTimestamp
      );
      const data = orderByClaims.map((data1) => data1.reportTimestamp);
      setTimestampClaims(data[0]);
    }
  }, [uidVehiculo]);

  useEffect(() => {
    if (timestampClaims !== undefined) {
      const formatTimestamp = format(new Date(timestampClaims), "yyyy-MM-dd");
      const dateTimestamp = Date.now();
      const formatToday = format(new Date(dateTimestamp), "yyyy-MM-dd");
      var result = differenceInCalendarDays(
        new Date(formatToday),
        new Date(formatTimestamp)
      );
      setDayTranscurridos(result);
    }
  }, [timestampClaims]);
  useEffect(() => {
    if (treeAsistencia !== undefined) {
      const formatTree = format(new Date(treeAsistencia), "yyyy-MM-dd");
      const dateTree = Date.now();
      const TodayTree = format(new Date(dateTree), "yyyy-MM-dd");
      var resultTree = differenceInCalendarDays(
        new Date(TodayTree),
        new Date(formatTree)
      );
      setOnDayTree(resultTree);
    }
  }, [treeAsistencia]);

  const [isMessageErrorGrua, setIsMessageErrorGrua] = useState(false);
  const handleClose = () => {
    setIsMessageOpen(false);
    setTipoAsistencia("");
    setHideAsistencia("");
  };

  console.log(selectV);
  return (
    <div
      style={{ display: "flex", justifyContent: "flex-start", width: "80%" }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{
          paddingBottom: 3,
        }}
      >
        <Typography>¿Qué tipo de asistencia vial requieres?</Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={tipoAsistecia}
            onChange={handleTipoAsistencia}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <FormControlLabel value="grua" control={<Radio />} label="Grúa" />
              <FormControlLabel
                value="cambiollanta"
                control={<Radio />}
                label="Cambio de llanta"
              />
              <FormControlLabel
                value="gasolina"
                control={<Radio />}
                label="Envío de gasolina"
              />
              {selectV.type !== "moto" && (
                <FormControlLabel
                  value="cerrajero"
                  control={<Radio />}
                  label="Envío de cerrajero"
                  sx={{ marginLeft: "180px", marginTop: "-130px" }}
                />
              )}
              <FormControlLabel
                value="corriente"
                control={<Radio />}
                label="Paso de corriente"
                sx={{
                  marginLeft: "180px",
                  marginTop: selectV.type === "moto" ? "-130px" : "0",
                  marginBottom: selectV.type === "moto" ? "60px" : "",
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>

        <Dialog
          isOpen={isMessageOpen}
          title={
            tipoAsistecia == "grua"
              ? "Servicio de grúa"
              : tipoAsistecia == "corriente"
              ? "Paso de corriente"
              : tipoAsistecia == "cambiollanta"
              ? "Cambio llanta"
              : tipoAsistecia == "cerrajero"
              ? "Cerrajeria"
              : tipoAsistecia == "gasolina"
              ? "Gasolina"
              : ""
          }
          onClose={handleClose}
          content={
            tipoAsistecia === "grua" ? (
              <Stack spacing={2}>
                <Typography>
                  Antes de solicitar este servicio considera lo siguiente:
                </Typography>
                <Typography>
                  -Que el motivo de la solicitud sea por una falla mecánica y no
                  a consecuencia de un accidente vehicular.
                </Typography>
                <Typography>
                  -Que el vehículo se pueda poner en neutral y que todas las
                  llantas giren.
                </Typography>
                <Typography>
                  -Que el vehículo se encuentre en una zona accesible para la
                  grúa.
                </Typography>
                <Typography>
                  -El servicio cubre sin costo hasta 80 km a la redonda desde el
                  origen.
                </Typography>
                <Typography>
                  -Únicamente una persona deberá viajar en la cabina de la grúa
                  con el conductor.
                </Typography>
                <Typography>
                  -Las casetas y peajes que se utilicen en el traslado de la
                  unidad no están incluidas.
                </Typography>
              </Stack>
            ) : tipoAsistecia === "corriente" ? (
              <Stack spacing={2}>
                <Typography>
                  Antes de solicitar este servicio considera lo siguiente:
                </Typography>
                <Typography>
                  -El servicio no se refiere a una sustitución o préstamo de una
                  batería sino al paso de corriente.
                </Typography>
                <Typography>
                  -Que la batería se encuentre en buenas condiciones (no
                  “chorreada”, perforada o con las terminales sulfatadas).
                </Typography>
                <Typography>
                  -Que el vehículo se encuentre en una ubicación y posición en
                  la que permita realizar el servicio de paso de corriente.
                </Typography>
              </Stack>
            ) : tipoAsistecia === "cambiollanta" ? (
              <Stack spacing={2}>
                <Typography>
                  Antes de solicitar este servicio considera lo siguiente:
                </Typography>
                <Typography>
                  -El servicio no se refiere a una sustitución por una llanta
                  nueva, sino a su cambio mecánico.
                </Typography>
                <Typography>
                  -Verifica que tu llanta de refacción se encuentre en buenas
                  condiciones y montada debidamente en el rin.
                </Typography>
                <Typography>
                  -En caso de ser necesario, verifica que cuentes con el birlo
                  de seguridad.
                </Typography>
                <Typography>
                  -El vehículo se debe encontrar en una posición que permita
                  realizar el cambio de llanta.
                </Typography>
              </Stack>
            ) : tipoAsistecia === "cerrajero" ? (
              <Stack spacing={2}>
                <Typography>
                  Antes de solicitar este servicio considera lo siguiente:
                </Typography>
                <Typography>
                  -El envío de cerrajero se limita a que la causa sea el olvido
                  de las llaves dentro del vehículo asegurado.
                </Typography>
                <Typography>
                  -Este servicio no incluye fabricar duplicados y/o codificación
                  de llaves.
                </Typography>
                <Typography>
                  -Es indispensable que te identifiques con el prestador de
                  servicio antes de que este proceda a realizar el servicio.
                </Typography>
                <Typography>
                  -En caso de que el contratante no se encuentre en lugar, se
                  deberá conceder su aprobación por los medios de contacto
                  registrados ante Clupp.
                </Typography>
              </Stack>
            ) : tipoAsistecia === "gasolina" ? (
              <Stack spacing={2}>
                <Typography>
                  Antes de solicitar este servicio considera lo siguiente:
                </Typography>
                <Typography>
                  -El servicio incluye sin costo el envío de hasta 5 litros de
                  combustible.
                </Typography>
                <Typography>
                  -Es necesario indicar el tipo de combustible que se
                  requiere(magna, premium o diesel).
                </Typography>
                <Typography>
                  -En caso de requerir más de 5 litros, selecciona la opción de
                  hablar con alguien para que un asesor te cotice y comparta el
                  costo excedente que deberás cubrir.
                </Typography>
              </Stack>
            ) : (
              <Typography>
                ¿Quieres llamarnos por teléfono o prefieres hacer tu reporte vía
                WhatsApp?"
              </Typography>
            )
          }
          Actions={
            <Stack spacing={2} direction="row">
              {tipoAsistecia === "grua" ||
              tipoAsistecia === "corriente" ||
              tipoAsistecia === "cambiollanta" ||
              tipoAsistecia === "cerrajero" ||
              tipoAsistecia === "gasolina" ? (
                <a
                  href={whatsappUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="text"
                    onClick={() => {
                      setIsMessageOpen(false);
                      setTipoAsistencia("");
                    }}
                    sx={{
                      textTransform: "initial",
                      color: "gray",
                    }}
                  >
                    Prefiero hablar con alguien
                  </Button>
                </a>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<PhoneIcon/>}
                  onClick={() => {
                    setIsMessageType(false);
                    window.open("tel:5592252185");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Llamar por teléfono
                </Button>
              )}
              {tipoAsistecia === "grua" ? (
                <Button
                  variant="contained"
                  onClick={async () => {
                    await loginAvi();
                    setIsMessageOpen(false);
                    setHideAsistencia("grua");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Continuar
                </Button>
              ) : tipoAsistecia === "corriente" ? (
                <Button
                  variant="contained"
                  onClick={async () => {
                    await loginAvi();
                    setIsMessageOpen(false);
                    setHideAsistencia("corriente");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Continuar
                </Button>
              ) : tipoAsistecia === "cambiollanta" ? (
                <Button
                  variant="contained"
                  onClick={async () => {
                    await loginAvi();
                    setIsMessageOpen(false);
                    setHideAsistencia("cambiollanta");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Continuar
                </Button>
              ) : tipoAsistecia === "gasolina" ? (
                <Button
                  variant="contained"
                  onClick={async () => {
                    await loginAvi();
                    setIsMessageOpen(false);
                    setHideAsistencia("gasolina");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Continuar
                </Button>
              ) : tipoAsistecia === "cerrajero" ? (
                <Button
                  variant="contained"
                  onClick={async () => {
                    await loginAvi();
                    setIsMessageOpen(false);
                    setHideAsistencia("cerrajero");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Continuar
                </Button>
              ) : (
                <a
                  href={whatsappUrlServicio}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<WhatsAppIcon/>}
                    onClick={() => {
                      setIsMessageType(false);
                      //setQuePaso("");
                    }}
                    sx={{
                      textTransform: "initial",
                    }}
                  >
                    Escribir por WhatsApp
                  </Button>
                </a>
              )}
            </Stack>
          }
        />
        <Dialog
          isOpen={isMessageError}
          content={
            <Typography>
              Por favor, selecciona el vehículo para el que requieres la
              asistencia.
            </Typography>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                onClick={() => {
                  setIsMessageError(false);
                  setTipoAsistencia("");
                }}
                sx={{
                  textTransform: "initial",
                }}
              >
                Regresar
              </Button>
            </Stack>
          }
        />
        <Dialog
          isOpen={isMessageError}
          content={
            <Typography>
              Por favor, selecciona el vehículo para el que requieres la
              asistencia.
            </Typography>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                onClick={() => {
                  setIsMessageError(false);
                  setTipoAsistencia("");
                }}
                sx={{
                  textTransform: "initial",
                }}
              >
                Regresar
              </Button>
            </Stack>
          }
        />

        <Dialog
          isOpen={isMessageTree}
          content={
            <Typography>
              Has agotado tus servicios de asistencia ya que tu cobertura
              incluye 3 servicios al año, con máximo 1 grúa.
            </Typography>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    setIsMessageTree(false);
                    setTipoAsistencia("");
                    setHideAsistencia("");
                    //                    setOnDayTree(undefined);
                  }}
                  sx={{
                    textTransform: "initial",
                    color: "gray",
                  }}
                >
                  Contactar a un asesor
                </Button>
              </a>
              <Button
                variant="contained"
                onClick={() => {
                  setIsMessageTree(false);
                  setTipoAsistencia("");
                  setHideAsistencia("");
                  //setOnDayTree(undefined);
                }}
                sx={{
                  textTransform: "initial",
                }}
              >
                Ok, cerrar
              </Button>
            </Stack>
          }
        />
        <Dialog
          isOpen={isMessageType}
          content={
            tipoAsistecia === "grua" ? (
              <Typography>
                El servicio de grúa solo puede ser solicitado para autos y
                motos.
              </Typography>
            ) : tipoAsistecia === "corriente" ? (
              <Typography>
                El servicio de corriente solo puede ser solicitado para autos y
                motos.
              </Typography>
            ) : tipoAsistecia === "gasolina" ? (
              <Typography>
                El servicio de gasolina solo puede ser solicitado para autos y
                motos.
              </Typography>
            ) : tipoAsistecia === "cambiollanta" ? (
              <Typography>
                El servicio de cambio de llanta solo puede ser solicitado para
                autos y motos.
              </Typography>
            ) : tipoAsistecia === "cerrajero" ? (
              <Typography>
                El servicio de cerrajeria solo puede ser solicitado para autos.
              </Typography>
            ) : (
              <Typography>Error</Typography>
            )
          }
          Actions={
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                onClick={() => {
                  setIsMessageType(false);
                  setTipoAsistencia("");
                }}
                sx={{
                  textTransform: "initial",
                }}
              >
                Regresar
              </Button>
            </Stack>
          }
        />
        <Dialog
          isOpen={isMessageErrorGrua}
          content={
            <Stack spacing={2} direction="column">
              <>
                {userData?.level > 1 ? (
                  <Typography>
                    Tu cobertura incluye un servicio de grúa gratuito al año el
                    cuál ya has utilizado, pero al estar en el nivel de lealtad{" "}
                    <b>{LEALTAD_INFO[userData?.level]}</b> tienes derecho al
                    doble de asistencias, por favor selecciona la opción de
                    hablar con un asesor
                  </Typography>
                ) : (
                  "Tu cobertura incluye un servicio de grúa gratuito al año el cuál ya has utilizado."
                )}
              </>
            </Stack>
          }
          Actions={
            <Stack spacing={2} direction="row">
              <Button
                variant="text"
                onClick={() => {
                  setIsMessageErrorGrua(false);
                  setTipoAsistencia("");
                }}
                sx={{
                  textTransform: "initial",
                  color: "gray",
                }}
              >
                Ok, cerrar
              </Button>
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsMessageErrorGrua(false);
                    setTipoAsistencia("");
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Contactar con un asesor
                </Button>
              </a>
            </Stack>
          }
        />
      </Stack>
    </div>
  );
};
export default TipoAsistencia;
