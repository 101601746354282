import appendURLParams from "../../utils/appendURLParams";

const endpoint = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/googleMaps/getDistance`;
const getDistance = ({ destination, origin }) => {
  const finalEndpoint = appendURLParams(endpoint, [
    ["destination", destination],
    ["origin", origin],
  ]);

  return fetch(finalEndpoint);
};

export default getDistance;
