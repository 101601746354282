import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";

const GENTER_OPTIONS = ["Masculino", "Femenino", "Prefiero no decir"];

function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
}

export default function UserDataForm({
  userData,
  setUserData,
  onSubmit,
  isLoading,
  setIsRefError,
  isRfcError,
  setIsZipCodeError,
  isZipCodeError,
}) {
  function handleOnChange(e) {
    let title = e.target.value;

    if (!["rfc", "gender", "zipCode"].includes(e.target.name)) {
      title = toTitleCase(e.target.value);
    } else if (["rfc"].includes(e.target.name)) {
      if (title.length !== 12 && title.length !== 13) {
        setIsRefError(true);
      } else {
        setIsRefError(false);
      }
      title = e.target.value.toUpperCase();
    } else if (["zipCode"].includes(e.target.name)) {
      if (title.length !== 5) {
        setIsZipCodeError(true);
      } else {
        setIsZipCodeError(false);
      }
    }
    setUserData((prev) => ({
      ...prev,
      [e.target.name]: title,
    }));
  }

  return (
    <form
      // component={"form"}
      onSubmit={onSubmit}
      style={{ display: "grid", gap: "18px" }}
    >
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        Hablemos de ti. Ingresa tus datos personales.
      </Typography>

      <TextField
        variant="standard"
        label="Nombre"
        name="firstName"
        value={userData.firstName}
        onChange={handleOnChange}
      />
      <TextField
        variant="standard"
        label="Apellidos"
        name="lastName"
        value={userData.lastName}
        onChange={handleOnChange}
      />
      <TextField
        variant="standard"
        label="RFC"
        name="rfc"
        error={isRfcError}
        helperText={isRfcError ? "El RFC debe tener 12 o 13 dígitos" : ""}
        value={userData.rfc}
        onChange={handleOnChange}
      />
      <FormControl>
        <InputLabel sx={{ marginLeft: "-15px" }} id="gender-select">
          Sexo biológico
        </InputLabel>
        <Select
          variant="standard"
          labelId="gender-select"
          name="gender"
          label="Sexo biológico"
          value={userData.gender}
          onChange={handleOnChange}
        >
          {GENTER_OPTIONS.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        variant="standard"
        label="Código postal"
        name="zipCode"
        type="number"
        error={isZipCodeError}
        helperText={
          isZipCodeError ? "El código postal debe tener 5 dígitos" : ""
        }
        value={userData.zipCode}
        onChange={handleOnChange}
      />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        sx={{ textTransform: "none" }}
        onClick={onSubmit}
        type="submit"
      >
        Guardar
      </LoadingButton>
    </form>
  );
}
