import { useEffect, useState, useRef } from "react";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  useTheme,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Dialog from "../../Components/Dialog.jsx";
import { Box } from "@mui/system";
import { formatCurrency } from "../../utils/formatCurrency.js";
import { db, storage } from "../../firebase.js";
import cluppFestejo from "../../Assets/Clupp_Festejo.png";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAnonymusAuth } from "../../context/anonymusUserContext.js";
import logError from "../../utils/logError.js";
import Swal from "sweetalert2";

const SMARTPHONE_INITIAL_STATE = {
  value: "",
  brand: "",
  model: "",
  IMEI: "",
  phone: "",
};

const PAY_PERIODS = {
  month: "mensual",
  "3month": "trimestral",
  "6month": "semestral",
  year: "anual",
};

function AddSmartphoneDialog({ open, onClose, userUID }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [smartphoneData, setSmartphoneData] = useState(
    SMARTPHONE_INITIAL_STATE
  );
  const { currentUser } = useAnonymusAuth();
  const [inputImage, setInputImage] = useState(null);
  const [readingImage, setReadingImage] = useState(false);
  const [phoneNumberValidation, setPhoneNumberValidation] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [quotationValue, setQuotationValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [queCubreModal, setQueCubreModal] = useState(false);
  const [smartphoneValueUpdated, setSmartphoneValueUpdated] =
    useState(undefined);
  const [openConfirmSmartphoneAdded, setOpenConfirmSmartphoneAdded] =
    useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [userData, setUserData] = useState();
  const inputImgRef = useRef();

  useEffect(() => {
    async function getUserData() {
      const docRef = doc(db, "users", userUID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }

    async function getVehicleUserData() {
      const q = query(
        collection(db, `users/${userUID}/vehicles`),
        where("deleted", "==", false),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      const vehicles = [];
      querySnapshot.forEach((doc) => {
        vehicles.push({ ...doc.data(), id: doc.id });
      });
      setVehicleData(vehicles);
    }
    if (currentUser) {
      getUserData();
      getVehicleUserData();
    }
  }, [userUID, currentUser]);

  useEffect(() => {
    if (inputImage) {
      readImageToGetIMEI();
    }
  }, [inputImage]);

  function readImageToGetIMEI() {
    setReadingImage(true);
    const storageRef = ref(
      storage,
      `users/${userUID}/readIMEI/IMEIImage_${new Date().getTime()}.${inputImage.name
        .split(".")
        .pop()}`
    );
    const uploadTask = uploadBytesResumable(storageRef, inputImage);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.error(error);
        logError({
          source: "Plataforma de smartphones",
          error: error?.message,
          metadata: {
            description: "Error al subir foto de IMEI",
            userUid: userUID || "No user",
            userAgent: navigator?.userAgent,
            imageName: inputImage?.name,
            phoneData: smartphoneData,
          },
        });
        Swal.fire({
          text: "Error al subir foto, por favor intentalo más tarde",
          icon: "error",
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: "Ok",
        });
        setReadingImage(false);
      },
      async () => {
        const imgURL = await getDownloadURL(uploadTask.snapshot.ref);
        fetch(
          "https://us-central1-segucel-mx.cloudfunctions.net/api/labelImage",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              analysisType: "TEXT_DETECTION",
              imageURL: imgURL,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const IMEI = data?.result?.textAnnotations.filter(
              (item) => item?.description?.length === 15
            );
            if (IMEI.length > 0) {
              setSmartphoneData((prev) => ({
                ...prev,
                IMEI: IMEI[0]?.description,
              }));
              Swal.fire({
                icon: "success",
                text: "IMEI detectado con exito",
                confirmButtonText: "Ok",
                confirmButtonColor: theme.palette.primary.main,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: "No se encontró el IMEI en la foto, por favor intentalo nuevamente",
                confirmButtonText: "Ok",
                confirmButtonColor: theme.palette.primary.main,
              });
            }
            setReadingImage(false);
          });
      }
    );
  }

  function handleQuotePhone(e) {
    e.preventDefault();
    if (smartphoneData.value < 1000 || smartphoneData.value > 50000) {
      Swal.fire({
        icon: "warning",
        text: "El valor del celular debe ser mayor a $1,000 y menor a $50,000",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }
    setIsLoading(true);
    fetch(
      `https://clupp-api.web.app/v1/clupp/users/${userUID}/smartphones/quote`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: Number(smartphoneData.value),
          periods: ["month", "3month", "6month", "year"],
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          Swal.fire({
            icon: "error",
            text: "Ocurrió un error al cotizar tu celular, intentalo más tarde",
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
          logError({
            source: "Plataforma de smartphones",
            error: data?.error,
            metadata: {
              userUID,
              value: smartphoneData?.value,
              description: "Error al cotizar celular",
              menssage: data?.message,
            },
          });
          setIsLoading(false);
          return;
        }
        setQuotationValue(data.premiums);
        setSelectedValue(data.premiums[0]);
        setSmartphoneValueUpdated(smartphoneData.value);
        setIsLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Ocurrió un error al cotizar tu celular, intentalo más tarde",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        logError({
          error: error?.message,
          source: "Plataforma de smartphones",
          metadata: {
            userUID,
            value: smartphoneData?.value,
            description: "Error al cotizar celular",
            menssage: error?.message,
          },
        });
        console.error("Error:", error);
        setIsLoading(false);
      });
  }

  function onCloseQueCubreModal() {
    setQueCubreModal(false);
  }

  function handleOnChange(event) {
    if (event.target.name === "phone") {
      setPhoneNumberValidation(event.target.value.length !== 10 ? true : false);
    }

    if (event.target.name === "value") {
      setValueError(
        event.target.value < 1000 || event.target.value > 50000 ? true : false
      );
    }

    setSmartphoneData({
      ...smartphoneData,
      [event.target.name]: event.target.value,
    });
  }

  function closeConfirmSmartphoneAddedDialog() {
    setOpenConfirmSmartphoneAdded(false);
  }
  function openConfirmSmartphoneAddedDialog(e) {
    e.preventDefault();
    if (smartphoneData.brand.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa la marca de tu celular",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    } else if (smartphoneData.model.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa la modelo de tu celular",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    } else if (smartphoneData.IMEI.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa el IMEI de tu celular",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    } else if (smartphoneData.value.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa el valor de tu celular",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    } else if (smartphoneData.phone.trim() === "") {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa el número de teléfono",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }
    if (smartphoneData.phone.length < 10 || smartphoneData.phone.length > 10) {
      Swal.fire({
        icon: "warning",
        text: "El número de teléfono debe tener 10 dígitos",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }
    if (!(smartphoneValueUpdated === smartphoneData.value)) {
      Swal.fire({
        icon: "warning",
        text: "Has modificado el valor de tu celular, por favor cotiza nuevamente tu celular",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }

    setIsLoading(true);
    fetch(
      `https://clupp-api.web.app/v1/clupp/users/${userUID}/smartphones/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          period: selectedValue?.period,
          value: Number(smartphoneValueUpdated),
          phone: smartphoneData.phone.trim(),
          IMEI: smartphoneData.IMEI.trim(),
          brand: smartphoneData.brand.trim(),
          model: smartphoneData.model.trim(),
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.error) {
          Swal.fire({
            icon: "error",
            text: "Ocurrió un error al agregar tu celular, intentalo más tarde",
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
          logError({
            error: data.error,
            source: "Plataforma de smartphones",
            metadata: {
              menssage: data?.message,
              userUID: userUID,
              description: "Error al contratar smartphone",
              smartphoneData: smartphoneData,
              value: selectedValue?.value,
              period: selectedValue?.period,
            },
          });
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        setOpenConfirmSmartphoneAdded(true);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Ocurrió un error al agregar tu celular, intentalo más tarde",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        logError({
          error: error.message,
          source: "Plataforma de smartphones",
          metadata: {
            userUID,
            value: smartphoneData?.value,
            description: "Error al cotizar celular",
            menssage: error?.message,
          },
        });
        console.error("Error:", error);
        setIsLoading(false);
      });
  }
  function handleCleanQuotation() {
    setSmartphoneData(SMARTPHONE_INITIAL_STATE);
    setQuotationValue(0);
  }
  function handleConfirmQuotePhone() {
    const smartphoneDataToSend = {
      value: smartphoneData.value,
      brand: smartphoneData.brand.trim(),
      model: smartphoneData.model.trim(),
      IMEI: smartphoneData.IMEI.toString().trim(),
      phone: smartphoneData.phone.toString().trim(),
    };

    setIsLoading(true);
    Swal.fire({
      icon: "success",
      text: "Celular agregado correctamente",
      confirmButtonText: "Ok",
      confirmButtonColor: theme.palette.primary.main,
    });
    console.log("Agregando celular", smartphoneDataToSend);
    setIsLoading(false);
    // onClose();
  }
  function handleSelectPremium(period) {
    quotationValue.forEach((item) => {
      if (item.period === period) {
        setSelectedValue(item);
      }
    });
  }
  return (
    <>
      <ConfirmSmartphoneAdded
        open={openConfirmSmartphoneAdded}
        onClose={() => {
          closeConfirmSmartphoneAddedDialog();
          onClose();
          handleCleanQuotation();
          window.location.reload();
        }}
        smartphoneData={smartphoneData}
        handleConfirmQuotePhone={handleConfirmQuotePhone}
        quotationValue={selectedValue}
        userData={userData}
        vehicleData={vehicleData[0]}
      />
      <QueCubre
        isOpen={queCubreModal}
        onClose={onCloseQueCubreModal}
        value={smartphoneValueUpdated}
      />
      <Dialog
        isOpen={open}
        onClose={() => {
          onClose();
          handleCleanQuotation();
        }}
        title="Agregar celular"
        content={
          <Box sx={{ position: "relative" }}>
            {quotationValue.length === 0 && (
              <Typography variant="body1" color="text.primary">
                Cotiza tu nuevo celular para agregarlo a tu seguro
              </Typography>
            )}
            <Box>
              {quotationValue.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "sticky",
                    background: "#FFF",
                    backgroundColor: "#FFF",
                    top: "0",
                    zIndex: "10",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <Box>
                    <Typography variant="body1" color="text.primary">
                      Seguro para tu{" "}
                      {smartphoneData.brand && smartphoneData.model
                        ? `${smartphoneData.brand} ${smartphoneData.model}`
                        : "celular"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setQueCubreModal((prev) => !prev)}
                    >
                      ¿Qué cubre?
                    </Typography>
                  </Box>

                  <Typography
                    variant="body2"
                    color="secondary.main"
                    fontWeight="700"
                    sx={{ textAlign: "right" }}
                  >
                    {formatCurrency(selectedValue?.total, {
                      maximumFractionDigits: 0,
                    })}
                    <br />/{PAY_PERIODS[selectedValue?.period]}
                  </Typography>
                </Box>
              )}
              <form
                onSubmit={
                  quotationValue > 0
                    ? openConfirmSmartphoneAddedDialog
                    : handleQuotePhone
                }
                style={{
                  padding: "10px 0",
                  display: "grid",
                  gap: "15px",
                  marginTop: "10px",
                  paddingTop: quotationValue > 0 ? "75px" : "0px",
                }}
              >
                <Typography style={{ color: "black" }}>
                  Valor del teléfono:
                </Typography>
                <TextField
                  label="Valor comercial del teléfono"
                  variant="outlined"
                  fullWidth
                  name="value"
                  type="number"
                  size="small"
                  error={valueError}
                  helperText={
                    valueError
                      ? "El valor debe ser mayor a $1,000 y menor a $50,000"
                      : ""
                  }
                  value={smartphoneData.value}
                  onChange={handleOnChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                {quotationValue.length > 0 && (
                  <Box sx={{ display: "grid", gap: "15px" }}>
                    <Typography style={{ color: "black" }}>Marca:</Typography>
                    <TextField
                      label="Escribe la marca de tu celular"
                      variant="outlined"
                      fullWidth
                      name="brand"
                      size="small"
                      value={smartphoneData.brand}
                      onChange={handleOnChange}
                    />
                    <Typography style={{ color: "black" }}>Modelo:</Typography>
                    <TextField
                      label="Modelo"
                      variant="outlined"
                      fullWidth
                      name="model"
                      size="small"
                      value={smartphoneData.model}
                      onChange={handleOnChange}
                    />
                    <Typography style={{ color: "black" }}>
                      IMEI: <br />
                      Para obtener el IMEI de tu celular marca en tu teléfono:{" "}
                      <b>*#06#</b>
                    </Typography>
                    <TextField
                      label="Escribe la clave IMEI de tu celular"
                      variant="outlined"
                      fullWidth
                      name="IMEI"
                      size="small"
                      type="number"
                      value={smartphoneData.IMEI}
                      onChange={handleOnChange}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        setInputImage(e.target.files[0]);
                      }}
                      ref={inputImgRef}
                      accept="image/*"
                      style={{
                        width: "0.1px",
                        height: "0.1px",
                        opacity: "0",
                        overflow: "hidden",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                    />
                    <Typography variant="body2" sx={{ color: "black" }}>
                      O si prefieres , puedes tomar una captura de pantalla de
                      tu IMEI o tomarle una foto y subirla aquí:
                    </Typography>
                    <LoadingButton
                      color="success"
                      variant="contained"
                      loading={readingImage}
                      onClick={() => {
                        inputImgRef.current.click();
                      }}
                      sx={{
                        textTransform: "none",
                        borderRadius: "20px",
                        width: "180px",
                        margin: "0 auto",
                      }}
                    >
                      Prefiero subir imagen
                    </LoadingButton>
                    <Typography style={{ color: "black" }}>
                      Telefóno:
                    </Typography>
                    <TextField
                      label="Teléfono"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="phone"
                      size="small"
                      value={smartphoneData.phone}
                      onChange={handleOnChange}
                      error={phoneNumberValidation}
                      helperText={
                        phoneNumberValidation
                          ? "El número de teléfono debe tener 10 dígitos"
                          : " "
                      }
                    />
                  </Box>
                )}
              </form>
              <Box>
                {quotationValue.length > 0 && (
                  <>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 700, textAlign: "center" }}
                      >
                        Periodicidad de pago
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        height: "120px",
                        gridTemplateColumns:
                          selectedValue?.discount > 0 ? "1fr 1fr" : "1fr",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "120px",
                        }}
                      >
                        {selectedValue?.discount > 0 && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              textAlign: "center",
                              textDecorationLine: "line-through",
                            }}
                          >
                            MX{" "}
                            {formatCurrency(selectedValue?.subtotal, {
                              maximumFractionDigits: 0,
                            })}
                          </Typography>
                        )}
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          MX{" "}
                          {formatCurrency(selectedValue?.total, {
                            maximumFractionDigits: 0,
                          })}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="secondary"
                          sx={{ fontWeight: 700 }}
                        >
                          Pago {PAY_PERIODS[selectedValue?.period]}
                        </Typography>
                      </Box>
                      {selectedValue?.discount > 0 && (
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              textAlign: "center",
                              display: "grid",
                              placeItems: "center",
                              height: "120px",
                            }}
                          >
                            {selectedValue?.discount * 100}% de <br />
                            descuento
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        placeItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        color={
                          selectedValue?.period === "month"
                            ? "secondary"
                            : "success"
                        }
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          borderRadius: "20px",
                          width: "100px",
                        }}
                        onClick={() => {
                          handleSelectPremium("month");
                        }}
                      >
                        Mensual
                      </Button>
                      <Button
                        color={
                          selectedValue?.period === "3month"
                            ? "secondary"
                            : "success"
                        }
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          borderRadius: "20px",
                          width: "100px",
                        }}
                        onClick={() => {
                          handleSelectPremium("3month");
                        }}
                      >
                        Trimestral
                      </Button>
                      <Button
                        color={
                          selectedValue?.period === "6month"
                            ? "secondary"
                            : "success"
                        }
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          borderRadius: "20px",
                          width: "100px",
                        }}
                        onClick={() => {
                          handleSelectPremium("6month");
                        }}
                      >
                        Semestral
                      </Button>
                      <Button
                        color={
                          selectedValue?.period === "year"
                            ? "secondary"
                            : "success"
                        }
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          borderRadius: "20px",
                          width: "100px",
                        }}
                        onClick={() => {
                          handleSelectPremium("year");
                        }}
                      >
                        Anual
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        }
        Actions={
          <>
            {quotationValue.length > 0 ? (
              <Stack
                justifyContent="flex-end"
                gap={1}
                flex="1"
                direction={["column", "column", "row"]}
              >
                <LoadingButton
                  loading={isLoading}
                  sx={{ textTransform: "none", borderRadius: "20px" }}
                  variant="outlined"
                  onClick={handleQuotePhone}
                  color="secondary"
                >
                  Volver a cotizar
                </LoadingButton>
                <LoadingButton
                  loading={isLoading}
                  color="secondary"
                  sx={{ textTransform: "none", borderRadius: "20px" }}
                  variant="contained"
                  onClick={openConfirmSmartphoneAddedDialog}
                >
                  Contratar seguro
                </LoadingButton>
              </Stack>
            ) : (
              <LoadingButton
                loading={isLoading}
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={handleQuotePhone}
              >
                Cotizar mi seguro de celular
              </LoadingButton>
            )}
          </>
        }
      />
    </>
  );
}

function ConfirmSmartphoneAdded({
  open,
  onClose,
  quotationValue,
  smartphoneData,
}) {
  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      title="¡Tu celular ha sido agregado exitosamente!🎉"
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            src={cluppFestejo}
            style={{ width: "90px" }}
            alt="icono clupp festejo"
          />
          <Typography variant="body1">
            No olvides enviar tu comprobante de pago por WhatsApp para activar
            tu seguro
          </Typography>
          <Typography variant="body1" color="text.primary">
            Tu prima {PAY_PERIODS[quotationValue?.period]} para tu celular{" "}
            {smartphoneData.brand} {smartphoneData.model} será de:{" "}
            {formatCurrency(quotationValue?.total)}
          </Typography>
        </Box>
      }
      Actions={
        <>
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={onClose}
          >
            Cerrar
          </Button>
          {/* <LoadingButton
            loading={isLoading}
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={handleConfirmQuotePhone}
          >
            Contratar
          </LoadingButton> */}
        </>
      }
    />
  );
}

function QueCubre({ isOpen, onClose, value }) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Seguro para tu celular"
      content={
        <Box>
          <Typography variant="body1" color="text.primary">
            Cubre tu celular por hasta{" "}
            <b>{formatCurrency(value, { maximumFractionDigits: 0 })}</b> ante
            robo con violencia. Nuestro seguro es el único en el mercado SIN
            DEDUCIBLE siempre y cuando tengas activado un trayecto en nuestra
            app al momento del robo. De otro modo, aplica un deducible del 40%.
          </Typography>
        </Box>
      }
    />
  );
}

export default AddSmartphoneDialog;
