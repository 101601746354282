import {
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import { getInsuredVehicles } from "../utils/vehicles/getInsuredVehicles";
import { getPaidVehicles } from "../utils/vehicles/getPaidVehicles";
import { getAutosWithPhotos } from "../utils/vehicles/getAutosWithPhotos";
import { getAddressFromCoords } from "../Services/GoogleMaps/getAddressFromCoords";
import { ClaveReport, getContactPhone } from "../utils";
import { deviceDetect } from "react-device-detect";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useContext, useEffect, useState, useReducer } from "react";
import UserContex from "../User/UserContex";
import { addDocument, updateDocument } from "../Services/firestore";
import Dialog from "./Dialog";
import { roboReducer, roboInitialState } from "./RoboTrackingContext";
import AutoCompletado from "./AutoCompletado/AutoCompletado";
import Swal from "sweetalert2";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SuccessRoboReportModal from "./SuccessRoboReportModal";
import FailedRoboReportModal from "./FailedRoboReportModal";
import logError from "../utils/logError";
import { transformerTypeItem } from "../utils/vehicles/getVehicleType";
import { postMessageAndComment } from "../utils/postMessageSlackAndComment";

function convertMinutesToMilliseconds(minutes) {
  return minutes * 60000;
}
const slackMessageDelayinMinutes = 10;

const FACTS_DESCRIPTION_MANDATORY_CHARACTERS = 300;

const RoboTracking = ({
  userUID,
  userLocation,
  whatsappUrl,
  nameAsegurado,
  lastNameAsegurado,
}) => {
  const { vehicles } = useContext(UserContex);

  const [openModal, setOpenModal] = useState(false);
  const [ArticulosAsegurados, setArticulosAsegurados] = useState(undefined);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [contentModal, setContenModal] = useState("");
  const [showInputReport, setInputReport] = useState(false);
  const [location, setLocation] = useState("");
  const [clear, setClear] = useState(false);
  const [onFoc, setOnFoc] = useState(true);
  const [secondModal, setSecondModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [reportID, setReportID] = useState("");
  const [roboState, dispatch] = useReducer(roboReducer, roboInitialState);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(new Date());
  const [timerId, setTimerId] = useState(null);
  const isVehicleReport = [
    "auto",
    "moto",
    "eBike",
    "bike",
    "scooter",
    "other",
  ].includes(roboState?.vehicle?.type);

  const isSmarthoneReport = !isVehicleReport;

  useEffect(() => {
    return () => clearTimeout(timerId);
  }, [timerId]);

  const startTimer = (value = undefined) => {
    const vehicleOrSmart = value || roboState.vehicle;
    const isSubVehicleReport = [
      "auto",
      "moto",
      "eBike",
      "bike",
      "scooter",
      "other",
    ].includes(vehicleOrSmart?.type);

    let messageSlack = Boolean(vehicleOrSmart)
      ? `El asegurado ${userUID} inició un reporte de robo para su ${
          isSubVehicleReport ? transformerTypeItem(vehicleOrSmart) : "Celular"
        } ${vehicleOrSmart?.id}`
      : `El asegurado ${userUID} inició un reporte de robo`;
    let messageAdmin = Boolean(vehicleOrSmart)
      ? `[Mensaje automático] El asegurado inició un reporte de robo para su ${
          isSubVehicleReport ? transformerTypeItem(vehicleOrSmart) : "Celular"
        } ${vehicleOrSmart?.id}`
      : `El asegurado ${userUID} inició un reporte de robo`;
    // Clear any existing timer
    if (timerId) {
      clearTimeout(timerId);
    }
    // Set a new timer
    const newTimerId = setTimeout(() => {
      postMessageAndComment(userUID, messageSlack, messageAdmin);
    }, convertMinutesToMilliseconds(slackMessageDelayinMinutes));

    setTimerId(newTimerId);
  };

  let date = new Date();
  let slackMedicalAssistanceMessage = `El asegurado ${userUID} inició un reporte de robo para su ${transformerTypeItem(
    roboState.vehicle
  )} ${
    roboState.vehicle.id
  } pero indicó que había heridos por lo que se le sugirió primero llamar al 911 por lo que no pudo finalizar su reporte.`;
  let commentMedicalAssistanceMessage = `[Mensaje automático] El asegurado inició un reporte de robo para su ${transformerTypeItem(
    roboState.vehicle
  )} ${
    roboState.vehicle.id
  } pero indicó que había heridos por lo que se le sugirió primero llamar al 911 por lo que no pudo finalizar su reporte.`;

  let slackReport911Message = `El asegurado ${userUID} inició un reporte de robo para su ${transformerTypeItem(
    roboState.vehicle
  )} ${
    roboState.vehicle.id
  } pero aún no tiene número de reporte al 911 por lo que no pudo finalizar.`;
  let commentReport911Message = `[Mensaje automático] El asegurado inició un reporte de robo para su ${transformerTypeItem(
    roboState.vehicle
  )} ${
    roboState.vehicle.id
  } pero aún no tiene número de reporte al 911 por lo que no pudo finalizar.`;

  useEffect(() => {
    calls();
  }, [vehicles]);

  // useEffect(() => {
  //   onLoad();
  // }, []);

  async function calls() {
    let paidVehicles = [];
    let autosWithPhotos = [];
    const availableVehicles = getInsuredVehicles(vehicles);
    if (availableVehicles.length > 0) {
      paidVehicles = getPaidVehicles(availableVehicles, userUID);
    }
    if (paidVehicles[0] !== undefined) {
      autosWithPhotos = await getAutosWithPhotos(paidVehicles, userUID);
    }

    const insuredProperty = [...autosWithPhotos];
    setArticulosAsegurados(insuredProperty);
    // console.log("Artículos asegurados", insuredProperty);
    // console.log("vehiculos", autosWithPhotos);
    onLoad(insuredProperty);
  }

  async function onLoad(insuredProperty) {
    let deviceReport;
    let currentAddress;
    const device = deviceDetect();
    // console.log("-------------", userUID);
    const reponse = device.isBrowser
      ? (deviceReport = `${device.osName} ${device.osVersion} ${device.browserName}`)
      : (deviceReport = `${device.vendor} ${device.model}`);
    currentAddress = await getAddressFromCoords(
      userLocation.lon,
      userLocation.lat
    );
    dispatch({
      type: "ON_LOAD",
      payload: {
        currentLocation: userLocation,
        currentAddress: currentAddress.response.results[0].formatted_address,
        deviceReport: deviceReport,
        user: userUID,
        startReportTimestamp: Date.now(),
        accidentTimestamp: Date.now(),
      },
    });

    if (insuredProperty.length === 1) {
      dispatch({
        type: "SELECT_VEHICLE",
        payload: insuredProperty[0],
      });
    }
  }

  useEffect(() => {
    dispatch({
      type: "ROBO_ADDRESS",
      payload: location.currentAddress,
    });
    dispatch({
      type: "FACTS_POSITION",
      payload: location.currentLocation,
    });
    console.log(location);
  }, [location]);

  const handleChange = (e) => {
    const insuredProperty = e.target.value;
    const typeOfProperty = transformerTypeItem(insuredProperty);
    // console.log("Report type", typeOfProperty);
    if (typeOfProperty === "celular") {
      addDocument("/IT/processes/claimStarted/", {
        uid: userUID,
        smartphoneUID: insuredProperty.id,
        type: ["cellphoneTheft"],
        timestamp: Date.now(),
      });
    } else {
      addDocument("/IT/processes/claimStarted/", {
        uid: userUID,
        vehicleUID: insuredProperty.id,
        type: ["carTheft"],
        timestamp: Date.now(),
      });
    }
    dispatch({
      type: "SELECT_VEHICLE",
      payload: e.target.value,
    });
  };

  const handleOpenModal = () => {
    setOpenModal(false);
  };
  const handleError = (reportData) => {
    const errorObject = { ...reportData };
    console.log("error", errorObject);
    for (let property in errorObject) {
      if (errorObject[property] == undefined) {
        errorObject[property] = "undefined";
      }
    }
    return errorObject;
  };

  const handleForm = () => {
    // Perfom validations

    if (roboState["requiresMedicalAssistance"] === null) {
      return Swal.fire({
        icon: "error",
        text: "Por favor, responde la pregunta: ¿Hay alguna persona herida?",
      });
    }

    if (roboState["911Report"].trim().length < 4) {
      if (showInputReport) {
        return Swal.fire({
          icon: "error",
          text: "Por favor, ingresa el número de reporte al 911",
        });
      }

      return Swal.fire({
        icon: "error",
        text: "Por favor, realiza tu denuncia de robo al 911, para poder continuar",
      });
    }

    if (typeof roboState["accidentAddress"] === "undefined") {
      if (showAutocomplete) {
        return Swal.fire({
          icon: "error",
          text: "Por favor, responde la pregunta: ¿En dónde ocurrió el robo?",
        });
      }
      return Swal.fire({
        icon: "error",
        text: "Por favor, respondes la pregunta: ¿Te encuentras en el lugar del robo?",
      });
    }

    if (roboState["isViolentEvent"] === null) {
      return Swal.fire({
        icon: "error",
        text: "Por favor, responde la pregunta: ¿Los hechos fueron con violencia?",
      });
    }

    if (isSmarthoneReport && typeof roboState["appTurnedOn"] === "undefined") {
      return Swal.fire({
        icon: "error",
        text: "Por favor, responde la pregunta: ¿Tenías activado un viaje en la App al momento del robo?",
      });
    }

    if (
      roboState["factsDescription"].trim().length <
      FACTS_DESCRIPTION_MANDATORY_CHARACTERS
    ) {
      return Swal.fire({
        icon: "error",
        text: `Por favor, describe los hechos con al menos ${FACTS_DESCRIPTION_MANDATORY_CHARACTERS} caracteres`,
      });
    }

    if (roboState["phoneContact"].trim().length < 10) {
      return Swal.fire({
        icon: "error",
        text: `Por favor, indica un número de contacto a 10 dígitos`,
      });
    }

    // Create the report
    // If the report is for a vehicle use the B prefix
    // If the report is not for a vehicle , we assume is
    // a report for a smartphone, so, we the C prefix
    const type = isVehicleReport ? "B" : "C";

    const commonFields = {
      user: roboState.user,
      isActive: true,
      source: "app",
      startReportTimestamp: roboState.startReportTimestamp,
      accidentTimestamp: roboState.accidentTimestamp,
      currentAddress: roboState.currentAddress,
      currentLocation: roboState.currentLocation,
      accidentAddress: roboState.accidentAddress,
      accidentLocation: roboState.accidentLocation,
      requiresMedicalAssistance: roboState.requiresMedicalAssistance,
      "911Report": roboState["911Report"],
      user: roboState.user,
      deviceReport: roboState.deviceReport,
      isViolentEvent: roboState.isViolentEvent,
      factsDescription: roboState.factsDescription,
      phoneContact: roboState.phoneContact,
      reportTimestamp: Date.now(),
    };

    const reportData = isVehicleReport
      ? {
          ...commonFields,
          type: ["carTheft"],
          vehicle: roboState.vehicle.id,
        }
      : {
          ...commonFields,
          appTurnedOn: roboState.appTurnedOn,
          type: ["cellphoneTheft"],
          smartphone: roboState.vehicle.id,
          smartphoneMake: roboState.vehicle.brand || "",
          smartphoneModel: roboState.vehicle.model || "",
        };
    const errorResponse = handleError(reportData);
    try {
      setLoading(true);
      const reportUID = ClaveReport(type);
      setReportID(reportUID);
      console.log({ ...errorResponse });
      updateDocument(`/users/${userUID}/claims/${reportUID}`, {
        ...errorResponse,
      });
      setSecondModal(true);
    } catch (e) {
      setFailedModal(true);
      logError({
        error: e.toString(),
        metadata: {
          uid: roboState.user,
          ...errorResponse,
        },
      });
    } finally {
      setLoading(false);
    }
  };
  if (ArticulosAsegurados !== undefined && ArticulosAsegurados.length == 0)
    return (
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: "340px" }}
      >
        <Typography sx={{ textAlign: "center" }}>
          Por favor, puedes realizar tu reporte comunicándote con uno de
          nuestros agentes.
        </Typography>
        <Stack
          alignItems={"center"}
          justifyContent={"space-around"}
          spacing={2}
        >
          <Button
            variant="contained"
            startIcon={<PhoneIcon />}
            sx={{
              textTransform: "none",
              width: "100%",
              height: "50px",
              textAlign: "center",
              fontSize: "13px",
            }}
            href={`tel:+525592252185`}
          >
            Llamar por teléfono
          </Button>
          <Button
            color="success"
            startIcon={<WhatsAppIcon />}
            variant="contained"
            sx={{
              textTransform: "none",
              height: "50px",
              textAlign: "center",
              fontSize: "12px",
            }}
            href={whatsappUrl}
            target="_blank"
          >
            Enviar mensaje por WhatsApp
          </Button>
        </Stack>
      </Stack>
    );

  return (
    <div
      style={{ display: "flex", justifyContent: "flex-start", width: "80%" }}
    >
      {ArticulosAsegurados == undefined ? (
        <CircularProgress />
      ) : (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{
            paddingBottom: 3,
          }}
        >
          <Typography>¿Qué te robaron?</Typography>
          {ArticulosAsegurados.length === 1 ? (
            ArticulosAsegurados[0].brand && ArticulosAsegurados[0].model ? (
              <Typography>
                {ArticulosAsegurados[0].brand} {ArticulosAsegurados[0].model}{" "}
                {!ArticulosAsegurados[0].year
                  ? ""
                  : ArticulosAsegurados[0].year}{" "}
              </Typography>
            ) : (
              <Typography>Celular</Typography>
            )
          ) : (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{
                p: 3,
              }}
            >
              <Select
                variant="outlined"
                className="vehicle-select text-input"
                onChange={handleChange}
                defaultValue={"0"}
                sx={{
                  width: "250px",
                }}
              >
                <MenuItem value={"0"}>
                  <em>Selecciona</em>
                </MenuItem>
                {ArticulosAsegurados &&
                  ArticulosAsegurados.map((data) => (
                    <MenuItem key={data.id} value={data}>
                      {data.brand && data.model ? (
                        <span>
                          {`
                          ${data.brand} 
                          ${data.model} 
                          ${!data.year ? "" : data.year}`}{" "}
                        </span>
                      ) : (
                        <span>Celular</span>
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </Stack>
          )}
          {(roboState.vehicle || ArticulosAsegurados.length === 1) && (
            <>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel focused={false}>
                    ¿Hay alguna persona herida?
                  </FormLabel>
                  <RadioGroup
                    onChange={(e) => {
                      startTimer();
                      if (e.target.value === "Si") {
                        dispatch({
                          type: "GET_MEDICAL_ASSISTANCE",
                          payload: true,
                        });

                        postMessageAndComment(
                          userUID,
                          slackMedicalAssistanceMessage,
                          commentMedicalAssistanceMessage
                        );
                        setOpenModal(true);
                        setContenModal(
                          "En caso de requerir ambulancia solicitala al 911"
                        );
                      } else {
                        dispatch({
                          type: "GET_MEDICAL_ASSISTANCE",
                          payload: false,
                        });
                      }
                    }}
                  >
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel focused={false}>
                    ¿Has realizado la denuncia de robo al 911?
                  </FormLabel>
                  <RadioGroup
                    onChange={(e) => {
                      startTimer();
                      if (e.target.value === "Si") {
                        setInputReport(!openModal);
                        setContenModal("¿Cuál es el número de reporte al 911?");
                      } else {
                        setInputReport(false);
                        setOpenModal(true);
                        postMessageAndComment(
                          userUID,
                          slackReport911Message,
                          commentReport911Message
                        );
                        setContenModal(
                          `Por tu seguridad y para evitar tu responsabilidad en caso de mal uso del ${
                            isVehicleReport ? "vehículo" : "celular"
                          } es muy importante que primero reportes el robo al 911`
                        );
                      }
                    }}
                  >
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                    {showInputReport && (
                      <TextField
                        placeholder="Ingresa el número de reporte"
                        required
                        onBlur={(e) => {
                          dispatch({
                            type: "REPORT_911",
                            payload: e.target.value.trim(),
                          });
                        }}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>¿Cuándo y a qué hora ocurrió el robo?</FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      maxDate={date}
                      maxDateTime={date}
                      value={value}
                      onChange={(newValue) => {
                        console.log(newValue);
                        startTimer();
                        dispatch({
                          type: "SET_ACCIDENT_TIMESTAMP",
                          payload: Date.parse(newValue),
                        });
                        setValue(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl>
                  <FormLabel focused={false}>
                    ¿Te encuentras en el lugar del robo?
                  </FormLabel>
                  <RadioGroup
                    onChange={(e) => {
                      startTimer();
                      if (e.target.value == "Si") {
                        dispatch({
                          type: "ROBO_ADDRESS",
                          payload: roboState.currentAddress,
                        });
                        dispatch({
                          type: "FACTS_POSITION",
                          payload: roboState.currentLocation,
                        });
                        setShowAutocomplete(false);
                      } else setShowAutocomplete(true);
                    }}
                  >
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                {showAutocomplete && (
                  <>
                    <Typography sx={{ maxWidth: "400px" }}>
                      ¿En dónde ocurrió el robo?
                      <Typography sx={{ fontSize: "11px" }}>
                        La dirección debe tener calle, número, colonia, ciudad y
                        estado. Ejemplo: Reforma 222, Avenida Paseo de la
                        Reforma, Juárez, Ciudad de México, CDMX, México
                      </Typography>
                    </Typography>
                    <AutoCompletado
                      clear={clear}
                      setClear={setClear}
                      datos={location}
                      setDatos={setLocation}
                      typeAddress={"currentAddress"}
                      typeLocation={"currentLocation"}
                      postal={"postalCurrent"}
                      onFoc={onFoc}
                      setOnFoc={setOnFoc}
                    />
                  </>
                )}

                <FormControl>
                  <FormLabel focused={false}>
                    ¿Los hechos fueron con violencia?
                    <Typography sx={{ fontSize: "11px" }}>
                      Es decir, el asaltante te amagó con un arma
                    </Typography>
                  </FormLabel>

                  <RadioGroup
                    required
                    onChange={(e) => {
                      startTimer();
                      dispatch({
                        type: "VIOLENT_EVENT",
                        payload: e.target.value === "Si" ? true : false,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>

                {isSmarthoneReport && (
                  <FormControl>
                    <FormLabel focused={false}>
                      ¿Tenías activado un viaje en la App al momento del robo?
                    </FormLabel>

                    <RadioGroup
                      required
                      onChange={(e) => {
                        startTimer();
                        dispatch({
                          type: "APP_TURNED_ON",
                          payload: e.target.value === "true",
                        });
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Si"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                )}

                <FormControl>
                  <FormLabel
                    sx={{ maxWidth: "400px" }}
                    error={
                      roboState.factsDescription.length <
                      FACTS_DESCRIPTION_MANDATORY_CHARACTERS
                    }
                  >
                    Por favor, describe los hechos lo más detallado posible.
                    <Typography sx={{ fontSize: "11px" }}>
                      Incluye el nombre del conductor, a dónde se dirigía,
                      cuántas personas estaban en el vehículo, la descripción de
                      los asaltantes, si los asaltantes tenían armas y de qué
                      tipo, el diálogo que hubo, etc.
                    </Typography>
                  </FormLabel>
                  <TextField
                    sx={{ width: "100%" }}
                    error={
                      roboState.factsDescription.length <
                      FACTS_DESCRIPTION_MANDATORY_CHARACTERS
                    }
                    multiline
                    helperText={`${roboState.factsDescription.length}/${FACTS_DESCRIPTION_MANDATORY_CHARACTERS} Mínimo ${FACTS_DESCRIPTION_MANDATORY_CHARACTERS} caracteres`}
                    onChange={(e) => {
                      startTimer();
                      dispatch({
                        type: "DESCRIPTION",
                        payload: e.target.value,
                      });
                    }}
                    inputProps={{
                      minLength: FACTS_DESCRIPTION_MANDATORY_CHARACTERS,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    sx={{ maxWidth: "400px" }}
                    error={roboState.phoneContact.length < 10}
                  >
                    ¿A qué otro número te podemos contactar?
                  </FormLabel>
                  <TextField
                    type="number"
                    helperText={"Ingresa 10 dígitos"}
                    error={roboState.phoneContact.length < 10}
                    sx={{ width: "100%" }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onChange={(e) => {
                      startTimer();
                      dispatch({
                        type: "SET_SECOND_PHONE",
                        payload: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <SuccessRoboReportModal
                  openModal={secondModal}
                  idReport={reportID}
                  nameAsegurado={nameAsegurado}
                  lastNameAsegurado={lastNameAsegurado}
                  userUID={userUID}
                />
                <FailedRoboReportModal
                  openModal={failedModal}
                  whatsappUrl={whatsappUrl}
                />
                <LoadingButton
                  type="submit"
                  loading={loading}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  component="button"
                  onClick={handleForm}
                >
                  Generar Reporte
                </LoadingButton>
              </Stack>
            </>
          )}

          <Dialog
            isOpen={openModal}
            onClose={handleOpenModal}
            content={<Typography>{contentModal}</Typography>}
            Actions={
              <Stack spacing={2} direction="row" justifyContent="center">
                <a
                  href="tel:911"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      console.log("hola");
                    }}
                    sx={{
                      textTransform: "initial",
                    }}
                  >
                    Llamar al 911
                  </Button>
                </a>
              </Stack>
            }
          />
        </Stack>
      )}
    </div>
  );
};

export default RoboTracking;
