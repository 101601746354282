import { useReducer } from "react";
import {
  Stack,
  Typography,
  TextField,
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Dialog from "../Components/Dialog";

import useQueryParams from "../hooks/useQueryParams";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  addDocument,
  getDocument,
  getDocuments,
  updateDocument,
} from "../Services/firestore";
import Swal from "sweetalert2";
import { postMessageInCustomerSupportChannel } from "../Services/slack";
import getEmergencyContactMessageTemplate from "../utils/getEmergencyContactMessageTemplate";
import fetchUser from "../Services/users/fetchUser";
import { addOrUpdateMember } from "../Services/mailchimp";
import { logError } from "../utils/index";

const initialState = {
  customer: {
    adress: "",
    apartment_number: "",
    emergency_contact_number: "",
    emergency_contact_name: "",
    coords: {},
    adress_details: "",
  },
  wantsToAddEmergencyContact: true,
  isConfirmationDialogOpen: false,
  isSaving: false,
};

const ACTIONS = {
  UPDATE_CUSTOMER: "update-customer",
  TOOGLE_WANTS_TO_ADD_EMERGENCY_CONTACT: "is-emergency-contact-section-visible",
  TOGGLE_CONFIRMATION_DIALOG_OPEN: "toggle-confirmation-dialog-open",
  TOGGLE_IS_SAVING: "toggle-is-saving",
  RESET_FORM: "reset-form",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
        },
      };
    case ACTIONS.TOOGLE_WANTS_TO_ADD_EMERGENCY_CONTACT:
      return {
        ...state,
        wantsToAddEmergencyContact: !state.wantsToAddEmergencyContact,
      };
    case ACTIONS.TOGGLE_CONFIRMATION_DIALOG_OPEN:
      return {
        ...state,
        isConfirmationDialogOpen: !state.isConfirmationDialogOpen,
      };
    case ACTIONS.TOGGLE_IS_SAVING:
      return {
        ...state,
        isSaving: !state.isSaving,
      };
    case ACTIONS.RESET_FORM:
      return initialState;
    default:
      console.warn(`${action.type} is not a valid action`);
      return state;
  }
}

const StickerForm = (props) => {
  const query = useQueryParams();
  const uid = query.get("uid");
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    customer,
    isConfirmationDialogOpen,
    wantsToAddEmergencyContact,
    isSaving,
  } = state;

  const {
    adress,
    apartment_number,
    emergency_contact_number,
    emergency_contact_name,
    coords,
    adress_details,
  } = customer;

  async function handleOnSubmit(e) {
    e.preventDefault();
    dispatch({ type: ACTIONS.TOGGLE_IS_SAVING });
    // do not allow empry string as value
    if (!Boolean(adress_details.trim())) {
      Swal.fire(
        "",
        "Ingresa las referencias o instrucciones especiales de tu envío",
        "warning"
      );
      return;
    }

    if (wantsToAddEmergencyContact) {
      if (!isValidPhoneNumber) {
        Swal.fire("", "Ingresa un número telefónico válido", "warning");
        return;
      }

      if (!Boolean(emergency_contact_name.trim())) {
        Swal.fire(
          "",
          "Ingresa el nombre de tu contacto de emergencia",
          "warning"
        );
        return;
      }
    }

    const data = {
      metadata: {
        adress: {
          ...adress,
          adress_details: adress_details.trim(),
          apartment_number: apartment_number.trim(),
          coords,
        },
        emergency_contact: {
          phoneNumber: emergency_contact_number,
          name: emergency_contact_name.trim(),
        },
      },
      items: [
        {
          name: "Etiqueta para vehículo",
          price: 0,
        },
      ],
      status: "paid",
      timestamp: Date.now(),
      totalAmount: 0,
    };

    try {
      // check the person the correct id
      const userDoc = await getDocument({ docPath: `users/${uid}` });

      const needsToCorrectUID =
        !userDoc.exists || (userDoc.exists && !Boolean(userDoc.doc.email));

      if (needsToCorrectUID) {
        const prospectDoc = await getDocument({
          docPath: `prospectsNew/${uid}`,
        });

        const email = prospectDoc.doc.email;
        const docsResponse = await getDocuments({
          collectionPath: "users",
          where: ["email", "==", email],
        })
          .then((response) => response.json())
          .then((docs) => docs);

        const correctUserDoc = docsResponse.docs[0];
        if (correctUserDoc) {
          const messageBody = getEmergencyContactMessageTemplate({
            extraContent: `Se corrigió el uid del usuario en mailChimp\n*UID Incorrecto*: ${uid}\n\n*UID Correcto*: ${correctUserDoc.id}`,
            user: { ...correctUserDoc.doc, uid: correctUserDoc.id },
            address: adress.description.trim(),
            instructions: adress_details,
            apartment_number: apartment_number.trim(),
          });

          await Promise.all([
            addOrUpdateMember({ email, uid: correctUserDoc.id }),
            addDocument(`users/${correctUserDoc.id}/purchases/`, data),
            updateDocument(`users/${correctUserDoc.id}`, {
              emergency_contact: {
                phoneNumber: emergency_contact_number,
                name: emergency_contact_name.trim(),
              },
            }),
            postMessageInCustomerSupportChannel(messageBody),
          ]);
        } else {
          await logError({
            error: `No se encontró el perfil correcto del asegurado con uid: ${uid}`,
            source: "Página para solicitar sticker",
            metadata: {
              incorrectUID: uid,
              prospectDoc,
              docsResponse,
            },
          });
        }
      } else {
        await addDocument(`users/${uid}/purchases/`, data);
        await updateDocument(`users/${uid}`, {
          emergency_contact: {
            phoneNumber: emergency_contact_number,
            name: emergency_contact_name.trim(),
          },
        });

        const user = await fetchUser(uid);
        const messageBody = getEmergencyContactMessageTemplate({
          user,
          address: adress.description.trim(),
          instructions: adress_details,
          apartment_number: apartment_number.trim(),
        });
        await postMessageInCustomerSupportChannel(messageBody);
      }

      Swal.fire(
        "",
        "Hemos recibido tu petición, nos pondremos en contacto contigo para brindarte detalles de tu envío.",
        "success"
      );
      dispatch({ type: ACTIONS.RESET_FORM });
    } catch (error) {
      console.log(error);
      dispatch({ type: ACTIONS.TOGGLE_IS_SAVING });
      Swal.fire("", "Algo salió mal, inténtalo nuevamente", "error");
    }
  }

  // use a curried function to handle multiple inputs
  function handleOnCustomerChange(property, conditional = undefined) {
    return (e) => {
      if (!conditional) {
        dispatch({
          type: ACTIONS.UPDATE_CUSTOMER,
          payload: { [property]: e.target.value },
        });
        return;
      }
      if (conditional(e)) {
        return dispatch({
          type: ACTIONS.UPDATE_CUSTOMER,
          payload: { [property]: e.target.value },
        });
      }
    };
  }

  const {
    suggestions: {
      data: placesData,
      status: placesQueryStatus,
      loading: isPlacesOptionsLoading,
    },
    setValue: setPlacesQuery,
    // init,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["mx"] },
      types: ["geocode", "establishment"],
    },
    debounce: 300,
  });

  // // // import google maps library
  // useScript({
  //   src: `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDnZvA3t3TGaPq3XAxgE0zjsYZE28hzjAU"}&libraries=places`,
  //   onload: () => {
  //     init();
  //   },
  //   checkForExisting: true,
  // });

  async function getLangAndLng(placeId) {
    if (placeId) {
      try {
        const results = await getGeocode({ placeId });
        const { lat: latitude, lng: longitude } = await getLatLng(results[0]);
        return { latitude, longitude };
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function handleOnCustomerAdressInputChange(event, newValue) {
    if (newValue) {
      const { latitude, longitude } = await getLangAndLng(newValue.place_id);

      dispatch({
        type: ACTIONS.UPDATE_CUSTOMER,
        payload: {
          adress: newValue,
          coords: {
            latitude,
            longitude,
          },
        },
      });
    }
  }

  const isOptionEqualToValue = (option, value) =>
    option.description === value.description;

  const getPlaceOptionLabel = (option) => option.description || "";

  const customerAdressInputOptions =
    placesQueryStatus === "OK"
      ? placesData.map(({ description, place_id }) => ({
          description,
          place_id,
        }))
      : [];

  // console.log(placesQueryStatus);

  const isValidPhoneNumber =
    emergency_contact_number.length === 10 &&
    /\d{10}/.test(emergency_contact_number);

  function handleOnWantsToAddEmergencyContactChange(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      return toggleConfirmationDialog();
    }

    dispatch({ type: ACTIONS.TOOGLE_WANTS_TO_ADD_EMERGENCY_CONTACT });
  }

  function toggleConfirmationDialog() {
    dispatch({
      type: ACTIONS.TOGGLE_CONFIRMATION_DIALOG_OPEN,
    });
  }

  function handleNoEmergencyContactConfirmation() {
    toggleConfirmationDialog();
    dispatch({ type: ACTIONS.TOOGLE_WANTS_TO_ADD_EMERGENCY_CONTACT });
  }
  return (
    <Stack sx={{ p: 4 }} spacing={2}>
      <Typography variant="subtitle1">
        Solicita tu estampa de emergencia (6x6 cm), para que puedas recibir
        asistencia con mayor facilidad en caso de emergencia. Puedes solicitarla
        a tu domicilio, oficina o alguna otra dirección de tu conveniencia.
      </Typography>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        onSubmit={handleOnSubmit}
      >
        <Typography variant="h6">Información de envío</Typography>
        <Stack spacing={0.5}>
          <Typography variant="subtitle2">Dirección de entrega</Typography>
          <Autocomplete
            loading={isPlacesOptionsLoading}
            value={adress}
            disablePortal
            onInputChange={(_, value, reason) => {
              if (reason === "input") {
                setPlacesQuery(value);
              }
            }}
            onChange={handleOnCustomerAdressInputChange}
            options={customerAdressInputOptions}
            getOptionLabel={getPlaceOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (
              <TextField {...params} required size="small" label={null} />
            )}
            clearText="Borrar dirección"
            openText="Abrir menú de opciones"
            closeText="Cerrar"
            loadingText="Cargando..."
            noOptionsText="Escribe tu dirección"
          />
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="subtitle2">
            Referencias o instrucciones especiales
          </Typography>
          <TextField
            required
            autoComplete="off"
            value={adress_details}
            onChange={handleOnCustomerChange("adress_details")}
            size="small"
          />
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="subtitle2">
            Número interior (Opcional)
          </Typography>
          <TextField
            autoComplete="off"
            value={apartment_number}
            onChange={handleOnCustomerChange("apartment_number")}
            size="small"
          />
        </Stack>

        <Typography variant="h6">Contacto de emergencia</Typography>
        <Typography variant="subtitle1">
          Te recomendamos ampliamente agregar un contacto de emergencia. Esto
          para contactar a dicha persona en caso de que en un accidente nos
          intentemos comunicar contigo sin éxito. No contactaremos a esta
          persona para ningún otro tema distinto a una emergencia de tránsito.
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={!wantsToAddEmergencyContact}
              onChange={handleOnWantsToAddEmergencyContactChange}
            />
          }
          label="No deseo agregar un contacto de emergencia"
        />

        <Dialog
          title="¿Confirmas que no deseas proporcionar un contacto de emergencia?"
          isOpen={isConfirmationDialogOpen}
          Actions={
            <Stack spacing={2} sx={{ width: "100%", pb: 2, px: 2 }}>
              <Button
                sx={{ textTransform: "none" }}
                variant="outlined"
                color="inherit"
                onClick={toggleConfirmationDialog}
              >
                No, cancelar
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                color="error"
                disableElevation
                onClick={handleNoEmergencyContactConfirmation}
              >
                Si, no quiero agregar un contacto
              </Button>
            </Stack>
          }
        />

        <Collapse orientation="vertical" in={wantsToAddEmergencyContact}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Número</Typography>
            <TextField
              required={wantsToAddEmergencyContact}
              value={emergency_contact_number}
              onChange={handleOnCustomerChange(
                "emergency_contact_number",
                (e) => e.target.value.length <= 10
              )}
              size="small"
              inputMode="tel"
              type="tel"
              autoComplete="tel"
              helperText={
                emergency_contact_number.length > 1
                  ? !isValidPhoneNumber
                    ? "Ingresa un número telefónico válido"
                    : ""
                  : "Ingresa un número a 10 dígitos"
              }
              error={emergency_contact_number.length > 1 && !isValidPhoneNumber}
            />
          </Stack>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Nombre</Typography>
            <TextField
              required={wantsToAddEmergencyContact}
              value={emergency_contact_name}
              onChange={handleOnCustomerChange("emergency_contact_name")}
              size="small"
              inputMode="text"
              type="text"
              autoComplete="name"
              error={emergency_contact_name.length > 1 && !isValidPhoneNumber}
            />
          </Stack>
        </Collapse>

        <Stack alignItems="flex-end">
          <LoadingButton
            loading={isSaving}
            type="submit"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            color="primary"
            disableElevation
          >
            Continuar
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};

export default StickerForm;
