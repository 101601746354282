const ENDPOINT =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/collection";

function fetchVehicleByPlates(plates) {
  const url = new URL(ENDPOINT);

  url.searchParams.append("collectionPath", "vehicles");
  url.searchParams.append("groupQuery", true);

  url.searchParams.append("limit", 1);

  url.searchParams.append("where", "plates");
  url.searchParams.append("where", "==");
  url.searchParams.append("where", plates);

  let vehicle = null,
    error = null;

  return fetch(url)
    .then((response) => response.json())
    .then(({ docs }) => {
      // const docs = json.docs;
      if (docs.length === 1) {
        vehicle = { ...docs[0].doc, vehicleId: docs[0].id };
        return { vehicle, error };
      }
      error = "not/found";
      return { vehicle, error };
    })
    .catch((error) => {
      return { vehicle, error: "server-error" };
    });
}

export default fetchVehicleByPlates;
