import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import UserContex from "../User/UserContex";
import { getTypeName } from "../utils/getDeviceName";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import { isSameDay } from "date-fns";
import LocationInput from "./LocationInput";
import { LocalPhone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import SuccessRoboReportModal from "./SuccessRoboReportModal";
import { ClaveReport } from "../utils";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import getDeviceData from "../utils/getDeviceData";

const GADGET_REPORT_TYPES = {
  cellphoneTheft: "Robo",
  "screen-breakage": "Rotura de pantalla",
  breakdown: "Falla o descompostura",
  "accidental-damage":
    "Daños por variación de voltaje, mojadura, incendio y/o explosión.",
};

const EVENT_OPTIONS = Object.entries(GADGET_REPORT_TYPES).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const REPORT_MAX_DATE = new Date();

const REPORT_MAX_TIME = new Date();

const FACTS_DESCRIPTION_MANDATORY_CHARACTERS = 200;

function getReportTimestamp({ date, time }) {
  if (!(date instanceof Date)) {
    return Date.now();
  }
  if (!(time instanceof Date)) {
    return Date.now();
  }

  let reportDate = new Date(date);
  reportDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

  return reportDate.getTime();
}

export default function GadgetReportForm(props) {
  const { gadgets, userLocation, ...state } = useContext(UserContex);
  const [{ reportUID, ...form }, setForm] = useState({
    factsDescription: "",
    "selected-gadget": "",
    "event-type": "",
    date: null,
    time: null,
    "alternate-phone-number": "",
    address: {
      description: "",
      place_id: "",
    },
    coords: {
      lat: null,
      lng: null,
    },
    postalCode: "",
    violentEvent: "",
    isInIncidentLocation: undefined,
    startReportTimestamp: Date.now(),
    loading: false,
    reportUID: ClaveReport("C"),
  });
  const setFormValue = (key, value) =>
    setForm((prev) => ({ ...prev, [key]: value }));

  useState(() => {
    if (gadgets.length === 1) {
      setFormValue("selected-gadget", gadgets[0].id);
    }
  }, [gadgets]);

  const isInIncidentLocation = form["isInIncidentLocation"] === "yes";

  const selectedGadget = gadgets.find(
    (gadget) => gadget.id === form["selected-gadget"]
  );

  const reportTimestamp = getReportTimestamp({
    date: form["date"],
    time: form["time"],
  });
  const report = {
    accidentAddress: isInIncidentLocation
      ? userLocation.address
      : form["address"].description,
    accidentLocation: {
      lat: isInIncidentLocation ? userLocation.lat : form["coords"].lat,
      lon: isInIncidentLocation ? userLocation.lon : form["coords"].lng,
    },
    addressZipcode: isInIncidentLocation
      ? userLocation.postalCode
      : form["postalCode"],
    currentZipcode: userLocation.postalCode,
    currentLocation: {
      lat: userLocation.lat,
      lon: userLocation.lon,
    },
    currentAddress: userLocation.address,
    factsDescription: form["factsDescription"],
    isActive: true,
    isMock: 0,
    isViolentEvent: form["violentEvent"] === "yes",
    phoneContact: form["alternate-phone-number"].trim(),
    reportTimestamp: Date.now(),
    accidentTimestamp: reportTimestamp,
    source: "app",
    startReportTimestamp: form["startReportTimestamp"],
    type: [form["event-type"]],
    user: state.iduser,
    userReport: state.iduser,
    smartphone: form["selected-gadget"],
    smartphoneMake: selectedGadget?.brand,
    smartphoneModel: selectedGadget?.model,
    deviceReport: getDeviceData(),
  };
  const [success, setSuccess] = useState(false);

  async function handleOnSubmit(e) {
    e.preventDefault();

    try {
      setFormValue("loading", true);
      await setDoc(
        doc(db, `users/${state.iduser}/claims/${reportUID}`),
        report
      );
      setSuccess(true);
    } catch (error) {
      console.error("Error creating report", error);
    }
    setFormValue("loading", false);
  }

  const isInIncidentLocationInputLabel =
    form["event-type"] === "cellphoneTheft"
      ? "¿Te encuentras en el lugar del robo?"
      : "¿Te encuentras en el lugar del incidente?";

  const directionInputLabel =
    form["event-type"] === "cellphoneTheft"
      ? "¿Dónde ocurrió el robo?"
      : "¿Dónde ocurrió el incidente?";

  function handleIsInIncidentLocationChange(e) {
    setFormValue("isInIncidentLocation", e.target.value);
  }
  return (
    <>
      <SuccessRoboReportModal
        idReport={reportUID}
        nameAsegurado={state.nameAsegurado}
        lastNameAsegurado={state.lastAsegurado}
        userUID={state.iduser}
        openModal={success}
      />

      <form onSubmit={handleOnSubmit} style={{ width: "80%" }}>
        <Stack gap={4} pb={4}>
          <Stack gap={1}>
            <Typography>¿A cuál dispositivo?</Typography>
            <Select
              value={form["selected-gadget"]}
              onChange={(e) => setFormValue("selected-gadget", e.target.value)}
              id="gadget-report-form"
              fullWidth
              required
            >
              {gadgets.map((gadget) => {
                const gadgetType = capitalizeFirstLetter(
                  getTypeName(gadget.type)
                );

                const display = `${gadgetType} - ${gadget.brand} ${gadget.model}`;
                return (
                  <MenuItem
                    sx={{ whiteSpace: "normal" }}
                    key={gadget.id}
                    value={gadget.id}
                  >
                    {display}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack gap={1}>
            <Typography>¿Qué le pasó?</Typography>
            <Select
              fullWidth
              required
              value={form["event-type"]}
              onChange={(e) => setFormValue("event-type", e.target.value)}
            >
              {EVENT_OPTIONS.map((event) => {
                return (
                  <MenuItem
                    sx={{ whiteSpace: "normal" }}
                    key={event.value}
                    value={event.value}
                  >
                    {event.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>

          <Stack gap={2}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <Typography>¿Cuándo ocurrió el incidente?</Typography>
              <Stack
                direction={["column", "row"]}
                gap={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <DatePicker
                  sx={{ width: "100%" }}
                  label="Fecha"
                  maxDate={REPORT_MAX_DATE}
                  value={form["date"]}
                  onChange={(date) => setFormValue("date", date)}
                />
                <TimePicker
                  ampm
                  disableFuture={isSameDay(form["date"], REPORT_MAX_TIME)}
                  sx={{ width: "100%" }}
                  label="Hora"
                  value={form["time"]}
                  onChange={(date) => setFormValue("time", date)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>

          <Stack gap={2}>
            <Typography>{isInIncidentLocationInputLabel}</Typography>

            <FormControl>
              <RadioGroup
                value={form["isInIncidentLocation"]}
                onClick={handleIsInIncidentLocationChange}
                required
              >
                <FormControlLabel value="yes" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Stack>
          {form["isInIncidentLocation"] === "no" && (
            <Stack gap={2}>
              <Stack>
                <Typography>{directionInputLabel}</Typography>
                <Typography variant="caption">
                  La dirección debe tener calle, número, colonia, ciudad y
                  estado. Ejemplo: Reforma 222, Avenida Paseo de la Reforma,
                  Juárez, Ciudad de México, CDMX, México
                </Typography>
              </Stack>
              <LocationInput
                value={form["address"]}
                onChange={(_, { address, coords, postalCode }) => {
                  console.log("LocationInput", address, coords, postalCode);
                  setFormValue("address", address);
                  setFormValue("coords", coords);
                  setFormValue("postalCode", postalCode);
                }}
              />
            </Stack>
          )}

          {form["event-type"] === "cellphoneTheft" && (
            <>
              <Stack gap={2}>
                <Stack value={form["violentEvent"]}>
                  <Typography>¿Los hechos fueron con violencia?</Typography>
                  <Typography variant="caption">
                    Es decir, el asaltante te amagó con un arma
                  </Typography>
                </Stack>

                <FormControl>
                  <RadioGroup
                    value={form["violentEvent"]}
                    onClick={(e) => {
                      setFormValue("violentEvent", e.target.value);
                    }}
                    required
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Sí"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </>
          )}

          <Stack gap={2}>
            <Stack>
              <Typography>
                Por favor, describe los hechos lo más detallado posible.
              </Typography>
              <Typography variant="caption">
                Describe dónde estabas, qué hacías, si te ocurrió a ti y cómo
                fue exactamente
              </Typography>
            </Stack>
            <TextField
              required
              multiline
              minRows={3}
              value={form["factsDescription"]}
              error={
                form["factsDescription"].length > 0 &&
                form["factsDescription"].length <
                  FACTS_DESCRIPTION_MANDATORY_CHARACTERS
              }
              inputProps={{
                minLength: FACTS_DESCRIPTION_MANDATORY_CHARACTERS,
              }}
              onChange={(e) => setFormValue("factsDescription", e.target.value)}
              sx={{
                "& .MuiFormHelperText-root": {
                  marginX: 0,
                },
              }}
              helperText={
                <Stack direction="row" gap={1} justifyContent="space-between">
                  <span>
                    Mínimo {FACTS_DESCRIPTION_MANDATORY_CHARACTERS} caracteres
                  </span>
                  <span>
                    {form["factsDescription"].length}/
                    {FACTS_DESCRIPTION_MANDATORY_CHARACTERS}
                  </span>
                </Stack>
              }
            />
          </Stack>

          <Stack gap={2}>
            <Typography>¿A qué otro número te podemos contactar?</Typography>
            <TextField
              required
              value={form["alternate-phone-number"]}
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  return;
                }
                setFormValue("alternate-phone-number", e.target.value);
              }}
              InputProps={{
                inputMode: "tel",
                type: "tel",
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhone />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <LoadingButton
            loading={form.loading}
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            type="submit"
          >
            Generar reporte
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
