import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { Close } from "@mui/icons-material";

const Dialog = ({
  isOpen,
  Actions,
  textContent,
  content,
  onClose,
  title,
  maxWidth = "sm",
  sx ={
    zIndex: "1050",
    margin: "0",
    width: "auto",
    "& .MuiPaper-root": {
      margin: 2,
      padding: 2,
      width: "calc(100% - 14px)",
    },
  },
  ...rest
}) => (
  <MUIDialog
    {...rest}
    maxWidth={maxWidth}
    fullWidth
    open={isOpen}
    onClose={onClose}
    sx={{
      ...sx,
    }}
  >
    <DialogTitle>
      {title}
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{ ml: "auto", position: "absolute", top: "10px", right: "10px" }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
    {Boolean(textContent) && (
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", margin: "5px" }}>
          {textContent}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogContent sx={{ overflow: "scroll" }}>
      {Boolean(content) && content}
    </DialogContent>
    <DialogActions sx={{ justifyContent: "center" }}>{Actions}</DialogActions>
  </MUIDialog>
);

export default Dialog;
