const ACTIONS = {
  RESET_FORM: "RESET_FORM",
  FETCH_CUSTOMER: "FETCH_CUSTOMER",
  FETCH_CUSTOMER_SUCCESS: "FETCH_CUSTOMER_SUCCESS",
  FETCH_CUSTOMER_ERROR: "FETCH_CUSTOMER_ERROR",
  TOGGLE_REQUIRES_INVOICES: "TOGGLE_REQUIRES_INVOICES",
  UPDATE_FORM_FIELD: "UPDATE_FORM_FIELD",
  TOGGLE_IS_SAVING: "TOGGLE_IS_SAVING",
};

const initialValue = {
  isLoading: true,
  isSaving: false,
  customer: undefined,
  // customer form info
  requiresInvoices: false,
  rfc: "",
  name: "",
  firstName: "",
  lastName: "",
  address: "",
  fiscalRegime: "",
  fiscalRegimeCode: "",
  usage: "",
  usageCode: "",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FETCH_CUSTOMER_SUCCESS: {
      const { customer, lastInvoiceProfile } = action.payload;
      return {
        ...state,
        customer,
        hasInvoiceProfile: Boolean(lastInvoiceProfile),
        isLoading: false,
        // update form data with the last invoice data or the existing customer data
        firstName: lastInvoiceProfile
          ? lastInvoiceProfile.firstName
          : customer.firstName || "",
        lastName: lastInvoiceProfile
          ? lastInvoiceProfile.lastName
          : customer.lastName || "",
        rfc: lastInvoiceProfile ? lastInvoiceProfile.rfc : customer.rfc || "",
        requiresInvoices: Boolean(customer.requiresInvoices),

        address: lastInvoiceProfile?.address || "",
        fiscalRegime: lastInvoiceProfile ? lastInvoiceProfile.fiscalRegime : "",
        usage: lastInvoiceProfile?.usage || "",
      };
    }

    case ACTIONS.FETCH_CUSTOMER_ERROR: {
      return {
        ...state,
        isLoading: false,
        customer: undefined,
      };
    }

    case ACTIONS.UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.update,
      };

    case ACTIONS.TOGGLE_REQUIRES_INVOICES:
      return {
        ...state,
        requiresInvoices: !state.requiresInvoices,
      };
    case ACTIONS.TOGGLE_IS_SAVING:
      return {
        ...state,
        isSaving: !state.isSaving,
      };
    case ACTIONS.RESET_FORM:
      return initialValue;
    default:
      return new Error(`${action.type} is not a valid action`);
  }
}

export { ACTIONS, reducer, initialValue };
