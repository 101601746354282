import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MobileHeader from "../../Components/MobileHeader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import useExtraInsurances from "../../hooks/useExtraInsurances";
import getTypeImgAndTitle from "../../utils/getExtraInsImgAndTitle";

import Swal from "sweetalert2";
import useUser from "../../hooks/useUser";

const ExtraInsuranceList = ({}) => {
  const navigate = useNavigate();
  const [cardDropdown, setCardDropdown] = useState(null);
  const { user } = useUser();
  const { extraInsurances, isLoadingInsurances, fetchData } = useExtraInsurances();

  const handleDelete = async (insurance) => {
    Swal.fire({
      text: `¿Estás seguro de querer eliminar la información de tu ${getTypeImgAndTitle(
        {
          type: insurance.type,
        }
      ).title.toLowerCase()}?`,
      showCancelButton: true,
      confirmButtonColor: "red",
      denyButtonColor: "#cecece",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const insuranceRef = await doc(
          db,
          `users/${user.uid}/insurances/${insurance.id}`
        );
        setDoc(insuranceRef, { deleted: true }, { merge: true });
        fetchData();
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          title: "Seguro eliminado",
        });
      }
    });
  };

  return user ? (
    <>
      <MobileHeader user={user} showGradientHeader={false} />
      <Stack
        sx={{
          position: "relative",
          height: "88svh",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            padding: "20px 20px 0px 20px",
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          Mis seguros
        </Typography>
        <Box
          sx={{
            margin: "0 auto",
            width: "100%",
            height: "73vh",
            maxHeight: "73vh",
            overflowY: "scroll",
            flexGrow: "1",
          }}
        >
          {extraInsurances && extraInsurances.length > 0 ? (
            extraInsurances.map((insurence, i) => (
              <Card
                key={`${insurence.id}`}
                sx={{
                  margin: `0px 20px ${
                    extraInsurances.length - 1 === i ? "95px" : "20px"
                  } 20px`,
                  borderRadius: "8px",
                  minHeight: "220px",
                  border: "1px solid #cecece",
                  boxShadow: 3,
                  position: "relative",
                }}
              >
                {!insurence.isFromClupp && (
                  <>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                        p: 0,
                      }}
                      id={`${insurence.id}`}
                      onClick={(e) => {
                        setCardDropdown(e.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      autoFocus={false}
                      anchorEl={cardDropdown}
                      open={Boolean(
                        cardDropdown && `${insurence.id}` === cardDropdown.id
                      )}
                      onClose={() => setCardDropdown(null)}
                    >
                      <MenuItem
                        onClick={() =>
                          navigate(
                            `/agregar-seguro?uid=${user.uid}&ins=${insurence.id}`
                          )
                        }
                      >
                        Editar seguro
                      </MenuItem>
                      <MenuItem
                        sx={{ color: "red" }}
                        onClick={() => handleDelete(insurence)}
                      >
                        Eliminar seguro
                      </MenuItem>
                    </Menu>
                  </>
                )}

                <Stack
                  sx={{
                    marginTop: "10px",
                    p: 2,
                  }}
                >
                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {`${getTypeImgAndTitle({type: insurence.type}).title}`}
                    </Typography>
                    {getTypeImgAndTitle({
                      type: insurence.type,
                      cluppType: insurence.cluppType,
                    }).img && (
                      <img
                        src={
                          getTypeImgAndTitle({
                            type: insurence.type,
                            cluppType: insurence.cluppType,
                          }).img
                        }
                        style={{
                          maxWidth: "40px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    )}
                  </Stack>
                  <Stack sx={{ p: 0 }}>
                    <Typography variant="subtitle1">
                      Seguro de
                      {` ${insurence.insuranceCompany}`}
                    </Typography>
                    {insurence.name && (
                      <Typography variant="subtitle1">
                        <strong>Nombre: </strong>
                        {` ${insurence.name}`}
                      </Typography>
                    )}
                    <Typography variant="subtitle1">
                      <strong>Vigencia: </strong>
                      {`${format(
                        new Date(insurence.insuranceStartTimestamp),
                        "dd/MM/yyyy"
                      )} - ${format(
                        new Date(insurence.insuranceEndTimestamp),
                        "dd/MM/yyyy"
                      )}`}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    marginBottom: "10px",
                    padding: "0px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    disabled={!insurence?.policyURL}
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      marginLeft: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <InsertDriveFileIcon />
                    <a
                      style={{
                        color: insurence?.policyURL ? "#00bbee" : "#bdbdbd",
                        textTransform: "none",
                        textDecoration: "none",
                      }}
                      href={`${
                        insurence.policyURL ? insurence.policyURL : "#"
                      }`}
                      download={`póliza-${insurence.name}.pdf`}
                    >
                      {insurence.isFromClupp ? "Ver carátula" : "Ver póliza"}
                    </a>
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      color: "red",
                    }}
                  >
                    <PhoneInTalkIcon />
                    <a
                      style={{
                        color: "red",
                        textTransform: "none",
                        textDecoration: "none",
                      }}
                      href={`tel:${insurence.phoneContact}`}
                    >
                      Reportar siniestro
                    </a>
                  </Button>
                </Stack>
              </Card>
            ))
          ) : extraInsurances &&
            extraInsurances.length === 0 &&
            !isLoadingInsurances ? (
            <>
              <Typography variant="subtitle1" padding="5px 20px 0px 20px">
                Seguro de auto, gastos médicos, y hasta mascotas.
              </Typography>
              <Typography
                variant="subtitle1"
                padding="5px 20px 0px 20px"
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Cotiza o registra cualquier seguro de cualquier aseguradora para
                tener a un clic la información de cualquiera de ellos.
              </Typography>
              <Typography variant="subtitle1" padding="5px 20px 0px 20px">
                Solo da clic en agregar seguro.
              </Typography>
            </>
          ) : (
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Stack>
          )}
          <Box
            sx={{
              margin: "20px auto 0px auto",
              position: "absolute",
              backgroundColor: "#fff",
              height: "70px",
              width: "100%",
              bottom: "0",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "10",
              boxShadow: "0 -5px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Button
              onClick={() => navigate(`/tipos-de-seguros?uid=${user.uid}`)}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                fontSize: "18px",
                width: "90%",
                marginTop: "15px",
              }}
            >
              + Agregar Seguro
            </Button>
          </Box>
        </Box>
      </Stack>
    </>
  ) : (
    <></>
  );
};
export default ExtraInsuranceList;
