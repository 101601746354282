import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useRef, useState } from "react";
import { Stack, TextField, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { sendVerificationCode } from "../Services/users/Auth";

function getLastTwoLetters(str) {
  if (str.length >= 2) {
    return str.substring(str.length - 2);
  } else {
    return str; // If the string has less than 2 characters, return the whole string.
  }
}

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer({
  open,
  setOpen,
  number,
  window,
  onSubmit = () => {},
  isLoading,
}) {
  const theme = useTheme();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const [inputValues, setInputValues] = useState(Array(6).fill(""));

  const handleInput = (event, index) => {
    const { value } = event.target;

    if (value.trim().length === 6) {
      setInputValues(value.split(""));
      inputRefs[inputRefs.length - 1].current?.focus();
      return;
    }

    if (value.length > 1) {
      // Truncate value to a single character if needed
      event.target.value = value.slice(0, 1);
    }

    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value.length >= 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  function handlerOnSubmit(e) {
    e.preventDefault();
    if (inputValues.includes("")) {
      Swal.fire({
        icon: "warning",
        text: "Por favor, ingresa el código completo",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
    } else {
      onSubmit(e, inputValues.join(""));
    }
  }

  async function handleSendVerificationCode() {
    await sendVerificationCode({
      phone: number,
    });

    Swal.fire({
      icon: "success",
      text: "Se ha enviado un nuevo código de verificación. Revisa tu WhatsApp",
      confirmButtonText: "Ok",
      confirmButtonColor: theme.palette.primary.main,
    });
  }

  function handleKeyUp(e, inputIndex) {
    // Focus the previous input if the user pressed Backspace
    if (e.keyCode === 8 && e.target.value.trim() === "") {
      const previousInput = inputRefs[inputIndex - 1];

      if (previousInput !== undefined) {
        previousInput.current.focus();
      }
    }
  }

  function handleOnPaste(e) {
    e.preventDefault();
    e.stopPropagation();
    const paste = (e.clipboardData || window.clipboardData)
      .getData("Text")
      .replace(/[^\x20-\xFF]/gi, "");
    if (String(paste.trim()).length === 6) {
      setInputValues(paste.split(""));
      inputRefs[inputRefs.length - 1].current.focus();
      return;
    }
  }

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox>
          <Puller />
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            marginTop: "18px",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Stack
            sx={{
              placeSelf: "flex-start",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: 1,
            }}
          >
            <Typography
              sx={{ color: "black", lineHeight: "34px" }}
              variant="h6"
            >
              Ingresa el código de confirmación
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "black", lineHeight: "26px" }}
            >
              (Hemos enviado un mensaje vía WhatsApp al número {number} )
            </Typography>
            <Typography variant="body2" sx={{ color: "black" }}>
              ¿No has recibido el código?{" "}
              <a
                style={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={handleSendVerificationCode}
              >
                Reenviar código
              </a>
            </Typography>
          </Stack>
          <form
            onSubmit={handlerOnSubmit}
            style={{ display: "grid", gap: "18px" }}
          >
            <Stack
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: "18px",
                maxWidth: "380px",
                margin: "0 auto",
              }}
            >
              {inputRefs.map((inputRef, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  type="number"
                  autoComplete="off"
                  autoCorrect="off"
                  inputRef={inputRef}
                  value={inputValues[index]}
                  onChange={(e) => handleInput(e, index)}
                  onKeyUp={(e) => handleKeyUp(e, index)}
                  onPaste={handleOnPaste}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center" }, // Optional: Center the text in the TextField
                  }}
                />
              ))}
            </Stack>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{
                textTransform: "none",
                maxWidth: "380px",
                margin: "0 auto",
              }}
              onClick={handlerOnSubmit}
              loading={isLoading}
              type="submit"
            >
              Confirmar
            </LoadingButton>
          </form>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

export default SwipeableEdgeDrawer;
