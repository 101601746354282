import { useEffect, useState } from "react";
import { getDocument } from "../Services/firestore";
import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  Accordion,
  Paper,
  Button,
  TextField,
  Box,
  useTheme,
  Divider,
  IconButton,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useQueryParams from "../hooks/useQueryParams";
import SearchIcon from "@mui/icons-material/Search";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddCardIcon from "@mui/icons-material/AddCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import logoclup from "../Assets/logo-clupp.png";
import cluppcitoFestejoIcon from "../Assets/Clupp_Festejo.png";
import cluppcitoTristeIcon from "../Assets/Clupp_Triste.png";
import format from "date-fns/format";
import es from "date-fns/locale/es";
import CircularProgress from "@mui/material/CircularProgress";
import PaidIcon from "@mui/icons-material/Paid";

const INVOICE_STATUS = {
  draft: "Programado",
  paid: "Pagado",
  open: "Pendiente",
};
const CluppAdminAPI =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI";

async function getUserData(searchParam) {
  const regexEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  const isEmail = regexEmail.test(searchParam);
  const userRef = collection(db, "users");
  const q = query(
    userRef,
    where(isEmail ? "email" : "phone", "==", searchParam)
  );

  const docs = await getDocs(q);

  return docs.docs.map((item) => ({ ...item.data(), uid: item.id }));
}

function PaymentOptions() {
  const [number] = useState("5577460177");

  return (
    <>
      <Stack
        p={1}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          position: "fixed",
          width: "100%",
          background: "#fff",
          borderBottom: "1px solid #a3a3a3",
          zIndex: "1201",
        }}
      >
        <img
          src={logoclup}
          alt="clupp-logo"
          style={{
            width: "170px",
            height: "65px",
            objectFit: "scale-down",
          }}
        />
      </Stack>
      <Divider
        sx={{ marginBottom: "100px" }}
        orientation="horizontal"
        flexItem
      />
      {number && (
        <>
          <Typography
            variant={"h5"}
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              margin: "1.5em 0 3rem",
            }}
          >
            Conoce nuestras formas de pago
          </Typography>
          <Stack
            sx={{
              width: "100%",
              height: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Stack
              sx={{ width: "90%", maxWidth: "400px", marginBottom: "20px" }}
              spacing={2}
            >
              <CardOptionPayments number={number} />
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}

function CardOptionPayments({ number }) {
  const query = useQueryParams();
  const [uid, setUid] = useState(query.get("uid"));
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [isFetchingInvoices, setIsFetchingInvoices] = useState(false);
  const [isLoadingUpdatePayment, setIsLoadingUpdatePayment] = useState(false);
  const [expanded, setExpanded] = useState(true); // Set the first item to be expanded initially
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const OPTION_PAYMENTS = [
    {
      cardTitle: "Paga por transferencia",
      cardHeader: "Los datos para pago por transferencia son:",
      cardBody: [
        { bodyData: "Banco: STP" },
        { bodyData: "Clabe: 646090258693453549" },
        { bodyData: "Beneficiario: CLUPP MUTUALIDAD SC DE RL DE CV" },
      ],
      cardFooter: `Una vez que hayas realizado el pago, envíanos tu comprobante por WhatsApp al `,
    },
    {
      cardTitle: "Paga en efectivo",
      cardHeader:
        "Acude a realizar el pago a cualquier cajero o ventanilla de BBVA con los siguientes datos:",
      cardBody: [
        { bodyData: "Número de cuenta: 0116536727" },
        { bodyData: "Beneficiario: CLUPP MUTUALIDAD SC DE RL DE CV" },
      ],
      cardFooter: `Una vez que hayas realizado el pago, envíanos tu comprobante por WhatsApp al `,
    },
  ];

  function handleSearchUser(e) {
    e.preventDefault();
    if (emailOrPhone) {
      setIsFetchingUser(true);
      getUserData(emailOrPhone.trim().toLowerCase()).then((docs) => {
        const validateClientStatus = docs.filter((item) =>
          Boolean(item?.clientStatus)
        );
        if (
          docs.length > 0 &&
          [1, 2].includes(validateClientStatus[0]?.clientStatus)
        ) {
          setUser(validateClientStatus[0]);
          setUid(validateClientStatus[0].uid);
          navigate({
            pathname: "/formas-de-pago",
            search: `?uid=${validateClientStatus[0].uid}`,
          });
          setIsFetchingUser(false);
        } else if (validateClientStatus[0]?.clientStatus === 3) {
          setIsFetchingUser(false);
          Swal.fire({
            icon: "warning",
            text: "El seguro de tu vehículo se encuentra cancelado",
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
        } else {
          setIsFetchingUser(false);
          Swal.fire({
            icon: "warning",
            text: "No se ha encontrado la información del usuario",
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
        }
      });
    } else {
      Swal.fire({
        icon: "warning",
        text: "Por favor, ingresa tu correo o número telefónico para realizar la búsqueda de tu usuario.",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
    }
  }
  useEffect(() => {
    if (!user?.stripeId) return;

    async function fetchInvoices() {
      setIsFetchingInvoices(true);
      try {
        const URI = `${CluppAdminAPI}/customers/stripe/${user.stripeId}/invoices`;
        const response = await fetch(URI);

        if (response.ok) {
          const { invoices, upcomingInvoices: upcomingInvoice } =
            await response.json();

          let list = invoices.data.filter((invoice) => {
            const isOpen = invoice.status === "open";
            return isOpen;
          });

          if (list.length > 0) {
            list = [...list];
          } else if (upcomingInvoice) {
            list = [upcomingInvoice];
          }

          setInvoices(list);
          return;
        }
      } catch (error) {
        Swal.fire(
          "Ups! No pudimos obtener información de tus pagos",
          "Inténtalo nuevamente más tarde",
          "error"
        );
      } finally {
        setIsFetchingInvoices(false);
      }
    }
    fetchInvoices();
  }, [user?.stripeId]);

  useEffect(() => {
    if (!user && uid) {
      getDocument({
        docPath: `users/${uid}`,
      }).then((item) => {
        setUser(item?.doc);
        setIsLoading(false);
      });
    }
    if (!uid) setIsLoading(false);
  }, [uid]);

  function updatePaymentType() {
    setIsLoadingUpdatePayment(true);
    fetch(
      `https://clupp-api.web.app/v1/clupp/payments/session?uid=${uid}d&customer=${user?.stripeId}`
    )
      .then(async (data) => {
        console.log(data);
        const res = await data.json();
        if (data.ok) {
          window.location.href = res.url;
          // window.open(res.url)
        } else {
          Swal.fire({
            icon: "error",
            text: res?.message,
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: "Ok",
          });
        }
        // setIsLoadingUpdatePayment(false)
      })
      .catch((e) => {
        setIsLoadingUpdatePayment(false);
      });
  }

  return isLoading ? (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <CircularProgress />
    </Box>
  ) : user && user.sellerId === "Ndm5mv0XysZryOeQbdfX" ? (
    <Typography color="black" textAlign="center">
      Para temas relacionados con el pago de tu seguro, ponte en contacto con el
      servicio de atención a clientes de{" "}
      <Link to={"https://www.woowtodobien.com/"} target="_blank">
        WOOW
      </Link>
    </Typography>
  ) : (
    <>
      {user && (
        <Box
          sx={{
            marginBottom: "12px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Typography
            variant={"p"}
            sx={{ textAlign: "center", fontSize: "22px" }}
          >
            Hola, {user.firstName}
          </Typography>
        </Box>
      )}
      <Accordion elevation={6} sx={{ borderRadius: "6px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"p"}>Pago con tarjeta</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "grid", gap: "10px" }}>
          {user ? (
            <>
              <LoadingButton
                loading={isLoadingUpdatePayment}
                startIcon={<CreditCardIcon />}
                sx={{ textTransform: "none" }}
                variant="contained"
                fullWidth
                onClick={() => {
                  updatePaymentType();
                }}
              >
                Cambiar de tarjeta
              </LoadingButton>
              <LoadingButton
                loading={isLoadingUpdatePayment}
                startIcon={<AddCardIcon />}
                sx={{ textTransform: "none" }}
                variant="contained"
                fullWidth
                onClick={() => {
                  updatePaymentType();
                }}
              >
                Dar de alta una nueva tarjeta
              </LoadingButton>
              <LoadingButton
                loading={isLoadingUpdatePayment}
                startIcon={<CreditCardOffIcon />}
                sx={{ textTransform: "none" }}
                variant="contained"
                fullWidth
                onClick={() => {
                  updatePaymentType();
                }}
              >
                Eliminar una tarjeta existente
              </LoadingButton>
            </>
          ) : (
            <form onSubmit={handleSearchUser}>
              <Typography color="black">
                Ingresa tu correo electrónico o número teléfonico para consultar
                la información de pago.
              </Typography>
              <Box
                sx={{
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                }}
              >
                <TextField
                  placeholder="Ingresa tu número teléfonico o correo electrónico"
                  disabled={isFetchingUser}
                  onChange={(e) => {
                    setEmailOrPhone(e.target.value);
                  }}
                  fullWidth
                  size="small"
                />
                <LoadingButton
                  type="submit"
                  loading={isFetchingUser}
                  onClick={handleSearchUser}
                  variant="contained"
                  sx={{ height: "100%" }}
                >
                  <SearchIcon />
                </LoadingButton>
              </Box>
            </form>
          )}
        </AccordionDetails>
      </Accordion>

      {OPTION_PAYMENTS.map((option, index) => {
        return (
          <Accordion elevation={6} sx={{ borderRadius: "6px" }} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {option.cardTitle ? option.cardTitle : null}
              {/* <Typography variant={"p"}>{option.cardTitle}</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={3}>
                <Typography variant={"p"}>{option.cardHeader}</Typography>

                {option.cardBody && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {option.cardBody.map((body, index) => {
                      return (
                        <Typography variant={"p"}>{body.bodyData}</Typography>
                      );
                    })}
                  </div>
                )}
                <Typography variant={"p"}>
                  {option.cardFooter}
                  <a
                    style={{ color: "rgb(45, 219, 111)" }}
                    href={`https://wa.me/521${number}`}
                  >
                    {number}
                  </a>
                </Typography>
                {option.cardTitle === "Paga en efectivo" && (
                  <Button
                    startIcon={<LocationOnIcon />}
                    sx={{ textTransform: "none" }}
                    fullWidth
                    variant="contained"
                    onClick={() =>
                      window.open(
                        "https://www.google.com.mx/maps/search/cajero+bbva/",
                        "_blank"
                      )
                    }
                  >
                    Ver ubicaciones para pago 24/7
                  </Button>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {user && (
        <Accordion
          expanded={expanded}
          onChange={() => {
            setExpanded((prev) => !prev);
          }}
          elevation={6}
          sx={{ borderRadius: "6px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {isFetchingInvoices && (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "118px" }}
              />
            )}
            <Typography variant={"p"}>
              {!isFetchingInvoices
                ? invoices.length > 0 && invoices[0].status === "open"
                  ? "Pagos pendientes"
                  : "Próximo pago"
                : ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "grid", gap: "10px" }}>
            {isFetchingInvoices && (
              <Box
                sx={{ width: "100%", display: "grid", placeItems: "center" }}
              >
                <CircularProgress />
              </Box>
            )}
            <Box sx={{ display: "grid", gap: "20px", marginTop: "10px" }}>
              {!isFetchingInvoices &&
                invoices.map((item, index) => {
                  return <PaymentListItem key={index} invoice={item} />;
                })}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

const PaymentListItem = ({ invoice }) => {
  const [isPayment, setIsPaymet] = useState(false);
  const theme = useTheme();

  function handleDoPayment(invoiceId) {
    setIsPaymet(true);
    fetch("https://clupp-api.web.app/v1/clupp/payments/payment.pay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoiceId: invoiceId,
      }),
    })
      .then(async (data) => {
        const response = await data.json();
        if (data.ok) {
          Swal.fire({
            iconHtml: `<img src=${cluppcitoFestejoIcon} style="width: 76px;">`,
            text: response?.message,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
        } else {
          Swal.fire({
            iconHtml: `<img src=${cluppcitoTristeIcon} style="width: 76px;">`,
            text: response?.message,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Ok",
            confirmButtonColor: theme.palette.primary.main,
          });
        }
        setIsPaymet(false);
      })
      .catch((err) => {
        Swal.fire({
          iconHtml: `<img src=${cluppcitoTristeIcon} style="width: 76px;">`,
          text: err?.message,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        setIsPaymet(false);
      });
  }

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
      }}
    >
      <Stack spacing={1}>
        {invoice?.lines?.data.map((lineItem) => (
          <Typography variant="subtitle2" key={lineItem.id}>
            {lineItem.description}
          </Typography>
        ))}
      </Stack>

      <Stack direction={["column", "row"]} gap={[0, 2]}>
        <Typography variant="subtitle2">
          <b>Inicio de vigencia:</b>{" "}
          {format(
            new Date(invoice?.lines.data[0]?.period?.start * 1000),
            "dd 'de' MMMM 'de' yyyy",
            {
              locale: es,
            }
          )}
        </Typography>
      </Stack>
      <Stack direction={["column", "row"]} gap={[0, 2]}>
        <Typography variant="subtitle2">
          <b>Fin de vigencia:</b>{" "}
          {format(
            new Date(invoice?.lines.data[0]?.period?.end * 1000),
            "dd 'de' MMMM 'de' yyyy",
            {
              locale: es,
            }
          )}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle1" sx={{ fontWeight: 900, flex: 1 }}>
          $
          {invoice.total.toString().slice(0, -2).toLocaleString("en", {
            useGrouping: true,
          })}
        </Typography>
        <Typography sx={{ fontWeight: 700 }}>
          {INVOICE_STATUS[invoice.status]}
        </Typography>
      </Stack>
      {invoice?.status === "open" && (
        <Stack>
          <LoadingButton
            loading={isPayment}
            startIcon={<PaidIcon />}
            fullWidth
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => {
              handleDoPayment(invoice?.id);
            }}
          >
            Pagar con tarjeta guardada
          </LoadingButton>
        </Stack>
      )}
    </Paper>
  );
};

export default PaymentOptions;
