async function fetchCoupon({ uid, coupon, numberOfUses }) {
  const API = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAPIV1/v1/users/${uid}/coupon`;

  const url = new URL(API);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        coupon,
        numberOfUses,
      }),
    });
    const json = await response.json();
    if (!json.success) {
      throw new Error("Error generating cuopon");
    }
    return json 
  } catch (error) {
    console.log(error);
    throw new Error("Error generating cuopon");
  }
}

export default fetchCoupon;
