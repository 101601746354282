import { Typography, Box, useTheme, List, Button } from "@mui/material";
import { useState } from "react";
import LinkListItem from "./LinkListItem";
import LinkListImage from "./LinkListImage";
import CustomAccordion from "./CustomAccordion";
import Swal from "sweetalert2";

export default function EnlacesUtiles() {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [subExpanded, setSubExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubChange = (panel) => (event, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  const handleVerifyCenter = () => {
    Swal.fire({
      text: "Por favor, haga clic en 'Iniciar' y luego en 'Nuevo trámite'.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#00bbee",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "https://verificentros.sedema.cdmx.gob.mx/DVC/";
      }
    });
  };

  return (
    <main
      style={{
        padding: "10px 10px",
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          gap: "10px",
          width: "100%",
          maxWidth: "640px",
          margin: "0 auto",
        }}
      >
        <Typography variant="h5">Enlaces útiles</Typography>
        <Box sx={{ display: "grid", gap: "14px", marginTop: "6px" }}>
          <Typography color="black">
            Aquí encontrarás algunos enlaces que te podrían ser de utilidad
          </Typography>
          <LinkListItem
            text="Conoce por qué Clupp es único"
            href="https://clupp.com.mx/blog/clupp-un-seguro-unico-en-mexico/?utm_source=app&utm_campaign=enlaces-utiles&utm_medium=enlaces-utiles"
          />
          <List>
            <Box sx={{ marginBottom: "10px" }}>
              <Typography color="black">Selecciona tu estado:</Typography>
            </Box>
            <Box sx={{ display: "grid" }}>
              <CustomAccordion
                expanded={expanded === "panel1"}
                handleChange={handleChange("panel1")}
                summary="CDMX"
                details={
                  <>
                    <LinkListItem
                      text="Consulta si tienes multas de tránsito"
                      href="https://data.finanzas.cdmx.gob.mx/consulta_adeudos"
                    />
                    <LinkListItem
                      text="¿Qué hacer si te detienen mientras conduces en CDMX?"
                      href="https://clupp.com.mx/blog/que-hacer-si-te-detienen-mientras-conduces-en-cdmx/"
                    />
                    <LinkListItem
                      text="Apps para pagar parquímetros en CDMX"
                      href="https://clupp.com.mx/blog/apps-parquimetros-cdmx/"
                    />
                    <LinkListItem
                      text="Sticker QR de emergencia"
                      href="https://clupp.com.mx/blog/sticker-con-qr-de-emergencia-para-reportar-accidentes-clupp/?utm_source=app&utm_campaign=enlaces-utiles&utm_medium=enlaces-utiles"
                    />
                    <LinkListItem
                      text="Tenencia vehicular"
                      href="https://www.finanzas.cdmx.gob.mx/descuento-de-100/descuento-del-100"
                    />
                    <LinkListItem
                      text="Renovar/reponer tarjeta de circulación"
                      href="https://clupp.com.mx/blog/como-renovar-la-tarjeta-de-circulacion/"
                    />

                    <CustomAccordion
                      expanded={subExpanded === "subpanel1"}
                      handleChange={handleSubChange("subpanel1")}
                      summary="Programa de verificación vehicular"
                      details={
                        <>
                          <LinkListImage
                            src="https://www.sedema.cdmx.gob.mx/storage/app/media/DGCA/verificacioncalendario01.png"
                            href="https://www.sedema.cdmx.gob.mx/storage/app/media/DGCA/verificacioncalendario01.png"
                          />
                          <LinkListImage
                            src="https://sedema.cdmx.gob.mx/storage/app/media/DGCA/verificacioncalendario02.png"
                            href="https://sedema.cdmx.gob.mx/storage/app/media/DGCA/verificacioncalendario02.png"
                          />

                          <Button
                            size="small"
                            onClick={handleVerifyCenter}
                            fullWidth
                            variant="contained"
                            sx={{ textTransform: "none", marginTop: "8px" }}
                          >
                            Programar cita
                          </Button>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://sedema.cdmx.gob.mx/programas/programa/verificacion-vehicular"
                          >
                            <Button
                              size="small"
                              fullWidth
                              variant="contained"
                              sx={{ textTransform: "none", marginTop: "8px" }}
                            >
                              Ver más detalles
                            </Button>
                          </a>
                        </>
                      }
                    />
                    <CustomAccordion
                      expanded={subExpanded === "subpanel2"}
                      handleChange={handleSubChange("subpanel2")}
                      summary="Programa hoy no circula"
                      details={
                        <>
                          <LinkListImage
                            src="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula.jpg"
                            href="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula.jpg"
                          />
                          <LinkListImage
                            src="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula2.jpg"
                            href="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula2.jpg"
                          />
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://sedema.cdmx.gob.mx/programas/programa/hoy-no-circula"
                          >
                            <Button
                              size="small"
                              fullWidth
                              variant="contained"
                              sx={{ textTransform: "none", marginTop: "8px" }}
                            >
                              Ver más detalles
                            </Button>
                          </a>
                          <Button
                            href="https://hoynocircula.cdmx.gob.mx/"
                            target="_blank"
                            size="small"
                            fullWidth
                            variant="contained"
                            sx={{ textTransform: "none", marginTop: "8px" }}
                          >
                            Consultar
                          </Button>
                        </>
                      }
                    />
                  </>
                }
              />
              <CustomAccordion
                expanded={expanded === "panel2"}
                handleChange={handleChange("panel2")}
                summary="Estado de México"
                details={
                  <>
                    <LinkListItem
                      text="Consulta si tienes multas de tránsito"
                      href="https://infracciones.edomex.gob.mx/"
                    />
                    <CustomAccordion
                      expanded={subExpanded === "subpanel2"}
                      handleChange={handleSubChange("subpanel2")}
                      summary="Programa hoy no circula"
                      details={
                        <>
                          <LinkListImage
                            src="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula.jpg"
                            href="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula.jpg"
                          />
                          <LinkListImage
                            src="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula2.jpg"
                            href="https://sedema.cdmx.gob.mx/storage/app/media/uploaded-files/Hoy%20No%20Circula2.jpg"
                          />
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://sedema.cdmx.gob.mx/programas/programa/hoy-no-circula"
                          >
                            <Button
                              size="small"
                              fullWidth
                              variant="contained"
                              sx={{ textTransform: "none", marginTop: "8px" }}
                            >
                              Ver más detalles
                            </Button>
                          </a>
                          <Button
                            href="https://hoynocircula.cdmx.gob.mx/"
                            target="_blank"
                            size="small"
                            fullWidth
                            variant="contained"
                            sx={{ textTransform: "none", marginTop: "8px" }}
                          >
                            Consultar
                          </Button>
                        </>
                      }
                    />
                  </>
                }
              />
              <CustomAccordion
                expanded={expanded === "panel3"}
                handleChange={handleChange("panel3")}
                summary="Jalisco"
                details={
                  <LinkListItem
                    text="Consulta si tienes multas de tránsito"
                    href="https://gobiernoenlinea1.jalisco.gob.mx/serviciosVehiculares/adeudos"
                  />
                }
              />
              <CustomAccordion
                expanded={expanded === "panel4"}
                handleChange={handleChange("panel4")}
                summary="Otros"
                details={
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfXpWC0qr5I7sSgFybpvG1JnbX_4zi92MG8Js_8rKptdvwgZQ/viewform?embedded=true"
                    width="100%"
                    height="420px"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                    title="otros"
                  >
                    Cargando…
                  </iframe>
                }
              />
            </Box>
          </List>
        </Box>
      </Box>
    </main>
  );
}
