import React, { useContext, useEffect, useState } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import UserContex from "../../User/UserContex";
import Swal from "sweetalert2";
import { whatReport, whatUrl, ClaveReport, whatDatos } from "../../utils";
import { Stack, Typography, Button, TextField } from "@mui/material";
import AutoCompletado from "../AutoCompletado/AutoCompletado";
import Dialog from "../Dialog";
import getContactPhone from "../../utils/getContactPhone";
import CircularProgress from "@mui/material/CircularProgress";
import Map from "../Map/Map";
import { configPrueba } from "../../Services/Avi";
/**Corriente solicitada */
const CorrienteSolicitada = ({
  setTipoAsistencia,
  setHideVehiculo,
  setHideTipo,
  setHideAsistencia,
}) => {
  const {
    report,
    setReport,
    selectV,
    modelData,
    loginData,
    headers,
    nameAsegurado,
    lastAsegurado,
    uidAsegurado,
  } = useContext(UserContex);
  const urlPrueba = configPrueba();
  const [isMessageGuardar, setIsMessageGuardar] = useState(false);
  const [isMessageDatos, setIsMessageDatos] = useState(false);
  const [isProgreess, setIsProgreess] = useState(false);
  const [clear, setClear] = useState(false);
  const [onFoc, setOnFoc] = useState(true);
  const [quoteData, setQuoteData] = useState();
  const [tripData, setTripData] = useState({ cost: 0, distance: 0 });
  const [registerCarData, setRegisterCarData] = useState();
  const [quote, setQuote] = useState(undefined);
  const [placeId, setPlaceId] = useState("");
  const [directionId, setDirectionId] = useState("");

  useEffect(() => {
    if (modelData !== undefined) {
      registerVehicle();
    }
  }, [modelData]);
  const registerVehicle = () => {
    const vehicleParameters = {
      year: selectV.year,
      color: selectV.color,
      plate: selectV.plates,
      vehicleModelId: modelData.id,
    };
    const urlRegisterVehicle = urlPrueba
      ? `https://zazu.aviapp.mx/sandbox/vehicle/`
      : `https://zazu.aviapp.mx/vehicle/`;
    fetch(urlRegisterVehicle, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(vehicleParameters),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setIsMessageDatos(true);
        } else {
          setRegisterCarData(data);
          setQuote(data.id);
          //console.log(data);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: "Ha ocurrido un error: " + err,
        });
      });
  };
  const getQuote = (CarId) => {
    const quoteParameters = {
      startLatitude: report.currentLocation ? report.currentLocation.lat : null,
      startLongitude: report.currentLocation
        ? report.currentLocation.lon
        : null,
      service: 2,
      questions: JSON.stringify([
        {
          answers: [{ answer: "Si", id: 1, questionId: 1 }],
          id: 1,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Se puede poner en neutral?",
          service: 1,
          type: 1,
        },
        {
          answers: [{ answer: "Si", id: 1, questionId: 2 }],
          id: 2,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Giran las 4 llantas?",
          service: 1,
          type: 1,
        },
        {
          answers: [{ answer: "A pie de calle", id: 1, questionId: 3 }],
          id: 3,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Donde se encuentra?",
          service: 1,
          type: 1,
        },
      ]),
      vehicleId: CarId,
    };
    const urlQuote = urlPrueba
      ? `https://zazu.aviapp.mx/sandbox/quote/`
      : `https://zazu.aviapp.mx/quote/`;
    fetch(urlQuote, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(quoteParameters),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message || !data) {
          Swal.fire({
            icon: "error",
            text: "Ha ocurrido un error: " + data.message,
          });
        } else {
          setQuoteData(data);
          guardarCorriente(data.cost);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "error: " + error,
        });
      });
  };
  useEffect(() => {
    setReport({
      ...report,
      startReportTimestamp: Date.now(),
    });
  }, []);
  const [hideGuarda, setHideGuarda] = useState(false);

  useEffect(() => {
    if (report.currentLocation && report.environmentDescription) {
      setHideGuarda(true);
    }
  }, [report.currentLocation, report.environmentDescription]);
  /**Creación para reporte  SaveBack */
  const guardarCorriente = async (costo) => {
    const id = report.id;
    const idv = report.vehicle;
    const URIV = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${id}/vehicles/${idv}`;
    const responsev = await fetch(URIV, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dataV = await responsev.json();
    const type = "E";
    const uidReport = ClaveReport(type);
    setReport({ ...report, claveReport: uidReport });
    const data = {
      user: id,
      type: [report.type],
      isActive: report.isActive,
      source: report.source,
      vehicle: report.vehicle,
      reportTimestamp: Date.now(),
      accidentTimestamp: report.startReportTimestamp,
      startReportTimestamp: report.startReportTimestamp,
      assistanceType: "battery",
      currentAddress: report.currentAddress ? report.currentAddress : "",
      destinationAddress: report.destinationAddress
        ? report.destinationAddress
        : "",
      environmentDescription: report.environmentDescription
        ? report.environmentDescription
        : "",
      currentLocation: report.currentLocation
        ? report.currentLocation
        : { lat: 0, lon: 0 },
      destinationLocation: report.destinationLocation
        ? report.destinationLocation
        : { lat: 0, lon: 0 },
      currentZipcode: report.postalCurrent ? report.postalCurrent : "",
      userReport: id,
    };
    const URI = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${id}/claims/${uidReport}`;
    const response = await fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ update: data }),
    });
    const resultado = await response.json();
    registerTrip(uidReport, costo);
  };
  const registerTrip = (uidReport, costo) => {
    const tripParameters = {
      startLatitude: report.currentLocation ? report.currentLocation.lat : null,
      startLongitude: report.currentLocation
        ? report.currentLocation.lon
        : null,
      startAddress: report.currentAddress ? report.currentAddress : null,
      endLatitude: 0,
      endLongitude: 0,
      endAddress: "",
      service: 2,
      questions: JSON.stringify([
        {
          answers: [{ answer: "Si", id: 1, questionId: 1 }],
          id: 1,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Se puede poner en neutral?",
          service: 1,
          type: 1,
        },
        {
          answers: [{ answer: "Si", id: 1, questionId: 2 }],
          id: 2,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Giran las 4 llantas?",
          service: 1,
          type: 1,
        },
        {
          answers: [{ answer: "A pie de calle", id: 1, questionId: 3 }],
          id: 3,
          parentAnswer: 0,
          parentId: 0,
          question: "¿Donde se encuentra?",
          service: 1,
          type: 1,
        },
      ]),
      quantity: 0,
      vehicleId: registerCarData.id,
    };
    const urlTrip = urlPrueba
      ? `https://zazu.aviapp.mx/sandbox/trip/${loginData.user.id}`
      : `https://zazu.aviapp.mx/trip/${loginData.user.id}`;
    fetch(urlTrip, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(tripParameters),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          Swal.fire({
            icon: "error",
            text: "Ha ocurrido un error: " + data.message,
          });
        } else {
          const folio = data.folio;
          setTripData(data);
          guardarMov(uidReport, costo, folio);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "error: " + error,
        });
      });
  };
  const guardarMov = async (uidReportBack, costo, folio) => {
    const id = report.id;
    const folioAvi = {
      providerReportId: folio,
    };
    const URIFOLIO = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc?docPath=users/${id}/claims/${uidReportBack}`;
    try {
      const responseFolio = await fetch(URIFOLIO, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ update: folioAvi }),
      });
      const resultadoFolio = await responseFolio.json();
    } catch (error) {
      console.log(error);
    }
    const movimiento = {
      type: "Gastos",
      concept: "Asistencia vial",
      description: "automático",
      amount: Number(costo),
      reserve: true,
      timestampMovement: Date.now(),
      deleted: false,
      userUid: id,
    };
    const URIMOV = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/addDoc?collectionPath=users/${id}/claims/${uidReportBack}/movements`;
    try {
      const responseMov = await fetch(URIMOV, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(movimiento),
      });
      const resultadomov = await responseMov.json();
      const dataA4 = resultadomov.doc;
      const docId4 = resultadomov.doc.id;
    } catch (error) {
      console.log(error);
    }
    setIsProgreess(false);
    setIsMessageGuardar(true);
  };
  const whatsappDatos = whatDatos(nameAsegurado, lastAsegurado);
  const whatsappUrl = whatUrl(
    nameAsegurado,
    lastAsegurado,
    uidAsegurado,
    report.vehicle
  );
  const whatsappReport = whatReport(
    nameAsegurado,
    lastAsegurado,
    report.claveReport
  );
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      <Typography
        sx={{
          paddingLeft: 3,
        }}
      >
        ¿Cuál es la ubicación exacta del vehículo?
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        width="100%"
        sx={{
          paddingLeft: 3,
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          La dirección, debe tener calle, número, colonia y estado.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        width="100%"
        sx={{
          paddingLeft: 3,
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          Ejemplo: Reforma 222, Avenida Paseo de la Reforma, Juárez, Ciudad de
          México, CDMX, México
        </Typography>
      </Stack>
      <Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
          sx={{
            paddingLeft: 3,
          }}
        >
          <LocationOnOutlinedIcon
            sx={{ paddingLeft: "25px", paddingTop: "15px" }}
          />
          <AutoCompletado
            setPlaceId={setPlaceId}
            description={directionId}
            placeId={placeId}
            clear={clear}
            setClear={setClear}
            datos={report}
            setDatos={setReport}
            typeAddress={"currentAddress"}
            typeLocation={"currentLocation"}
            postal={"postalCurrent"}
            onFoc={onFoc}
            setOnFoc={setOnFoc}
          />
        </Stack>
        <Stack style={{marginLeft: "97px"}}>
          <Map
            setPlaceId={setPlaceId}
            setDirectionId={setDirectionId}
            disabled={false}
            position={
              report.currentLocation
                ? report.currentLocation
                : { lat: "", lon: "" }
            }
            callBack={(e, direction) => {
              console.log(e);
              setReport({
                ...report,
                currentLocation: {
                  lat: e.latLng.lat(),
                  lon: e.latLng.lng(),
                },
                currentAddress: direction,
              });
            }}
          />
        </Stack>
      </Stack>
      <Typography sx={{ paddingLeft: 3 }}>
        ¿Hay alguna complejidad de acceso al vehículo?
      </Typography>
      <Typography sx={{ paddingLeft: 3, fontSize: "15px" }}>
        Por ejemplo, que se encuentre en un estacionamiento subterráneo o mal
        estacionado.
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        sx={{
          paddingLeft: 3,
        }}
      >
        <MapsUgcOutlinedIcon sx={{ paddingLeft: "25px", paddingTop: "13px" }} />
        <TextField
          variant="outlined"
          style={{ width: "300px" }}
          onChange={(e) =>
            setReport({ ...report, environmentDescription: e.target.value })
          }
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ paddingBottom: "20px", paddingLeft: "30px" }}
        spacing={3}
      >
        <div style={{ marginRight: "0" }}>
          {hideGuarda ? (
            <Button
              variant="contained"
              onClick={() => {
                getQuote(quote);
                setIsProgreess(true);
              }}
              sx={{
                textTransform: "initial",
              }}
            >
              Solicitar asistencia
            </Button>
          ) : (
            <div style={{ marginRight: "0" }}>
              <Button
                variant="contained"
                color="success"
                disabled
                sx={{
                  textTransform: "initial",
                }}
              >
                Solicitar asistencia
              </Button>
            </div>
          )}
        </div>
      </Stack>
      <Dialog
        isOpen={isProgreess}
        content={
          <Stack spacing={2} direction="column">
            <CircularProgress sx={{ marginLeft: "120px" }} />
            <Typography sx={{ paddingLeft: "50px", fontWeight: "bold" }}>
              Guardando datos
            </Typography>
          </Stack>
        }
      />
      <Dialog
        isOpen={isMessageGuardar}
        title="¡Asistencia solicitada con éxito!"
        content={
          <Stack spacing={2} direction="column">
            <Typography>
              En este momento estamos localizando al proveedor más cercano para
              asignarlo.
            </Typography>
            <Typography>El número de tu asistencia es:</Typography>
            <Typography>{report.claveReport}</Typography>
            <Typography>
              Con este número podrás dar seguimiento a tu solicitud vía whatsapp
              o por llamada.
            </Typography>
          </Stack>
        }
        Actions={
          <Stack spacing={2} direction="row">
            <Button
              onClick={() => {
                setIsMessageGuardar(false);
                window.open("tel:" + getContactPhone());
                setHideVehiculo(false);
                setHideTipo(false);
                setHideAsistencia("");
              }}
              color="success"
              variant="contained"
              sx={{
                textTransform: "initial",
              }}
            >
              Llamar
            </Button>
            <a
              href={whatsappReport}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setIsMessageGuardar(false);
                  setHideVehiculo(false);
                  setHideTipo(false);
                  setHideAsistencia("");
                }}
                sx={{
                  textTransform: "initial",
                }}
              >
                Escribir por WhatsApp
              </Button>
            </a>
          </Stack>
        }
      />
      <Dialog
        isOpen={isMessageDatos}
        content={
          <Stack spacing={2} direction="column">
            <Typography>
              Ha ocurrido un error en tu solicitud, por favor ponte en contacto
              con un asesor.
            </Typography>
          </Stack>
        }
        Actions={
          <Stack spacing={2} direction="row">
            <Button
              onClick={() => {
                // setIsMessageDatos(false);
                setHideVehiculo(false);
                setHideTipo(false);
                setHideAsistencia("");
                window.open("tel:" + getContactPhone());
              }}
              color="success"
              variant="contained"
              sx={{
                textTransform: "initial",
              }}
            >
              Llamar
            </Button>
            <a
              href={whatsappDatos}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  // setIsMessageDatos(false);
                  setHideVehiculo(false);
                  setHideTipo(false);
                  setHideAsistencia("");
                }}
                sx={{
                  textTransform: "initial",
                }}
              >
                Escribir por WhatsApp
              </Button>
            </a>
          </Stack>
        }
      />
    </Stack>
  );
};
export default CorrienteSolicitada;
