import getContactPhone from "./getContactPhone";

const whatDatos = (nameAsegurado, lastAsegurado) => {
  //console.log(nameAsegurado);
  const url =
    "https://wa.me/521" +
    getContactPhone() +
    "?text=Hola%20mi%20nombre%20es%20" +
    nameAsegurado +
    "%20" +
    lastAsegurado +
    "%20estoy%20tratando%20de%20solicitar%20una%20asistencía%20víal.";
  return url;
};
export default whatDatos;
