import { Button, Stack, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Dialog from "./Dialog";
import { getContactPhone } from "../utils";
const FailedRoboReportModal = ({ openModal, whatsappUrl }) => {
  return (
    <Dialog
      isOpen={openModal}
      content={
        <>
          <Typography sx={{ textAlign: "center" }}>
            Ha ocurrido un error en tu solicitud, por favor ponte en contacto
            con un asesor.
          </Typography>
        </>
      }
      Actions={
        <Stack sx={{width: "100%"}} spacing={2} direction={["column"]}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<PhoneIcon />}
            sx={{
              textTransform: "none",
              width: "100%",
              height: "50px",
              textAlign: "center",
              fontSize: "13px",
            }}
            href={`tel:+525592252185`}
          >
            Llamar por teléfono
          </Button>
          <Button
            fullWidth
            color="success"
            startIcon={<WhatsAppIcon />}
            variant="contained"
            sx={{
              textTransform: "none",
              height: "50px",
              textAlign: "center",
              fontSize: "12px",
            }}
            href={whatsappUrl}
            target="_blank"
          >
            Enviar mensaje por WhatsApp
          </Button>
        </Stack>
      }
    />
  );
};

export default FailedRoboReportModal;
