import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment-timezone";
import { PickersDay } from "@mui/x-date-pickers";
import getRestrictedDays from "../utils/getRestrictedDayHoyNoCircula";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import getLastNumericDigit from "../utils/getLastNumericDigit";



const PlateDisplay = ({ plate }) => {
  const lastDigit = getLastNumericDigit(plate);

  // Si no hay dígitos numéricos, mostramos la placa completa en gris
  if (lastDigit === null) {
    return <Typography style={{ color: "gray" }}>{plate}</Typography>;
  }

  // Encontramos el índice del último dígito numérico
  const lastDigitIndex = plate.lastIndexOf(lastDigit.toString());

  // Dividimos la placa en dos partes: antes y después del último dígito numérico
  const plateBeforeLastDigit = plate.slice(0, lastDigitIndex);
  const plateLastDigit = plate.slice(lastDigitIndex, lastDigitIndex + 1);
  const plateAfterLastDigit = plate.slice(lastDigitIndex + 1);

  return (
    <Box display="flex" alignItems="center" sx={{ marginTop: "-5px" }}>
      <Typography style={{ color: "gray", fontWeight: "bold" }} variant="h5">
        {plateBeforeLastDigit}
      </Typography>
      <Typography style={{ fontWeight: "bold", color: "black" }} variant="h5">
        {plateLastDigit}
      </Typography>
      <Typography style={{ color: "gray", fontWeight: "bold" }} variant="h5">
        {plateAfterLastDigit}{" "}
      </Typography>
    </Box>
  );
};

const HoyNoCircula = ({
  vehicleHologram,
  plate,
  vid,
  isOpen,
  handleClose,
  uid,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState({
    hologram: vehicleHologram,
    plate: plate,
  });

  const [restrictedDays, setRestrictedDays] = useState([]);
  const handleOnCloseAndReset = () => {
    setRestrictedDays([]);
    setValues({ hologram: "", plate: plate });
    handleClose();
  };

  useEffect(() => {
    const { hologram, plate } = values;
    if (hologram && plate) {
      if (hologram === "1" || hologram === "2") {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        setRestrictedDays(
          getRestrictedDays(
            month,
            year,
            parseInt(hologram),
            getLastNumericDigit(plate)
          )
        );
      } else {
        setRestrictedDays([]);
      }
    }
  }, [values]);

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleDateChange = (e) => {
    const month = e.getMonth() + 1;
    const year = e.getFullYear();
    const { hologram, plate } = values;

    if (hologram === "1" || hologram === "2") {
      setRestrictedDays(
        getRestrictedDays(
          month,
          year,
          parseInt(values.hologram),
          getLastNumericDigit(plate)
        )
      );
    } else {
      setRestrictedDays([]);
    }
  };

  const BlueCircleDay = (props) => {
    const { restrictedDays, day, disabled, ...other } = props;
    const formattedDay = moment(day).format("YYYY-MM-DD");
    const isSelected = restrictedDays.includes(formattedDay);

    return (
      <PickersDay
        {...other}
        day={day}
        sx={{
          backgroundColor: isSelected && !disabled ? "#EE7E6B" : "",
          color: disabled ? "gray" : "#000",
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: isSelected && !disabled ? "#EE7E6B" : "#fff",
            cursor: "default",
          },
        }}
      />
    );
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const ref = doc(db, `users/${uid}/vehicles/${vid}`);
      await setDoc(ref, { hologram: values.hologram }, { merge: true });
      Swal.fire({
        icon: "success",
        showConfirmButton: false,
        text: "Holograma actualizado exitosamente.",
      });
    } catch (err) {
      console.log("Error updating hologram:", err);
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        text: "Lo sentimos, ocurrio un error inesperado.",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Dialog
        open={isOpen}
        onClose={handleOnCloseAndReset}
        sx={{ margin: "3px" }}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Hoy no circula</DialogTitle>
        <DialogContent sx={{ marginTop: "-10px" }}>
          <Stack>
            <Typography variant="body2" gutterBottom>
              Último dígito númerico de la placa
            </Typography>

            <PlateDisplay plate={values.plate} />
            <FormControl sx={{ marginTop: "15px" }}>
              <InputLabel>Holograma</InputLabel>
              <Select label="Holograma" name="hologram" onChange={handleChange} value={values.hologram}>
                <MenuItem value={"00"}>00</MenuItem>
                <MenuItem value={"0"}>0</MenuItem>
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Stack>
            <DateCalendar
              readOnly
              disablePast
              onMonthChange={handleDateChange}
              views={["day"]}
              slots={{
                day: BlueCircleDay,
              }}
              slotProps={{
                day: {
                  restrictedDays,
                },
              }}
            />
          </Stack>

          {Boolean(restrictedDays.length > 0) && (
            <Box
              display="flex"
              alignItems="flex-start"
              width={"80%"}
              margin="-15px auto 0px auto"
            >
              <Box
                sx={{
                  width: "21px",
                  height: "21px",
                  minWidth: "21px",
                  minHeight: "21px",
                  backgroundColor: "#EE7E6B",
                  borderRadius: "4px",
                  marginRight: "5px",
                }}
              ></Box>
              <Typography variant="subtitle2">
                Días que no circulas en un horario de las 5 a las 22 hrs.
              </Typography>
            </Box>
          )}
          {Boolean(values.hologram === "0" || values.hologram === "00") && (
            <Box
              display="flex"
              alignItems="flex-start"
              width={"80%"}
              margin="-15px auto 0px auto"
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  minWidth: "20px",
                  minHeight: "20px",
                  backgroundColor: "#fff",
                  border: "1px solid #000",
                  borderRadius: "4px",
                  marginRight: "5px",
                }}
              ></Box>
              <Typography variant="subtitle2">
                Tu vehículo circula todos los días.
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            fullWidth
            disableElevation
            disabled={vehicleHologram === values.hologram}
            onClick={handleSave}
            loading={isSaving}
            type="submit"
            variant="contained"
            sx={{ maxWidth: "100px" }}
          >
            Guardar
          </LoadingButton>
          <Button onClick={handleOnCloseAndReset}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default HoyNoCircula;
