export default async function postMessage(
  messageBody
) {
  try {
    const response = await fetch("https://hooks.slack.com/services/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",
      {
        method: "POST",
        // omit Content-Type header to avoid CORS error from Slack API
        body: JSON.stringify({
          text: messageBody
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Cannot post message to Slack channel");
    }
  } catch (error) {
    console.error(error);
  }
}
