import house from "../Assets/house.png";
import life from "../Assets/life.png";
import medical from "../Assets/medical.png";
import vehicle from "../Assets/cluppCar.png";
import other from "../Assets/other.png";
import health from "../Assets/health.png";
import pet from "../Assets/pet.png";
import travel from "../Assets/travel.png";
import oldman from "../Assets/oldman.png";
import sports from "../Assets/sport.png";
import diabetes from "../Assets/diabetes.png";
import divicesActive from "../Assets/divicesActive.png";
import moto from "../Assets/cluppMoto.png";
import bike from "../Assets/bike.png";
import eBike from "../Assets/cluppeBike.png";
import scooter from "../Assets/cluppMono.png";

const getTypeImgAndTitle = ({ type, uid = "", email = "", cluppType = "" }) => {
  switch (type) {
    case "life":
      return {
        img: life,
        title: "Seguro de vida",
        link: `https://clupp.typeform.com/to/Bp1gddfk#uid=${uid}`,
      };
    case "house":
      return {
        img: house,
        title: "Seguro de hogar",
        link: "https://cotizadores.bbvaseguros.mx/psns_mult_web_psnspublicwebapp_02/seguroHogarDinamico#/paso1-perfilador",
      };
    case "medical":
      return {
        img: medical,
        title: "Seguro de gastos médicos mayores",
        link: `https://clupp.typeform.com/CluppGMM#uid=${uid}`,
      };
    case "health":
      return { img: health, title: "Seguro de salud" };
    case "pet":
      return {
        img: pet,
        title: "Seguro de mascotas",
        link: "https://clupp.tienda.ammia.io/",
      };
    case "vehicle":
      if (cluppType === "moto") {
        return {
          img: moto,
          title: "Seguro de motocicleta",
          link: ``,
        };
      }
      if (cluppType === "auto") {
        return {
          img: vehicle,
          title: "Seguro de automóvil",
          link: ``,
        };
      }
      if (cluppType === "bike") {
        return {
          img: bike,
          title: "Seguro de bicicleta",
          link: ``,
        };
      }
      if (cluppType === "eBike") {
        return {
          img: eBike,
          title: "Seguro de bicicleta eléctrica",
          link: ``,
        };
      }
      if (cluppType === "scooter") {
        return {
          img: scooter,
          title: "Seguro de monopatín",
          link: ``,
        };
      }
      return {
        img: vehicle,
        title: "Seguro de vehículo",
        link: `https://cotizador-clupp.web.app/?utm_source=app&utm_medium=web&utm_campaign=upsell&utm_content=${uid}&email=${email}`,
      };
    case "travel":
      return {
        img: travel,
        title: "Seguro de viajes",
        link: `https://axa-asistenciaviaje.com.mx/`,
      };
    case "medical-olderly":
      return {
        img: oldman,
        title: "Seguro de gastos médicos para personas mayores",
        link: `https://clupp.typeform.com/to/b2oc4c6v#uid=${uid} `,
      };
    case "sports":
      return {
        img: sports,
        title: "Seguro deportivo",
        link: `https://journey.app/?reference=Afl.Clupp`,
      };

    case "diabetes":
      return {
        img: diabetes,
        title: "Diabetes, hipertensión y obesidad",
        link: `https://www.monitoria.mx/?utm_source=Clupp&utm_medium=Webapp%20Button&utm_campaign=MonitorIA-Clupp`,
      };

    case "devices":
      return {
        img: divicesActive,
        title: "Seguro para dispositivos",
        link: `https://cotizador-clupp.web.app/?userUID=${uid}&email=${email}&vType=devices&utm_source=app&utm_campagin=upsell&utm_medium=web&utm_content=${uid}`,
      };
    case "tablet":
      return {
        img: undefined,
        title: "Seguro de tablet",
        link: ` https://cluppseguro.web.app/smartphones/?userUID=${uid}`,
      };
    case "laptop":
      return {
        img: undefined,
        title: "Seguro de laptop",
        link: ` https://cluppseguro.web.app/smartphones/?userUID=${uid}`,
      };
    default:
      return { img: other, title: "Otro" };
  }
};

export default getTypeImgAndTitle;
