import { LoadingButton } from "@mui/lab";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import { useTheme } from "@emotion/react";

function isValidPassword(input) {
  if (typeof input !== "string") {
    return false;
  }
  if (input.length < 6) {
    return false;
  }
  const containsNumber = /\d/.test(input);

  return containsNumber;
}

function validateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

export default function MailAuth({ onSubmit, isLoading }) {
  const theme = useTheme();
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isNotMatchPassword, seNotIsMatchPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState({
    first: false,
    confirm: false,
  });
  const [formData, setFormData] = useState({
    email: "",
    passwordFirst: "",
    passwordConfirm: "",
  });
  function handlerOnChange(e) {
    if (e.target.name === "passwordFirst" && !isValidPassword(e.target.value)) {
      setIsInvalidPassword(true);
    } else if (isValidPassword(e.target.value)) {
      setIsInvalidPassword(false);
    }
    if (e.target.name === "passwordConfirm") {
      seNotIsMatchPassword(false);
    }
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  function onSubmitHandler(e) {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      Swal.fire({
        icon: "warning",
        text: "El correo electrónico es inválido, por favor verifique que sea el correcto",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (!formData?.passwordConfirm) {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor ingresa una contraseña",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (!formData?.passwordFirst) {
      Swal.fire({
        icon: "warning",
        text: "Para continuar, por favor confirma tu contraseña",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (isInvalidPassword) {
      Swal.fire({
        icon: "warning",
        text: "Tu contraseña no tiene el formato correcto, debe ser de 6 carácteres y contener por lo menos un número",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      return;
    } else if (formData.passwordConfirm !== formData.passwordFirst) {
      Swal.fire({
        icon: "warning",
        text: "Las contraseñas no coinciden, por favor verifica la información",
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: "Ok",
      });
      seNotIsMatchPassword(true);
      return;
    }
    onSubmit({
      email: formData.email,
      password: formData.passwordFirst,
    });
  }
  return (
    <Stack>
      <Box>
        <form
          style={{ display: "grid", gap: "18px", marginTop: "24px" }}
          onSubmit={onSubmitHandler}
        >
          <TextField
            label="Ingresa tu correo electrónico"
            fullWidth
            variant="standard"
            name="email"
            value={formData.email}
            onChange={handlerOnChange}
            helperText={" "}
          />
          <TextField
            label="Contraseña"
            fullWidth
            variant="standard"
            name="passwordFirst"
            value={formData.passwordFirst}
            onChange={handlerOnChange}
            error={isInvalidPassword}
            type={isShowPassword.first ? "text" : "password"}
            helperText={
              isInvalidPassword
                ? "Tu contraseña debe ser de 6 carácteres y contener por lo menos un número"
                : " "
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsShowPassword((prev) => ({
                        ...prev,
                        first: !prev.first,
                      }));
                    }}
                  >
                    {isShowPassword.first ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirma tu contraseña"
            fullWidth
            variant="standard"
            name="passwordConfirm"
            type={isShowPassword.confirm ? "text" : "password"}
            value={formData.passwordConfirm}
            onChange={handlerOnChange}
            error={isNotMatchPassword}
            helperText={
              isNotMatchPassword ? "Las contraseñas no coinciden" : " "
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsShowPassword((prev) => ({
                        ...prev,
                        confirm: !prev.confirm,
                      }));
                    }}
                  >
                    {isShowPassword.confirm ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            sx={{ textTransform: "none" }}
            fullWidth
            variant="contained"
            onClick={onSubmitHandler}
            loading={isLoading}
            type="submit"
          >
            Verificar
          </LoadingButton>
        </form>
      </Box>
    </Stack>
  );
}
