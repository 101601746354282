import { Typography, Box } from "@mui/material";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { getInsuranceStatusMessage, isVehicleInsured } from "../../utils";
import { formatCurrency } from "../../utils/formatCurrency";

export default function SmartphoneCard({ smartphone }) {
  const insuranceMessage = getInsuranceStatusMessage(smartphone);
  const [isInsured, insuredStatus] = isVehicleInsured(smartphone);
  const insuranceStatusText =
    isInsured && insuredStatus === "paid" ? "#00CA7D" : "error.main";
  const startVigencyDate =
    smartphone?.insuranceStartYear &&
    format(
      new Date(
        smartphone?.insuranceStartYear,
        smartphone?.insuranceStartMonth - 1,
        smartphone?.insuranceStartDay
      ).getTime(),
      "d 'de' MMMM 'del' yyyy",
      {
        locale: es,
      }
    );
  const endVigencyDate =
    smartphone?.insuranceEndYear &&
    format(
      new Date(
        smartphone?.insuranceEndYear,
        smartphone?.insuranceEndMonth - 1,
        smartphone?.insuranceEndDay
      ).getTime(),
      "d 'de' MMMM 'del' yyyy",
      {
        locale: es,
      }
    );

  return (
    <Box
      sx={{
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
        width: "100%",
        maxWidth: "450px",
      }}
    >
      <Typography
        sx={{
          textTransform: "capitalize",
          fontWeight: "600",
          fontSize: "18px",
        }}
      >
        {smartphone?.brand
          ? `${smartphone?.brand} ${smartphone?.model}`
          : "Celular"}
      </Typography>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "16px",
          color: insuranceStatusText,
        }}
      >
        {insuranceMessage}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3px",
        }}
      >
        <Typography>IMEI: {smartphone?.IMEI}</Typography>
        <Typography>Inicio de vigencia: {startVigencyDate}</Typography>
        <Typography>Fin de vigencia: {endVigencyDate}</Typography>
        <Typography>
          Valor del celular:{" "}
          {formatCurrency(smartphone?.value, { maximumFractionDigits: 0 })}
        </Typography>
      </Box>
    </Box>
  );
}
