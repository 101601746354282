import { Box, Stack, Typography } from "@mui/material";
import MobileHeader from "../../Components/MobileHeader";
import suitcase from "../../Assets/suitcase.png";
import paper from "../../Assets/paper.png";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";

const ExtraInsurancesOptions = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  return (
    <>
      <MobileHeader user={user} showGradientHeader={false} />
      <Stack
        sx={{
          position: "relative",
          height: "90vh",
          width: "100%",
        }}
      >
        <Box sx={{ p: 2, marginTop: "20px" }}>
          <Box
            sx={{
              p: 2,

              marginBottom: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #cecece",
              borderRadius: "6px",
            }}
            onClick={() => navigate(`/opciones-seguros?uid=${user.uid}`)}
          >
            <Box>
              <img
                src={suitcase}
                width={"50px"}
                height={"50px"}
                alt="suitcase"
              />
            </Box>
            <Stack sx={{ marginLeft: "15px" }}>
              <Typography variant="h6" fontWeight={"bold"}>
                Cotizar seguro
              </Typography>
              <Typography variant="subtitle1">
                Cotiza un nuevo seguro de cualquier tipo
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              p: 2,
              marginBottom: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #cecece",
              borderRadius: "6px",
            }}
            onClick={() => navigate(`/agregar-seguro?uid=${user.uid}`)}
          >
            <Box>
              <img src={paper} width={"50px"} height={"50px"} alt="suitcase" />
            </Box>
            <Stack sx={{ marginLeft: "15px" }}>
              <Typography variant="h6" fontWeight={"bold"}>
                Agregar seguro
              </Typography>
              <Typography variant="subtitle1">
                Carga un seguro con el que ya cuentas actualmente
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default ExtraInsurancesOptions;
