import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import UserContex from "../../User/UserContex";

/**ProggressBar */
const ProggressVehiculo = ({
  setHideProgressVehiculo,
  setHideVehiculo,
  setHideVehiculoCancelado,
  setHideAsistencia,
}) => {
  const { vehicles } = useContext(UserContex);
  const [progressLineal, setProgressLineal] = useState(true);
  useEffect(() => {
    if (vehicles !== null) {
      const countVehicles = vehicles.filter(
        (vehicul) =>
          vehicul.isInsured &&
          vehicul.insuranceCompany == "Clupp" &&
          !vehicul.deleted
      );
      const valor = countVehicles.length;
      if (valor === 0) {
        setProgressLineal(false);
        setHideProgressVehiculo(false);
        setHideVehiculoCancelado(true);
      } else {
        setProgressLineal(false);
        setHideProgressVehiculo(false);
        setHideAsistencia("");
        setHideVehiculo(true);
      }
    }
    //setCountVeh(valor);
  }, [vehicles]);
  return (
    <div>
      {progressLineal && (
        <Box sx={{ width: "100%" }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};
export default ProggressVehiculo;
