import { addDays, isBefore, isToday } from "date-fns";

export default function isVehicleInsured(vehicle) {
  if (!vehicle) return [false];
  if (vehicle.deleted) return [false];
  const {
    insuranceEndYear = 0,
    insuranceEndMonth = 0,
    insuranceEndDay = 0,
  } = vehicle;

  const insuranceEndDate = new Date(
    insuranceEndYear,
    insuranceEndMonth - 1,
    insuranceEndDay
  );

  const maxPaymentDate = addDays(insuranceEndDate, 30);

  const today = new Date();

  const isInsurancePaid =
    isBefore(today, insuranceEndDate) || isToday(insuranceEndDate);

  const isInGracePeriod = isBefore(today, maxPaymentDate);

  if (isInsurancePaid || isInGracePeriod) {
    return [true, isInsurancePaid ? "paid" : "grace-period"];
  }

  return [false];
}
