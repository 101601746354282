import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useParams } from "react-router-dom";

function ExternalSourceRedirect(props) {
  let { sourceName } = useParams();
  useEffect(() => {
    const q = query(
      collection(db, "IT/externalLinks/docs"),
      where("sourceName", "==", sourceName)
    );

    getDocs(q)
      .then((snapshot) => {
        if (snapshot.empty) {
          window.location.href = "https://clupp.com.mx/404";
        }
        if (snapshot.docs[0].data().link) {
          window.location.href = snapshot.docs[0].data().link;
        }
      })
      .catch((error) => {
        window.location.href = "https://clupp.com.mx/404";
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "1.5rem",
        width: "100%",
        gap: "5rem",
      }}
    >
      <Box
        sx={{
          width: "90%",
        }}
      >
        <Typography
          variant="h5"
          style={{ textAlign: "center", fontSize: ["1rem", "1.5rem"] }}
        >
          Estamos enviandote a la página solicitada...
        </Typography>
        <LinearProgress sx={{ mt: 2 }} variant="indeterminate" />
      </Box>
    </div>
  );
}

export default ExternalSourceRedirect;
