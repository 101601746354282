import PadLeft from "./PadLeft";
function toleranceDay (day, month, year){
    if (month == 1 && day == 31) {
        return year + "-" + PadLeft(2, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 2 &&
        day == 28
      ) {
        return year + "-" + PadLeft(3, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 3 &&
        day == 31
      ) {
        return year + "-" + PadLeft(4, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 4 &&
        day == 30
      ) {
        return year + "-" + PadLeft(5, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 5 &&
        day == 31
      ) {
        return year + "-" + PadLeft(6, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 6 &&
        day == 30
      ) {
        return year + "-" + PadLeft(7, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 7 &&
        day == 31
      ) {
        return year + "-" + PadLeft(8, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 8 &&
        day == 31
      ) {
        return year + "-" + PadLeft(9, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 9 &&
        day == 30
      ) {
        return year + "-" +PadLeft(10, 2) +"-" +PadLeft(1, 2);
      } else if (
        month == 10 &&
        day == 31
      ) {
        return year + "-" + PadLeft(11, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 11 &&
        day == 30
      ) {
        return year + "-" + PadLeft(12, 2) + "-" + PadLeft(1, 2);
      } else if (
        month == 12 &&
        day == 31
      ) {
        const yearPago = year + 1;
        return yearPago + "-" + PadLeft(1, 2) + "-" + PadLeft(1, 2);
      } else {
        const dayPag = day + 1;
        return year + "-" + PadLeft(month, 2) + "-" + PadLeft(dayPag, 2);
      }

}
export default toleranceDay