import { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  TextField,
  Box,
  FormControl,
  Switch,
  Skeleton,
  useTheme,
  IconButton,
  Button,
} from "@mui/material";
import {
  getDocument,
  getDocuments,
  addDocument,
} from "../../Services/firestore";
import { updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from "../../firebase";
import useReadImage from "../../hooks/useReadImage";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import { logError } from "../../utils/index";
import ShareIcon from "@mui/icons-material/Share";
import Tooltip from "@mui/material/Tooltip";
import userIcon from "../../Assets/user.png";

async function getSocialmediaData(userUID) {
  const socialMedia = await getDocuments({
    collectionPath: `users/${userUID}/socialMediaProfiles`,
    limit: 1,
    orderBy: ["timestamp", "desc"],
  });

  return socialMedia.json();
}
async function getImage(userUID) {
  const data = await getDocument({
    docPath: `users/${userUID}`,
  });

  return data;
}

export default function ContactInfo() {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const userUID = params.get("userUID");
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [wasUpdated, setWasUpdated] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const { inputImage, setInputImage, imgRef, inputImgRef } = useReadImage();
  const theme = useTheme();
  const referURL = `https://cluppseguro.web.app/tarjeta-presentacion?userUID=${userUID}`;
  const [showInfoCheckBox, setShowInfoCheckBox] = useState({
    whatsapp: false,
    facebook: false,
    instagram: false,
    tiktok: false,
    linkedIn: false,
    twitter: false,
    beReal: false,
  });
  const [userInfo, setUserInfo] = useState({
    profileURL: "",
    firstName: "",
    lastName: "",
    email: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    linkedIn: "",
    twitter: "",
    beReal: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getDocument({
      docPath: `users/${userUID}`,
    })
      .then((doc) => {
        setUserInfo((prev) => ({
          ...prev,
          firstName: doc.doc?.firstName,
          lastName: doc.doc?.lastName,
          email: doc.doc?.email,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
    getImage(userUID)
      .then((doc) => {
        setUserInfo((prev) => ({
          ...prev,
          profileURL: doc.doc?.profileURL,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
    getSocialmediaData(userUID)
      .then((data) => {
        if (data.docs.length === 0) return;
        const doc = data.docs[0].doc;
        setUserInfo((prev) => ({
          ...prev,
          whatsapp: Number(doc.whatsapp.number)
            ? Number(doc.whatsapp.number)
            : "",
          facebook: doc.facebook.url ? doc.facebook.url : "",
          instagram: doc.instagram.url ? doc.instagram.url : "",
          tiktok: doc.tiktok.url ? doc.tiktok.url : "",
          linkedIn: doc.linkedIn.url ? doc.linkedIn.url : "",
          twitter: doc.twitter.url ? doc.twitter.url : "",
          beReal: doc.beReal.url ? doc.beReal.url : "",
        }));
        setShowInfoCheckBox((prev) => ({
          ...prev,
          whatsapp: doc.whatsapp.show,
          facebook: doc.facebook.show,
          instagram: doc.instagram.show,
          tiktok: doc.tiktok.show,
          linkedIn: doc.linkedIn.show,
          twitter: doc.twitter.show,
          beReal: doc.beReal.show,
        }));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userUID]);

  useEffect(() => {
    if (inputImage) {
      uploadImage();
    }
  }, [inputImage]);

  function handleOnChange(e) {
    setWasUpdated(true);
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  }

  function handleCheckbox(e) {
    setWasUpdated(true);
    setShowInfoCheckBox({
      ...showInfoCheckBox,
      [e.target.name]: e.target.checked,
    });
  }

  function autocompleteUrl(text, url) {
    if (text.includes(`https://${url}`) || text === "" || text === " ") {
      return text;
    } else {
      return `https://${url}` + text;
    }
  }

  const uploadImage = async () => {
    try {
      const userDocRef = doc(db, `users/${userUID}`);
      const storageRef = ref(
        storage,
        `users/${userUID}/profileImage/${inputImage.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, inputImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.error(error);
        },
        async () => {
          const imgURL = await getDownloadURL(uploadTask.snapshot.ref);
          await updateDoc(userDocRef, { profileURL: imgURL });
        }
      );
    } catch (error) {
      logError({
        error: error.message,
        source: "Tarjeta de presentación",
        metadata: {
          userUID,
          userInfo,
          showInfoCheckBox,
        },
      });
      console.error(error);
      Swal.fire({
        title: "Error al subir la imagen",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
    }
  };

  function saveInfo(e) {
    e.preventDefault();
    if (
      userInfo.whatsapp.toString().length !== 10 &&
      userInfo.whatsapp.toString().length !== 13
    ) {
      Swal.fire({
        text: "El número de whatsapp debe contar con 10 o 13 dígitos",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: theme.palette.primary.main,
      });
      return;
    }
    setIsSavingData(true);
    const saveData = {
      whatsapp: {
        number: userInfo.whatsapp.toString().trim(),
        show: showInfoCheckBox.whatsapp,
      },
      facebook: {
        url: userInfo.facebook.trim(),
        show: showInfoCheckBox.facebook,
      },
      instagram: {
        url: userInfo.instagram.trim(),
        show: showInfoCheckBox.instagram,
      },
      tiktok: {
        url: userInfo.tiktok.trim(),
        show: showInfoCheckBox.tiktok,
      },
      linkedIn: {
        url: userInfo.linkedIn.trim(),
        show: showInfoCheckBox.linkedIn,
      },
      twitter: {
        url: userInfo.twitter.trim(),
        show: showInfoCheckBox.twitter,
      },
      beReal: {
        url: userInfo.beReal.trim(),
        show: showInfoCheckBox.beReal,
      },
      timestamp: new Date().getTime(),
    };
    addDocument(`users/${userUID}/socialMediaProfiles`, saveData)
      .then((doc) => {
        console.log(doc);
        Swal.fire({
          title: "Datos guardados",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        setIsSavingData(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Ocurrió un error al guardar los datos, intenta más tarde",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: theme.palette.primary.main,
        });
        setIsSavingData(false);
      });
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          padding: "20px 20px 30px 20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "2rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "2rem", width: "100%" }} />
          <Skeleton variant="circular" width={160} height={160} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "100%" }} />
        </Box>
      </Box>
    );
  }

  return (
    <main>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          maxWidth: "760px",
          margin: "0 auto",
          padding: "20px",
        }}
      >
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Ingresa tus datos para compartir
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", marginBottom: "10px" }}
        >
          Los campos con casillas seleccionadas serán visibles al compartir la
          liga de tu tarjeta de presentación
        </Typography>
        <Stack>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
            onSubmit={saveInfo}
          >
            <Box
              sx={{
                margin: "0 auto",
              }}
            >
              {inputImage ? (
                <img
                  onClick={() => inputImgRef.current.click()}
                  ref={imgRef}
                  style={{
                    height: "180px",
                    width: "180px",
                    objectFit: "contain",
                    margin: "0 auto",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  alt="imagen cedula"
                />
              ) : (
                <img
                  onClick={() => inputImgRef.current.click()}
                  src={userInfo?.profileURL || userIcon}
                  alt="empty profile"
                  style={{
                    height: "180px",
                    width: "180px",
                    objectFit: "cover",
                    margin: "0 auto",
                    borderRadius: "50%",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                  }}
                />
              )}
              <input
                type="file"
                onChange={(e) => {
                  setInputImage(e.target.files[0]);
                }}
                accept="image/png, image/jpeg, image/jpg, application/pdf"
                ref={inputImgRef}
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: "0",
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: "-1",
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "right" }}
            >
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Mostrar
              </Typography>
            </Box>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                disabled
                name="firstName"
                label="Nombre"
                fullWidth
                value={userInfo.firstName}
              />
              <Switch sx={{ marginBottom: "20px" }} defaultChecked disabled />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                disabled
                fullWidth
                name="lastName"
                label="Apellido"
                value={userInfo.lastName}
                onChange={handleOnChange}
              />
              <Switch sx={{ marginBottom: "20px" }} defaultChecked disabled />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                fullWidth
                disabled
                name="email"
                label="Correo electrónico"
                value={userInfo.email}
                onChange={handleOnChange}
              />
              <Switch sx={{ marginBottom: "20px" }} defaultChecked disabled />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                fullWidth
                name="whatsapp"
                label="Número de WhatsApp"
                error={phoneError}
                helperText={
                  phoneError ? "El número debe tener 10 o 13 dígitos" : " "
                }
                value={userInfo.whatsapp}
                onChange={(e) => {
                  if (
                    e.target.value.toString().length !== 10 &&
                    e.target.value.toString().length !== 13
                  ) {
                    setPhoneError(true);
                  } else {
                    setPhoneError(false);
                  }
                  handleOnChange(e);
                }}
                type="number"
                onBlur={(e) => {
                  if (e.target.value.length === 10) {
                    setUserInfo({
                      ...userInfo,
                      whatsapp: "521" + e.target.value,
                    });
                  }
                }}
              />
              <Switch
                sx={{ marginBottom: "20px" }}
                checked={showInfoCheckBox.whatsapp}
                name="whatsapp"
                onChange={handleCheckbox}
              />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                fullWidth
                name="facebook"
                label="Facebook"
                value={userInfo.facebook}
                onChange={handleOnChange}
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    facebook: autocompleteUrl(e.target.value, "facebook.com/"),
                  });
                }}
              />
              <Switch
                sx={{ marginBottom: "20px" }}
                checked={showInfoCheckBox.facebook}
                name="facebook"
                onChange={handleCheckbox}
              />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                fullWidth
                name="instagram"
                label="Instagram"
                value={userInfo.instagram}
                onChange={handleOnChange}
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    instagram: autocompleteUrl(
                      e.target.value,
                      "instagram.com/"
                    ),
                  });
                }}
              />
              <Switch
                sx={{ marginBottom: "20px" }}
                checked={showInfoCheckBox.instagram}
                name="instagram"
                onChange={handleCheckbox}
              />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                fullWidth
                name="tiktok"
                label="TikTok"
                value={userInfo.tiktok}
                onChange={handleOnChange}
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    tiktok: autocompleteUrl(e.target.value, "tiktok.com/"),
                  });
                }}
              />
              <Switch
                sx={{ marginBottom: "20px" }}
                checked={showInfoCheckBox.tiktok}
                name="tiktok"
                onChange={handleCheckbox}
              />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                fullWidth
                name="linkedIn"
                label="LinkedIn"
                value={userInfo.linkedIn}
                onChange={handleOnChange}
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    linkedIn: autocompleteUrl(
                      e.target.value,
                      "linkedin.com/in/"
                    ),
                  });
                }}
              />
              <Switch
                sx={{ marginBottom: "20px" }}
                checked={showInfoCheckBox.linkedIn}
                name="linkedIn"
                onChange={handleCheckbox}
              />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                fullWidth
                name="twitter"
                label="Twitter"
                value={userInfo.twitter}
                onChange={handleOnChange}
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    twitter: autocompleteUrl(e.target.value, "twitter.com/"),
                  });
                }}
              />
              <Switch
                sx={{ marginBottom: "20px" }}
                checked={showInfoCheckBox.twitter}
                name="twitter"
                onChange={handleCheckbox}
              />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                helperText=" "
                fullWidth
                name="beReal"
                label="BeReal"
                value={userInfo.beReal}
                onChange={handleOnChange}
                onBlur={(e) => {
                  setUserInfo({
                    ...userInfo,
                    beReal: autocompleteUrl(e.target.value, "BeRe.al/"),
                  });
                }}
              />
              <Switch
                sx={{ marginBottom: "20px" }}
                checked={showInfoCheckBox.beReal}
                name="beReal"
                onChange={handleCheckbox}
              />
            </FormControl>
            <LoadingButton
              disabled={!wasUpdated}
              loading={isSavingData}
              variant="contained"
              type="submit"
              sx={{ textTransform: "none" }}
            >
              Actualizar datos
            </LoadingButton>
          </form>
        </Stack>
        <Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              Comparte tu tarjeta de presentación digital con este código QR o
              con el siguiente icono:
            </Typography>
            <Tooltip
              title={
                <Typography>
                  Comparte tu tarjeta de presentación desde este botón.
                </Typography>
              }
            >
              <IconButton
                onClick={() => {
                  navigator.share({
                    title: "Tarjeta de presentación",
                    text: "Hola, te comparto mi tarjeta de presentación digital.",
                    url: referURL,
                  });
                }}
              >
                <ShareIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
          <img
            style={{
              width: "200px",
              height: "200px",
              margin: "0 auto",
            }}
            src={`https://api.qrserver.com/v1/create-qr-code/?data=${referURL}&size=200x200`}
            alt=""
          />
        </Stack>
        <Button
          sx={{ textTransform: "none" }}
          variant="outlined"
          onClick={() => {
            window.open(referURL, "_blank");
          }}
        >
          Ver mi tarjeta
        </Button>
      </Box>
    </main>
  );
}
