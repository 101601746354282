import { addDays, format } from "date-fns";
import { es } from "date-fns/locale";
import isVehicleInsured from "./isVehicleInsured";

function getInsuranceStatusMessage(vehicle) {
  if (!vehicle) return "";

  const [isInsured, status] = isVehicleInsured(vehicle);

  if (isInsured) {
    if (status === "grace-period") {
      return "Seguro activo con adeudo pendiente";
    }
    return "Seguro activo";
  }

  const insuranceEndDate = new Date(
    vehicle.insuranceEndYear,
    vehicle.insuranceEndMonth - 1,
    vehicle.insuranceEndDay
  );

  const maxPaymentDate = addDays(insuranceEndDate, 30);

  return `Seguro cancelado desde el ${format(
    maxPaymentDate,
    "dd 'de' MMMM 'de' yyyy",
    { locale: es }
  )}`;
}

export default getInsuranceStatusMessage;
