import { Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { getContactPhone } from "../utils";
import Dialog from "./Dialog";
import { roboInitialState } from "./RoboTrackingContext";
import { roboReducer } from "./RoboTrackingContext";
import { getDeviceCurrentPosition } from "../utils";

const Location = ({ whatsappUrl, getUserLocation }) => {
  const [openModal, setOpenModal] = useState(false);
  const [sucess, setSucess] = useState(true);
  const [contentModal, setContentModal] =
    useState(`Para agilizar el servicio requerimos que nos permitas acceder a tu
  ubicación ¿Estás de acuerdo?`);
  const [roboState, dispatch] = useReducer(roboReducer, roboInitialState);

  const claimsDepartmentNumber = getContactPhone();
  useEffect(() => {
    setOpenModal(true);
  }, []);
  useEffect(() => {
    console.log(roboState);
  }, [roboState]);
  const handleOnCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Dialog
        isOpen={openModal}
        content={
          <Typography>
            {contentModal}
            {!sucess && (
              <>
                <a href={whatsappUrl}>aquí</a>
                <Typography sx={{ display: "inline" }}>
                  {" "}
                  para comunicarte directamente
                </Typography>
              </>
            )}
          </Typography>
        }
        Actions={
          <Stack spacing={2} direction="row">
            {sucess && (
              <>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    getDeviceCurrentPosition(
                      ({ lat, long }) => {
                        getUserLocation(lat, long);
                        setOpenModal(false);
                      } /*****/,
                      () => {
                        setContentModal(
                          `No podremos continuar registrando tu reporte por este medio. Puedes continuar tu reporte dando clic `
                        );
                        setSucess(false);
                      }
                    );
                  }}
                  sx={{
                    textTransform: "initial",
                  }}
                >
                  Si
                </Button>
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setContentModal(
                        `No podremos continuar registrando tu reporte por este medio. Puedes continuar tu reporte dando clic `
                      );
                      setSucess(false);
                    }}
                    sx={{
                      textTransform: "initial",
                    }}
                  >
                    No
                  </Button>
                </a>
              </>
            )}
            {!sucess && (
              <Button
                variant="contained"
                onClick={() => {
                  getDeviceCurrentPosition(
                    ({ lat, long }) => {
                      getUserLocation(lat, long);
                      setOpenModal(false);
                    } /*****/,
                    () => {
                      setContentModal(
                        `No podremos continuar registrando tu reporte por este medio. Puedes continuar tu reporte dando clic `
                      );
                      setSucess(false);
                    }
                  );
                }}
                sx={{
                  textTransform: "initial",
                }}
              >
                Prefiero otorgar permisos de ubicación
              </Button>
            )}
          </Stack>
        }
      />
    </>
  );
};

export default Location;
