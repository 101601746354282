import {
  ListItemButton,
  ImageListItem,
} from "@mui/material";

export default function LinkListImage({href="", src="", alt=""}) {
  return (
    <ListItemButton
    component="a"
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    <ImageListItem>
      <img
        src={src}
        alt={alt}
      />
    </ImageListItem>
  </ListItemButton>
  )
}
