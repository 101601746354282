import { LoadingButton } from "@mui/lab";
import { Box, ButtonBase, Stack, TextField, Typography } from "@mui/material";
import Car from "../../Assets/AutoGris.svg";
import CarActive from "../../Assets/AutoVerde.svg";
import Bici from "../../Assets/BicicletaGris.svg";
import BiciActive from "../../Assets/BicicletaVerde.svg";
import Moto from "../../Assets/MotoGris.svg";
import MotoActive from "../../Assets/MotoVerde.svg";
import Monopatin from "../../Assets/MonopatinGris.svg";
import MonopatinActive from "../../Assets/MonopatinVerde.svg";
import BiciElectrica from "../../Assets/BiciElectricaGris.svg";
import BiciElectricaActive from "../../Assets/BiciElectricaVerde.svg";
import Otro from "../../Assets/OtroGris.svg";
import OtroActive from "../../Assets/OtroVerde.svg";

const VEHICLE_TYPES = [
  {
    type: "auto",
    imgActive: CarActive,
    imgInActive: Car,
  },
  {
    type: "moto",
    imgActive: MotoActive,
    imgInActive: Moto,
  },
  {
    type: "bike",
    imgActive: BiciActive,
    imgInActive: Bici,
  },
  {
    type: "eBike",
    imgActive: BiciElectricaActive,
    imgInActive: BiciElectrica,
  },
  {
    type: "scooter",
    imgActive: MonopatinActive,
    imgInActive: Monopatin,
  },
  {
    type: "other",
    imgActive: OtroActive,
    imgInActive: Otro,
  },
];

function VehicleButton({
  vehicleData,
  imgActive,
  imgInActive,
  type,
  handleSelectVehicle,
}) {
  const isSelected = vehicleData.type === type;
  return (
    <ButtonBase
      sx={{ background: "transparent", borderRadius: "50%" }}
      onClick={() => {
        handleSelectVehicle(type);
      }}
    >
      {isSelected ? (
        <img src={imgActive} alt="opcion seleccionado" />
      ) : (
        <img src={imgInActive} alt="opcion no seleccionada" />
      )}
    </ButtonBase>
  );
}

export default function VehicleDataForm({
  onSubmit,
  vehicleData,
  setVehicleData,
  isLoading,
}) {
  function handleOnChange(e) {
    if (
      e.target.name === "model" &&
      (!Boolean(vehicleData.name) || vehicleData.name === vehicleData.model)
    ) {
      setVehicleData((prev) => ({
        ...prev,
        name: e.target.value,
        [e.target.name]: e.target.value,
      }));
    } else {
      setVehicleData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  }

  function handleSelectVehicle(vehicleType) {
    setVehicleData((prev) => ({
      ...prev,
      type: vehicleType,
    }));
  }

  return (
    <Stack
      component={"form"}
      onSubmit={onSubmit}
      sx={{ display: "grid", gap: "18px" }}
      paddingTop={"24px"}
    >
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        ¿Qué vehículo tienes?
      </Typography>
      <Box
        sx={{
          marginBottom: "12px",
          width: "100%",
          display: "grid",
          placeItems: "center",
          gap: ["16px", "12px"],
          gridTemplateColumns: [
            "1fr 1fr 1fr",
            "1fr 1fr 1fr",
            "1fr 1fr 1fr 1fr 1fr 1fr",
          ],
        }}
      >
        {VEHICLE_TYPES.map((item) => (
          <VehicleButton
            type={item.type}
            vehicleData={vehicleData}
            imgActive={item.imgActive}
            imgInActive={item.imgInActive}
            handleSelectVehicle={handleSelectVehicle}
          />
        ))}
      </Box>
      <TextField
        variant="standard"
        label="Marca"
        name="brand"
        value={vehicleData.brand}
        onChange={handleOnChange}
      />
      <TextField
        variant="standard"
        label="Modelo"
        name="model"
        value={vehicleData.model}
        onChange={handleOnChange}
      />
      {["auto", "moto", "other"].includes(vehicleData.type) && (
        <TextField
          variant="standard"
          label={`Año`}
          name="year"
          type="number"
          value={vehicleData.year ? vehicleData.year : ""}
          onChange={handleOnChange}
        />
      )}
      <TextField
        variant="standard"
        label="Apodo"
        name="name"
        value={vehicleData.name}
        onChange={handleOnChange}
      />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        sx={{ textTransform: "none" }}
        onClick={onSubmit}
        type="submit"
      >
        Guardar
      </LoadingButton>
    </Stack>
  );
}
