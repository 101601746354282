import {
  Alert,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MobileHeader from "../../Components/MobileHeader";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useQueryParams from "../../hooks/useQueryParams";
import { useCallback, useEffect, useRef, useState } from "react";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import Swal from "sweetalert2";
import { getDeviceName } from "../../utils/getDeviceName";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { LoadingButton } from "@mui/lab";
import smartphoneFrontEj from "../../Assets/Cel-frontal.png";
import laptopFrontEj from "../../Assets/Lap.png";
import smartphoneBackEj from "../../Assets/Cel-trasero.png";
import tabletEj from "../../Assets/Tablet.png";
import CloseIcon from "@mui/icons-material/Close";
import Webcam from "react-webcam";
import useCountdown from "../../hooks/useCountdown";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import UploadIcon from "@mui/icons-material/Upload";
import ImageIcon from "@mui/icons-material/Image";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WhatsappService from "./WhatsappService";

const DevicesPhotosRegister = () => {
  const { user } = useUser();
  const [device, setDevice] = useState({});
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingImg, setIsSavingImg] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [isTaking, setIsTaking] = useState("front");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [imgs, setImgs] = useState({
    front: "",
    back: "",
  });
  const navigate = useNavigate();
  const query = useQueryParams();
  const uid = query.get("uid");
  const ig = query.get("ig");
  const webcamRef = useRef(null);

  const captureFront = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    await uploadImage(imageSrc, "front");

    setImgs((prev) => ({ ...prev, front: imageSrc }));
  }, [webcamRef]);

  const captureBack = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    await uploadImage(imageSrc, "back");
    setImgs((prev) => ({ ...prev, back: imageSrc }));
  }, [webcamRef]);

  const { secondsLeft, start } = useCountdown(
    isTaking === "front" ? captureFront : captureBack
  );

  const updatesRef = collection(
    db,
    `users/${uid}/smartphones/${ig}/userUpdates`
  );

  useEffect(() => {
    const getDevices = async () => {
      try {
        const deviceRef = doc(db, `users/${uid}/smartphones/${ig}`);
        const deviceSnapshot = await getDoc(deviceRef);
        const deviceData = deviceSnapshot.data();
        if (deviceData?.id) {
          setDevice({ ...deviceData, id: deviceData.id });

          setImgs((prev) => ({
            ...prev,
            ...(deviceData.pictureDeviceFront && {
              front: deviceData.pictureDeviceFront,
            }),
            ...(deviceData.pictureDeviceBack && {
              back: deviceData.pictureDeviceBack,
            }),
          }));
        } else {
          navigate("/device-notfound");
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          text: "Lo sentimos, ocurrio un error inesperado.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (uid && ig) getDevices();
  }, [uid, ig]);

  const checkImages = () => {
    if (!imgs.front || (device.type === "smartphone" && !imgs.back)) {
      return Swal.fire({
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        text: `Es necesario que tomes las fotografías desde tu ${getDeviceName(
          device.type
        )} para avanzar.`,
      });
    }
    setConfirmOpen(true);
  };

  const congratsPicture = () => {
    Swal.fire({
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/auto-clupp.appspot.com/o/Clupp_Dedoarriba.png?alt=media&token=11c2a70b-f797-4927-ac50-70f91df5bfb4",
      imageHeight: 300,
      confirmButtonColor: "#00bbee",
      title: "¡Imagen cargada!",
      text:
        isTaking === "front"
          ? `¡Genial! Tu ${getDeviceName(device.type)} se ve increíble.`
          : "Wow, también se ve genial de atrás.",
    });
    setOpenCamera(false);
  };

  const confirmContinue = async () => {
    setIsSavingImg(true);
    try {
      navigate(
        `/registro/gadgets/final?uid=${uid}&ig=${ig}&type=${getDeviceName(
          device.type
        )}`
      );
    } catch (err) {
      console.log("Error: ", err);
      Swal.fire({
        icon: "error",
        title: "Error al cargar la imagen",
        text: "Hubo un problema al cargar la imagen. Por favor, inténtalo de nuevo más tarde.",
      });
    } finally {
      setIsSavingImg(false);
    }
  };

  async function uploadImage(imageInput, isTaking) {
    try {
      const storageRef = ref(
        storage,
        `users/${uid}/smartphones/${ig}/${isTaking}-${Date.now()}`
      );
      uploadString(storageRef, imageInput, "data_url")
        .then(async (snapshot) => {
          const downloadURL = await getDownloadURL(snapshot.ref);
          const deviceRef = doc(db, `users/${uid}/smartphones/${ig}`);
          await updateDoc(deviceRef, {
            [isTaking === "front" ? "pictureDeviceFront" : "pictureDeviceBack"]:
              downloadURL,
          });

          await addDoc(updatesRef, {
            [isTaking === "front" ? "pictureDeviceFront" : "pictureDeviceBack"]:
              downloadURL,
            timestamp: Date.now(),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
  const videoConstraints = {
    facingMode: "environment",
  };
  return isLoading ? (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      <CircularProgress />
    </Stack>
  ) : (
    <>
      <MobileHeader user={user} showGradientHeader={false} />
      <Stack
        sx={{
          position: "relative",
          height: ["90vh", "100%"],
          width: ["95%", "50%"],
          margin: ["0px auto 40px auto", "0px auto 140px auto"],
        }}
      >
        <Box sx={{ p: 2, marginTop: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            ¡Último paso! ¡Ya casi has terminado!
          </Typography>

          <Alert severity="info">{`Por último, toma las fotografías necesarias para la verificación de tu ${getDeviceName(
            device.type
          )}`}</Alert>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "30px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                padding: "0px 20px 0px 20px",
                marginBottom: "10px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              {`Toma una selfie a la pantalla de tu ${getDeviceName(
                device.type
              )} frente a un espejo utilizando la cámara FRONTAL de tu ${getDeviceName(
                device.type
              )}.`}
            </Typography>
            {imgs?.front ? (
              <Stack
                sx={{
                  width: "200px",
                  height: "200px",
                  maxHeight: "200px",
                  mixHeight: "200px",
                  position: "relative",
                  textAlign: "center",
                  backgroundImage: `url(${imgs.front})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "80%",
                    marginBottom: "5px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setImgs((prev) => ({ ...prev, front: "" }));
                    setIsTaking("front");
                    setOpenCamera(true);
                  }}
                >
                  Repetir foto
                </Button>
              </Stack>
            ) : (
              <img
                src={
                  device.type === "smartphone"
                    ? smartphoneFrontEj
                    : device.type === "tablet"
                    ? tabletEj
                    : laptopFrontEj
                }
                alt="example-front"
                style={{
                  width: "60%",
                  borderRadius: "6px",
                }}
              />
            )}

            {device &&
              device?.brand?.toLowerCase?.().includes?.("apple") &&
              device?.type === "laptop" && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    padding: "0px 20px 0px 20px",
                    marginBottom: "10px",
                  }}
                >
                  En caso de que tu computadora sea una Mac, accede a la app
                  Photo Booth.
                </Typography>
              )}

            <LoadingButton
              sx={{
                marginTop: "10px",
                textTransform: "none",
              }}
              disabled={imgs.front}
              loading={isSavingImg}
              startIcon={
                imgs.front ? <CheckCircleIcon sx={{ color: "green" }} /> : <></>
              }
              onClick={() => {
                setOpenCamera(true);
                setIsTaking("front");
              }}
              variant={imgs.front ? "text" : "contained"}
            >
              {imgs.front ? `${device.model}-frontal` : "Comenzar"}
            </LoadingButton>
          </Stack>
          {device?.type === "smartphone" && (
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "80px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  padding: "0px 20px 0px 20px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                {`Toma una selfie a la pantalla de tu ${getDeviceName(
                  device.type
                )} frente a un espejo utilizando la cámara TRASERA de tu ${getDeviceName(
                  device.type
                )}.`}
              </Typography>
              {imgs?.back ? (
                <Stack
                  sx={{
                    width: "200px",
                    height: "200px",
                    maxHeight: "200px",
                    mixHeight: "200px",
                    position: "relative",
                    textAlign: "center",
                    backgroundImage: `url(${imgs.back})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "80%",
                      marginBottom: "5px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setImgs((prev) => ({ ...prev, back: "" }));
                      setIsTaking("back");
                      setOpenCamera(true);
                    }}
                  >
                    Repetir foto
                  </Button>
                </Stack>
              ) : (
                <img
                  src={smartphoneBackEj}
                  alt="example-back"
                  style={{
                    width: "60%",
                    borderRadius: "6px",
                  }}
                />
              )}

              <LoadingButton
                sx={{
                  marginTop: "10px",
                }}
                disabled={imgs.back}
                loading={isSavingImg}
                startIcon={
                  imgs.back ? (
                    <CheckCircleIcon sx={{ color: "green" }} />
                  ) : (
                    <></>
                  )
                }
                onClick={() => {
                  setOpenCamera(true);
                  setIsTaking("back");
                }}
                variant={imgs.back ? "text" : "contained"}
              >
                {imgs.back ? `${device.model}-trasera` : "Comenzar"}
              </LoadingButton>
            </Stack>
          )}
        </Box>
      </Stack>
      <Stack
        sx={{
          position: "fixed",
          zIndex: 5,
          bottom: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: "0 -3px 20px 3px rgba(0, 0, 0, 0.1)",
        }}
      >
        <LoadingButton
          sx={{
            width: ["80%", "30%"],
            textTransform: "capitalize",
            fontSize: "22px",
          }}
          loading={isSavingImg}
          onClick={checkImages}
          variant={"contained"}
        >
          {"Continuar"}
        </LoadingButton>
      </Stack>
      <Dialog fullWidth="lg" maxWidth={true} open={openCamera} fullScreen>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenCamera(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 1, flex: 1, fontSize: "16px" }}
              variant="h6"
              component="div"
            >
              Toma la foto {isTaking === "front" ? "FRONTAL" : "TRASERA"}
            </Typography>
          </Toolbar>
        </AppBar>
        {isTaking === "front" && imgs?.front === "" ? (
          <>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              {secondsLeft > 0 && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      border: "4px solid #fff",
                      padding: "10px 20px",
                      color: "#fff",
                      backgroundColor: "#bcbcbc23",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {secondsLeft}
                  </div>
                </div>
              )}

              <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>

            <div
              style={{
                position: "absolute",
                bottom: "10px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <button
                variant="contained"
                onClick={() => start(5)}
                style={{
                  backgroundColor: "#00bbee",
                  left: "45px",
                  color: "#fff",
                  padding: "15px",
                  borderRadius: "6px",
                  width: "80%",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                <AddAPhotoIcon sx={{ fontSize: "20px", marginRight: "5px" }} />
                Tomar foto
              </button>
            </div>
          </>
        ) : isTaking === "back" && imgs?.back === "" ? (
          <>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              {secondsLeft > 0 && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    height: "100vh",
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      border: "4px solid #fff",
                      backgroundColor: "#bcbcbc23",
                      padding: "10px 20px",
                      color: "#fff",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {secondsLeft}
                  </div>
                </div>
              )}

              <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <button
                variant="contained"
                onClick={() => start(5)}
                style={{
                  backgroundColor: "#00bbee",
                  left: "45px",
                  color: "#fff",
                  padding: "15px",
                  borderRadius: "6px",
                  width: "80%",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                <AddAPhotoIcon sx={{ fontSize: "20px", marginRight: "5px" }} />
                Tomar foto
              </button>
            </div>
          </>
        ) : (
          <>
            <img
              src={isTaking === "front" ? imgs.front : imgs.back}
              alt="screenshot"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <button
              variant="contained"
              onClick={() =>
                setImgs((prev) => ({
                  ...prev,
                  [isTaking]: "",
                }))
              }
              style={{
                backgroundColor: "#00bbee",
                color: "#fff",
                padding: "10px",
                margin: "10px auto 5px auto",
                borderRadius: "6px",
                width: "80%",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AddAPhotoIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
              Tomar nuevamente
            </button>
            <LoadingButton
              loading={isSavingImg}
              startIcon={<UploadIcon />}
              sx={{
                width: "80%",
                margin: "10px auto",
                textTransform: "capitalize",
              }}
              onClick={congratsPicture}
              variant="contained"
            >
              Continuar
            </LoadingButton>
          </>
        )}
      </Dialog>
      <ConfirmationDialog
        confirmOpen={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        setImgs={setImgs}
        isSavingImg={isSavingImg}
        confirmContinue={confirmContinue}
        imgs={imgs}
        type={device.type}
      />
      <WhatsappService uid={uid} haveFooter={true} />
    </>
  );
};

const ConfirmationDialog = ({
  confirmOpen,
  setConfirmOpen,
  setImgs,
  isSavingImg,
  confirmContinue,
  imgs,
  type,
}) => {
  return (
    <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
      <DialogTitle>{"Antes de avanzar"}</DialogTitle>
      <DialogContent
        sx={{
          overflow: "hidden",
        }}
      >
        {imgs?.front && (
          <img
            src={imgs.front}
            alt="frontPic"
            style={{
              width: type === "laptop" ? "100%" : "50%",
              minHeight: "200px",
            }}
          />
        )}
        {imgs?.back && (
          <img
            src={imgs.back}
            alt="backPic"
            style={{
              width: type === "laptop" ? "100%" : "50%",
              minHeight: "200px",
            }}
          />
        )}
        <DialogContentText>
          Antes de avanzar, por favor verifica que las fotos que tomaste estén
          bien iluminadas, no estén cortadas y estén bien enfocadas. Esto
          asegurará la calidad de tus imágenes. ¿Estás seguro de continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          width: "99%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "80%",
            marginTop: "5px",
            marginBottom: "5px",
            textTransform: "none",
          }}
          onClick={() => {
            setImgs({
              front: "",
              back: "",
            });
            setConfirmOpen(false);
          }}
        >
          Repetir fotos
        </Button>
        <LoadingButton
          loading={isSavingImg}
          sx={{
            width: "80%",
            marginTop: "5px",
            marginBottom: "5px",
            marginRight: "8px",
            textTransform: "none",
          }}
          onClick={confirmContinue}
          variant="contained"
        >
          Continuar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DevicesPhotosRegister;
