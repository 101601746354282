const ENDPOINT =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/collection";

async function getCustomerVehicles(customerUID) {
  const url = new URL(ENDPOINT);

  url.searchParams.append("collectionPath", `users/${customerUID}/vehicles`);

  try {
    const response = await fetch(url);

    const json = await response.json();

    const vehicles = json.docs.map(({ doc, id }) => ({ vehicle: doc, id }));

    // filter out the vehicles with cancelled insurance
    return vehicles
    // .filter(({ vehicle }) =>
    //   isVehicleWithActiveInsurance(vehicle)
    // );
  } catch (error) {
    throw new Error("server-error");
  }
}

function isVehicleWithActiveInsurance(vehicle) {
  return vehicle.deleted === false;
}

export default getCustomerVehicles;
