import React, { useState, useEffect } from "react";

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const map = (value, inMin, inMax, outMin, outMax) =>
  ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

const BETA_SENSITIVITY = 3;
const GAMMA_SENITIVITY = 4;
const BusinessCard = ({ children, style }) => {
  const [rotation, setRotation] = useState({ beta: 0, gamma: 0 });

  useEffect(() => {
    const handleOrientation = (event) => {
      try {
        let { beta, gamma } = event;

        // Adjust beta sensitivity
        beta = beta * BETA_SENSITIVITY;

        // Make sure the card only rotates between 90 and 120 degrees on the x-axis
        const normalizedBeta = clamp(beta, 90, 120);
        // Map the normalized beta value to a value between -10 and 10
        // This means that when the device is at 90 degrees, the card will rotate -10 degrees
        // When the device is at 120 degrees, the card will rotate 10 degrees
        beta = map(normalizedBeta, 90, 120, -10, 10);

        gamma = gamma * GAMMA_SENITIVITY;

        gamma = clamp(gamma, -25, 25);

        setRotation({ beta: beta, gamma });
      } catch (e) {
        console.error(e);
      }
    };

    window.addEventListener("deviceorientation", handleOrientation);

    return () => {
      window.removeEventListener("deviceorientation", handleOrientation);
    };
  }, []);

  const { beta, gamma } = rotation;

  const cardStyle = {
    backgroundImage:
      "linear-gradient(120deg, rgb(253 251 251 / 67%) 0%, rgb(235 237 238) 100%)",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transform: `perspective(600px) rotateX(${beta}deg) rotateY(${gamma}deg) `,
    transition: "transform 0.1s ease-out",
    ...style,
  };

  return <div style={cardStyle}>{children}</div>;
};

export default BusinessCard;
