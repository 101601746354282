import Dialog from "../../Components/Dialog";
import { Typography, Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function NoCedulaModal({
  isOpen,
  onClose,
  inputImgRef,
  sendInformation,
  isLoading,
}) {
  function continueWithoutCedula() {
    sendInformation(null, true);
  }
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      content={
        <Box>
          <Typography>
            ADVERTENCIA: Si algo no coincide de manera EXACTA no podremos hacer
            tu factura.
          </Typography>
        </Box>
      }
      Actions={
        <>
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            onClick={continueWithoutCedula}
          >
            Continuar
          </LoadingButton>
          <Button
            variant="contained"
            onClick={() => {
              inputImgRef.current.click();
              onClose();
            }}
          >
            Cargar CIF
          </Button>
        </>
      }
    >
      <h1>hola</h1>
    </Dialog>
  );
}
