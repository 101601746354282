import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MobileHeader from "../../Components/MobileHeader";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useQueryParams from "../../hooks/useQueryParams";
import { useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import Swal from "sweetalert2";
import { getDeviceName } from "../../utils/getDeviceName";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import useReadImage from "../../hooks/useReadImage";
import { LoadingButton } from "@mui/lab";
import UploadIcon from "@mui/icons-material/Upload";
import Cluppcito from "../../Assets/Clupp_Normal.png";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import WhatsappService from "./WhatsappService";

const DeviceRegister = () => {
  const { user } = useUser();
  const [device, setDevice] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingImg, setIsSavingImg] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [formData, setFormData] = useState({
    IMEI: "",
    serialNumber: "",
    phone: "",
  });
  const { inputImage, setInputImage, imgRef, inputImgRef } = useReadImage();
  const navigate = useNavigate();
  const query = useQueryParams();
  const uid = query.get("uid");
  const ig = query.get("ig");

  useEffect(() => {
    const getDevices = async () => {
      try {
        const deviceRef = doc(db, `users/${uid}/smartphones/${ig}`);
        const deviceSnapshot = await getDoc(deviceRef);
        const deviceData = deviceSnapshot.data();

        setFormData({
          IMEI: deviceData.IMEI || "",
          serialNumber: deviceData.serialNumber || "",
          phone: deviceData.phone || "",
        });

        if (deviceData.IMEIPictureURL) setImageData(deviceData.IMEIPictureURL);
        if (deviceData.systemInfoPictureURL)
          setImageData(deviceData.systemInfoPictureURL);
        deviceData?.id
          ? setDevice({ ...deviceData, id: deviceData.id })
          : navigate("/device-notfound");
      } catch (err) {
        console.log("Error ->", err);
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          text: "Lo sentimos, ocurrio un error inesperado.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (uid && ig) getDevices();
  }, [uid, ig]);

  const updatesRef = collection(
    db,
    `users/${uid}/smartphones/${ig}/userUpdates`
  );

  const handleChange = (e) => {
    if (e.target.name === "IMEI" && e.target.value.length > 17) return;
    if (e.target.name === "phone" && e.target.value.length > 10) return;

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function uploadImage() {
    setIsSavingImg(true);
    try {
      const storageRef = ref(storage, `users/${uid}/smartphones/${ig}`);
      const uploadTask = uploadBytesResumable(storageRef, inputImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.error(error);
          setIsSavingImg(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            setImageData(downloadURL);
            const deviceRef = doc(db, `users/${uid}/smartphones/${ig}`);
            await updateDoc(deviceRef, {
              [device.type === "smartphone"
                ? "IMEIPictureURL"
                : "systemInfoPictureURL"]: downloadURL,
            });

            await addDoc(updatesRef, {
              [device.type === "smartphone"
                ? "IMEIPictureURL"
                : "systemInfoPictureURL"]: downloadURL,
              timestamp: Date.now(),
            });
            Swal.fire({
              icon: "success",
              title: "¡Imagen cargada!",
              confirmButtonColor: "#00bbee",
              text: "La imagen se cargó correctamente.",
            });
            setIsSavingImg(false);
          });
        }
      );
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error al cargar la imagen",
        text: "Hubo un problema al cargar la imagen. Por favor, inténtalo de nuevo más tarde.",
      });
      setIsSavingImg(false);
    }
  }

  useEffect(() => {
    if (inputImage) {
      uploadImage();
    }
  }, [inputImage]);

  const phoneError = formData.phone.length > 0 && formData.phone.length !== 10;

  const confirmAndCotinue = async () => {
    setIsSavingImg(true);
    try {
      const deviceRef = doc(db, `users/${uid}/smartphones/${ig}`);

      const fieldToUpdate =
        device.type === "smartphone" ? "IMEI" : "serialNumber";

      await updateDoc(deviceRef, {
        [fieldToUpdate]:
          device.type === "smartphone" ? formData.IMEI : formData.serialNumber,
        ...(device.type === "smartphone" && { phone: formData.phone }),
      });

      await addDoc(updatesRef, {
        [fieldToUpdate]:
          device.type === "smartphone" ? formData.IMEI : formData.serialNumber,
        ...(device.type === "smartphone" && { phone: formData.phone }),
        timestamp: Date.now(),
      });

      navigate(`/registro/gadgets/fotos?uid=${uid}&ig=${ig}`);
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setIsSavingImg(false);
    }
  };

  const checkImagesAndInfo = () => {
    if (device.type === "smartphone" && formData.phone.length !== 10) {
      Swal.fire({
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        text: `Es necesario que ingreses un número de celular válido para continuar.`,
      });
      return;
    }

    if (
      device.type === "smartphone" &&
      (formData.IMEI.length < 15 || formData.IMEI.length > 17)
    ) {
      Swal.fire({
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        text: `Es necesario que llenes la información sobre tu IMEI para avanzar.`,
      });
      return;
    }
    if (device.type !== "smartphone" && !formData.serialNumber) {
      Swal.fire({
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        text: `Es necesario que llenes la información sobre tu número de serie para avanzar.`,
      });
      return;
    }
    if (!imageData) {
      Swal.fire({
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        text: `Es necesario que cargues la foto de tu ${
          device.type === "smartphone" ? "IMEI" : "Información del Sistema"
        } para avanzar.`,
      });
      return;
    }
    setConfirmOpen(true);
  };

  // The IMEI number must have between 15 - 17 digits
  const IMEIError =
    formData.IMEI.length > 0 &&
    (formData.IMEI.length < 15 || formData.IMEI.length > 17);

  return isLoading ? (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      <CircularProgress />
    </Stack>
  ) : (
    <>
      <MobileHeader user={user} showGradientHeader={false} />
      <Stack
        sx={{
          position: "relative",
          height: "100%",
          width: ["95%", "50%"],
          margin: ["0px auto 250px auto", "0px auto 140px auto"],
        }}
      >
        <Box
          sx={{
            p: 2,
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={Cluppcito}
            alt="cluppcito"
            width={"160px"}
            style={{ marginBottom: "10px" }}
          />

          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            ¡Casi estamos! Solo unos detalles más
          </Typography>
          {device.type === "smartphone" ? (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "10px",
                  marginTop: "5px",
                }}
              >
                {`Para registrar tu celular ${device.brand} ${device.model} es necesario que le tomes una captura de pantalla a la clave IMEI que muestra tu celular al marcar al *#06# desde tu celular.`}
              </Typography>

              <Button
                variant="outlined"
                startIcon={<PermPhoneMsgIcon />}
                href={`tel:*#06#`}
              >
                Marcar *#06#
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                {`Para registrar tu ${getDeviceName(device.type)} ${
                  device.brand
                } ${
                  device.model
                } es necesario que le tomes una captura de pantalla a la información de tu ${getDeviceName(
                  device.type
                )} cuando ingresas a “Información del sistema”.`}
              </Typography>
            </>
          )}

          <Stack
            sx={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              margin: "10px auto 0px auto",
            }}
          >
            <input
              type="file"
              onChange={(e) => {
                setInputImage(e.target.files[0]);
              }}
              accept="image/jpeg, image/jpg"
              ref={inputImgRef}
              style={{
                width: "0.1px",
                height: "0.1px",
                opacity: "0",
                overflow: "hidden",
                position: "absolute",
                zIndex: "-1",
              }}
            />
            {imageData ? (
              <Stack
                sx={{
                  width: "200px",
                  height: "200px",
                  maxHeight: "200px",
                  mixHeight: "200px",
                  position: "relative",
                  textAlign: "center",
                  backgroundImage: `url(${imageData})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "80%",
                    marginBottom: "5px",
                  }}
                  onClick={() => {
                    setInputImage(null);
                    setImageData(null);
                    setConfirmOpen(false);
                  }}
                >
                  Repetir foto
                </Button>
              </Stack>
            ) : (
              <LoadingButton
                loading={isSavingImg}
                startIcon={<UploadIcon />}
                disabled={inputImage}
                ref={imgRef}
                onClick={() => {
                  inputImgRef.current.click();
                }}
                variant={"contained"}
              >
                {"Cargar Foto"}
              </LoadingButton>
            )}
          </Stack>
        </Box>

        {device.type === "smartphone" ? (
          <>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "10px",
              }}
            >{`Por favor, ingresa el número de IMEI de tu ${getDeviceName(
              device.type
            )}.`}</Typography>
            <TextField
              sx={{
                marginTop: "10px",
              }}
              label="IMEI"
              variant="filled"
              type="number"
              name="IMEI"
              value={formData.IMEI}
              error={IMEIError}
              helperText={
                IMEIError ? (
                  "El IMEI debe tener 15 o 17 dígitos"
                ) : (
                  <span>
                    El IMEI es un número de 15 dígitos que se encuentra en la
                    caja de tu celular o marcando{" "}
                    <a href="tel:*#06#">
                      {" "}
                      <b>*#06#</b>
                    </a>{" "}
                    en tu celular.
                  </span>
                )
              }
              onChange={handleChange}
              inputProps={{ inputMode: "numeric" }}
            />

            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              Por favor, ingresa tu número de celular
            </Typography>

            <TextField
              sx={{
                marginTop: "10px",
              }}
              label="Número de celular"
              variant="filled"
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              inputProps={{ inputMode: "numeric" }}
              helperText="El número debe tener 10 dígitos"
              error={phoneError}
            />
          </>
        ) : (
          <>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >{`Por favor, ingresa el número de serie de tu ${getDeviceName(
              device.type
            )}.`}</Typography>
            <TextField
              label="Número de Serie"
              variant="filled"
              type="numer"
              name="serialNumber"
              value={formData.serialNumber}
              onChange={handleChange}
              inputProps={{ inputMode: "numeric" }}
            />
          </>
        )}
      </Stack>
      <Stack
        sx={{
          position: "fixed",
          zIndex: 5,
          bottom: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: "0 -3px 20px 3px rgba(0, 0, 0, 0.1)",
        }}
      >
        <LoadingButton
          sx={{
            width: ["80%", "30%"],
            textTransform: "capitalize",
            fontSize: "22px",
          }}
          onClick={checkImagesAndInfo}
          variant={"contained"}
        >
          {"Continuar"}
        </LoadingButton>
      </Stack>
      <ConfirmationDialog
        confirmOpen={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        imageData={imageData}
        type={device.type}
        setInputImage={setInputImage}
        isSavingImg={isSavingImg}
        confirmAndCotinue={confirmAndCotinue}
        setImageData={setImageData}
        inputImgRef={inputImgRef}
      />
      <WhatsappService uid={uid} haveFooter={true} />
    </>
  );
};

const ConfirmationDialog = ({
  confirmOpen,
  setConfirmOpen,
  imageData,
  setInputImage,
  isSavingImg,
  setImageData,
  confirmAndCotinue,
  type,
}) => {
  return (
    <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
      <DialogTitle>
        {type === "smartphone"
          ? "Asegúrate que el IMEI que ingresaste coincida con la imagen:"
          : "Asegúrate que el número de serie que ingresaste coincida con la imagen:"}
      </DialogTitle>
      <DialogContent>
        {imageData && (
          <img
            src={imageData}
            alt="inputImage"
            style={{
              width: "100%",
              minHeight: "200px",
              objectFit: "cover",
            }}
          />
        )}
        <DialogContentText
          sx={{
            overflow: "hidden",
          }}
        >
          Antes de continuar, por favor asegúrate de que los números sean
          claramente visibles en las fotos que subiste. ¿Estás seguro de
          continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          width: "99%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "80%",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          onClick={() => {
            setInputImage(null);
            setImageData(null);
            setConfirmOpen(false);
          }}
        >
          Repetir foto
        </Button>
        <LoadingButton
          loading={isSavingImg}
          sx={{
            width: "80%",
            marginTop: "5px",
            marginBottom: "5px",
            marginRight: "8px",
          }}
          onClick={confirmAndCotinue}
          variant="contained"
        >
          Continuar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceRegister;
