function isZipCodeInCdmxOrEdomex(zipCode) {
  // Convertir el código postal a un número entero
  const zipCodeInt = parseInt(zipCode, 10);

  // Verificar si el código postal está en el rango de CDMX o Edomex
  if (
    (zipCodeInt >= 0 && zipCodeInt <= 19000) ||
    (zipCodeInt >= 50000 && zipCodeInt <= 57999)
  ) {
    return true;
  } else {
    return false;
  }
}

export default isZipCodeInCdmxOrEdomex;
