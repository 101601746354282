import { useEffect, useState } from "react";
import { Box, Paper, Typography, Skeleton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { db } from "../../firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

const getTutorials = async (userUID) => {
  const q = query(
    collection(db, "IT/customers/tutorials"),
    where("deleted", "==", false),
    orderBy("timestamp", "desc")
  );

  const querySnapshot = await getDocs(q);
  const tutoriales = [];
  querySnapshot.forEach((doc) => {
    tutoriales.push({ ...doc.data(), id: doc.id });
  });
  return tutoriales;
};

export default function Tutoriales() {
  const [loomsVideoTutorials, setLoomsVideoTutorials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const sortVideos = [...loomsVideoTutorials].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = getTutorials()
      .then((data) => {
        setLoomsVideoTutorials(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    return unsubscribe;
  }, []);

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <Box
        sx={{
          margin: "0 20px 0 20px",
          paddingTop: "10px",
          paddingBottom: "30px",
        }}
      >
        <Box sx={{ maxWidth: "900px", margin: "0 auto" }}>
          <Typography
            sx={{ marginTop: "30px", marginBottom: "20px", fontWeight: "600", textAlign: "center" }}
            variant="h4"
          >
            Videos tutoriales
          </Typography>
          <Typography variant="body1">
            Te damos la bienvenida a los tutoriales. Aquí podrás consultar algunos videos para resolver algunas
            dudas o saber cómo usar la app.
          </Typography>
        </Box>
        {isLoading && (
          <>
            <Skeleton
              sx={{ maxWidth: "900px", margin: "0 auto", marginTop: "60px", }}
              variant="rectangular"
              width={"100%"}
              height={100}
            />
            <Skeleton
              sx={{ maxWidth: "900px", margin: "0 auto", marginTop: "60px", }}
              variant="rectangular"
              width={"100%"}
              height={100}
            />
            <Skeleton
              sx={{ maxWidth: "900px", margin: "0 auto", marginTop: "60px", }}
              variant="rectangular"
              width={"100%"}
              height={100}
            />
          </>
        )}
        {sortVideos.map((item) => (
          <Box key={item.timestamp}>
            <Box
              sx={{
                maxWidth: "900px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "40px",
                padding: "15px",
                flexDirection: ["column", "row"],
                border: "1px solid #00bbee",
                borderRadius: "3px",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontWeight: 600, textAlign: "center" }} variant="h6">{item.name}</Typography>
                <Typography>{item.description}</Typography>
              </Box>
              <Box sx={{width: "100%"}}>
                <a
                  style={{
                    textDecoration: "none",
                    width: "100%"
                  }}
                  href={`${item?.url}?hide_owner=true`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{ color: "white", width: "100%", textTransform: "none" }}
                  >
                    Ver video tutorial
                  </LoadingButton>
                </a>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}
