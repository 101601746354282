import { Button, Stack } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsappService = ({ uid, haveFooter = false }) => {
  return (
    <Stack
      sx={{
        position: "fixed",
        zIndex: 7,
        bottom: haveFooter ? "5.5rem" : "1rem",
        right: "5px",
        borderRadius: "50%",
        paddingTop: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        href={`https://wa.me/525577460177?text=Hola,%20necesito%20ayuda%20para%20registrar%20mi%20dispositivo%20mi%20uid%20es:%20${uid}👋`}
      >
        <WhatsAppIcon
          sx={{
            color: "green",
            fontSize: "40px",
          }}
        />
      </Button>
    </Stack>
  );
};

export default WhatsappService;
