import { Call, Email, WhatsApp } from "@mui/icons-material";
import { Divider, IconButton, ListItem, ListItemText } from "@mui/material";
import Accordion from "../../Components/Accordion";
import { Fragment } from "react";

function AccordionReferrals({ table, vehicles, earnings , amounts}) {
  return (
    <>
      <Accordion info1={table[0].amount} info2={table[0].state} panel="panel1">
        {table[0].guest.map((guest) =>
          vehicles.map((vehicle) => {
            const vehiclePath = vehicle.docs[0].ref._path.segments[1];
            const vehicleBrand = vehicle.docs[0].doc.brand;
            const vehicleModel = vehicle.docs[0].doc.model;
            const vehicleYear = vehicle.docs[0].doc.year;
            return guest.id === vehiclePath ? (
              <Fragment key={vehicle.docs[0].id}>
                <Divider style={{ margin: "0" }} />
                <ListItem>
                  <ListItemText
                    primary={`${
                      guest.doc.firstName
                        ? guest.doc.firstName
                        : "El usuario no 	especificó su nombre"
                    }`}
                    secondary={`${vehicleBrand} ${vehicleModel} ${
                      vehicleYear === 0 ? " " : vehicleYear
                    }`}
                  />
                </ListItem>
              </Fragment>
            ) : null;
          })
        )}
      </Accordion>
      <Accordion info1={table[1].amount} info2={table[1].state} panel="panel2">
        {table[1].guest.map((guest) =>
          vehicles.map((vehicle) => {
            const vehiclePath = vehicle.docs[0].ref._path.segments[1];
            const vehicleBrand = vehicle.docs[0].doc.brand;
            const vehicleModel = vehicle.docs[0].doc.model;
            const vehicleYear = vehicle.docs[0].doc.year;
            return guest.id === vehiclePath ? (
              <Fragment key={vehicle.docs[0].id}>
                <Divider style={{ margin: "0" }} />
                <ListItem>
                  <ListItemText
                    primary={`${
                      guest.doc.firstName
                        ? guest.doc.firstName
                        : "El usuario no 	especificó su nombre"
                    }`}
                    secondary={`${vehicleBrand} ${vehicleModel} ${
                      vehicleYear === 0 ? " " : vehicleYear
                    }`}
                  />
                  {guest.doc.email ? (
                    <a href={`mailto:${guest.doc.email}`}>
                      <IconButton style={{ marginBottom: "80%" }}>
                        <Email />
                      </IconButton>
                    </a>
                  ) : (
                    <div></div>
                  )}
                  {guest.doc.phone ? (
                    <>
                      <a href={`tel:${guest.doc.phone}`}>
                        <IconButton style={{ marginBottom: "80%" }}>
                          <Call />
                        </IconButton>
                      </a>
                      <a href={`https://wa.me/521${guest.doc.phone}`}>
                        <IconButton style={{ marginBottom: "80%" }}>
                          <WhatsApp />
                        </IconButton>
                      </a>
                    </>
                  ) : (
                    <div></div>
                  )}
                </ListItem>
              </Fragment>
            ) : null;
          })
        )}
      </Accordion>
      <Accordion info1={table[2].amount} info2={table[2].state} panel="panel3">
        {table[2].guest.map((guest) =>
          vehicles.map((vehicle) => {
            const vehiclePath = vehicle.docs[0].ref._path.segments[1];
            const vehicleBrand = vehicle.docs[0].doc.brand;
            const vehicleModel = vehicle.docs[0].doc.model;
            const vehicleYear = vehicle.docs[0].doc.year;
            return guest.id === vehiclePath ? (
              <Fragment key={vehicle.docs[0].id}>
                <Divider style={{ margin: "0" }} />
                <ListItem>
                  <ListItemText
                    primary={`${
                      guest.doc.firstName
                        ? guest.doc.firstName
                        : "El usuario no 	especificó su nombre"
                    }`}
                    secondary={`${vehicleBrand} ${vehicleModel} ${
                      vehicleYear === 0 ? " " : vehicleYear
                    }`}
                  />
                  {guest.doc.email ? (
                    <a href={`mailto:${guest.doc.email}`}>
                      <IconButton style={{ marginBottom: "80%" }}>
                        <Email />
                      </IconButton>
                    </a>
                  ) : (
                    <div></div>
                  )}
                  {guest.doc.phone ? (
                    <>
                      <a href={`tel:${guest.doc.phone}`}>
                        <IconButton style={{ marginBottom: "80%" }}>
                          <Call />
                        </IconButton>
                      </a>
                      <a href={`https://wa.me/521${guest.doc.phone}`}>
                        <IconButton style={{ marginBottom: "80%" }}>
                          <WhatsApp />
                        </IconButton>
                      </a>
                    </>
                  ) : (
                    <div></div>
                  )}
                </ListItem>
              </Fragment>
            ) : null;
          })
        )}
      </Accordion>
      <Accordion info1={table[3].amount} info2={table[3].state} panel="panel4">
        {table[3].guest.map((guest) =>
          vehicles.map((vehicle, index) => {
            const vehiclePath = vehicle.docs[0].ref._path.segments[1];
            return guest.id === vehiclePath ? (
              <Fragment key={vehicle.docs[0].id}>
                <Divider style={{ margin: "0" }} />
                <ListItem>
                  <ListItemText
                    primary={`${
                      guest.doc.firstName
                        ? guest.doc.firstName
                        : "El usuario no 	especificó su nombre"
                    }`}
                    secondary={`Ganaste $${earnings(vehicle, amounts[index])}`}
                  />
                  {guest.doc.email ? (
                    <a href={`mailto:${guest.doc.email}`}>
                      <IconButton style={{ marginBottom: "80%" }}>
                        <Email />
                      </IconButton>
                    </a>
                  ) : (
                    <div></div>
                  )}
                  {guest.doc.phone ? (
                    <>
                      <a href={`tel:${guest.doc.phone}`}>
                        <IconButton style={{ marginBottom: "80%" }}>
                          <Call />
                        </IconButton>
                      </a>
                      <a href={`https://wa.me/521${guest.doc.phone}`}>
                        <IconButton style={{ marginBottom: "80%" }}>
                          <WhatsApp />
                        </IconButton>
                      </a>
                    </>
                  ) : (
                    <div></div>
                  )}
                </ListItem>
              </Fragment>
            ) : null;
          })
        )}
      </Accordion>
    </>
  );
}

export default AccordionReferrals;
