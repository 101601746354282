const ENDPOINT =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/collection";

function getDocuments({
  collectionPath,
  limit,
  where = [],
  collectionGroupQuery = false,
  orderBy = [],
}) {
  const url = new URL(ENDPOINT);

  url.searchParams.append("collectionPath", collectionPath);
  if (collectionGroupQuery) {
    url.searchParams.append("groupQuery", true);
  }

  if (orderBy.length > 0) {
    const [field, direction] = orderBy;
    // posible values for direction "asc", "desc"
    url.searchParams.append("orderBy", field);
    url.searchParams.append("orderBy", direction);
  }

  if (limit) {
    url.searchParams.append("limit", limit);
  }

  if (where.length > 0) {
    const [field, condition, value] = where;
    // posible values for condition "==", ">=", "!==", "<="
    url.searchParams.append("where", field);
    url.searchParams.append("where", condition);
    url.searchParams.append("where", value);
  }

  return fetch(url);
}

export default getDocuments;
