import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import QueCubreModal from "../Modal/QueCubreModal";
import "./TablaCobertura.css";
import { useEffect, useState } from "react";

const TablaCoberturaDispositivos = ({
  selectedMobility,
  deviceValue: deviceValueInput = 0,
}) => {
  const [coverages, setCoverages] = useState([]);
  useEffect(() => {
    setCoverages(dinamicCoverages(coberturas, selectedMobility));
  }, [selectedMobility]);

  const deviceValue = deviceValueInput.toLocaleString("es-MX");
  const coberturas = [
    {
      cobertura: "Robo con violencia",
      queCubre:
        "Cubre el reembolso o reposición de tu dispositivo en caso de robo con violencia.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "40% del valor",
    },
    {
      cobertura: "Robo sin violencia",
      queCubre:
        "Cubre el reembolso o reposición de tu dispositivo en caso de robo sin violencia.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "40% del valor",
    },
    {
      cobertura: "Rotura de pantalla",
      queCubre:
        "Ampara el reembolso del 60% del gasto de reposición de la pantalla de tu dispositivo en caso de rotura.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "40% de los gastos",
    },
    {
      cobertura: "Daños al equipo",
      queCubre:
        "Ampara el reembolso del 50% del gasto de reparación o reposición de tu dispositivo derivado de daños por variación de voltaje, mojadura, incendio y/o explosión.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "50% de los gastos",
    },
    {
      cobertura: "Descompostura",
      queCubre:
        "Ampara el reembolso del 50% del gasto de reparación o reposición del dispositivo derivado de falla o descompostura.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "50% de los gastos",
    },
  ];

  const dinamicCoverages = (coverages, mobilitySelected) => {
    const coveragesInit = [] 
    coverages.forEach((cover) => {
      if (mobilitySelected === 2) {
        coveragesInit.push(cover)
      } else if (
        mobilitySelected === 1 &&
        cover.cobertura !== "Daños al equipo" &&
        cover.cobertura !== "Descompostura"
      ) {
        coveragesInit.push(cover)
      } else if (
        mobilitySelected === 0 &&
        cover.cobertura === "Robo con violencia"
      ) {
        coveragesInit.push(cover)
      }
    });
    return coveragesInit;
  };
  return (
    <div className="table-container">
      <p
        style={{ textAlign: "center", marginBottom: "10px", fontSize: "16px" }}
      >
        Este plan cubre:
      </p>
      <TableContainer sx={{width: ["100%", "125%"]}}>
        <Table aria-label="customized table" sx={{width: "100%"}}>
          <TableBody>
            {coverages.map((item) => (
              <>
                <TableRow >
                  <TableCell component="th" scope="row" align="left" sx={{border: "none"}}>
                    {`✅ ${item.cobertura}`}
                    <QueCubreModal
                      title={item.cobertura}
                      content={item.queCubre}
                    />
                    {item.valorCubierto}
                  </TableCell>
                  <TableCell align="right" sx={{border: "none"}}>
                    {`Lo que tú pagarías es el `}
                    {item.loQuePagas}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TablaCoberturaDispositivos;
