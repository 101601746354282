import { useSearchParams } from "react-router-dom";
import { Button, Container, Stack, Typography, Skeleton } from "@mui/material";
import { Share, WhatsApp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { fetchUser } from "../../Services/users";
import { getDocuments } from "../../Services/firestore";
import Header from "../../Components/Header";
import AccordionReferrals from "./AccordionReferrals";

function Refer(props) {
  const [searchParams] = useSearchParams();
  const userUID = searchParams.get("uid");
  const [user, setUser] = useState(undefined);
  const [vehicles, setVehicles] = useState(null);
  const [referrals, setReferrals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [amounts, setAmounts] = useState(null);
  const [table, setTable] = useState([
    {
      amount: 0,
      state: "Cotizaron su seguro ",
      guest: [],
    },
    {
      amount: 0,
      state: "Mostraron interés ",
      guest: [],
    },
    {
      amount: 0,
      state: "Están a un paso de contratar",
      guest: [],
    },
    {
      amount: 0,
      state: "Contrataron",
      guest: [],
    },
  ]);

  useEffect(() => {
    const calls = async () => {
      const user = await getUserInfo();
      await searchReferrals(user);
    };
    calls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUID]);

  const referURL = `https://auto-clupp.web.app/ref.html?uid=${userUID}`;

  const shareData = {
    title: "Seguro de movilidad Clupp",
    text: "Te invito a contratar tu seguro con Clupp",
    url: referURL,
  };

  // check if the browser supports tha share API
  const canShare = navigator.canShare && navigator.canShare(shareData);

  function handleShare() {
    if (canShare) {
      // open the native share menu
      return navigator.share(shareData);
    }
    // redirect to WhatsApp
    window.open(
      `https://wa.me/?text=${shareData.text} \n\n${shareData.url}`,
      "_blank"
    );
  }

  function earnings2(guest, amount) {
    if (guest.doc.period == "mensual") {
      return amount;
    }
    if (guest.doc.period == "trimestral") {
      return amount / 3;
    }
    if (guest.doc.period == "semestral") {
      return amount / 6;
    }
    if (guest.doc.period == "year") {
      return amount / 12;
    }
  }
  function earnings(vehicle, amount) {
    const mobility = vehicle.docs[0].doc.mobility;
    const totalExtras = amount.docs[0].doc.spPremium;
    const areExtrasCovered = amount.docs[0].doc.spCovered;
    const premium = amount.docs[0].doc[`premium${mobility}`];
    const earnings = areExtrasCovered ? premium + totalExtras : premium;
    if (earnings > 500) return 1200;
    if (earnings > 400) return 900;
    if (earnings > 300) return 600;
    if (earnings < 300) return 200;
  }

  async function getUserInfo() {
    try {
      const user = await fetchUser(userUID);
      setUser(user);
      return user;
    } catch (error) {
      setUser(undefined);
    }
  }
  async function searchReferrals(user) {
    try {
      let UIDS = [];
      const response = await getDocuments({
        collectionPath: `prospectsNew`,
        collectionGroupQuery: false,
        where: ["utm_source", "==", userUID],
      });
      const json = await response.json();
      // filter out the quotations if the email
      // is the same as the customer's email
      const filteredQuotations = json.docs.filter(
        ({ doc }) => doc?.email !== user?.email
      );

      setReferrals(filteredQuotations);
      UIDS = [...filteredQuotations];
      const routes = UIDS.map((item) => {
        return `prospectsNew/${item.id}/vehicles`;
      });
      const routes2 = UIDS.map((item) => {
        return `prospectsNew/${item.id}/quotationsRecord`;
      });

      let requests = routes.map((uid) =>
        getDocuments({
          collectionPath: uid,
          limit: 1,
          orderBy: ["timestamp", "desc"],
        })
      );

      let twoRequests = routes2.map((uid) => {
        return getDocuments({
          collectionPath: uid,
          limit: 1,
          orderBy: ["timestamp", "desc"],
        });
      });

      const responses = await Promise.all(requests);
      const vehicles = await Promise.all(responses.map((r) => r.json()));

      const quotations = await Promise.all(twoRequests);
      const quotationsJson = await Promise.all(quotations.map((r) => r.json()));
      filterData(filteredQuotations);
      setVehicles(vehicles);
      setAmounts(quotationsJson);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function filterData(json) {
    let newTable;
    if (json.length === 0) return;
    else {
      for (let item of json) {
        if (item.doc.stripeSuccess) {
          newTable = [...table];
          newTable[3].amount += 1;
          newTable[3].guest.push(item);
          continue;
        }
        if (item.doc.qStatus === 1) {
          newTable = [...table];
          newTable[0].amount += 1;
          newTable[0].guest.push(item);
        }
        if (item.doc.qStatus === 1.5 || item.doc.qStatus === 2) {
          newTable = [...table];
          newTable[1].amount += 1;
          newTable[1].guest.push(item);
        }
        if (item.doc.qStatus === 3 && !item.doc.stripeSuccess) {
          newTable = [...table];
          newTable[2].amount += 1;
          newTable[2].guest.push(item);
        }
      }
    }
    setTable(newTable);
  }

  return (
    <>
      <Header sx={{height: "70px"}}/>
      <Container
        maxWidth="sm"
        sx={{ display: "flex", flexDirection: "column", gap: 4, p: 4 }}
      >
        <Typography variant="h6" style={{ userSelect: "none" }}>
          Refiere y gana con Clupp
        </Typography>
        {isLoading ? (
          <>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="300px"
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="300px"
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="80px"
            />
          </>
        ) : user && userUID ? (
          <>
            <Stack spacing={2}>
              <Typography variant="subtitle1" style={{ userSelect: "none" }}>
                Gana hasta $1,200 por referido recomendando Clupp a tus familiares
                y amig@s. Usa tu beneficio en cientos de establecimientos y elige
                tu favorito. Conoce los establecimientos participantes en{" "}
                <a
                  rel="noreferrer"
                  href="https://clupp.tdr.tienda/"
                  style={{ color: "#00bbee" }}
                  target="_blank"
                >
                  nuestro catálogo
                </a>
                .
              </Typography>
            </Stack>

            <Stack alignItems="center" spacing={2}>
              <Typography variant="subtitle1" style={{ userSelect: "none" }}>
                {user.firstName}, comparte este QR personalizado, que es único
                para ti, para referir.
              </Typography>
              <img
                alt=""
                style={{ minWidth: "200px", maxWidth: "300px" }}
                src={`https://api.qrserver.com/v1/create-qr-code/?data=${referURL}&size=200x200`}
              />
            </Stack>

            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                style={{ userSelect: "none", width: "60%" }}
              >
                O bien, comparte tu liga única y personalizada para referir, dando
                clic aquí:
              </Typography>
              <Button
                startIcon={canShare ? <Share /> : <WhatsApp />}
                variant="outlined"
                disableElevation
                color={canShare ? "primary" : "success"}
                onClick={handleShare}
                sx={{
                  textTransform: "none",
                }}
              >
                Compartir
              </Button>
            </Stack>

            <Stack>
              <Typography
                variant="h6"
                align="center"
                color="black"
                style={{ fontWeight: "600" }}
              >
                ¡SIGUE EL ESTADO DE TUS REFERIDOS Y CONSIGUE GRANDES DESCUENTOS!
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                align="center"
                color="black"
                style={{ marginBottom: "2rem" }}
              >
                Has invitado a{" "}
                <Typography color="primary" style={{ display: "inline" }}>
                  {referrals.length}{" "}
                  {`${referrals.length === 1 ? "persona " : "personas "}`}
                </Typography>
                a contratar con Clupp
              </Typography>
              {vehicles.length === 0 ? (
                <div></div>
              ) : (
                <AccordionReferrals
                  table={table}
                  vehicles={vehicles}
                  earnings={earnings}
                  amounts={amounts}
                />
              )}
            </Stack>
          </>
        ) : (
          <Typography>
            Hubo un error al obtener tu información, contácta a soporte técnico.
          </Typography>
        )}
      </Container>
    </>
  );
}

export default Refer;
