export default function getTotalPremium(
  monthlyPremium,
  paymentPeriod,
  extras,
  options = { msi: false }
) {
  const { msi } = options;

  // Función para calcular el costo total de un extra considerando la cantidad
  const calculateExtraTotal = (extra) => {
    const quantity = extra.items?.[0]?.quantity || 1; // Si hay cantidad, usamos ese valor, de lo contrario, asumimos 1
    return extra.premium * quantity;
  };

  // Calcular la suma total de los extras
  const extrasTotal = extras.reduce((total, extra) => total + calculateExtraTotal(extra), 0);

  if (paymentPeriod === "year" && msi) {
    const discountPercentage = 6;
    const premium = monthlyPremium * 12;
    const premiumDiscount = Math.floor((discountPercentage * premium) / 100);
    const totalPremium = Math.ceil(premium - premiumDiscount) + extrasTotal;

    return [totalPremium, Math.round(premiumDiscount), premium];
  }

  switch (paymentPeriod) {
    case "month":
      return [monthlyPremium + extrasTotal, 0, monthlyPremium];
    case "3month": {
      const discountPercentage = 3;
      const premium = monthlyPremium * 3;
      const premiumDiscount = (discountPercentage * premium) / 100;
      const totalPremium = Math.ceil(premium - premiumDiscount) + extrasTotal;

      return [totalPremium, Math.round(premiumDiscount), premium];
    }

    case "6month": {
      const discountPercentage = 6;
      const premium = monthlyPremium * 6;
      const premiumDiscount = (discountPercentage * premium) / 100;
      const totalPremium = Math.ceil(premium - premiumDiscount) + extrasTotal;

      return [totalPremium, Math.round(premiumDiscount), premium];
    }

    case "year": {
      const discountPercentage = 10;
      const premium = monthlyPremium * 12;
      const premiumDiscount = Math.floor((discountPercentage * premium) / 100);
      const totalPremium = Math.ceil(premium - premiumDiscount) + extrasTotal;

      return [totalPremium, Math.round(premiumDiscount), premium];
    }

    default:
      return [0, 0, 0]; // Manejar otros casos si es necesario
  }
}