import { createTheme } from '@mui/material';

const cluppTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#00bbee',
      contrastText: 'rgba(253,252,252,0.87)',
    },
    secondary: {
      main: '#0058f4',
    },
    background: {
      default: '#F3F3F3',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FE7676',
      contrastText: '#fff',
    },
    success: {
      main: '#00CA7D',
      contrastText: '#fff',
    },
    darkBlue: {
      main: '#0058F4',
      contrastText: '#fff',
    },
  },
});

export default cluppTheme;
