const API =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/firestore/doc";

async function fetchUser(uid) {
  const url = new URL(API);

  url.searchParams.append("docPath", `users/${uid}`);
  try {
    const response = await fetch(url);
    const json = await response.json();
    if (!json.doc) {
      throw new Error("not/found");
    }
    return { ...json.doc, uid: json.id };
  } catch (error) {
    console.log(error);
    throw new Error("not/found");
  }
}

export default fetchUser;
