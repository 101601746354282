import { getDocuments } from "../../Services/firestore";
import postMessageInClaimsChannel from "../../Services/slack/postMessageInClaimsChannel";
import { addDocument } from "../../Services/firestore";

async function getAutosWithPhotos(vehicles, userUID) {
  const autos = vehicles.filter((vehicle) => vehicle.type == "auto");
  const otherTypeVehicles = vehicles.filter((vehicle) => {
    return (
      vehicle.type == "moto" ||
      vehicle.type == "bike" ||
      vehicle.type == "eBike" ||
      vehicle.type == "scooter" ||
      vehicle.type == "other"
    );
  });
  if (autos.length > 0) {
    const URLS = autos.map((auto) => {
      return getDocuments({
        collectionPath: `users/${userUID}/vehicles/${auto.id}/odometer`,
        orderBy: ["timestamp", "desc"],
        collectionGroupQuery: false,
        limit: 1,
      });
    });
    console.log(URLS);
    const responses = await Promise.all(URLS);
    const jsons = await Promise.all(responses.map((r) => r.json()));
    const odometherPhoto = jsons.map((json, index) => {
      return {
        ...autos[index],
        odometherPhoto: json?.docs?.[0]?.doc?.pictureURL,
      };
    });
    console.log(odometherPhoto);
    const filterautos = odometherPhoto.filter((filterVehicle) => {
      return (
        filterVehicle.pictureCarFront &&
        filterVehicle.pictureCarBack &&
        filterVehicle.pictureCarLeft &&
        filterVehicle.pictureCarRight &&
        filterVehicle.odometherPhoto
      );
      // else {
      //   let messageInClaimsAndComments = `El asegurado ${userUID} inició un reporte de robo para su vehículo ${filterVehicle.id} pero no tiene completas sus fotos.`;
      //   // postMessageInClaimsChannel({
      //   //   text: messageInClaimsAndComments,
      //   // });
      //   // addDocument(`users/${userUID}/comments`, {
      //   //   comment: `Mensaje automático: ${messageInClaimsAndComments}`,
      //   //   timestamp: Date.now(),
      //   // });
      // }
    });
    console.log(filterautos);
    return filterautos.concat(otherTypeVehicles);
  } else return vehicles;
}

export { getAutosWithPhotos };
