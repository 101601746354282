export const getDeviceName = (type) => {
    switch (type) {
      case "smartphone":
        return "celular"
      case "laptop":
        return "computadora"
      case "tablet":
        return "tableta"
        case "bike":
          return "bicicleta"
      default:
        return "dispositivo";
    }
  };


  export const getDeviceTitle = (type) => {
    switch (type) {
      case "smartphone":
        return "el celular"
      case "laptop":
        return "la computadora"
      case "tablet":
        return "la tableta"
      default:
        return "el dispositivo";
    }
  };

  export const getTypeName = (type) => {
    switch (type) {
      case "devices":
        return "dispositivos"
      case "smartphone":
        return "celular"
      case "laptop":
        return "computadora"
      case "tablet":
        return "tableta"
      case "bike":
        return "bicicleta"
      case "bikes":
        return "bicicletas";
      case "eBike":
        return "bicicleta eléctrica"
      case "eBikes":
        return "bicicletas eléctricas";
      case "otro":
        return "otro"
      case "scooter":
        return "monopatín"
      case "moto":
        return "motocicleta"
      case "auto":
        return "auto"
      default:
        return "auto";
    }
  };
  