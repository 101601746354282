export default function getEmergencyContactMessageTemplate({
  extraContent,
  user,
  address,
  instructions,
  apartment_number = undefined,
}) {
  const userNameText = `*Asegurado*: ${user.firstName || ""} ${
    user.lastName || ""
  }`;
  const userUIDText = `*UID*: <https://console.firebase.google.com/u/2/project/auto-clupp/firestore/data/~2Fusers/${user.uid}| *${user.uid}*>`;

  const userAddressNumber = Boolean(apartment_number)
    ? `*Número interior*: ${apartment_number}`
    : "";
  const userAddressText = `*Dirección de envío*: ${address}.\n*Referencias o instrucciones especiales*: ${instructions}\n${userAddressNumber}`;
  const notificationPreview = `Un asegurado acaba de dar de alta su contacto de emergencia.\n\n${userNameText}\n\n${userAddressText}`;
  let template = {
    text: notificationPreview,
    blocks: [
      {
        type: "header",
        text: {
          type: "plain_text",
          text: "Un asegurado acaba de dar de alta su contacto de emergencia.",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${userNameText}\n\n${userUIDText}\n\n${userAddressText}${
            extraContent ? `\n\n${extraContent}` : ""
          }`,
        },
      },
      {
        type: "divider",
      },
    ],
  };

  return template;
}
