import {
  Skeleton,
  Stack,
  Typography,
  Button,
  Box,
  Divider,
  FormControlLabel,
  Switch,
  Paper,
} from "@mui/material";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import useQuery from "../../hooks/useQueryParams";
import fetchUser from "../../Services/users/fetchUser";
import { getCustomerVehicles } from "../../Services/vehicles";
import { getInsuranceStatusMessage, isVehicleInsured } from "../../utils";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import Header from "../../Components/Header";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CarCrashIcon from "@mui/icons-material/CarCrash";

function deleteDuplicatedVehicles(vehicles = []) {
  const serialNumbers = {};
  const vehiclesToReturn = [];
  const sortVehicles = vehicles;
  sortVehicles.sort((a, b) => {
    const timestampA = new Date(
      a.vehicle.insuranceEndYear,
      a.vehicle.insuranceEndMonth,
      a.vehicle.insuranceEndDay
    ).getTime();
    const timestampB = new Date(
      b.vehicle.insuranceEndYear,
      b.vehicle.insuranceEndMonth,
      b.vehicle.insuranceEndDay
    ).getTime();

    return timestampB - timestampA;
  });
  sortVehicles.forEach((obj, index) => {
    if (!serialNumbers[obj.vehicle.serialNumber]) {
      vehiclesToReturn.push(obj);
      serialNumbers[obj.vehicle.serialNumber] = [index];
    }
  });
  return vehiclesToReturn;
}

const InsuranceStatus = (props) => {
  const [vehicles, setVehicles] = useState([]);
  const [isShowDeletedVehicles, setIsShowDeletedVehicles] = useState(false);
  const [deletedVehicles, setDeletedVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isClient, setIsClient] = useState(false);
  let navigate = useNavigate();
  const query = useQuery();
  const uid = query.get("uid");

  useEffect(() => {
    if (!uid) return;

    Promise.all([
      getCustomerVehicles(uid).then((vehicles) => {
        const activeVehicles = vehicles.filter(
          (vehicleDoc) => vehicleDoc.vehicle.deleted === false
        );
        const canceledVehicles = vehicles.filter(
          (vehicleDoc) => vehicleDoc.vehicle.deleted === true
        );
        setVehicles(activeVehicles);
        setDeletedVehicles(deleteDuplicatedVehicles(canceledVehicles));
      }),
      fetchUser(uid).then((userData) => {
        const { clientStatus } = userData;
        setIsClient(Boolean(clientStatus));
      }),
    ])
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire("Ups!", "Algo salió mal, inténtalo más tarde", "error");
        setIsClient(false);
      });
  }, [uid]);

  function handleRoute(vehicleid) {
    navigate(`/coberturas?userUID=${uid}&vehicleId=${vehicleid}`);
  }

  function handleRouteDigitalExp(vehicleid) {
    navigate(`/expediente-digital?uid=${uid}&vid=${vehicleid}`);
  }
  function handleShowDeletedVehicles(e) {
    if (e?.target?.checked) {
      setIsShowDeletedVehicles(e.target.checked);
    } else {
      setIsShowDeletedVehicles((prev) => !prev);
    }
  }
  return (
    <>
      <Header sx={{ height: "80px" }} />
      <Stack
        spacing={4}
        alignItems="center"
        p={2}
        sx={{ position: "relative" }}
      >
        {isLoading ? (
          <>
            <VehicleCardSkeleton />
            <VehicleCardSkeleton />
          </>
        ) : vehicles.length > 0 ? (
          vehicles.map(({ vehicle, id }) => (
            <VehicleCard
              key={id}
              vehicle={vehicle}
              handleRoute={handleRoute}
              isClient={isClient}
              id={id}
              handleRouteDigitalExp={handleRouteDigitalExp}
            />
          ))
        ) : (
          <Typography>
            No tienes ningún vehículo con seguro clupp activo
          </Typography>
        )}
        {deletedVehicles.length > 0 && (
          <Paper
            elevation={1}
            sx={{
              width: "100%",
              position: "sticky",
              top: "80px",
              zIndex: 100,
              padding: "0 14px",
              backgroundColor: "#fff",
            }}
          >
            <Divider sx={{ marginBottom: "10px" }}></Divider>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                "& .MuiFormControlLabel-root": { marginLeft: "0" },
              }}
            >
              <FormControlLabel
                sx={{
                  color: "#000",
                }}
                label={
                  <Box sx={{ display: "flex", gap: "12px" }}>
                    <CarCrashIcon
                      sx={{
                        color: "#FE7676",
                      }}
                    />
                    <Typography color={"#FE7676"}>
                      Ver vehículos no asegurados
                    </Typography>
                  </Box>
                }
                labelPlacement="start"
                control={
                  <Switch
                    sx={{
                      "& .Mui-checked": {
                        color: "#fff",
                      },

                      "& .Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#FE7676 !important",
                        background: "#FE7676",
                      },
                      "&.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#FE7676 !important",
                        background: "#FE7676",
                      },
                      "& .MuiSwitch-root": {
                        backgroundColor: "#FE7676",
                        background: "#FE7676",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: "#FE7676",
                        background: "#FFF",
                      },
                    }}
                    value={isShowDeletedVehicles}
                    onChange={handleShowDeletedVehicles}
                  />
                }
              />
            </Box>
          </Paper>
        )}
        {isShowDeletedVehicles &&
          deletedVehicles.map((vehicle, id) => (
            <>
              <VehicleCard
                key={id}
                vehicle={vehicle?.vehicle}
                handleRoute={handleRoute}
                isClient={false}
                id={id}
                handleRouteDigitalExp={handleRouteDigitalExp}
                isDeleted={true}
              />
            </>
          ))}
      </Stack>
    </>
  );
};

const VehicleCardSkeleton = (props) => (
  <Stack spacing={2}>
    <Skeleton
      animation="wave"
      sx={{ borderRadius: "10px" }}
      variant="rectangular"
      width={260}
      height={260}
    />
    <Stack spacing={1}>
      <Skeleton animation="wave" variant="text" height={32} />
      <Skeleton animation="wave" variant="text" height={24} />
    </Stack>
  </Stack>
);

const VehicleCard = ({
  vehicle,
  handleRoute,
  id,
  isClient,
  handleRouteDigitalExp,
  isDeleted = false,
}) => {
  const [isInsured, insuredStatus] = isVehicleInsured(vehicle);
  const insuranceStatusMessage = getInsuranceStatusMessage(vehicle);
  const whatsUrl = `https://wa.me/5215577460177?text=Hola%2C+quiero+reactivar+el+seguro+de+mi+${vehicle.brand}+${vehicle.model}+${vehicle.year}`;

  const insuranceStart =
    isInsured &&
    new Date(
      vehicle?.insuranceStartYear,
      vehicle?.insuranceStartMonth - 1,
      vehicle?.insuranceStartDay
    );

  const insuranceEnd =
    isInsured &&
    new Date(
      vehicle?.insuranceEndYear,
      vehicle?.insuranceEndMonth - 1,
      vehicle?.insuranceEndDay
    );
  const insuranceStartText = isInsured
    ? format(insuranceStart, "dd 'de' MMMM 'de' yyyy", {
        locale: es,
      })
    : "";
  const insuranceEndText = isInsured
    ? format(insuranceEnd, "dd 'de' MMMM 'de' yyyy", {
        locale: es,
      })
    : "";

  const vehiclePictureURL = vehicle?.pictureCarFront || "no-photo.png";

  const insuranceStatusText =
    isInsured && insuredStatus === "paid" ? "#00CA7D" : "error.main";

  function redirectWhats() {
    window.open(whatsUrl, "_blank");
  }

  return (
    <Stack
      spacing={2}
      sx={{
        maxWidth: "300px",
      }}
    >
      <Stack
        sx={{
          borderRadius: "10px",
          overflow: "hidden",
          maxHeight: "225px",
        }}
      >
        <img
          alt={`${vehicle?.brand} ${vehicle?.model} ${vehicle?.year}`}
          style={{
            objectFit: "cover",
            boxShadow: "black 0px 0px 1px",
            width: ["moto", "scooter", "bike", "eBike"].includes(vehicle?.type)
              ? "133%"
              : "100%",
            height: "100%",
            transform: ["moto", "scooter", "bike", "eBike"].includes(
              vehicle?.type
            )
              ? "rotate(90deg) translateY(50px) translateX(-25px)"
              : "",
          }}
          src={vehiclePictureURL}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h6" sx={{ userSelect: "none" }}>
          {vehicle?.brand} {vehicle?.model}{" "}
          {Boolean(vehicle?.year) && vehicle?.year}
        </Typography>

        <Typography
          sx={{
            fontWeight: 700,
            color: insuranceStatusText,
            userSelect: "none",
          }}
        >
          {insuranceStatusMessage}
        </Typography>
        {Boolean(vehicle?.version) && (
          <Typography sx={{ userSelect: "none" }}>
            Versión: {vehicle?.version}
          </Typography>
        )}

        {Boolean(vehicle?.plates) && (
          <Typography sx={{ userSelect: "none" }}>
            Placas: {vehicle?.plates}
          </Typography>
        )}
        {isInsured && (
          <>
            <Typography
              variant="body2"
              sx={{
                userSelect: "none",
                fontWeight: 500,
              }}
            >
              Inicio de vigencia: {insuranceStartText}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                userSelect: "none",
                fontWeight: 500,
              }}
            >
              Fin de vigencia: {insuranceEndText}
            </Typography>
          </>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {isClient && (
            <Button
              color="primary"
              variant="outlined"
              endIcon={<ViewTimelineIcon />}
              sx={{
                marginTop: "8px",
                alignSelf: "flex-end",
                textTransform: "none",
                width: "100%",
              }}
              onClick={() => {
                handleRouteDigitalExp(id);
              }}
            >
              Expediente digital
            </Button>
          )}
          {isClient && (
            <Button
              color="primary"
              variant="contained"
              sx={{
                marginTop: "8px",
                alignSelf: "flex-end",
                textTransform: "none",
                width: "100%",
              }}
              onClick={() => {
                handleRoute(id);
              }}
            >
              Ver coberturas
            </Button>
          )}
          {isDeleted && (
            <Button
              variant="contained"
              sx={{ background: "#25D366", textTransform: "none" }}
              onClick={redirectWhats}
              startIcon={<WhatsAppIcon />}
            >
              Reactivar seguro
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default InsuranceStatus;
